import { gql } from '@apollo/client/core';

const fragBullhorn = gql`fragment bullhorn on Bullhorn {
  id
  bullhorn
  favorite
}`;

export const findBullhorns = gql`
 ${fragBullhorn}
query($getfavourite: Boolean) {
  findBullhorns(getfavourite: $getfavourite) {
    ...bullhorn
    description
    category {
      id
      category
    }
  }
}`;

export const findBullhorn = gql`
 ${fragBullhorn}
query($id: Int!) {
  findBullhorn(id: $id) {
    ...bullhorn
    description
    category {
      id
      category
    }
    createdAt
    createdBy
  }
}`;

export const findEntityQuotes = gql`query($entityType: String!, $entityId: Int!) {
  findEntityQuotes(entityType: $entityType entityId: $entityId) {
    id
    author
    quote
  }
}`;

export const findChannels = gql`query($getfavourite: Boolean) {
  findChannels(getfavourite: $getfavourite) {
    id
    channel
    description
    favorite
    category {
      id
      category
    }
  }
}`;

export const findChannel = gql`query($id: Int!) {
  findChannel(id: $id) {
    id
    channel
    description
    favorite
    category {
      id
      category
    }
    createdAt
    createdBy
  }
}`;

export const findInterests = gql`query {
  findInterests {
    id
    interest
    favorite
    description
    category {
      id
      category
    }
  }
}`;

export const findInterest = gql`query($id: Int!) {
  findInterest(id: $id) {
    id
    interest
    description
    favorite
    category {
       id
       category
    }
    createdBy
    createdAt
  }
}`;

export const findValues = gql`query($getfavourite: Boolean) {
  findValues(getfavourite: $getfavourite) {
    id
    value
    description
    favorite
  }
}`;

export const findValue = gql`query($id: Int!) {
  findValue(id: $id) {
    id
    value
    description
    favorite
    createdBy
    createdAt
  }
}`;

export const mutateValue = gql`mutation ($input: ValueInput!) {
  mutateValue(input: $input) {
    id
    value
    description
    favorite
  }
}`;

export const dictionaryItems = gql` query{
  dictionaryItems {
    id
    term
    description
    source
    bullhorns {
      id
      bullhorn
   }
  }
}`;

export const dictionaryItem = gql` query($id: Int!){
  dictionaryItem(id: $id) {
    id
    term
    description
    source
    bullhorns {
      id
      bullhorn
   }
   createdBy
   createdAt
  }
}`;

export const mutateDictionaryItem = gql`mutation ($input: DictionaryInput!) {
  mutateDictionaryItem(input: $input) {
    id
    term
    description
    source
    bullhorns {
      id
      bullhorn
    }
    createdBy
    createdAt
  }
}`;

export const mutateBullhorn = gql`mutation ($input: BullhornInput!) {
  mutateBullhorn(input: $input) {
    id
    bullhorn
    description
  }
}`;

export const mutateChannel = gql`mutation ($input: ChannelInput!) {
  mutateChannel(input: $input) {
    id
    channel
    description
    favorite
  }
}`;

export const mutateEntityQuote = gql`mutation ($input: EntityQuoteInput!) {
  mutateEntityQuote(input: $input) {
    id
    author
    quote
  }
}`;

export const mutateInterest = gql`mutation ($input: InterestInput!) {
  mutateInterest(input: $input) {
    id
    interest
    description
    favorite
  }
}`;

export const mutateCategory = gql`mutation ($input: CategoryInput!) {
     mutateCategory(input: $input) {
        id
        categoryType
        category
     }
}`;

export const findCategories = gql`query ($type: String! ) {
    findCategories(type: $type) {
       id
       categoryType
       category
    }
}`;

export const findSegments = gql`query($entity: String!, $entityId : String!) {
  getSegments(entity: $entity entityId: $entityId) {
    id
    entity
    entityId
    segment
    cover
    description
    isActive
    createdAt
    updatedAt
  }
}`;

export const findSegment = gql`query($segmentId: String!) {
  getSegment(segmentId: $segmentId) {
    id
    entity
    entityId
    segment
    cover
    description
    isActive
    createdAt
    updatedAt
  }
}`;

export const mutateSegment = gql`mutation ($input: SegmentInput!) {
  addOrUpdateSegment(input: $input) {
    id
    entity
    entityId
    segment
    cover
    description
    isActive
    createdAt
    updatedAt
  }
}`;
