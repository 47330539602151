<template lang="pug">
app-main-content
    template(v-if="$route.path === '/manage-content'")
      .p-5.text-center.text-muted
        h6 Select the section from Menu to manage content
    template(v-else)
      router-view(v-slot="{ Component }")
        transition(name="fade" mode="out-in")
          component(:is="Component")
            //- views render here
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// import { useRouter, useRoute } from 'vue-router';
import { dashboard as menuItems } from '@/data/main-menu';

export default defineComponent({
  name: 'ManageContent',
  setup() {
    // moutned
    // re-routing the user - kinda hack to set default route
    // if (useRoute().path === '/manage-content') {
    //   useRouter().push('/manage-content/users');
    // }

    return {
      // data
      menuItems,
    };
  },
});
</script>
