<!-- eslint-disable max-len -->
<template lang="pug">
div
    div(v-for="item in stickers" @click="gotoStickerDetails(item.id)").row.border-bottom.py-2.purchase-box
      div.col-3.col-md-1.image-holder
        template(v-if="item.url")
          img(:src="item.url").img-fluid.list-image-item
          .pin.pin-bx.d-flex.p-2
            h6.mb-0.sticker-tag-text.ms-auto(v-if="item.campaignType != null")
              | {{ item.campaignType }}
      div.col-7.col-md-10.py-2.text-box
        .row.row-xs.align-items-center
          .col-auto
            img(
              v-if="item.entity==='User' && item.user && item.user.avatar"
              :src="item.user.avatar"
            ).d-block.size-24.obj-f-c.rounded-circle
            img(
              v-else-if="item.entity==='Organization' && item.organization && item.organization.avatar"
              :src="item.organization.avatar"
            ).d-block.size-24.obj-f-c.rounded-circle
            img(
              v-else
              src="@/assets/images/default-profile.jpeg"
            ).d-block.size-24.obj-f-c.rounded-circle
          .col.lh-1
            span(v-if="item.entity==='User' && item.user") {{ item.user.firstname }} {{ item.user.lastname }}
            span(v-else-if="item.entity==='Organization' && item.organization") {{ item.organization.name }}
            span(v-else) Spotlight
        div
          h6.mb-0 {{ item.title }}
        div
          .fs-14.lh-2.el-button.el-button--text
            span
              span(v-if="item.cost == 0") FREE
              span(v-else) ${{ item.cost.toFixed(2) }}
      div.col-1.icon-box
        img(src="@/assets/icons/chevron-right.svg",
            style="margin-left:15px;cursor: pointer; height: 40px;"
          )
</template>

<script lang="ts">
import {
  defineComponent, onMounted,
} from 'vue';
import gqlRequest from '../dashboard/gql-request';

export default defineComponent({
  name: 'WebAppStickersListTemplate',
  props: {
    stickers: { type: Array, default: () => [{}] },
  },
  setup() {
    const {
      route, router, loading,
    } = gqlRequest();

    function gotoStickerDetails(stickerId: any) {
      router.push(`/web/sticker/${stickerId}`);
    }

    onMounted(async () => {
      loading.value = true;
      loading.value = false;
    });

    return {
      route,
      router,
      loading,
      // data
      gotoStickerDetails,
    };
  },
});
</script>
<style scoped>
.list-image-item{
  max-width: 100%;
  max-height: 100%;
}
.image-holder{
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
}
.icon-box{
  display: flex;
  align-items: center;
}
.border-bottom{
  border-bottom: 1px solid grey;
}
.purchase-box{
  height: 120px;;
}
</style>