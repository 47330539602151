import {
  findStickers,
  mutateSticker,
  drawSweepstakesWinners,
  getSweepstakesWinners,
  mutateRemoveSticker,
  findSticker,
  findBullhorns,
  getMedia,
  getAllMedia,
  mutateMedia,
  mutateRemoveMedia,
  benevityCauses,
  findAllOrganizations,
  findAllSpots,
  getCMSHomeScreenData,
  mutateCMSHomeScreenData,
  mutateStickerStatus,
  postGiftSticker,
  sendSweepstakesLoserEmail,
} from '@/graphs/gql';
import { benevityCauseDetails } from '@/graphs/payment';
import { getStickerInsights, mutateStickerVarient } from '@/graphs/stickers';
import globalShared from '@/shared';

export default function commonShared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    removeItemGetConfirm,
    makeGraphRequest,
    getAuthToken,
    dateAndTime,
    preSignedUrl,
  } = globalShared();

  async function getStickers(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findStickers, {}, getAuthToken(),
    );
    return response.data.findStickers;
  }

  async function getSticker(id: number): Promise<any> {
    const response = await makeGraphRequest(
      'query', findSticker, { id }, getAuthToken(),
    );
    return response.data.findSticker;
  }

  async function updateSticker(input: any) {
    await makeGraphRequest(
      'mutation', mutateSticker, { input }, getAuthToken(),
    );
  }

  async function sendLoserEmails(stickerId: any) {
    await makeGraphRequest(
      'mutation', sendSweepstakesLoserEmail, { stickerId },
    );
}

  async function drawWinner(sweepstakesStickerId: any, participantId: any) {
    await makeGraphRequest(
      'mutation',
      drawSweepstakesWinners,
      { 
        sweepstakesStickerId, 
        participantIds: participantId ? participantId : []
      }
    );
  }
  
  async function getWinners(sweepstakesStickerId: any) {
    const response = await makeGraphRequest(
      'mutation',
      getSweepstakesWinners,
      { 
        sweepstakesStickerId,
      }
    );
    return response.data.getSweepstakesWinners;
  }

  async function approveSticker(input: any) {
    await makeGraphRequest(
      'mutation', mutateStickerStatus, { input }, getAuthToken(),
    );
  }

  async function removeSticker(id: number) {
    await makeGraphRequest(
      'mutation', mutateRemoveSticker, { id }, getAuthToken(),
    );
  }

  async function findMedia(id: string) {
    const response = await makeGraphRequest(
      'query', getMedia, { id }, getAuthToken(),
    );
    return response.data.getMedia;
  }

  async function findAllMedia() {
    const response = await makeGraphRequest(
      'query', getAllMedia, {}, getAuthToken(),
    );
    return response.data.getAllMedia;
  }

  async function doMutateMedia(input: any) {
    const response = await makeGraphRequest(
      'mutation', mutateMedia, { input }, getAuthToken(),
    );
    return response.data.mutateMedia;
  }

  async function removeMedia(id: string) {
    const response = await makeGraphRequest(
      'mutation', mutateRemoveMedia, { id }, getAuthToken(),
    );
    return response.data.mutateMedia;
  }

  async function getBullhornsAsTags(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findBullhorns, { getfavourite: false }, getAuthToken(),
    );
    return response.data.findBullhorns;
  }

  async function getcausesAsDropdown(query: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', benevityCauses, { q: query, page: '1' }, getAuthToken(),
    );
    return response.data.benevityCauses.causes;
  }

  async function getBenevityCauseDetails(causeId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', benevityCauseDetails, { causeId }, getAuthToken(),
    );
    return response.data.benevityCauseDetails;
  }

  async function getAllOrganizations(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findAllOrganizations, {}, getAuthToken(),
    );
    return response.data.findAllOrganizations;
  }

  async function getAllSpots(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findAllSpots, {}, getAuthToken(),
    );
    return response.data.findAllSpots;
  }

  async function fetchCMSHomeScreenData(): Promise<any> {
    const response = await makeGraphRequest(
      'query', getCMSHomeScreenData, {}, getAuthToken(),
    );
    return response.data.getCMSHomeScreenData;
  }

  async function saveCMSHomeScreenData(input: any) {
    const response = await makeGraphRequest(
      'mutation', mutateCMSHomeScreenData, { input }, getAuthToken(),
    );
    return response.data.mutateCMSHomeScreenData;
  }

  async function saveStickerStatusBulk(input: any) {
    const response = await makeGraphRequest(
      'mutation', mutateStickerStatus, { input }, getAuthToken(),
    );
    return response.data.mutateStickerStatus;
  }

  async function MutateStickerVarient(input: any) {
    await makeGraphRequest(
      'mutation', mutateStickerVarient, { input }, getAuthToken(),
    );
  }

  async function findStickerInsights(stickerId: any): Promise<any> {
    const response = await makeGraphRequest(
      'query', getStickerInsights, { stickerId }, getAuthToken(),
    );
    return response.data.getStickerInsights;
  }

  async function giftSticker(input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation', postGiftSticker, { input }, getAuthToken(),
    );
    return response.data;
  }

  return {
    ref,
    route,
    router,
    loading: ref(false),
    dialog: ref(false),
    removeItemGetConfirm,
    makeGraphRequest,
    preSignedUrl,
    getStickers,
    getSticker,
    updateSticker,
    drawWinner,
    getWinners,
    removeSticker,
    getAuthToken,
    dateAndTime,
    findMedia,
    findAllMedia,
    doMutateMedia,
    removeMedia,
    getBullhornsAsTags,
    findBullhorns,
    getcausesAsDropdown,
    benevityCauses,
    getBenevityCauseDetails,
    getAllOrganizations,
    getAllSpots,
    fetchCMSHomeScreenData,
    saveCMSHomeScreenData,
    saveStickerStatusBulk,
    MutateStickerVarient,
    findStickerInsights,
    approveSticker,
    giftSticker,
    sendLoserEmails,
  };
}
