import { gql } from '@apollo/client/core';

const fragSticker = gql`fragment sticker on Sticker {
   id
   title
   url
   description
   entity
   entityId
   user {
     id
     firstname
     lastname
     avatar
   }
   organization {
    id
    name
    avatar
    email
    phone
   }
   published
   tags
   cost
   campaignType
   causes
   isCausesSearchAllowed
   nonProfitSuggestions
   maxQuantity
   maxDonation
   donationMultiplier
   impact
   eventDate
   eventDateTimeZone
   startDate
   startDateTimeZone
   endDate
   endDateTimeZone
   seen
   isSpotlight
   createdAt
   isFeatured
   location
   waiverURL
   status
   varients {
    id
    stickerId
    title
    cost
    description
    sortOrder
    isActive
   }
   confirmationEmail
   reminderEmail
   hasOptions
   quickCosts
   glow {
    glowStartDate
    glowEndDate
    glowColor
    glowStartDateTimeZone
    glowEndDateTimeZone
   }
   sweepstakesStickerId
   sweepstakesSticker {
    aboutTheCause
    numberOfPrizesAndWinners
    prizes
    nonProfitBeneficiary
    drawingDateTime
    drawingDateTimeTimezone
    experienceDateTime
    experienceDateTimeTimezone
    approximateRetailValue
    sweepstakesConfirmation
    sweepstakesWinnerMessage
    sweepstakesLoserMessage
    checkoutPageLegal
    entries
    entryCost
  },
  isWinnerConfirmed,
}`;

const fragSweepstakeWinners = gql`
  fragment sweepstakeWinners on SweepstakeWinners {
    id
    stickerId
    entityId
    participantName
    participantAvatar
    eligible
    dateCreated
    dateUpdated
  }
`;

export const findStickers = gql`
${fragSticker}
query {
  findStickers {
     ...sticker
  }
}`;

export const findOrgStickers = gql`
${fragSticker}
query($orgId: String!) {
  findOrgStickers(orgId: $orgId) {
     ...sticker
  }
}`;

export const findPublishedStickers = gql`
${fragSticker}
query {
  findPublishedStickers {
     ...sticker
  }
}`;
export const findPublishedStickersWithFiltering = gql`
${fragSticker}
query($filter: [String]!) {
  findPublishedStickers (filter: $filter) {
     ...sticker
  }
}`;

export const findPurchasedStickers = gql`
${fragSticker}
query($entity: String! $entityId: String!) {
  findPurchasedStickers(entity: $entity, entityId: $entityId) {
     ...sticker
   }
}`;

export const findSticker = gql`
${fragSticker}
query($id: String!) {
   findSticker(id: $id) {
     ...sticker
     creatorTitle
   }
}`;

export const findOrgSticker = gql`
${fragSticker}
query($orgId: String! $id: String!) {
   findOrgSticker(orgId: $orgId, id: $id) {
     ...sticker
     creatorTitle
   }
}`;

export const mutateSticker = gql`
${fragSticker}
mutation($input: StickerInput!) {
   mutateSticker(input: $input) {
     ...sticker
   }
}`;

export const sendSweepstakesLoserEmail = gql`
mutation($stickerId: UUID!) {
   sendSweepstakesLoserEmail(stickerId: $stickerId)
}`;

export const drawSweepstakesWinners = gql`
  ${fragSweepstakeWinners}
  mutation($sweepstakesStickerId: UUID!, $participantIds: [UUID!]) {
    drawSweepstakesWinners(sweepstakesStickerId: $sweepstakesStickerId, participantIds: $participantIds) {
      ...sweepstakeWinners
    }
  }
`;

export const getSweepstakesWinners = gql`
  ${fragSweepstakeWinners}
    mutation($sweepstakesStickerId: UUID!) {
    getSweepstakesWinners(sweepstakesStickerId: $sweepstakesStickerId) {
      ...sweepstakeWinners
    }
  }`;

export const mutateRemoveSticker = gql`
mutation($id: String!) {
  mutateRemoveSticker(id: $id)
}`;

export const mutateOrgSticker = gql`
${fragSticker}
mutation($orgId: String!, $input: StickerInput!) {
   mutateOrgSticker(orgId: $orgId, input: $input) {
     ...sticker
   }
}`;

export const mutateRemoveOrgSticker = gql`
mutation($orgId: String!, $id: String!) {
  mutateRemoveOrgSticker(orgId: $orgId id: $id)
}`;

export const findAllSpots = gql`
query {
  findAllSpots {
    id
    title
    description
    coverMediaUrl
    coverMediaType
    thumbnailUrl
    entity
    sortOrder
    organization{
      name
      avatar
    }
    user{
      firstname
      lastname
      avatar
    }
    moodboards{
      id
      title
      metadata
      url
    }
   }
}`;

export const getCMSHomeScreenData = gql`
query {
  getCMSHomeScreenData {
    id
    orgCollectionTitle
    stickerCollectionTitle
    spotCollectionTitle
    metaCarousel {
      id
      title
      image
      order
    }
    metaFeaturedOrgs {
      id
      title
      image
      order
    }
    metaFeaturedStickers {
      id
      title
      image
      order
    }
    metaFeaturedSpots {
      id
      title
      image
      order
    }
  }
}`;

export const mutateCMSHomeScreenData = gql`
mutation($input: HomeScreenDataInput!) {
  mutateCMSHomeScreenData(input: $input) {
    id
    orgCollectionTitle
    stickerCollectionTitle
    spotCollectionTitle
    metaCarousel {
      id
      title
      image
      order
    }
    metaFeaturedOrgs {
      id
      title
      image
      order
    }
    metaFeaturedStickers {
      id
      title
      image
      order
    }
    metaFeaturedSpots {
      id
      title
      image
      order
    }
  }
}`;

export const isStickerPurchased = gql`
query($entity: String! $entityId: String! $stickerId: String!) {
  isStickerPurchased(entity: $entity, entityId: $entityId, stickerId: $stickerId)
}`;

export const mutateStickerStatus = gql`
mutation($input: [StickerStatusInput!]!) {
  mutateStickerStatus(input: $input) {
    id
    status
  }
}`;

export const mutateStickerVarient = gql`
mutation($input: StickerVarientInput!) {
  mutateStickerVarient(input: $input) {
    id
    isActive
  }
}`;

export const getStickerInsights = gql`
query($stickerId: String!) {
  getStickerInsights(stickerId: $stickerId) {
    totalRevenue
    unitsSold
    purchasedUsersCount
    varients {
      id
      stickerId
      title
      cost
      description
      sortOrder
      isActive
      totalRevenue
      unitsSold
    }
    causeSummary {
      key
      value
    }
    benevityCausesName
  }
}`;

export const postGiftSticker = gql`
mutation($input: GiftStickerInput!) {
  giftSticker(input: $input)
}`;
