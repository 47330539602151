<template lang="pug">
app-main-content-layout(title='Events')
  template(#actions)
    el-button(type="primary" @click="dialog = true").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Add New

  div
    PaymentLogsTabs
    el-table(
      :data="items"
      stripe
      v-loading="loading"
      @row-click="$router.push(`events/${$event.id}`);"
      style="width: 100%"
    ).clickable-rows
      el-table-column(width="15")
        // space

      el-table-column(width="70")
        template(#default="scope")
          img(:src="scope.row.img").size-50px.obj-f-c
      el-table-column(prop="title" label="Title" min-width="200" sortable)
      el-table-column(prop="eventType" label="Type" min-width="200" sortable)
      el-table-column(prop="date" label="Date" min-width="200" sortable)

      el-table-column(width="46")
        .el-table__expand-icon
          img(src="@/assets/icons/light/chevron-right-light.svg")

      el-table-column(width="15")
        // space

    dialog-add-event(
      v-if="dialog"
      :loading="loading"
      v-model="dialog"
      @save-item="saveItem"
      @close-dialog="dialog = false"
    )
</template>

<script>
import { defineComponent, onMounted } from 'vue';
import DialogAddEvent from './DialogAddEvent.vue';
import PaymentLogsTabs from '@/components/PaymentLogsTabs.vue';
import shared from './shared';

export default defineComponent({
  name: 'ManageContent_Events',
  components: { DialogAddEvent, PaymentLogsTabs },
  setup() {
    const {
      loading,
      dialog,
      ref,
      getEvents,
      updateEvent,
    } = shared();

    const items = ref([]);

    async function getItems() {
      try {
        loading.value = true;
        items.value = await getEvents();
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    async function saveItem(input) {
      await updateEvent(input);
      await getItems();
    }

    onMounted(async () => {
      await getItems();
    });

    return {
      items,
      loading,
      dialog,

      // methods
      saveItem,
    };
  },
});
</script>
