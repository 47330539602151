<template lang="pug">
app-main-content-layout(v-if="isPending" title='Organizations (Pending)')
app-main-content-layout(v-if="!isPending" title='Organizations')

app-main-content-layout()
  div
    div.filter-container
      span.padding-left-20(@click="headerFilter('All')"
        :class="getClass('All')") All
      span.padding-left-20(@click="headerFilter('Pending')"
        :class="getClass('Pending')") Pending
      span.padding-left-20(@click="headerFilter('Approved')"
        :class="getClass('Approved')") Approved
      span.padding-left-20(@click="headerFilter('Declined')"
        :class="getClass('Declined')") Declined
      span.padding-left-20(@click="headerFilter('Archived')"
        :class="getClass('Archived')") Archived
      span.padding-left-20(@click="headerFilter('Invited')"
        :class="getClass('Invited')") Invited

    .row.row-sm.align-items-center
      .col
      el-input(
        v-model="textFilter"
        placeholder="Search organizatons"
        suffix-icon="el-icon-search"
              style="width: 250px;"
              @change="searchFilter()"
            ).input-search-filter
          template(#suffix='')
            img(
              src="@/assets/icons/searchIcon.svg",
              style="font-size: 20px;")
      .col-auto()
         el-button(type="info" v-show="isFiltered" @click="clearTextFilter").has-fai
          fai(type="fal" icon="times").size-18.me-2
          span.min-w-110.d-inline-block Clear Filter
      .col-auto()
          VueMultiselect(
            v-model="sortBy",
            :multiple="false",
            :options="sortOptions",
            :searchable="false",
            :close-on-select="true",
            :show-labels="false"
            label="label"
            placeholder="Sort"
            @close="sortTable"
            style="width: 180px;"
          )

    el-table(
      :data="pagedTableData"
      stripe
      v-loading="loading"
      @row-click="routeToOrg($event)"
      style="width: 100%"
    ).clickable-rows.py-20
      el-table-column(width="15")
      el-table-column(label="Name" min-width="350")
        template(#default="scope")
          .lh-sm
            .fw-b {{ scope.row.name }}
            .fs-12 {{ scope.row.title }}

      el-table-column(prop="location" label="Onboarded" min-width="100")
        template(#default="scope") {{ showOnboardedStatus(scope.row) }}

      el-table-column(prop="location" label="Approved" min-width="250" v-if="isPending")
        template(#default="scope") {{ scope.row.approved? 'YES': 'NO' }}

      el-table-column(prop="email" label="Email" min-width="250")
        template(#default="scope") {{ scope.row.email }}

      el-table-column(prop="location" label="active" min-width="100")
        template(#default="scope") {{ showActiveStatus(scope.row) }}

     

      el-table-column(width="15")
          // space

    div.py-20.justify-center
      el-pagination(
        v-model:current-page="page"
        layout='prev, pager, next'
        :total="items.items.length"
        @current-change="setPage"
      )

  dialog-add-user(
    v-if="dialog"
    v-model="dialog"
    :sending-invite="sendingInvite"
    @save-item="organizationInvite"
    :server-error="serverError"
    @close-dialog="dialog=false"
  )
</template>

<script>

import {
  defineComponent, ref,
  onMounted, reactive,
  computed,
} from 'vue';
import { useRoute } from 'vue-router';
import VueMultiselect from 'vue-multiselect';
import shared from './shared';
import DialogAddUser from './DialogAddUser.vue';

export default defineComponent({
  name: 'ManageContent_Users',
  components: { DialogAddUser, VueMultiselect },
  setup() {
    const {
      router,
      loading,
      getAllOrganizations,
      createOrganizationInvite,
      doGetInvites,
      getOrganizationsV2,
    } = shared();
    const route = useRoute();
    const filter = ref('');
    const items = reactive({ items: [] });
    const invited = reactive({ items: [] });
    const allItems = reactive({ items: [] });
    const textFilter = ref('');
    const isFiltered = ref(false);
    const serverError = ref('');
    const sendingInvite = ref(false);
    const dialog = ref(false);
    const isPending = route.name === 'ManagePendingOrganizations';
    const test = 'pending';
    const sortBy = ref('');
    const sortOptions = [{ label: 'Name', value: 'name' }, { label: 'Email', value: 'email' }];

    const currentView = ref('All');
    const baseInput = reactive({
      ids: null,
      entity: null,
      entityIds: null,
      isActive: null,
      statuses: null,
      query: null,
      page: 1,
      pageSize: -1,
    });

    const page = ref(1);
    const pageSize = 10;
  

    function routeToOrg(itm) {
      if (itm.onboarded) {
        router.push(`/manage/orgs/${itm.id}/edit`);
      } else {
        router.push(`/manage/invites/${itm.id}/info`);
      }
    }

    async function organizationInvite(item) {
      try {
        loading.value = true;
        sendingInvite.value = true;
        await createOrganizationInvite(item);
        dialog.value = false;
        items.items = await getAllOrganizations();
      } catch (err) {
        serverError.value = err.message;
      } finally {
        loading.value = false;
        sendingInvite.value = false;
      }
    }

    function showOnboardedStatus(item) {
      return item.onboarded ? 'Onboarded' : '--';
    }

    function showActiveStatus(item) {
      return item.active ? 'Yes' : 'No';
    }
    

    function getClass(currentItem) {
      if (currentItem === currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }
    function sortTable() {
      if (sortBy.value.value) {
        // eslint-disable-next-line max-len
        items.items = items.items.sort((a, b) => a[sortBy.value.value].localeCompare(b[sortBy.value.value]));
      }
    }

    async function findInvites() {
      try {
        loading.value = true;
        invited.items = await doGetInvites();
        loading.value = false;
      } catch (err) {
        console.log(err);
      }
    }

    async function findOrganizationsV2() {
      try {
        loading.value = true;
        allItems.items = await getOrganizationsV2(baseInput);
        items.items = allItems.items;
        loading.value = false;
      } catch (err) {
        console.log(err);
      }
    }

   //________function that filters invites by entity ________
    function filterInvites(){
      items.items = invited.items.filter((item) => item.entity === 'Organization');
    }

    //________create function for pagination datatable________
    const pagedTableData = computed(() => {
      const start = (page.value - 1) * pageSize;
      const end = start + pageSize;
      return items.items.slice(start, end);
    })
    const setPage = (newPage) => {
      page.value = newPage;

    } 

   

  //________create function for search filter ________
    async function searchFilter() {
      baseInput.ids = null;
      baseInput.entity = null;
      baseInput.entityIds = null;
      baseInput.isActive = null;
      baseInput.statuses = currentView.value;
      baseInput.page = 1;
      if (textFilter.value === '') {
        isFiltered.value = false;
        if (currentView.value === 'Invited') {
        filterInvites()
        }
        else {
          await findOrganizationsV2();
        }
      }
      else{
        isFiltered.value = true;
      const regex = new RegExp(`${textFilter.value}`, 'gi');
      items.items = items.items.filter((x) => {
        if (x.name.match(regex) 
          || x.email.match(regex)) {
          return true;
        }
        return false;
      });
      
      }
    }

    async function clearTextFilter() {
      textFilter.value = '';
      isFiltered.value = false;
      if (currentView.value === 'Invited'){
        filterInvites()
      }
      else{
      await findOrganizationsV2();
    }
  }


    async function headerFilter(filterBy) {
      currentView.value = filterBy;

      baseInput.ids = null;
      baseInput.entity = null;
      baseInput.entityIds = null;
      baseInput.isActive = null;
      baseInput.query = null;
      baseInput.page = 1;

      if (filterBy === 'Invited') {
        filterInvites()
      } else {
        baseInput.statuses = filterBy;
        await findOrganizationsV2();
      }
    }

    onMounted(async () => {
      // await findOrganizations(route.name);
      await findInvites();
      if (route.name === 'ManagePendingOrganizations') {
        currentView.value = 'Pending';
        baseInput.statuses = 'Pending';
      }
      await findOrganizationsV2();
    });

    return {
      // data (static)
      loading,
      filter,
      isPending,
      test,
      // data (reactive)
      items,
      serverError,
      dialog,
      sendingInvite,
   
      getClass,
      headerFilter,
      currentView,
      invited,
      allItems,
      textFilter,
      isFiltered,
      page,
      pageSize,
      pagedTableData,
      // methods
      setPage,
      showOnboardedStatus,
      showActiveStatus,
      organizationInvite,
      routeToOrg,
      findInvites,
      searchFilter,
      clearTextFilter,
      sortBy,
      sortTable,
      sortOptions,
      baseInput,
      filterInvites,
    };
  },
});
</script>
