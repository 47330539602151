const getters = {
  GET_SORT: (state) => {
    return state.sort;
  },
  GET_DATE: (state) => {
    return state.date;
  },
  GET_SEARCH: (state) => {
    return state.search;
  },
  GET_LOGS: (state) => {
    return state.logs;
  },
  GET_LOGS_LOADING: (state) => {
    return state.logsLoading;
  },
  GET_LOGS_ERROR: (state) => {
    return state.logsError;
  },
  GET_SELECTED_LOGS: (state) => {
    return state.selectedLogs;
  }
}

export default getters
