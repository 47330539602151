<template lang="pug">
el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(title='Dictionary' back-to='/manage-content/directory/dictionary')
    template(#actions)
      el-button(type="danger" plain @click="removeDictionaryItem").has-fai
        fai(type="fal" icon="trash").size-18.me-2
        span.min-w-110.d-inline-block Delete

      el-button(type="primary" @click="validateForm").has-fai
        fai(type="fal" icon="edit").size-18.me-2
        span.min-w-110.d-inline-block Save

    template(#col-left)
      div
        el-form-item(label="Term" prop="term")
          el-input(v-model="item.term")

        el-form-item(label="Bullhorns", prop="bullhornId")
          el-select(v-model="item.bullhornIds"  placeholder="Select" multiple).w-100
            el-option(
              v-for="lookups in bullhorns.bullhorns"
              :key="lookups.id"
              :label="lookups.bullhorn"
              :value="lookups.id"
            )

        el-form-item(label="Description" prop="description")
          el-input(v-model="item.description" type="textarea")

        el-form-item(label="source" prop="source")
          el-input(v-model="item.source")

    template(#col-right)
      div
        el-form-item(label="Creator")
          el-input(v-model="item.creator" disabled)

        el-form-item
          template(#label)
            | Post Date
            span.text-muted.ms-2 Active for 3 Days
          el-input(v-model="item.postDate" disabled)

        el-form-item(label="Post Time")
          el-input(v-model="item.postTime" disabled)
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
} from 'vue';

import { requiredRule } from '@/data/rules';
import shared from './shared';

export default defineComponent({
  name: 'ManageContent_Directory_DictionaryDeail',
  setup() {
    const {
      route,
      router,
      loading,
      getBullhorns,
      getDictionaryItem,
      saveDictionaryItem,
      removeItemGetConfirm,
      dateAndTime,
    } = shared();

    const item = reactive({
      id: -1,
      term: '',
      description: '',
      bullhornIds: '',
      source: '',
      creator: '',
      postDate: '',
      postTime: '',
    });

    const refForm = ref(null);
    const { id } = route.params;
    const bullhorns = reactive({ bullhorns: [] });

    async function getItem() {
      loading.value = true;
      const itm = await getDictionaryItem(parseInt(id, 10));
      item.id = itm.id;
      item.term = itm.term;
      item.bullhornIds = itm.bullhorns.map((b) => b.id);
      item.source = itm.source;
      item.creator = itm.createdBy;
      item.description = itm.description;
      [item.postDate, item.postTime] = dateAndTime(itm.createdAt);
      loading.value = false;
    }

    async function saveItem() {
      await saveDictionaryItem({
        id: parseInt(item.id, 10),
        term: item.term,
        description: item.description,
        source: item.source,
        bullhornIds: item.bullhornIds,
      });
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        console.log(valid);
        if (valid) return saveItem();
        console.log('error submit!!');
        return false;
      });
    }

    async function removeItem() {
      try {
        await saveDictionaryItem({
          id: parseInt(item.id, 10),
          delete: true,
        });
      } catch (err) {
        console.log('err', err);
      }
      router.push('/manage-content/directory/dictionary');
    }

    function removeDictionaryItem() {
      removeItemGetConfirm(removeItem);
    }

    onMounted(async () => {
      bullhorns.bullhorns = await getBullhorns();
      await getItem();
    });

    return {
      // data
      refForm,
      rules: {
        term: requiredRule('term'),
        description: requiredRule('description'),
        source: requiredRule('source'),
      },
      item,
      bullhorns,

      // methods
      validateForm,
      removeDictionaryItem,
    };
  },
});
</script>
