<template lang="pug">
list-view(
  :items='myCMSHomeScreenData.metaFeaturedOrgs'
  :loading='loading')
  template(#action)
      el-button(type='success' color="#303133"  @click='dialog = true' v-bind:disabled = 'loading')

        img(src="@/assets/icons/plusWhite.svg"
        style="width: 35px; height: 20px; margin-right: 5px;").me-1.pe-2
        | Add
  template(#dialog)
    dialog-add-to-featured-items(
      v-model="dialog"
      title="SEARCH ORG"
      :items='items'
      :featuredItems='myCMSHomeScreenData.metaFeaturedOrgs'
      @save-item="saveItem"
      @close-dialog="dialog = false"
    )
</template>

<script>
import {
  defineComponent, reactive, onMounted, toRef, onBeforeMount,
} from 'vue';
import shared from '../../shared/index';

// import { orgs } from '@/data/static-home-page';

import ListView from './ListView.vue';
import DialogAddToFeaturedItems from './DialogAddToFeaturedItems.vue';

export default defineComponent({
  name: 'HomePage_Spots',
  props: {
    myCMSHomeScreenData: { type: Object, default: () => ({}) },
  },
  components: {
    DialogAddToFeaturedItems,
    ListView,
  },

  setup(props) {
    const {
      getAllOrganizations,
    } = shared();
    const state = reactive({
      items: [],
      dialog: false,
      loading: true,
    });

    // dummy promise function
    // const getItems = () => new Promise((resolve) => setTimeout(() => resolve(orgs), 500));
    async function getItems() {
      const organizations = await getAllOrganizations();
      const orgs = [];
      if(organizations != null){
        organizations.forEach((x) => {
          const org = {
            id: x.id,
            title: x.name,
            image: x.avatar,
            order: 0,
            featured: false,
          };
          orgs.push(org);
        });
      }

      return orgs;
    }

    const handleRemove = (item) => {
      // eslint-disable-next-line no-param-reassign
      item.featured = false;

      // if we are using db then remove from JSON
      // state.items.splice(state.items.indexOf(item), 1);

      // const index = item.value.conditionGrouping.conditions.indexOf(element);
      // item.value.conditionGrouping.conditions.splice(index, 1);
    };

    const saveItem = () => {
      console.log('saveItem', props);
    };

    onBeforeMount(async () => {
      state.loading = true;
      state.items = await getItems();
      state.loading = false;
    });

    return {
      items: toRef(state, 'items'),
      dialog: toRef(state, 'dialog'),
      loading: toRef(state, 'loading'),
      handleRemove,
      saveItem,
    };
  },
});

</script>
