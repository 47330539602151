<template lang="pug">
app-main-content-layout(title='Users')
  div
    div.filter-container
      span.padding-left-20(@click="headerFilter('ALL')"
      :class="getClass('ALL')") All
      span.padding-left-20(@click="headerFilter('active')"
        :class="getClass('active')") Active
      span.padding-left-20(@click="headerFilter('archived')"
        :class="getClass('archived')") Archived
      span.padding-left-20(@click="headerFilter('invited')"
        :class="getClass('invited')") Invited
    .container.align-items-center
    
      .row.align-items-center
        .col-lg
         .row.align-items-center {{selected.length}} selected
            .col-auto()
              VueMultiselect(
              v-model="setAs"
              :multiple="false",
              :options="setAsOption",
              :searchable="false",
              :close-on-select="true",
              :show-labels="false"
              :disabled="selected.length === 0"
              label="label"
              placeholder="Set as"
              @select="bulkUpdate('archive')"
              style="width: 180px;"
            )
        //- .col-auto()
        //-   el-button(type="info" v-show="selected.length >0"
        //-   @click="bulkUpdate('delete')").has-fai.bulk-options
        //-     fai(type="fal" icon="clock").size-18.me-2
        //-     span.min-w-110.d-inline-block Delete
        .col-md
          .row.align-items-center 
            el-input(
              v-model="searchQuery"
              placeholder="Search users"
              prefix-icon="el-icon-users"
              style="width: 200px;"
              @change="searchUsers"
            ).input-search-filter
              template(#prefix='')
                img(
                  src="@/assets/icons/searchIcon.svg",
                  style="font-size: 20px;")
            el-button(type="info" v-show="isFiltered" @click="clearSearch").has-fai.clear-filter-button
              fai(type="fal" icon="times").me-2
              span Clear Filter
            .col-auto()
              VueMultiselect(
                  v-model="sortBy",
                  :multiple="false",
                  :options="sortOptions",
                  :searchable="false",
                  :close-on-select="true",
                  :show-labels="false"
                  label="label"
                  placeholder="Sort"
                  @close="sortTable"
                  style="width: 180px;"
                )

   
    el-table(
      :data="items.items"
      v-loading="loading"
      @row-click="routeToUser($event)"
      style="width: 100%"
      @selection-change="onCheckboxChange"
    ).clickable-rows.margin-top-20
      el-table-column(width="15")
      el-table-column(type="selection" width="55")
      el-table-column(label="Name" min-width="156")
        template(#default="scope")
          .lh-sm(v-if="currentView != 'invited'")
            .font-black {{ scope.row.firstname }} {{ scope.row.lastname }}
          .lh-sm(v-else)
            .font-black {{ scope.row.name }}
      el-table-column(label="Email" min-width="180")
        template(#default="scope")
          .lh-sm
            .font-medium {{ scope.row.email }}

      el-table-column(prop="active" label="Status" min-width="60")
        template(#default="scope")
          .lh-sm
            .font-medium.status-active(v-if="scope.row.active")
              p Active
            .font-medium.status-archived(v-else-if="scope.row.active == false")
              p Archived
            .font-medium.status-invited(v-else-if="scope.row.entity === 'User'")
              p Invited
    div(v-if="currentView != 'invited'").margin-top-20.d-flex.justify-content-center 
      el-pagination(layout="prev, pager, next"  :current-page.sync="items.paginationMetadata.currentPage" :total="items.paginationMetadata.totalPageCount * pageSize" @current-change="setPage")
     
     
  dialog-add-user(
    v-if="dialog"
    v-model="dialog"
    @save-item="organizationInvite"
    :server-error="serverError"
    @close-dialog="dialog=false"
  )
</template>

<script>
import {
  defineComponent, ref, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import shared from './shared';
import DialogAddUser from './DialogAddUser.vue';



export default defineComponent({
  name: 'ManageContent_Users',
  components: { DialogAddUser, VueMultiselect },
  setup() {
    const {
      // route,
      router,
      loading,
      getUsers,
      createOrganizationInvite,
      doGetInvites,
      doUpdateUserIsActiveMutation,
    } = shared();
    const items = reactive({ items: [], paginationMetadata: {} });
    const invited = reactive({ items: [] });
    const serverError = ref('');
    const dialog = ref(false);
    const filter = ref('');
    const currentView = ref('ALL');
    const selected = ref([]);
    const searchQuery = ref('');
    const isFiltered = ref(false);
    const sortOptions = [{ label: 'First Name', value: 'name' }, { label: 'Last Name', value: 'last_name' }, { label: 'Email', value: 'email' }, {label: 'Recent', value: 'created_at_desc' }, {label: 'Oldest', value: 'created_at' }];
    const setAsOption = [{label:'Archived', value:'archive'}]
    const setAs = ref('');
    const sortBy = ref('');
    const page = ref(1);
    const pageSize = 10;

  const setPage = (newPage) => {
      items.paginationMetadata.currentPage = newPage;
      findUsers();
    }

    function routeToUser(itm) {
      if (currentView.value !== 'invited') {
        router.push(`/manage/users/${itm.id}/edit`);
      } else {
        router.push(`/manage/invites/${itm.id}/info`);
      }
    }

    async function findUsers() {
      try {
        loading.value = true;
        const usersWithPaginationMetadata = await getUsers(searchQuery.value, sortBy.value.value, filter.value, items.paginationMetadata.currentPage, pageSize);
        items.items = usersWithPaginationMetadata.users;
        items.paginationMetadata = usersWithPaginationMetadata.paginationMetadata;
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    async function organizationInvite(item) {
      try {
        loading.value = true;
        await createOrganizationInvite(item);
        dialog.value = false;
      } catch (err) {
        serverError.value = err.message;
      } finally {
        loading.value = false;
      }
    }

    const bullhornsTruncateSize = 4;

    function getBullhorns(bullhorns) {
      console.log(bullhorns);
      return [];
    }

    function showOnboardedStatus(item) {
      if (item.usertype === 'ORG') {
        if (item.organization) {
          return 'Onboarded';
        }
      }
      return '--';
    }

    function searchUsers() {
      if (searchQuery.value.length > 0) {
        isFiltered.value = true;
        findUsers();
      } else {
        isFiltered.value = false;
        findUsers();
      }
    }

    function sortTable() {
      if (sortBy.value.value) {
        findUsers();
      }
    }

    function onCheckboxChange(checkedItems) {
      selected.value = checkedItems;
    }

    function getClass(currentItem) {
      if (currentItem === currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }

    function getButtonClass(currentItem) {
      switch (currentItem) {
        case 'Pending':
          return 'pending-button';
        case 'Not Approved':
          return 'notapproved-button';
        default:
          return 'approved-button';
      }
    }

    function clearSearch() {
      searchQuery.value = '';
      isFiltered.value = false;
      headerFilter(currentView.value);
      findUsers();
    }

    function headerFilter(filterBy) {
      currentView.value = filterBy;
      searchQuery.value = '';
      filter.value = filterBy;
      items.paginationMetadata.currentPage = 1;
      if(filterBy === 'invited') {

        items.items = invited.items.filter((item) => item.entity === 'User');
      } 
      else {
        findUsers();
      }
    }


async function bulkUpdate(type) {

  // Check if any users are selected
  if (selected.value.length === 0) {
    // Handle case when no users are selected
    return;
  }

  try {
    // Create an array of user IDs from the selected users
    const userIds = selected.value.map(user => user.id);

    // Determine the new `Active` status based on the `type` parameter
    const activeStatus = type !== 'archive';

    // Prepare the input objects for the GraphQL mutation
    const inputList = userIds.map(userId => ({
      id: userId,
      active: activeStatus,
    }));

    // Define the input variable with the expected structure
    const input = inputList;

    // Make the GraphQL request using your client library
     await doUpdateUserIsActiveMutation(input);

    // Clear the selected users
    selected.value = [];

    // Fetch updated user data
    await findUsers();
  } catch (err) {
    serverError.value = err.message;
  }
}


    async function findInvites() {
      try {
        loading.value = true;
        invited.items = await doGetInvites();
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }


    onMounted(async () => {
      await findUsers();
      await findInvites();
    });

    return {
      // data (static)
      loading,
      bullhornsTruncateSize,

      // data (reactive)
      items,
      invited,
      serverError,
      input: ref(),
      dialog,
      filter,
      selected,
      searchQuery,
      isFiltered,
      currentView,
      sortOptions,
      setAsOption,
      setAs,
      sortBy,
      page,
      pageSize,
      // methods
      getBullhorns,
      showOnboardedStatus,
      organizationInvite,
      routeToUser,
      getClass,
      getButtonClass,
      headerFilter,
      onCheckboxChange,
      bulkUpdate,
      searchUsers,
      sortTable,
      clearSearch,
      findInvites,
      setPage
    };
  },
});
</script>
