<script setup lang="ts">
  import { defineProps } from 'vue';
  import type { PaymentLogType } from 'src/data/types';

  const props = defineProps<{
    paymentLog: PaymentLogType | null;
  }>();

  const { paymentLog } = props;
</script>

<template>
  <section
    class="row row-xs align-items-center"
  >
    <div class="lh-sm" v-if="paymentLog.user">
      <div class="row row-xs align-items-center">
        <div class="col-auto">
          <img
            v-if="paymentLog.user?.avatar"
            :src="paymentLog.user?.avatar"
            class="d-block size-24 obj-f-c rounded-circle"
          />
          <img
            v-else
            src="@/assets/images/default-profile.jpeg"
            class="d-block size-24 obj-f-c rounded-circle"
          />
        </div>
        <div class="col lh-1 py-2">
          {{ paymentLog.user?.firstname }} {{ paymentLog.user?.lastname }}
        </div>
      </div>
      <div class="fw-m py-1">{{ paymentLog.user?.email }}</div>
      <div class="fw-m py-1">{{ paymentLog.user?.phone }}</div>
      <div class="fw-m py-1">Entity: User</div>
      <div class="fw-m py-1">EntityId: {{ paymentLog.user?.id }}</div>
    </div>

    <div class="lh-sm" v-else>
      <div class="row row-xs align-items-center">
        <div class="col-auto">
          <img
            v-if="paymentLog.organization?.avatar"
            :src="paymentLog.organization?.avatar"
            class="d-block size-24 obj-f-c rounded-circle"
          />
          <img
            v-else
            src="@/assets/images/default-profile.jpeg"
            class="d-block size-24 obj-f-c rounded-circle"
          />
        </div>
        <div class="col lh-1 py-2">{{ paymentLog.organization?.name }}</div>
      </div>
      <div class="fw-m py-1">{{ paymentLog.organization?.email }}</div>
      <div class="fw-m py-1">{{ paymentLog.organization?.phone }}</div>
      <div class="fw-m py-1">Entity: Organization</div>
      <div class="fw-m py-1">EntityId: {{ paymentLog.organization?.id }}</div>
    </div>

  </section>

  <div
    class="row row-xs align-items-center"
    v-if="paymentLog.benevityTransaction"
  >
    <div class="col-auto">
      <h6 class="mb-0 py-2">Benevity Transaction</h6>
      <div class="fw-b">Benevity Transaction Id: {{ paymentLog.benevityTransaction.id }}</div>
      <div class="fw-b">Amount: {{ paymentLog.benevityTransaction.amount }}</div>
      <div class="fw-b">transactionType: {{ paymentLog.benevityTransaction.transactionType }}</div>
      <div class="fw-b">cause Id: {{ paymentLog.benevityTransaction.causeId }}</div>
      <div class="fw-b">cause Name: {{ paymentLog.benevityTransaction.causeName }}</div>
      <div class="fw-b">Metadata: {{ paymentLog.benevityTransaction.meta }}</div>
    </div>
  </div>

  <div
    class="row row-xs align-items-center"
    v-if="paymentLog.orgPaymentStripeTransaction"
  >
    <div class="col-auto">
      <h6 class="mb-0 py-2">Creator Payout Stripe Transaction</h6>
      <div class="fw-b">Stripe Transaction Id: {{ paymentLog.orgPaymentStripeTransaction.id }}</div>
      <div class="fw-b">isProcessed: {{ paymentLog.orgPaymentStripeTransaction.processed }}</div>
      <div class="fw-b">transactionType: {{ paymentLog.orgPaymentStripeTransaction.transactionType }}</div>
      <div class="fw-b">intentId: {{ paymentLog.orgPaymentStripeTransaction.intentId }}</div>
      <div class="fw-b">Total Amount: {{ paymentLog.orgPaymentStripeTransaction.amount }}</div>
      <div class="fw-b">Wallet Deposit: {{ paymentLog.orgPaymentStripeTransaction.walletDeposit }}</div>
      <div class="fw-b">Convenience Fee: {{ paymentLog.orgPaymentStripeTransaction.fee_Convenience }}</div>
      <div class="fw-b">Credit Card Fee: {{ paymentLog.orgPaymentStripeTransaction.fee_CreditCard }}</div>
      <div class="fw-b">Metadata: {{ paymentLog.orgPaymentStripeTransaction.meta }}</div>
    </div>
  </div>

  <div
    class="row row-xs align-items-center"
    v-if="paymentLog.benevityW2WCreditTransaction"
  >
    <div class="col-auto">
      <h6 class="mb-0 py-2">Benevity W2W Credit Transaction</h6>
      <div class="fw-b">Benevity W2W Credit Transaction Id: {{ paymentLog.benevityW2WCreditTransaction.id }}</div>
      <div class="fw-b">Amount: {{ paymentLog.benevityW2WCreditTransaction.credits }}</div>
      <div class="fw-b">Notes: {{ paymentLog.benevityW2WCreditTransaction.notes }}</div>
      <div class="fw-b">Message: {{ paymentLog.benevityW2WCreditTransaction.message }}</div>
      <div class="fw-b">Meta: {{ paymentLog.benevityW2WCreditTransaction.meta }}</div>  <div class="row row-xs align-items-center">
      <div class="col-6 px-2">
        <div class="paymentlog-block">
          <h6 class="mb-0 py-2">From</h6>
          <div class="lh-sm" v-if="paymentLog.benevityW2WCreditTransaction.fromUser">
            <div class="row row-xs align-items-center">
              <div class="col-auto">
                <img
                  v-if="paymentLog.benevityW2WCreditTransaction.fromUser.avatar"
                  :src="paymentLog.benevityW2WCreditTransaction.fromUser.avatar"
                  class="d-block size-24 obj-f-c rounded-circle"
                />
                <img
                  v-else
                  src="@/assets/images/default-profile.jpeg"
                  class="d-block size-24 obj-f-c rounded-circle"
                />
              </div>
              <div class="col lh-1">
                <span>{{ paymentLog.benevityW2WCreditTransaction.fromUser.firstname }}</span>
                <span>{{ paymentLog.benevityW2WCreditTransaction.fromUser.lastname }}</span>
              </div>
            </div>
            <div class="fw-b">{{ paymentLog.benevityW2WCreditTransaction.fromUser.email }}</div>
            <div class="fw-b">{{ paymentLog.benevityW2WCreditTransaction.fromUser.phone }}</div>
            <div class="fw-b">Entity: User</div>
            <div class="fw-b">EntityId: {{ paymentLog.benevityW2WCreditTransaction.fromUser.id }}</div>
          </div>

          <div class="lh-sm" v-else>
            <div class="row row-xs align-items-center">
              <div class="col-auto">
                <img
                  v-if="paymentLog.benevityW2WCreditTransaction.fromOrganization.avatar"
                  :src="paymentLog.benevityW2WCreditTransaction.fromOrganization.avatar"
                  class="d-block size-24 obj-f-c rounded-circle"
                />
                <img
                  v-else
                  src="@/assets/images/default-profile.jpeg"
                  class="d-block size-24 obj-f-c rounded-circle"
                />
              </div>
              <div class="col lh-1">{{ paymentLog.benevityW2WCreditTransaction.fromOrganization.name }}</div>
            </div>
            <div class="fw-b">{{ paymentLog.benevityW2WCreditTransaction.fromOrganization.email }}</div>
            <div class="fw-b">{{ paymentLog.benevityW2WCreditTransaction.fromOrganization.phone }}</div>
            <div class="fw-b">Entity: Organization</div>
            <div class="fw-b">EntityId: {{ paymentLog.benevityW2WCreditTransaction.fromOrganization.id }}</div>
          </div>
        </div>
      </div>

      <div class="col-6 px-2">
        <div class="paymentlog-block">
          <h6 class="mb-0 py-2">To</h6>
          <div class="lh-sm" v-if="paymentLog.benevityW2WCreditTransaction.toUser">
            <div class="row row-xs align-items-center">
              <div class="col-auto">
                <img
                  v-if="paymentLog.benevityW2WCreditTransaction.toUser.avatar"
                  :src="paymentLog.benevityW2WCreditTransaction.toUser.avatar"
                  class="d-block size-24 obj-f-c rounded-circle"
                />
                <img
                  v-else
                  src="@/assets/images/default-profile.jpeg"
                  class="d-block size-24 obj-f-c rounded-circle"
                />
              </div>
              <div class="col lh-1">
                <span>{{ paymentLog.benevityW2WCreditTransaction.toUser.firstname }}</span>
                <span>{{ paymentLog.benevityW2WCreditTransaction.toUser.lastname }}</span>
              </div>
            </div>
            <div class="fw-b">{{ paymentLog.benevityW2WCreditTransaction.toUser.email }}</div>
            <div class="fw-b">{{ paymentLog.benevityW2WCreditTransaction.toUser.phone }}</div>
            <div class="fw-b">Entity: User</div>
            <div class="fw-b">EntityId: {{ paymentLog.benevityW2WCreditTransaction.toUser.id }}</div>
          </div>

          <div class="lh-sm" v-else>
            <div class="row row-xs align-items-center">
              <div class="col-auto">
                <img
                  v-if="paymentLog.benevityW2WCreditTransaction.toOrganization.avatar"
                  :src="paymentLog.benevityW2WCreditTransaction.toOrganization.avatar"
                  class="d-block size-24 obj-f-c rounded-circle"
                />
                <img
                  v-else
                  src="@/assets/images/default-profile.jpeg"
                  class="d-block size-24 obj-f-c rounded-circle"
                />
              </div>
              <div class="col lh-1">{{ paymentLog.benevityW2WCreditTransaction.toOrganization.name }}

                {{ paymentLog.benevityW2WCreditTransaction.toOrganization.email }}
                {{ paymentLog.benevityW2WCreditTransaction.toOrganization.phone }}
                Entity: Organization
                EntityId: {{ paymentLog.benevityW2WCreditTransaction.toOrganization.id }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  </div>
</template>

<style scoped>

</style>
