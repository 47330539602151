import {
  findUsers,
  findUserProfile,
  generateInviteCode,
  findAllOrganizations,
  findPendingOrganizations,
  approveDenyOrganization,
  organization,
  resendInvitation,
  removeInvitation,
  updateOrganizationProfileByAdmin,
  updateProfileByAdmin,
  findOrgStickers,
  findOrgSticker,
  mutateOrgSticker,
  mutateRemoveOrgSticker,
  updateOrganizationProfile,
  getStripeAuthorizeUrl,
  completeStripeOnboarding,
  getInvites,
  getInvite,
  createInvite,
  createUserInvite,
  findBullhorns,
  benevityCauses,
  updateUserIsActiveMutation,
  deleteUserProfileByAdmin,
  mutateStickerStatus,
} from '@/graphs/gql';
import { benevityCauseDetails } from '@/graphs/payment';
import {
  addAdminUserToOrgQuery,
  findOrganizationsV2,
  findSyncedBenevityCauses,
  getBenevityCauseSyncById,
  removeAdminUserFromOrgQuery,
  removeStripeAccount,
  updateBulkClaimStatus,
  updateClaimStatus,
} from '@/graphs/organization';
import { ElMessage } from 'element-plus';

import globalShared from '@/shared';
import { getStickerInsights } from '@/graphs/stickers';

export default function commonShared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    getAuthToken,
    makeGraphRequest,
    dateAndTime,
    removeItemGetConfirm,
    getConfirm,
    dobDate,
    isOrgAdmin,
    preSignedUrl,
  } = globalShared();

  const loading = ref(false);

  async function getUsers(searchTerm: string | null, sort: string | null, filter: string | null, page: number, pageSize: number): Promise<any> {
    const response = await makeGraphRequest('query', findUsers, { searchTerm, sort, filter, page, pageSize }, getAuthToken());

    if (!response.data || !response.data.findUsersByCriteria) {
      throw new Error("Invalid response from the server.");
    }

    const { users = [], paginationMetadata = {} } = response.data.findUsersByCriteria;

    return { users, paginationMetadata };
  }

  async function getAllOrganizations(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findAllOrganizations, {}, getAuthToken(),
    );
    return response.data.findAllOrganizations;
  }

  async function getPendingOrganizations(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findPendingOrganizations, {}, getAuthToken(),
    );
    return response.data.findPendingOrganizations;
  }

  async function approveOrDenyOrganization(id: string, approve: boolean): Promise<any> {
    const response = await makeGraphRequest(
      'mutation', approveDenyOrganization, { id, approve }, getAuthToken(),
    );
    return response.data.approveDenyOrganization;
  }

  async function getUserProfile(id: string): Promise<any> {
    const response = await makeGraphRequest(
      'query',
      findUserProfile,
      { id },
      getAuthToken(),
    );
    return response.data.findUserProfile;
  }

  async function getInviteCode(): Promise<any> {
    const response = await makeGraphRequest(
      'query',
      generateInviteCode,
      {},
      getAuthToken(),
    );
    return response.data.generateInviteCode;
  }

  async function getOrganization(id: string): Promise<any> {
    const response = await makeGraphRequest(
      'query',
      organization,
      { id },
      getAuthToken(),
    );
    return response.data.getOrganization;
  }

  async function resendInvite(id: string): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      resendInvitation,
      { id },
      getAuthToken(),
    );
    return response.data.resendInvitation;
  }

  async function doCreateInvite(input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      createInvite,
      { input },
      getAuthToken(),
    );
    return response.data.createInvite;
  }

  async function doUpdateUserIsActiveMutation(input: any): Promise<any> {
    try{
      const response = await makeGraphRequest(
        'mutation',
        updateUserIsActiveMutation,
        { input },
        getAuthToken(),
      );
      ElMessage({
        message: 'User arhived Succesfully'
      });
      return response.data?.updateUserIsActiveMutation;
    } catch (err) {
      ElMessage({
        message: 'Account Does not exist',
      });
    }
  }

  async function doCreateUserInvite(input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      createUserInvite,
      { input },
      getAuthToken(),
    );
    return response.data.createUserInvite;
  }

  async function removeInvite(id: string): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      removeInvitation,
      { id },
      getAuthToken(),
    );
    return response.data.removeInvitation;
  }

  async function updateOrgProfile(input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      updateOrganizationProfileByAdmin,
      { input },
      getAuthToken(),
    );
    return response.data.updateOrganizationProfileByAdmin;
  }

  async function doUpdateOrgProfile(orgId: string, input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      updateOrganizationProfile,
      { orgId, input },
      getAuthToken(),
    );
    return response.data.updateOrganizationProfile;
  }

  async function updateUserProfileByAdmin(input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      updateProfileByAdmin,
      { input },
      getAuthToken(),
    );
    return response.data.updateUserProfileByAdmin;
  }

  async function getOrgStickers(orgId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', findOrgStickers, { orgId }, getAuthToken(),
    );
    return response.data.findOrgStickers;
  }

  async function getOrgSticker(orgId: string, id: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', findOrgSticker, { orgId, id }, getAuthToken(),
    );
    return response.data.findOrgSticker;
  }

  async function updateOrgSticker(orgId: string, input: any) {
    await makeGraphRequest(
      'mutation', mutateOrgSticker, { orgId, input }, getAuthToken(),
    );
  }

  async function removeOrgSticker(orgId: string, id: number) {
    await makeGraphRequest(
      'mutation', mutateRemoveOrgSticker, { orgId, id }, getAuthToken(),
    );
  }

  async function doGetStripeAuthorizeUrl(orgId: string) {
    const response = await makeGraphRequest(
      'query', getStripeAuthorizeUrl, { orgId }, getAuthToken(),
    );
    return response.data.getStripeAuthorizeUrl;
  }

  async function doCompleteStripeOnboarding(code: string, state: string) {
    const response = await makeGraphRequest(
      'mutation', completeStripeOnboarding, { code, state }, getAuthToken(),
    );
    return response.data.completeStripeOnboarding;
  }

  async function doGetInvites(): Promise<any> {
    const response = await makeGraphRequest(
      'query',
      getInvites,
      {},
      getAuthToken(),
    );
    return response.data.getInvites;
  }

  async function doGetInvite(id: string): Promise<any> {
    const response = await makeGraphRequest(
      'query',
      getInvite,
      { id },
      getAuthToken(),
    );
    return response.data.getInvite;
  }

  async function addAdminUserToOrgRequest(orgId: string, userEmail: string): Promise<any> {
    try {
      const response = await makeGraphRequest(
        'mutation',
        addAdminUserToOrgQuery,
        orgId, userEmail,
        getAuthToken(),
      );
      ElMessage({
        message: 'Admin added Succesfully',
      });
      return response.data;
    } catch (err) {
      ElMessage({
        message: 'Account Does not exist.',
      });
    }
    return null;
  }

  async function removeAdminUserFromOrgRequest(orgId: string, userId: string): Promise<any> {
    try {
      const response = await makeGraphRequest(
        'mutation',
        removeAdminUserFromOrgQuery,
        orgId, userId,
        getAuthToken(),
      );
      ElMessage({
        message: 'Admin removed Succesfully',
      });
      return response.data;
    } catch (err) {
      ElMessage({
        message: 'Account or Organization Does not exist.',
      });
    }
    return null;
  }

  async function getBullhornsAsTags(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findBullhorns, { getfavourite: false }, getAuthToken(),
    );
    return response.data.findBullhorns;
  }

  async function getcausesAsDropdown(query: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', benevityCauses, { q: query, page: '1' }, getAuthToken(),
    );
    return response.data.benevityCauses.causes;
  }

  async function getBenevityCauseDetails(causeId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', benevityCauseDetails, { causeId }, getAuthToken(),
    );
    return response.data.benevityCauseDetails;
  }

  async function reqRemoveStripeAccount(orgId: string): Promise<any> {
    try {
      const response = await makeGraphRequest(
        'mutation',
        removeStripeAccount,
        orgId,
        getAuthToken(),
      );
      ElMessage({
        message: 'Stripe Account removed succesfully',
      });
      return response.data;
    } catch (err) {
      ElMessage({
        message: 'Stripe Account or Organization Does not exist.',
      });
    }
    return null;
  }

  async function getSyncedBenevityCauses(benevityCausesPaginationInput: any): Promise<any> {
    const response = await makeGraphRequest(
      'query', findSyncedBenevityCauses, { benevityCausesPaginationInput }, getAuthToken(),
    );
    return response.data.findSyncedBenevityCauses;
  }

  async function getClaimRequestDetails(claimRequestId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', getBenevityCauseSyncById, { claimRequestId} , getAuthToken(),
    );
    return response.data.getBenevityCauseSyncById;
  }

  async function mutateBulkClaimStatus(input: any) {
    const response = await makeGraphRequest(
      'mutation', updateBulkClaimStatus, { input }, getAuthToken(),
    );
    return response.data.updateBulkClaimStatus;
  }

  async function mutateClaimStatus(input: any) {
    const response = await makeGraphRequest(
      'mutation', updateClaimStatus, { input }, getAuthToken(),
    );
    return response.data.updateBenevityCauseStatus;
  }

  async function findStickerInsights(stickerId: any): Promise<any> {
    const response = await makeGraphRequest(
      'query', getStickerInsights, { stickerId }, getAuthToken(),
    );
    return response.data.getStickerInsights;
  }

  async function approveSticker(input: any) {
    const response = await makeGraphRequest(
      'mutation', mutateStickerStatus, { input }, getAuthToken(),
    );
    return response.data.mutateStickerStatus;
  }

  async function getOrganizationsV2(baseInput: any): Promise<any> {
    const response = await makeGraphRequest(
      'query', findOrganizationsV2, { baseInput }, getAuthToken(),
    );
    return response.data.findOrganizationsV2;
  }

  async function removeUser(id: number) {
    await makeGraphRequest(
      'mutation',  deleteUserProfileByAdmin, { id }, getAuthToken(),
    );
  }

  return {
    ref,
    loading,
    route,
    router,
    getUsers,
    getAllOrganizations,
    getPendingOrganizations,
    dateAndTime,
    dobDate,
    doGetStripeAuthorizeUrl,
    doCompleteStripeOnboarding,
    approveOrDenyOrganization,
    getUserProfile,
    getInviteCode,
    getOrganization,
    removeItemGetConfirm,
    doGetInvites,
    resendInvite,
    removeInvite,
    doGetInvite,
    doCreateInvite,
    doCreateUserInvite,
    updateOrgProfile,
    doUpdateOrgProfile,
    updateUserProfileByAdmin,
    isOrgAdmin,
    preSignedUrl,
    getOrgStickers,
    getOrgSticker,
    updateOrgSticker,
    removeOrgSticker,
    getConfirm,
    addAdminUserToOrgRequest,
    removeAdminUserFromOrgRequest,
    getBullhornsAsTags,
    findBullhorns,
    getcausesAsDropdown,
    benevityCauses,
    getBenevityCauseDetails,
    reqRemoveStripeAccount,
    getSyncedBenevityCauses,
    getClaimRequestDetails,
    mutateBulkClaimStatus,
    mutateClaimStatus,
    findStickerInsights,
    getOrganizationsV2,
    doUpdateUserIsActiveMutation,
    removeUser,
    approveSticker,
    getBenevityCauseSyncById,
  };
}
