<template lang="pug">
aside.main-menu.web-menu-background-color.web-app-menu
  .d-flex.flex-column.min-vh-100
    router-link(to='/web/').py-2.d-flex.mb-1.mt-3.padding-left-35
      img(src="@/assets/images/logos/bolt-rounded.png").w-60
      //- fai(type="fal" icon="bars" @click="isWebAppMenu = !isWebAppMenu").size-28.me-2.mx-auto

    #nav.flex-grow-1.d-flex.flex-column.pos-r.main-menu-items(
      :class="{ 'is-collapsed': isMainMenuCollapsed }"
    )

      h5.mb-3(v-if="backTo")
        .padding-left-60 {{ backBtnLabel }}
        fai(type="fal" icon="arrow-left" @click="goBack").back-arrow

      el-menu(
        :default-active="activeMenuItem"
        :collapse="isMainMenuCollapsed"
        background-color="transparent"
        active-text-color="#111"
        :unique-opened="true"
        @select="handleSelectMenu"
      ).flex-grow-1.d-flex.flex-column
        slot
          // default slot

        el-menu-item(
          v-for="(item,i) in items"
          :key="item.path"
          :index="item.path"
          :class="isMenuActive(item.path)"
          v-if="isSignedIn"
        )
          .d-flex(v-if="toShow[i]")
            div
              span.pl-15 {{ item.label }}
        el-menu-item(
          @click="handleSignOut(true)"
          index="/auth/web/signin"
          v-if="!isSignedIn"
        )
          .d-flex
            div
              span.pl-15 Login
        el-menu-item(
          @click="openLegalPage"
          index="#legal"
          class="external-link"
        )
          .d-flex
            div
              span.pl-15 Legal
        el-menu-item(
          @click="openHelp"
          index="#help"
          class="external-link"
        )
          .d-flex
            div
              span.pl-15 Help
        el-menu-item(
          @click="handleSignOut()"
          index="/auth/web/signin"
          v-if="isSignedIn"
        )
          .d-flex
            div
              span.pl-15 Logout
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore, mapGetters } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import { dashboard as menuItems } from '@/data/main-menu';
import shared from '@/shared';

export default defineComponent({
  name: 'WebAppMenu',
  components: { },

  computed: {
    ...mapGetters('shared', ['isMainMenuCollapsed']),
  },

  props: {
    items: { type: Array, default: menuItems },
    isWebAppMenu: { type: Boolean, default: false },
    backTo: { type: String, default: '' },
    backBtnLabel: { type: String, default: '' },
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const toShow = ref();

    // eslint-disable-next-line func-names
    const openLegalPage = function () {
      window.location.href = import.meta.env.VITE_LEGAL_PAGE_LINK;
    };

    // eslint-disable-next-line func-names
    const openHelp = function () {
      window.location.href = `mailto:${import.meta.env.VITE_EMAIL_SUPPORT}`;
    };
    const { isOrgAdmin } = shared();
    // const { orgId } = route.params;
    const isSignedIn = store.getters['users/isMeSignedIn'];

    // console.log('items are ', props.items, orgId);

    toShow.value = props.items.map((p: any) => {
      // hide logout button if not logged in
      if (p.label === 'Logout' && !isSignedIn) {
        // console.log('returning false');
        return false;
      }
      // hide login button if already logged in
      if (p.label === 'Login' && isSignedIn) {
        // console.log('returning false');
        return false;
      }
      // if the current page does not have org id, hide the sticker sidebar menu
      if (p.path.indexOf('ORG_ID') >= 0 && !route.params.orgId) {
        return false;
      }
      return true;
    });

    const isMenuActive = (vRoute: string): string => {
      if (route.path === vRoute) return 'is-active';
      return '';
    };

    const handleSelectMenu = (key: string): void => {
      // available perms: key, keyPath
      if (route.path === key) return;

      router.push(key);
    };

    const goBack = () => {
      router.go(-1);
    };

    const handleSignOut = (redirectSticker = false) => {
      store.dispatch('users/resetMe');
      let path = location.pathname;
      let pathSplit = path.split('/');
      router.push(`/auth/web/signin${redirectSticker === true && path.startsWith('/web/sticker/') ? `?stickerId=${pathSplit[pathSplit.length - 1]}` : ''}`);
    };

    return {
      // data
      activeMenuItem: ref(null),
      isOrgAdmin,
      toShow,
      isSignedIn,
      // methods
      isMenuActive,
      handleSelectMenu,
      handleSignOut,
      goBack,
      openLegalPage,
      openHelp,
    };
  },
});
</script>

<style lang="scss" scoped>
  .el-menu-item{
    font-weight: 500;
    font-size: 19px !important;
    background-color: transparent !important;
    color: #FFF !important;
  }
  .el-menu-item::after{
    background-color: transparent !important;
  }
  //aside.main-menu {
  //  z-index: 101;
  //}
  .el-menu-item.external-link.is-active::before {
    background-color: transparent !important;
  }
</style>
