<template lang="pug">
app-main-content-layout(title='Add Item')

el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)

  div
    .row.row-sm.align-items-top
      div.filter_container
       span.filter-inactive.px-10(@click="$router.push(`/manage-content/media/add-bullhorn`)") Bullhorn
       span.filter-inactive.px-10(@click="$router.push(`/manage-content/media/add-channel`)") Channel
       span.filter-active.px-10(@click="selectedForm='value'") Value
       span.filter-inactive.px-10(@click="$router.push(`/manage-content/media/add-interest`)") Interest
      //-  div current is {{item}}

      .col.mb-6
        el-form-item(label="Title *" prop="value")
          el-input(v-model="item.value"  placeholder="Title")

        el-form-item(label="Status")
          VueMultiselect(
            v-model="item.status",
            :multiple="false",
            :options="dropdown",
            :searchable="false",
            :close-on-select="true",
            :show-labels="false"
            placeholder="Pick a value"
          )

      .col.mb-3
        el-form-item(label="Details" prop="description")
          el-input(v-model="item.description" type="textarea"  :rows="7" placeholder="Details")
        .py-4.text-center
          el-button(type="success" @click="validateForm" color="#00BFB0" size="large" style="width: 100%") Save To Spotlight

</template>

<script>
import {
  defineComponent, reactive,
  ref, onMounted,
} from 'vue';
import { value } from '@/data/rules';
import VueMultiselect from 'vue-multiselect';
// import abc from '../directory/bullhorns/Detail.vue';
import shared from '../directory/values/shared';

export default defineComponent({
  name: 'AddValue',
  components: { VueMultiselect },
  emits: ['close-dialog', 'save-item'],

  setup() {
    const {
      route,
      loading,
      router,
      getValue,
      saveValue,
    } = shared();

    const refForm = ref(null);
    const item = reactive({
      value: '',
      description: '',
    });
    const selectedForm = ref('value');
    const dropdown = ['Active', 'Inactive'];

    function select(selection) {
      // console.log('selected ', selection);
      // item.type = selection;
      router.push(`/manage-content/media/add-${selection}`);
    }

    async function saveItem() {
      await saveValue({
        id: parseInt(item.id, 10),
        value: item.value,
        description: item.description,
      });
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    onMounted(async () => {
    });

    return {
      // data
      input: ref(''),
      user: {},
      item,
      select,
      selectedForm,
      dropdown,
      loading,

      // data
      refForm,
      rules: { value },

      // methods
      validateForm,
    };
  },
});
</script>
