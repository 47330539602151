<!-- eslint-disable max-len -->
<template>
  <div>
    <div class="card-header p-4 bg-transparent">
      <div class="row row-sm align-items-center">
        <div class="col">
          <div class="d-flex">
            <router-link :to="backPath">
              <fai class="size-28 me-2" icon="angle-left" type="fal"></fai>
            </router-link>
            <h4 class="mb-0">Stickers {{ orgId }}</h4></div>
        </div>
        <div class="col-auto">
          <el-button class="has-fai" plain="plain" type="danger" @click="removeItem">
            <fai class="size-18 me-2" icon="trash" type="fal"></fai>
            <span class="min-w-110 d-inline-block">Delete</span></el-button>
          <el-button v-loading="loading" class="has-fai" type="primary" @click="validateForm">
            <fai class="size-18 me-2" icon="edit" type="fal"></fai>
            <span class="min-w-110 d-inline-block">Save</span></el-button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <el-form ref="refForm" :model="item" :rules="rules" label-position="top"
               status-icon="status-icon">
        <div class="row">
          <div class="col-md-8">
            <el-form-item label="Title" prop="title">
              <el-input v-model="item.title"></el-input>
            </el-form-item>
            <div class="row">
              <div class="col-6">
                <el-form-item label="Tags" prop="tags">
                  <VueMultiselect v-model="item.tags" :close-on-select="true" :loading="tagsLoading"
                                  :multiple="true" :options="tagsArray" :searchable="true"
                                  :show-labels="false"
                                  placeholder="Please enter a tag"></VueMultiselect>
                </el-form-item>
              </div>
              <div class="col-6">
                <el-form-item label="Cost (optional)" prop="cost">
                  <el-input-number v-if="item.campaignType" v-model="item.cost" :min="1"
                                   type="number"></el-input-number>
                  <el-input-number v-else v-model="item.cost" :min="0"
                                   type="number"></el-input-number>
                </el-form-item>
              </div>

              <div class="col-12 my-2" v-if="item.campaignType !== 'Ticket'">
                <el-checkbox v-model="allowNamingPrices">Allow users to name price</el-checkbox>

                <div v-if="allowNamingPrices" class="py-2">
                  <p>Quick cost options</p>
                  <div class="d-flex align-items-center">
                    <el-input
                      v-for="(cost, index) in item.quickCosts"
                      :key="index"
                      v-model.number="item.quickCosts[index]"
                      size="small"
                      :placeholder="'Cost ' + (index + 1)"
                      type="number"
                      step="1"
                      min="0"
                      style="margin-right: 4px; margin-top: 4px;"
                      @input="validateInput(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <el-form-item label="Description" prop="description">
              <el-input v-model="item.description" type="textarea"></el-input>
            </el-form-item>
            <div class="d-flex mb-2">
              <div>Allow app users to search for more campaigns</div>
              <el-switch v-model="item.isCausesSearchAllowed" active-color="#13ce66" active-value="active-value"
                         class="ms-auto"></el-switch>
            </div>
            <div class="d-flex mb-2">
              <div>Campaign</div>
              <el-switch v-model="item.campaignType" active-color="#13ce66" active-value="Campaign"
                         class="ms-auto"></el-switch>
            </div>
            <div class="d-flex mb-2">
              <div>Ticket</div>
              <el-switch v-model="item.campaignType" active-color="#13ce66" active-value="Ticket"
                         class="ms-auto"></el-switch>
            </div>
            <div v-if="item.campaignType == 'Campaign'">
              <div v-if="item.campaignType !== 'MatchCampaign'">
                <el-form-item label="Non-Profit Suggestions (optional)" prop="cause">
                  <VueMultiselect v-model="item.causes" :close-on-select="true"
                                  :loading="causeLoading" :multiple="true"
                                  :options="causeOptionsArray" :searchable="true"
                                  :show-labels="false" placeholder="Please enter a tag"
                                  @search-change="getCausesDropdownOptions"></VueMultiselect>
                </el-form-item>
                <div class="row">
                  <div class="col-4">
                    <el-form-item label="Max Quantity (optional)" prop="maxQuantity">
                      <el-input-number v-model="item.maxQuantity"></el-input-number>
                    </el-form-item>
                  </div>
                  <div class="col-4">
                    <el-form-item label="Start Date" prop="startDate"><input :key="startDate"
                                                                             v-model="startDate"
                                                                             class="w-100"
                                                                             type="date"/>
                    </el-form-item>
                  </div>
                  <div class="col-4">
                    <el-form-item label="End Date" prop="endDate"><input :key="endDate"
                                                                         key="item"
                                                                         v-model="endDate" class="w-100"
                                                                         type="date"/></el-form-item>
                  </div>
                </div>
              </div>
              <div v-if="item.campaignType === 'MatchCampaign'">
                <div class="row">
                  <div class="col-6">
                    <el-form-item label="Max Donation" prop="maxDonation">
                      <el-input v-model="item.maxDonation" type="number"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-6">
                    <el-form-item label="Donation Multiplier" prop="donationMultiplier">
                      <el-input-number v-model="item.donationMultiplier"></el-input-number>
                    </el-form-item>
                  </div>
                </div>
                <el-form-item label="Non-Profit Suggestions (optional)" prop="cause">
                  <el-select v-model="item.causes" :loading="causeLoading" :remote-method="getCausesDropdownOptions"
                             filterable="filterable" multiple="multiple"
                             placeholder="Please enter a cause"
                             remote="remote" reserve-keyword="reserve-keyword">
                    <el-option v-for="cause in causeOptions" :key="cause.value" :label="cause.label"
                               :value="cause.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Impact" prop="impact">
                  <el-input v-model="item.impact"></el-input>
                </el-form-item>
                <div class="row">
                  <div class="col-6">
                    <el-form-item label="Start Date" prop="startDate"><input v-model="item.startDate"
                                                                             class="w-100"
                                                                             type="date"/>
                    </el-form-item>
                  </div>
                  <div class="col-6">
                    <el-form-item label="End Date" prop="endDate"><input v-model="item.endDate"
                                                                         class="w-100"
                                                                         type="date"/>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.campaignType">
              <div v-if="item.campaignType === 'Ticket'">
                <div class="row">
                  <div class="col-6">
                    <el-form-item label="Max Quantity (optional)" prop="maxQuantity">
                      <el-input-number v-model="item.maxQuantity"></el-input-number>
                    </el-form-item>
                  </div>
                  <div class="col-6">
                    <el-form-item label="Event Date &amp; Time" prop="startDate"><input
                      v-model="item.eventDate" class="w-100" type="datetime-local"/></el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <el-form-item key="item" label="Sale Start Date &amp; Time" prop="startDate">
                      <input v-model="item.startDate" class="w-100" type="datetime-local"/>
                    </el-form-item>
                  </div>
                  <div class="col-6">
                    <el-form-item label="Sale End Date &amp; Time" prop="endDate"><input
                      v-model="item.endDate" class="w-100" type="datetime-local"/></el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <el-form-item label="Location" prop="location">
                      <el-input v-model="item.location"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-6">
                    <el-form-item label="Waiver URL" prop="waiver">
                      <el-input v-model="item.waiverURL"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-form-item label="Cause (optional)">
                  <VueMultiselect v-model="item.causes" :close-on-select="true"
                                  :loading="causeLoading" :multiple="true"
                                  :options="causeOptionsArray" :searchable="true"
                                  :show-labels="false" placeholder="Please enter a tag"
                                  @search-change="getCausesDropdownOptions"></VueMultiselect>
                </el-form-item>
                <cost-varients-list :loading="loading" :sticker="item" class="mb-4"
                                    @save-item="getItem()"></cost-varients-list>
                <div class="col"><p class="pt-4">Message for Confirmation Email</p>
                  <el-input v-model="item.confirmationEmail" placeholder="Message for Confirmation Email"
                            type="textarea"></el-input>
                </div>
                <div class="col"><p class="pt-4">Message for Reminder email</p>
                  <el-input v-model="item.reminderEmail" placeholder="Message for Reminder email"
                            type="textarea"></el-input>
                </div>
              </div>
            </div>
            <glow-picker ref="glowPickerRef" />
          </div>
          <div class="col-md-4">
            <el-upload v-loading="uploading" :before-upload="beforeUpload"
                       :http-request="handleUpload" :on-error="handleError"
                       :on-success="handleSuccess" :show-file-list="false"
                       action="" class="img-uploader pos-r mb-4">
              <template v-if="item.url"><img :src="item.url" class="img-fluid"/>
                <div class="pin pin-bx d-flex p-2">
                  <el-button class="ms-auto" type="primary">Change</el-button>
                </div>
              </template>
              <template
                v-else><img class="img-uploader-icon"
                                     src="@/assets/icons/file-image-outline.svg"/>
                <p class="upload-picture-text">Drop an image file here or</p>
                <div class="fake-upload-button">Browse For File</div>
              </template>
            </el-upload>
            <el-form-item label="Share Sticker">
              <div class="px-3">
                <qrcode-vue :value="stickerUrl" size="300"></qrcode-vue>
                <div class="primary-color-text">{{ stickerUrl }}</div>
              </div>
            </el-form-item>
            <div v-if="!loading" class="card">
              <div
                class="card-header d-flex align-items-center bg-transparent border-0 py-3 fs-14 fw-bold">
                <div>Insights</div>
              </div>
              <div class="px-3">
                <p>Sold {{ stickerInsights.unitsSold }} Units to
                  {{ stickerInsights.purchasedUsersCount }} customers for
                  ${{ parseFloat(stickerInsights.totalRevenue).toFixed(2) }} in net sales</p>
                <el-button link="link" @click="isStickerInsights = !isStickerInsights">View
                  Details
                </el-button>
              </div>
            </div>
            <el-form-item label="Creator">
              <el-input v-model="item.creatorTitle" disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="Is Free?">
              <el-input v-model="item.isFree" disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item>
              <template #label>Post Date<span
                class="text-muted ms-2">Active for 3 Days</span></template>
              <el-input v-model="item.postDate" disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="Post Time">
              <el-input v-model="item.postTime" disabled="disabled"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <sticker-insights :isStickerInsights="isStickerInsights" :loading="loading"
                      :stickerInsights="stickerInsights"
                      @close-dialog="isStickerInsights = !isStickerInsights"></sticker-insights>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, reactive, ref} from 'vue';
import VueMultiselect from 'vue-multiselect';
import QrcodeVue from 'qrcode.vue';
import fileUpload from '@/shared/upload';
import {requiredRule, title} from '@/data/rules';
import CostVarientsList from '@/views/manage-content/media/stickers/CostVarientsList.vue';
import StickerInsights from '@/views/manage-content/media/stickers/StickerInsights.vue';
import shared from '../shared';
import GlowPicker from "@/components/StickerTIcket/GlowPicker.vue";
import { validateTime, joinDateAndTime, unJoinDateAndTime } from "@/shared/utility-functions";

export default defineComponent({
  name: 'OrgSticker',
  components: {
    VueMultiselect, CostVarientsList, StickerInsights, QrcodeVue, GlowPicker
  },
  setup() {
    const {
      route, router, loading, getOrgSticker,
      updateOrgSticker, removeItemGetConfirm,
      dateAndTime, removeOrgSticker, getBullhornsAsTags,
      getcausesAsDropdown, getBenevityCauseDetails,
      findStickerInsights,
    } = shared();
    const refForm = ref(null);
    const {
      uploading, uploadStatus,
      beforeUpload, handleUploadRequest,
    } = fileUpload('stickers', 'Sticker');

    const {id} = route.params;
    const tagsList = ref([]);
    const tagsOptions = ref([]);
    const tagsLoading = ref(false);
    const causeList = ref([]);
    const causeOptions = ref([]);
    const tagsArray = ref([]);
    const causeOptionsArray = ref([]);
    const causeLoading = ref(false);
    const isStickerInsights = ref(false);
    const stickerInsights = reactive({});
    const glowPickerRef = ref(null);

    const item = reactive({
      id,
      title: '',
      description: '',
      url: '',
      published: false,
      tags: ref([]),
      cost: 0,
      campaignType: null,
      causes: ref([]),
      // nonProfitSuggestions: ref([]),
      maxQuantity: 0,
      maxDonation: null,
      donationMultiplier: 1,
      impact: null,
      startDate: null,
      endDate: null,
      user: {name: ''},
      creatorTitle: null,
      isCausesSearchAllowed: false,
      confirmationEmail: null,
      reminderEmail: null,
      quickCosts: [null, null, null, null],
    });

    const allowNamingPrices = ref(false);

    const formatDate = (dateStr) => {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const startDate = computed({
      get() {
        return formatDate(item.startDate);
      },
      set(value) {
        item.startDate = value;
      }
    });

    const endDate = computed({
      get() {
        return formatDate(item.endDate);
      },
      set(value) {
        item.endDate = value;
      }
    });

    async function getItem() {
      const itm = await getOrgSticker(route.params.orgId, route.params.id);
      Object.assign(item, itm);
      item.tags = (item.tags !== null && item.tags !== '') ? item.tags.split(' |=| ') : [];
      item.causes = (item.causes !== null && item.causes !== '') ? item.causes.split(' |=| ') : [];
      [item.postDate, item.postTime] = dateAndTime(itm.createdAt);

      item.startDate = item.startDate?.substring(0, 16);
      item.endDate = item.endDate?.substring(0, 16);
      item.eventDate = item.eventDate?.substring(0, 16);



      for (const i in item.quickCosts) {
        if (i) {
          allowNamingPrices.value = true;
        }
      }

      if (!item.quickCosts?.length) {
        item.quickCosts = [null, null, null, null];
      } else {
        const additionalCostsNeeded = 4 - item.quickCosts.length;
        if (additionalCostsNeeded > 0) {
          item.quickCosts = [...item.quickCosts, ...Array(additionalCostsNeeded).fill(null)];
        }
      }

      if (item.glowColor) {
        if (item.glowColor) {
          glowPickerRef.value.glow = true; // decide to check it
        }
        glowPickerRef.value.color = item.glowColor;
        const start = unJoinDateAndTime(item.glowStartDate);
        glowPickerRef.value.dateStart = start.theDate;
        glowPickerRef.value.timeStart = start.time;
        const end = unJoinDateAndTime(item.glowEndDate);
        glowPickerRef.value.dateEnd = end.theDate;
        glowPickerRef.value.timeStart = end.time;
      }

      await nextTick();
    }

    async function getBullhornsAsTagsList() {
      const bullhornTags = await getBullhornsAsTags();
      bullhornTags.forEach((element) => {
        const data = {value: `${element.bullhorn}`, label: `${element.bullhorn}`};
        tagsList.value.push(data);
        // tagsArray is used to render the correct values for the new dropdown
        tagsArray.value.push(element.bullhorn);
      });
    }

    // eslint-disable-next-line no-unused-vars
    async function getTagsDropdownOptions(query) {
      if (query) {
        tagsLoading.value = true;
        setTimeout(() => {
          tagsLoading.value = false;
          // eslint-disable-next-line arrow-body-style
          tagsOptions.value = tagsList.value.filter((dropdownItem) => {
            return dropdownItem.label.toLowerCase().includes(query.toLowerCase());
          });
        }, 200);
      } else {
        tagsOptions.value = [];
      }
    }

    async function getCausesList(query) {
      const benevityCauses = await getcausesAsDropdown(query);
      benevityCauses.forEach((element) => {
        const data = {
          value: `${element.name} : ${element.id}`,
          label: `${element.name} : ${element.id}`,
        };
        causeList.value.push(data);
      });
    }

    // eslint-disable-next-line no-unused-vars
    async function getCausesDropdownOptions(query) {
      if (query) {
        causeLoading.value = true;
        const benevityCauses = await getcausesAsDropdown(query);
        setTimeout(() => {
          causeLoading.value = false;
          causeOptions.value = [];
          benevityCauses.forEach((element) => {
            const data = {
              value: `${element.name} : ${element.id}`,
              label: `${element.name} : ${element.id}`,
            };
            causeOptions.value.push(data);
            causeOptionsArray.value.push(`${element.name} : ${element.id}`);
          });
        }, 200);
      } else {
        causeOptions.value = [];
      }
    }

    async function handleUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.url = uploadStatus.resourceUrl;
      }
    }

    function handleSuccess() {
      console.log('success');
    }

    function handleError() {
      console.log('error');
    }

    async function saveItem() {
      loading.value = true;
      const input = JSON.parse(JSON.stringify(item));
      let itemToSave = {
        id,
        title: input.title,
        url: input.url,
        description: input.description,
        tags: input.tags.join(' |=| '),
        cost: parseFloat(parseFloat(input.cost).toFixed(2)),
        campaignType: input.campaignType ? input.campaignType : null,
        causes: `${input.causes.join(' |=| ')}`,
        // nonProfitSuggestions: input.nonProfitSuggestions,
        maxQuantity: input.maxQuantity,
        maxDonation: parseFloat(parseFloat(input.maxDonation).toFixed(2)),
        donationMultiplier: input.donationMultiplier,
        impact: input.impact,
        startDate: input.startDate,
        endDate: input.endDate,
        isCausesSearchAllowed: input.isCausesSearchAllowed,
        reminderEmail: input.reminderEmail,
        confirmationEmail: input.confirmationEmail,
        varients: input.varients
      }


      if (item.campaignType !== 'Ticket') {
        const updatedQuickCosts = [];
        item.quickCosts.map(cost => {
          if (cost !== '' && cost !== 0 && cost !== null) {
            updatedQuickCosts.push(cost);
          }
        });

        let quickCosts = allowNamingPrices.value ? updatedQuickCosts : null;
        itemToSave = Object.assign({}, itemToSave, {quickCosts});
      }

      if (glowPickerRef?.value) {
        const { dateStart, dateEnd, timeStart, timeEnd, color, glow } = glowPickerRef.value
        if (glow) {
          itemToSave.glowStartDate =  joinDateAndTime(dateStart, timeStart);
          itemToSave.glowEndDate = joinDateAndTime(dateEnd, timeEnd);
          itemToSave.glowColor = color;
        } else {
          itemToSave.glowStartDate = null;
          itemToSave.glowEndDate = null;
          itemToSave.glowColor = null;
        }
      }

      await updateOrgSticker(route.params.orgId, itemToSave );
      loading.value = false;
    }

    function removeItem() {
      removeItemGetConfirm(async () => {
        await removeOrgSticker(route.params.orgId, item.id);
        router.push('/manage-content/media/stickers');
      });
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    const stickerUrl = `${import.meta.env.VITE_WEB_BASE_URL}/web/sticker/${item.id}`;

    async function getStickerInsights() {
      loading.value = true;
      const mStickerInsights = await findStickerInsights(route.params.id);
      Object.assign(stickerInsights, mStickerInsights);
      loading.value = false;
    }

    const validateInput = (index) => {
      if (item.quickCosts[index] < 0) {
        item.quickCosts[index] = 0;
      }
    }

    onMounted(async () => {
      await getBullhornsAsTagsList();
      await getCausesList('');
      await getItem();
      await getStickerInsights();
    });

    return {
      // data
      refForm,
      item,
      loading,
      uploading,
      rules: {
        title,
        description: requiredRule('description'),
        tags: requiredRule('tags'),
        startDate: requiredRule('startDate'),
        endDate: requiredRule('endDate'),
      },
      stickerUrl,
      // methods
      beforeUpload,
      tagsList,
      tagsOptions,
      tagsLoading,
      causeList,
      causeOptions,
      causeLoading,
      getBullhornsAsTagsList,
      getTagsDropdownOptions,
      getCausesList,
      getCausesDropdownOptions,
      getBenevityCauseDetails,
      handleUpload,
      handleSuccess,
      handleError,
      backPath: `/manage/orgs/${route.params.orgId}/edit`,
      removeItem,
      validateForm,
      tagsArray,
      causeOptionsArray,
      isStickerInsights,
      stickerInsights,
      getItem,
      startDate,
      endDate,
      allowNamingPrices,
      validateInput,
      glowPickerRef
    };
  },
});
</script>
<style>
.el-select {
  display: block !important;
}
</style>
