import {
  findSegments,
  findSegment,
  mutateSegment,
} from '@/graphs/gql';

import commonShared from '../shared';

export default function shared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    getAuthToken,
    removeItemGetConfirm,
    makeGraphRequest,
    getEntityQuotes,
    saveEntityQuote,
    dateAndTime,
    exportData,
  } = commonShared('SEGMENT');

  const loading = ref(false);

  async function saveSegment(input: { [key: string]: any }): Promise<any> {
    await makeGraphRequest(
      'mutation', mutateSegment, { input }, getAuthToken(),
    );
  }

  async function getSegments(entity: string, entityId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', findSegments, { entity, entityId }, getAuthToken(),
    );
    return response.data.getSegments;
  }

  async function getSegment(segmentId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', findSegment, { segmentId }, getAuthToken(),
    );
    return response.data.getSegment;
  }

  return {
    loading,
    route,
    router,
    removeItemGetConfirm,
    getEntityQuotes,
    saveEntityQuote,
    dateAndTime,
    exportData,
    getSegments,
    getSegment,
    saveSegment,
  };
}
