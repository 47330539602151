import {
  findBullhorns,
  findBullhorn,
  mutateBullhorn,
} from '@/graphs/gql';

import commonShared from '../shared';

export default function shared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    getAuthToken,
    removeItemGetConfirm,
    makeGraphRequest,
    getTypedCategories,
    saveCategory,
    getEntityQuotes,
    saveEntityQuote,
    dateAndTime,
    exportData,
  } = commonShared('BULLHORN');

  const loading = ref(false);

  async function saveBullhorn(input: { [key: string]: any }): Promise<any> {
    await makeGraphRequest(
      'mutation', mutateBullhorn, { input }, getAuthToken(),
    );
  }

  async function getBullhorns(getfavourite: boolean): Promise<any> {
    const response = await makeGraphRequest(
      'query', findBullhorns, { getfavourite }, getAuthToken(),
    );
    return response.data.findBullhorns;
  }

  async function getBullhorn(id: number): Promise<any> {
    const response = await makeGraphRequest(
      'query', findBullhorn, { id }, getAuthToken(),
    );
    return response.data.findBullhorn;
  }

  return {
    loading,
    route,
    router,
    removeItemGetConfirm,
    getTypedCategories,
    getBullhorns,
    getBullhorn,
    saveBullhorn,
    saveCategory,
    getEntityQuotes,
    saveEntityQuote,
    dateAndTime,
    exportData,
    makeGraphRequest,
  };
}
