 <template lang="pug">
.spotlight_card.fw-b
  amount-input-form(
    :is-editable="editAmt"
    @edit-amt='editAmt = true'
    @input-amt='amt = $event'
  )
  .pt-2.bg-gray-100.mb-4
    // space

  .px-3(v-if="editAmt")
    .max-w-300.mx-auto
        el-button(type='success' round @click="editAmt = false").w-100.mb-2 Continue

  div(v-else)
    .px-3.mb-4
      .max-w-300.mx-auto
        div card info here

    .pt-2.bg-gray-100.mb-4
      // space

    .px-3
      .max-w-300.mx-auto
        .fs-14.mb-4
          .d-flex.mb-2
            div Your Deposit
            .ms-auto ${{ amt }}
          .d-flex.mb-2
            div Fees
            .ms-auto ${{ fee }}
          .d-flex
            div Total
            .ms-auto  ${{ amt + fee }}

        div
          el-button(type='success' round @click="editAmt = false").w-100.mb-2 Deposit

  .px-3
    .max-w-300.mx-auto
      el-button(link round).w-100.text-muted Cancel
</template>

<script>
import {
  ref,
  defineComponent,
  // onMounted
} from 'vue';

import AmountInputForm from './AmountInputForm.vue';
// import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'FinalizePaymentStatic',
  components: { AmountInputForm },
  setup() {
    return {
      amt: ref(0),
      fee: ref(10),
      editAmt: ref(true),
    };
  },
});
</script>
