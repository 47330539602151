import dayjs from "dayjs";

function combineDateAndTime(date, time) {
  if (!date || !time) return null;

  const dateString = dayjs(date).format('YYYY-MM-DD');
  const timeString = time;
  const datetimeString = `${dateString} ${timeString}`;
  const datetime = dayjs(datetimeString, 'YYYY-MM-DD hh:mm A');

  if (!datetime.isValid()) {
    console.error('Invalid datetime:', datetimeString);
    return null;
  }

  return datetime.format('YYYY-MM-DDTHH:mm:ss');
}

function uncombineDateAndTime(datetimeString, timezone) {
  console.log(timezone);
  if (!datetimeString) return { date: null, time: null };

  const datetime = dayjs.utc(datetimeString);

  if (!datetime.isValid()) {
    console.error('Invalid datetime:', datetimeString);
    return { date: null, time: null };
  }

  return {
    date: datetime.toDate(),
    time: datetime.format('hh:mm A'),
  };
}

export { combineDateAndTime, uncombineDateAndTime };
