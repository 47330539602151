import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { makeRequest as makeGraphRequest } from '@/graphs/index';
import {
  validateInvite,
  updateOrgAndAdmin,
  updateUserAttributes,
  verifyPhoneNumber,
  findBullhorns,
  findChannels,
  findValues,
  resendPhoneCode,
  returningUser,
  makeOrgAdmin as queryMakeOrgAdmin,
} from '@/graphs/gql';

export default function useOnboardingRoute(page: string): { [key: string]: any } {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const serverError = ref('');
  const submitting = ref(false);

  const { inviteId } = route.params;

  const initData = store.getters['onboarding/useronboarding'][page];
  const prevRoute = initData.prevRoute ? `/user-onboarding/${inviteId}/${initData.prevRoute}` : '';

  const checkHasData = Object.keys(initData).includes('data');

  function getUserToken() {
    const { token } = store.getters['onboarding/useronboarding'].verifyCode.data;
    return token;
  }

  async function makeUserOrgAdmin(input: any): Promise<any> {
    const result = await makeGraphRequest(
      'mutation',
      queryMakeOrgAdmin,
      { input },
      null,
    );
    return result;
  }

  async function makeOrgAdmin(input: any): Promise<any> {
    const result = await makeGraphRequest(
      'mutation',
      updateOrgAndAdmin,
      { input },
      null,
    );
    return result;
  }

  async function saveUserAttributes(input: any): Promise<any> {
    const result = await makeGraphRequest(
      'mutation',
      updateUserAttributes,
      { input },
      getUserToken(),
    );
    return result;
  }

  async function getPhoneCode(): Promise<boolean> {
    const { phone } = store.getters['onboarding/useronboarding'].userDetail.data;
    const result = await makeGraphRequest(
      'query',
      resendPhoneCode,
      { phone },
      null,
    );
    return result.data.resendPhoneCode;
  }

  async function getReturningUser(): Promise<any> {
    const result = await makeGraphRequest(
      'query',
      returningUser,
      { inviteId },
      null,
    );
    return result.data.returningUser;
  }

  function gotoNextRoute() {
    router.push(`/user-onboarding/${inviteId}/${initData.nextRoute}`);
  }

  function resetState() {
    store.commit('onboarding/SET_USER_ONBOARDING_INIT', null);
  }

  async function commitAndGotoNextRoute(data: any) {
    // vuex stuff
    serverError.value = '';
    if (page === 'userDetail') {
      // Create and Admin account with admin email and store it
      if (data.returnUser) {
        submitting.value = true;
        await makeUserOrgAdmin({
          invite_code: data.invite_code,
          user_id: data.user.id,
        });
        submitting.value = false;
        store.commit(
          'onboarding/SET_USER_ONBOARDING_DATA',
          {
            page,
            data: data.user,
          },
        );
        gotoNextRoute();
      } else {
        const input = {
          invite_code: inviteId,
          org_name: '',
          org_email: '',
          org_phone: '',
          org_dob: '',
          firstname: data.firstname,
          lastname: data.lastname,
          title: data.title,
          email: data.email,
          age: parseInt(data.age, 10),
          phone: data.phone,
          password: data.password,
        };
        try {
          submitting.value = true;
          await makeOrgAdmin(input);
          store.commit(
            'onboarding/SET_USER_ONBOARDING_DATA',
            {
              page,
              data: {
                firstname: data.firstname,
                lastname: data.lastname,
                title: data.title,
                phone: data.phone,
                age: data.age,
              },
            },
          );
          gotoNextRoute();
        } catch (err) {
          serverError.value = err.message;
        } finally {
          submitting.value = false;
        }
      }
    } else if (page === 'whyJoiningSpotlight') {
      // const companyDetails = store.getters['onboarding/onboarding'].orgDetail;
      const params: { [key: string]: any } = {};
      const getter = store.getters['onboarding/useronboarding'];
      params.invite_code = inviteId;
      params.organization_type = '';
      params.parent_organization = false;
      // params.profit_type = getter.orgType.data.forProfitTypes;
      // params.nonprofit_type = getter.orgType.data.nonProfitTypes;
      params.bullhorns = getter.causes.data.join(',');
      params.channels = getter.channels.data.join(',');
      params.values = getter.Values.data.join(',');
      params.whyjoin = data.value.whyjoin.join(',');
      params.whyjoin_other = data.value.whyjoin_other;
      try {
        await saveUserAttributes(params);
        store.commit('onboarding/SET_USER_ONBOARDING_DATA', { page, data });
        resetState();
        gotoNextRoute();
      } catch (err) {
        serverError.value = err.message;
      }
    } else {
      store.commit('onboarding/SET_USER_ONBOARDING_DATA', { page, data });
      gotoNextRoute();
    }
  }

  function validateForm(refForm: any, data: any) {
    refForm.value.validate((valid: boolean) => {
      if (valid) return commitAndGotoNextRoute(data);
      console.log('error submit!!');
      return false;
    });
  }
  // graphcalls
  async function validateInviteCode() {
    const result = await makeGraphRequest(
      'query',
      validateInvite,
      { code: inviteId },
      null,
    );
    return result;
  }

  async function validatePhoneToken(token: string) {
    const { phone } = store.getters['onboarding/useronboarding'].userDetail.data;
    try {
      const result = await makeGraphRequest(
        'mutation',
        verifyPhoneNumber,
        { input: { phone, token } },
        null,
      );
      await commitAndGotoNextRoute(result.data.verifyPhoneNumber);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function getBullhorns(getfavourite: boolean) {
    try {
      const result = await makeGraphRequest(
        'query',
        findBullhorns,
        { getfavourite },
        getUserToken(),
      );
      return result.data.findBullhorns;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async function getChannels(getfavourite: boolean) {
    try {
      const result = await makeGraphRequest(
        'query',
        findChannels,
        { getfavourite },
        getUserToken(),
      );
      return result.data.findChannels;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async function getValues(getfavourite: boolean) {
    try {
      const result = await makeGraphRequest(
        'query',
        findValues,
        { getfavourite },
        getUserToken(),
      );
      return result.data.findValues;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  const camelCase = (str: string) => str.replace(/-./g, (match) => match[1].toUpperCase());

  function canOnBoard() {
    if (store.getters['users/isMeSignedIn']) {
      return false;
    }

    if (page !== 'userDetail') {
      const prevData = store.getters['onboarding/useronboarding'][camelCase(initData.prevRoute)];
      if (!prevData.passed) {
        router.push(prevRoute);
      }
    }

    return true;
  }

  async function userUpgrade(user: any) {
    commitAndGotoNextRoute({
      returnUser: true,
      invite_code: inviteId,
      user,
    });
  }
  // end graphcalls

  // kinda onMounted
  store.commit('onboarding/SET_PREV_ROUTE', prevRoute);

  return {
    canOnBoard,
    validateForm,
    validateInviteCode,
    validatePhoneToken,
    gotoNextRoute,
    commitAndGotoNextRoute,
    getValues,
    getBullhorns,
    getChannels,
    getPhoneCode,
    getUserToken,
    getReturningUser,
    serverError,
    resetState,
    userUpgrade,
    makeUserOrgAdmin,
    submitting,
    initData: checkHasData ? JSON.parse(JSON.stringify(initData.data)) : {},
  };
}
