<template lang="pug">
.row.min-h-150
  .col-lg-8.col-12
    div.carouselBody
      .pos-r
      Carousel(:items-to-show="2")
        Slide(v-for="item in myCMSHomeScreenData.metaCarousel" :key="item")
          img(:src="item.image").border.rounded.overflow-hidden.w-100.obj-f-c
          .pin.pin-tr
          img(src="@/assets/icons/deleteIcon.svg" @click='removeItemGetConfirm(() => handleDelete(item))').deleteIconHomePage
          //- Delete Item
        template(#addons)
          Navigation

  .col-lg-4.col-12.d-flex(:class='{"mx-auto": !myCMSHomeScreenData.metaCarousel}')
    el-upload(drag
              :show-file-list="false"
              :on-success="handleSuccess"
              :on-error="handleError"
              :before-upload="beforeUpload"
              :http-request="handleStickerUpload"
              action=""
            ).img-uploader.pos-r.w-100.mx-lg-2.mx-auto
              img(src="@/assets/icons/image-add.svg").sticker-image-icon
              p.upload-picture-text Add Banner
</template>

<script>
import {
  defineComponent, ref, reactive, onMounted, onBeforeMount,
} from 'vue';

import fileUpload from '@/shared/upload';
// import { banners } from '@/data/static-home-page';
import shared from '../../shared';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'



export default defineComponent({
  name: 'HomePage_Banners',
  props: {
    myCMSHomeScreenData: { type: Object, default: () => ({}) },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  setup(props) {
    const {
      removeItemGetConfirm,
    } = shared();
    const state = reactive({ loading: false, items: [] });

    const { uploadStatus, beforeUpload, handleUploadRequest } = fileUpload('stickers', 'Sticker');
    const itemUrl = ref(null);
    const errorMsg = ref('');

    const handleStickerUpload = async (payload) => {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        itemUrl.value = uploadStatus.resourceUrl;

        let imageOrder = 0;
        if (props.myCMSHomeScreenData.metaCarousel) {
          imageOrder = Math.max(...props.myCMSHomeScreenData.metaCarousel.map((o) => o.order)) + 1;
          if (imageOrder === -Infinity) {
            imageOrder = 0;
          }
        }
        // eslint-disable-next-line vue/no-mutating-props
        props.myCMSHomeScreenData.metaCarousel.push({
          id: "0",
          title: '',
          image: uploadStatus.resourceUrl,
          order: imageOrder,
        });
        if (itemUrl.value) errorMsg.value = null;
      }
    };

    const handleSuccess = () => {
      // console.log('succc');
    };

    const handleError = () => {
      // console.log('error');
    };

    // dummy promise function
    // const getItems = () => new Promise((resolve) => setTimeout(() => resolve(banners), 500));

    const handleDelete = (item) => {
      // eslint-disable-next-line vue/no-mutating-props
      props.myCMSHomeScreenData.metaCarousel.splice(state.items.indexOf(item), 1);
    };

    onBeforeMount(async () => {
      state.loading = true;
      state.loading = false;
    });

    return {
      state,
      beforeUpload,
      handleStickerUpload,
      handleSuccess,
      handleError,
      handleDelete,
      removeItemGetConfirm,
    };
  },
});
</script>
