<template lang="pug">
.card.max-w-350
  .card-body
      el-form(
        ref="formRef"
        :model="item"
        label-position="top"
        :rules="rules"
      )
        el-form-item(label="Current Password" prop="oldPass")
          el-input(
            v-model="item.oldPass"
            placeholder="Current Password"
            type="password"
          )

        el-form-item(label="New Password" prop="newPass").el-hint-text-relative
          el-input(
            v-model="item.newPass"
            placeholder="New Password"
            type="password"
          )

        el-form-item(label="Repeat New Password" prop="confirmPass").mb-0
          el-input(
            v-model="item.confirmPass"
            placeholder="Repeat New Password"
            type="password"
          )

  .card-footer
    el-button(type="success" @click="validateForm") Change Password
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import { changePassword } from '@/graphs/gql';
import { ElMessage } from 'element-plus';
import shared from '../shared';

export default defineComponent({
  name: 'ChangePassword',
  setup() {
    const formRef = ref(null);
    const { makeGraphRequest, getAuthToken } = shared();

    const item = reactive({
      email: '',
      oldPass: '',
      newPass: '',
      confirmPass: '',
    });

    const validatePass2 = (_, value, callback) => {
      // params: rule, value, callback
      if (value === '') {
        callback(new Error('Please enter confirm password'));
      } else if (value !== item.newPass) {
        callback(new Error('Password and confirm password don\'t match!'));
      } else {
        callback();
      }
    };

    const validatePass = (_, value, callback) => {
      // params: rule, value, callback
      if (value === '') {
        callback(new Error('Please enter new password'));
      } else if (value.length < 8 || value.search(/[a-z]/) < 0 || value.search(/[0-9]/) < 0
        || value.search(/[A-Z]/) < 0 || value.search(/[!@#$%^&*_-]/) < 0) {
        callback(new Error('Your password must be at least 8 characters in length and contain at least one special character, one lower case letter and one upper case letter.'));
      } else {
        callback();
      }
    };

    const rules = {
      oldPass: { required: true, message: 'Current password is required', trigger: 'blur' },
      // newPass: { required: true, message: 'New password is required', trigger: 'blur' },
      newPass: [
        { validator: validatePass, trigger: 'blur' },
      ],
      confirmPass: [
        { validator: validatePass2, trigger: 'blur' },
      ],
    };

    async function submitForm() {
      try {
        const result = await makeGraphRequest('mutation', changePassword, {
          oldPassword: item.oldPass,
          password: item.newPass,
        }, getAuthToken());
        console.log(result);
        ElMessage.info({
          typ: 'success',
          message: 'Password changed sucessfully',
        });
      } catch (err) {
        ElMessage({
          typ: 'error',
          message: 'Password update failed, please confirm your old password',
        });
      }
    }

    function validateForm() {
      formRef.value.validate((valid) => {
        if (valid) {
          return submitForm();
        }
        return false;
      });
    }

    return {
      // data (static)
      rules,

      // data (dynamic)
      formRef,
      item,

      // methods
      validateForm,
    };
  },
});
</script>
