<template lang="pug">
el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(title='Channels' back-to='/manage-content/directory/channels')
    template(#actions)
      .row.row-sm.align-items-center
        .col-auto
          div(@click.stop="handleToggleAddToFav").cp
            svg(v-if="item.favorite").size-32
              use(xlink:href="#fas-star")
            fai(v-else type="fal" icon="star").size-32

        .col-auto
          el-button(type="danger" plain @click="removeChannelItem").has-fai
            fai(type="fal" icon="trash").size-18.me-2
            span.min-w-110.d-inline-block Delete
          el-button(type="primary" @click="validateForm").has-fai
            fai(type="fal" icon="edit").size-18.me-2
            span.min-w-110.d-inline-block Save

    template(#col-left)
      div
        el-form-item(label="Channel" prop="channel")
          el-input(v-model="item.channel")

        el-form-item(label="Category", prop="categoryId")
          VueMultiselect(
              v-model="item.categoryId",
              :multiple="false",
              label="category",
              track-by="id",
              :options="categories.categories",
              :searchable="true",
              :close-on-select="true",
              :show-labels="false"
              placeholder="Pick a value"
            )
        el-form-item(label="Description" prop="description")
          el-input(v-model="item.description" type="textarea")

    template(#col-right)
      div
        el-form-item(label="Creator")
          el-input(v-model="item.creator" disabled)

        el-form-item
          template(#label)
            | Post Date
            span.text-muted.ms-2 Active for 3 Days
          el-input(v-model="item.postDate" disabled)

        el-form-item(label="Post Time")
          el-input(v-model="item.postTime" disabled)
</template>

<script>

import {
  defineComponent,
  reactive,
  ref,
  onMounted,
} from 'vue';

import { channel, category } from '@/data/rules';
import VueMultiselect from 'vue-multiselect';
import shared from './shared';

export default defineComponent({
  name: 'ManageContent_Directory_ChannelDeail',
  components: { VueMultiselect },
  setup() {
    const {
      route,
      router,
      loading,
      getTypedCategories,
      getChannel,
      saveChannel,
      removeItemGetConfirm,
      dateAndTime,
    } = shared();
    const refForm = ref(null);
    const { id } = route.params;
    const categories = reactive({ categories: [] });
    const item = reactive({
      id: -1,
      channel: '',
      creator: 'Admin Name',
      postDate: '02/12/2021',
      postTime: '2:30 PM',
      favorite: false,
    });

    async function getItem() {
      loading.value = true;
      const itm = await getChannel(parseInt(id, 10));
      item.id = itm.id;
      item.channel = itm.channel;
      item.description = itm.description;
      item.creator = itm.createdBy;
      item.categoryId = itm.category;
      [item.postDate, item.postTime] = dateAndTime(itm.createdAt);
      loading.value = false;
      item.favorite = itm.favorite;
    }

    async function saveItem() {
      await saveChannel({
        id: parseInt(item.id, 10),
        channel: item.channel,
        description: item.description,
        categoryId: parseInt(item.categoryId.id, 10),
      });
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) return saveItem();
        return false;
      });
    }

    onMounted(async () => {
      categories.categories = await getTypedCategories();
      await getItem();
    });

    async function removeItem() {
      await saveChannel({
        id: parseInt(item.id, 10),
        delete: true,
      });
      router.push('/manage-content/directory/channels');
      // console.log('remvoe item here :>> ');
    }

    function removeChannelItem() {
      removeItemGetConfirm(removeItem);
    }

    async function handleToggleAddToFav() {
      item.favorite = !item.favorite;
      loading.value = true;
      await saveChannel({
        id: parseInt(item.id, 10),
        favorite: item.favorite,
      });
      loading.value = false;
    }

    return {
      // data
      refForm,
      rules: { channel, categoryId: category },
      item,
      loading,
      categories,
      // methods
      validateForm,
      removeChannelItem,
      handleToggleAddToFav,
    };
  },
});
</script>
