<template lang="pug">
app-main-content
    template(v-if="$route.path === '/manage'")
      .p-5.text-center.text-muted
        h6 Select the section from Menu to manage Users, Organizations or Invites
    template(v-else)
      router-view(:key="$route.fullPath")
            //- views render here
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { dashboard, orgAdminMenu } from '@/data/main-menu';
import shared from './shared';

export default defineComponent({
  name: 'ManageContent',
  setup() {
    const { isOrgAdmin } = shared();

    return {
      menuItems: isOrgAdmin() ? orgAdminMenu : dashboard,
    };
  },
});
</script>
