import { gql } from '@apollo/client/core';

export const findWorkflows = gql`
  query {
   findWorkflows {
      id
      title
   }
}`;

export const mutateWorkflow = gql`
  mutation($input: WorkflowInput!){
  mutateWorkflow(input:$input){
    id
    title
  }
}`;

export const findWorkflow = gql`query($id: String!) {
    findWorkflow(id: $id){
      id
      title
  }
}`;
