<template lang="pug">
.text-center.py-4
  h4.mb-4.max-w-350.mx-auto
    span.text-success Spotlight
    |  is a members-only social network.

  .mb-3
    | By continuing, you agree to make consicious decisions and do intentional
    | work to brighten the world with your ideas, words and actions.
  .mb-5 Please read and agree to the following to continue:

  hr

  .py-2
    el-checkbox(v-model="item.codeOfConduct").me-4 Legal Stuff
    a(href="rules/legal-stuff.html" target="_blank").el-button.el-button--text.me-5 View

    el-checkbox(v-model="item.clubRules").me-4 Collective Rules
    a(href="rules/index.html" target="_blank").el-button.el-button--text View

  hr.mb-5

  .text-center
    el-button(
      round type="success"
      @click="submitForm"
      :disabled="!(item.codeOfConduct && item.clubRules)"
    ) Next
</template>

<script lang="ts">

import { defineComponent, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import useOnboardingRoute from './composables/useOnboardingRoute';

export default defineComponent({
  name: 'Onboarding_AgreeRules',

  setup() {
    const { initData, commitAndGotoNextRoute, canOnBoard } = useOnboardingRoute('agreeRules');
    const router = useRouter();

    onMounted(async () => {
      try {
        if (!canOnBoard()) {
          router.push('/');
        }
      } catch (err) {
        console.log(err);
      }
    });

    function viewRules(opt: string, e: any) {
      e.preventDefault();
      const basePath = import.meta.env.VITE_WEB_BASE_URL;
      if (opt === 'conduct') {
        window.open(`${basePath}/rules/index.html`, '_blank');
      } else if (opt === 'clubRules') {
        window.open(`${basePath}/rules/legal-stuff.html`, '_blank');
      }
    }

    return {
      // data
      item: reactive(initData),
      viewRules,
      // methods
      submitForm: () => commitAndGotoNextRoute(initData),
    };
  },
});
</script>
