<template lang="pug">
el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(title='Values' back-to='/manage-content/directory/values')
    template(#actions)
      .row.row-sm.align-items-center
        .col-auto
          div(@click.stop="handleToggleAddToFav").cp
            svg(v-if="item.favorite").size-32
              use(xlink:href="#fas-star")
            fai(v-else type="fal" icon="star").size-32

        .col-auto
          el-button(type="danger" plain @click="removeValueItem").has-fai
            fai(type="fal" icon="trash").size-18.me-2
            span.min-w-110.d-inline-block Delete
          el-button(type="primary" @click="validateForm").has-fai
            fai(type="fal" icon="edit").size-18.me-2
            span.min-w-110.d-inline-block Save

    template(#col-left)
      div
        el-form-item(label="Value Name" prop="value")
          el-input(v-model="item.value")

        //- el-form-item(label="Analytics")
        //-   .p-3.bg-light.rounded
        //-     .row
        //-       .col-auto
        //-         .text-muted Views
        //-         .lh-sm.fw-b.fs-18 {{ `13,490` }}
        //-       .col-auto
        //-         .text-muted Followers
        //-         .lh-sm.fw-b.fs-18 {{ `4,120` }}
        //-       .col-auto
        //-         .text-muted Action Taken
        //-         .lh-sm.fw-b.fs-18 {{ `728` }}

        el-form-item(label="Description" prop="description").mb-0
          el-input(v-model="item.description" type="textarea")

      div
        el-form-item.mb-0
          template(#label)
            h5 Quotes

          .p-3.bg-light.rounded
            quote(
              v-for="(quote, index) in item.quotes" :key="index"
              :item="quote" @save-item="handleAddNewQuote($event)"
              @delete-item="handleDeleteQuote($event)"
            ).mb-3
            div(v-if="addNewQuote")
              quote(
                is-new-item
                :item="{ author: '', quote: '' }"
                @save-item="handleAddNewQuote($event)"
                @cancel-item="addNewQuote = false"
              )
            .text-center(v-else)
              el-button(type="success" plain @click="addNewQuote = true") Add New

    template(#col-right)
      div
        el-form-item(label="Creator")
          el-input(v-model="item.creator" disabled)

        el-form-item
          template(#label)
            | Post Date
            span.text-muted.ms-2 Active for 3 Days
          el-input(v-model="item.postDate" disabled)

        el-form-item(label="Post Time").mb-0
          el-input(v-model="item.postTime" disabled)
</template>

<script>

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';
import { value } from '@/data/rules';
import shared from './shared';
import Quote from '../components/Quote.vue';

export default defineComponent({
  name: 'ManageContent_Directory_ValueDeail',
  components: { Quote },
  setup() {
    const {
      route,
      router,
      loading,
      getValue,
      saveValue,
      removeItemGetConfirm,
      dateAndTime,
      getEntityQuotes,
      saveEntityQuote,
    } = shared();
    const refForm = ref(null);
    const addNewQuote = ref(false);
    const { id } = route.params;
    const item = reactive({
      id: -1,
      value: 'Very Long Value',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      quotes: [],
      creator: 'Admin Name',
      postDate: '02/12/2021',
      postTime: '2:30 PM',
      favorite: false,
    });

    async function getItem() {
      loading.value = true;
      const itm = await getValue(parseInt(id, 10));
      item.id = itm.id;
      item.value = itm.value;
      item.description = itm.description;
      item.creator = itm.createdBy;
      [item.postDate, item.postTime] = dateAndTime(itm.createdAt);
      loading.value = false;
      item.favorite = itm.favorite;
    }

    async function saveItem() {
      await saveValue({
        id: parseInt(item.id, 10),
        value: item.value,
        description: item.description,
      });
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    onMounted(async () => {
      await getItem();
      item.quotes = await getEntityQuotes('VALUE', parseInt(id, 10));
    });

    async function removeItem() {
      await saveValue({
        id: parseInt(item.id, 10),
        delete: true,
      });
      router.push('/manage-content/directory/values');
    }

    function removeValueItem() {
      removeItemGetConfirm(removeItem);
    }

    async function handleAddNewQuote(quote) {
      // console.log('handleaddnewquote');
      // console.log(quote.value);
      const input = {
        entityType: 'VALUE',
        entityId: parseInt(id, 10),
        author: quote.value.author,
        quote: quote.value.quote,
      };
      if (quote.value.id) {
        input.id = parseInt(quote.value.id, 10);
      }
      // console.log(input);
      // console.log('handleaddnewquote');
      await saveEntityQuote(input);
      item.quotes = await getEntityQuotes('VALUE', parseInt(id, 10));
      addNewQuote.value = false;
    }

    async function handleDeleteQuote(quote) {
      removeItemGetConfirm(async () => {
        const input = {
          id: parseInt(quote.id, 10),
          entityType: 'VALUE',
          entityId: parseInt(id, 10),
          delete: true,
        };
        try {
          await saveEntityQuote(input);
        } catch (e) {
          console.log(e);
        }
        item.quotes = await getEntityQuotes('VALUE', parseInt(id, 10));
      });
    }

    async function handleToggleAddToFav() {
      item.favorite = !item.favorite;
      loading.value = true;
      await saveValue({
        id: parseInt(item.id, 10),
        favorite: item.favorite,
      });
      loading.value = false;
    }

    return {
      // data
      refForm,
      rules: { value },
      item,
      addNewQuote,

      // methods
      validateForm,
      removeValueItem,
      handleAddNewQuote,
      handleDeleteQuote,
      handleToggleAddToFav,
    };
  },
});
</script>
