<template lang="pug">
aside.main-menu(v-if="showAppMenu")
    .d-flex.flex-column.min-vh-100
      div.py-3(v-if="isOrgAdminActive")
       router-link(to='/manage/orgs').p-20
        img(src="@/assets/images/logos/bolt-rounded.png").size-50px
        img(src="@/assets/images/logos/logo-text@2x.png").w-170.h-30.pl-15
      div.py-3(v-else)
       router-link(to='/').p-20
        img(src="@/assets/images/logos/bolt-rounded.png").size-50px
        img(src="@/assets/images/logos/logo-text@2x.png").w-170.h-30.pl-15

      #nav.flex-grow-1.d-flex.flex-column.pos-r.main-menu-items(
        :class="{ 'is-collapsed': isMainMenuCollapsed }"
      )

        .px-20
            orgs-dropdown

        el-menu(
          :default-active="activeMenuItem"
          :collapse="isMainMenuCollapsed"
          text-color="#303133"
          active-text-color="#303133"
          :unique-opened="true"
          @select="handleSelectMenu"
        ).flex-grow-1.d-flex.flex-column
          template(
            v-for="(item,i) in menuItems"
          )
            el-sub-menu(
              v-if="item.hasSubNav"
              :key="item.path"
              :index="item.path"
              )
              template(
                #title=''
              )
                div
                  span(v-if='item.icon && isMenuOpened(item.path)')
                    fai(type="fas" :icon="item.icon").size-24
                  span(v-else-if='item.icon')
                    fai(type="fal" :icon="item.icon").size-24
                  span.pl-15.fs-20 {{ item.label  }}
              el-menu-item-group
                el-menu-item(
                  v-for="(submenu,i) in item.subNav"
                  :index = "submenu.path"
                  :key="submenu.path"
                )
                  span.fs-18 {{ submenu.label }}
                  span(v-if='submenu.faIcon').submenu-right-icon
                    fai(type="fal" :icon="submenu.icon").size-18
            el-menu-item(
              v-else
              :key="item.path"
              :index="item.path"
              :class="isMenuActive(item.path)"
            )
              div
                span(v-if='item.icon && isMenuActive(item.path)')
                  fai(type="fas" :icon="item.icon").size-24
                span(v-else-if='item.icon')
                  fai(type="fal" :icon="item.icon").size-24
                span.pl-15 {{ item.label  }}
          div.mt-auto
            el-menu-item(
            v-if="!isOrgAdminActive"
            index="/settings"
            :class="isMenuActive('/settings')"
            )
              fai(type="fal" icon="gear").size-24
              span.padding-left-20 Settings
            el-menu-item(@click='handleSignOut' index="/auth")
              fai(type="fal" icon="arrow-right-from-bracket").size-24
              span.padding-left-20 Sign out
            hr.solid.my-4.mx-4
</template>

  <script lang="ts">
  import { defineComponent, ref, computed, watch } from 'vue';
  import { useStore, mapGetters } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { ElMenu, ElMenuItem, ElMenuItemGroup, ElSubMenu } from 'element-plus';
  import OrgsDropdown from '@/components/OrgsDropdown.vue';
  import shared from '@/shared';
  import store from "@/store";

  import { dashboard as mainMenuItems,  orgAdminMenu as orgMenuItems } from '@/data/main-menu';

  const selectedOrganization = computed(() => store.getters["organizations/GET_SELECTED_ORGANIZATION"]);

  export default defineComponent({
    name: 'AppMainMenu',
    components: { ElMenu, ElMenuItem, ElMenuItemGroup, ElSubMenu, OrgsDropdown },

    computed: {
      ...mapGetters('shared', ['isMainMenuCollapsed']),
    },

    setup() {
      const route = useRoute();
      const router = useRouter();
      const store = useStore();
      const toShow = ref();
      const { isOrgAdmin } = shared();
      const showAppMenu = ref(true);
      const isOrgAdminActive = ref(false);

      const menuItems = computed(() => {
        if(!store.getters['users/isMeSignedIn']){
          showAppMenu.value = false;
          return [];
        }
        if (store.getters['users/isOrgAdmin']) {
          isOrgAdminActive.value = true;
          showAppMenu.value = true;
          return orgMenuItems.map((item) => {
            const i = item;
            if (i.label === 'Activity') {
              i.path = selectedOrganization.value!= null? `/activity/${selectedOrganization.value.id}` : '/activity/:id'
            }
            if(i.label === 'Stickers') {
              i.path = selectedOrganization.value!= null? `/manage/orgs/${selectedOrganization.value.id}/stickers`: '/manage/orgs/ORG_ID/stickers'
            }
            return item;
          });
        }
        showAppMenu.value = true;
        return mainMenuItems;
      });


      watch(
        () => route.params.orgId,
        (newOrgId) => {
          toShow.value = orgMenuItems.map((p) => {
            // if the current page does not have org id, hide the sticker sidebar menu
            if (p.path.indexOf('ORG_ID') >= 0 && !newOrgId) {
              return false;
            }
            return true;
          });
        },
        //trigger watch when the component is created
        { immediate: true }
      );
      const isMenuActive = (vRoute: string): string => {
        if (route.path === vRoute) return 'is-active';
        return '';
      };
      const isMenuOpened = (vRoute: string): boolean => {
        if (route.path.indexOf(vRoute + "/") >= 0) return true;
        return false;
      };

      const handleSelectMenu = (key: string): void => {
        // available perms: key, keyPath
        if (route.path === key) return;
        router.push({ path: key });
      };

      const goBack = () => {
        router.go(-1);
      };
      const handleSignOut = () => {
        localStorage.clear();
        console.log(localStorage);
        store.dispatch('organizations/SET_SELECTED_ORGANIZATION', null);
        store.dispatch('users/resetMe');
        router.push('/auth');
      };

      return {
        // data
        activeMenuItem: ref(null),
        isOrgAdmin,
        toShow,
        menuItems,
        showAppMenu,
        isOrgAdminActive,
        // methods
        isMenuActive,
        isMenuOpened,
        handleSelectMenu,
        handleSignOut,
        goBack,
      };
    },
  });
  </script>
