<template lang="pug">
el-form-item(label="Bullhorns")
  .p-3.bg-light.rounded
    .el-tag-wrapper
      el-tag(
        type="info" :key="index"
        v-for="(bullhorn, index) in item.bullhorns"
      ) {{ bullhorn.bullhorn }}

el-form-item(label="Values")
  .p-3.bg-light.rounded
    .el-tag-wrapper
      el-tag(
        type="info" :key="index"
        v-for="(value, index) in item.values"
      ) {{ value.value }}

el-form-item.mb-0(label="Channels")
  .p-3.bg-light.rounded
    .el-tag-wrapper
      el-tag(
        type="info" :key="index"
        v-for="(channel, index) in item.channels"
      ) {{ channel.channel }}
el-form-item.mb-0(label="Interests")
  .p-3.bg-light.rounded
    .el-tag-wrapper
      el-tag(
        type="info" :key="index"
        v-for="(interest, index) in item.interests"
      ) {{ interest.interest }}
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserCommonSections',

  props: {
    item: { type: Object, default: () => ({}) },
  },
});
</script>
