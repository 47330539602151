<!-- eslint-disable max-len -->
<template lang="pug">
.px-3
  .mx-auto.mb-4
    .d-flex

    .row.row-xs.align-items-center
      .col-auto
        img(:src="stickerDetails.url").w-60.obj-f-c
      .col.lh-1
          .ms-auto {{ stickerDetails.title }}
          .fs-12 {{ stickerDetails.creatorTitle }}
          .fs-12 Total Cost {{ totalCost }}
      .col-auto
        .ms-auto ${{ parseFloat(amt).toFixed(2) }} {{ assignStickerCostToAmount() }}
      .alert.alert-danger.margin-top-20(v-if="!isValid()") {{ validationError() }}

</template>

<script>
import {
  ref, computed, defineComponent,
  watch,
} from 'vue';
import shared from './shared';

export default defineComponent({
  name: 'TicketInputForm',
  props: {
    isEditable: { type: Boolean, default: false },
    userDetails: { type: Object, default: () => ({}) },
    showForm: { type: Boolean, default: true },
    stickerDetails: { type: Object, default: () => ({}) },
    validationDetails: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const amt = ref();
    const {
      route,
    } = shared();
    const {
      isWebUser,
    } = route.query;
    const termsAndConditionsLink = import.meta.env.VITE_TERMS_AND_CONDITIONS_LINK;
    const privacyPoliciesLink = import.meta.env.VITE_PRIVACY_POLICY_LINK;

    let variantIds = route.params.variantIds?.replace(' ', '').split(',');
    const quantities = route.params.quantities?.split(',');

    // Compute total cost based on variant IDs
    const totalCost = computed(() => {
      return props.stickerDetails.varients
        ?.filter(variant => variantIds?.includes(variant.id))
        .map(variant => {
          const index = variantIds.indexOf(variant.id);
          const quantity = quantities[index];
          return variant.cost * quantity;
        })
        .reduce((sum, cost) => sum + cost, 0);
    });

    function assignStickerCostToAmount() {
        amt.value = totalCost.value;
    }

    function isValid() {
      return this.validationDetails.isValid;
    }

    function validationError() {
      if (!this.validationDetails.isValid && this.validationDetails.errors) {
        return this.validationDetails.errors[0];
      }
      return null;
    }

    function getRedirectUrl() {
      if(isWebUser === 'true'){
        return import.meta.env.VITE_WEB_BASE_URL;
      }
      else {
        return import.meta.env.VITE_APP_REDIRECT_BASE_URL;
      }
    }

    function cancelIntent() {
      window.location.href = `${getRedirectUrl()}?action=StickerPurchaseCancel`;
    }

    watch(
      () => amt.value,
      () => {
        emit('input-amt', amt.value);
      },
    );
    return {
      amt,
      termsAndConditionsLink,
      privacyPoliciesLink,
      cancelIntent,
      assignStickerCostToAmount,
      isValid,
      validationError,
      variantIds,
      totalCost,
    };
  },
});
</script>
