<template lang="pug">
div(v-loading="loading")
  .card-header.p-4.bg-transparent
    .row.row-sm.align-items-center
      .col
        .d-flex
          router-link(to="/manage-content/spots")
            fai(type="fal" icon="angle-left").size-28.me-2
          h4.mb-0 Spots
      .col-auto
        div(@click.stop="handleToggleAddToFav").cp
          svg(v-if="item.isFavourite").size-32
            use(xlink:href="#fas-star")
          fai(v-else type="fal" icon="star").size-32

      .col-auto
        el-button(type="danger" plain @click="removeItem").has-fai
          fai(type="fal" icon="trash").size-18.me-2
          span.min-w-110.d-inline-block Delete

        el-button(type="primary" @click="validateForm" v-loading="loading").has-fai
          fai(type="fal" icon="edit").size-18.me-2
          span.min-w-110.d-inline-block Save

  .card-body
    el-form(
      ref="refForm"
      :rules="rules"
      :model="item"
      status-icon
      label-position="top"
    )
      el-tabs(v-model="activeTabName")
        el-tab-pane(label="Overview" name="overview")
          .row
            .col-md-8
              el-form-item(label="Spot Name" prop="title")
                el-input(v-model="item.title")

              el-form-item(label="Analytics")
                .p-3.bg-light.rounded
                  .row
                    .col-auto
                      .text-muted Views
                      .lh-sm.fw-b.fs-18 {{ `13,490` }}
                    .col-auto
                      .text-muted Followers
                      .lh-sm.fw-b.fs-18 {{ `4,120` }}
                    .col-auto
                      .text-muted Action Taken
                      .lh-sm.fw-b.fs-18 {{ `728` }}

              el-form-item(label="Ribbons")
                el-input(disabled)

              el-form-item(label="Description" prop="description")
                el-input(v-model="item.description" type="textarea")

              el-form-item(label="Additional Photos")
                .row
                  .col-3(v-for="(item, index) in 5" :key="index").mb-4
                    img(:src="`//placekitten.com/g/300/300`").w-100.obj-f-c

              el-form-item(label="Audience")
                el-input(disabled)

              el-form-item(label="Sub-Groups")
                el-input(disabled)

              el-form-item(label="Allies")
                el-input(disabled)

              el-form-item(label="Channel")
                el-select(v-model="item.selctedChanneleId" placeholder="Select" multiple).w-100
                  el-option(
                    v-for="type in valueLookups"
                    :key="type.id"
                    :label="type.title"
                    :value="type.id"
                  )

              el-form-item(label="Values")
                el-select(v-model="item.selcteValueIds" placeholder="Select" multiple).w-100
                  el-option(
                    v-for="value in valueLookups"
                    :key="value.id"
                    :label="value.title"
                    :value="value.id"
                  )

              el-form-item(label="Interests")
                el-select(v-model="item.selctedIntrestIds" placeholder="Select" multiple).w-100
                  el-option(
                    v-for="interest in interestLookups"
                    :key="interest.id"
                    :label="interest.title"
                    :value="interest.id"
                  )

              el-form-item(label="Bullhorns")
                el-select(v-model="item.selctedBullhornIds" placeholder="Select" multiple).w-100
                  el-option(
                    v-for="bullhorn in bullhornLookups"
                    :key="bullhorn.id"
                    :label="bullhorn.title"
                    :value="bullhorn.id"
                  )

              el-form-item(label="Benchmarks")
                el-input(disabled)

              el-form-item(label="Myths")
                .bg-light.rounded
                  .p-3(v-for="(item, index) in 2" :key="index").mb-n3
                    .card
                      .card-body.lh-n
                        | Lorem ipsum, or lipsum as it is sometimes known, is dummy
                        | text used in laying out print, graphic or web designs.

              el-form-item(label="Statistics")
                .p-3.bg-light.rounded
                  .row
                    .col-auto
                      .text-muted Metric
                      .lh-sm.fw-b.fs-18 {{ `13,490` }}
                    .col-auto
                      .text-muted Number One
                      .lh-sm.fw-b.fs-18 {{ `4,120` }}
                    .col-auto
                      .text-muted Number Two
                      .lh-sm.fw-b.fs-18 {{ `728` }}
                    .col-auto
                      .text-muted Another Metric
                      .lh-sm.fw-b.fs-18 {{ `$34,270` }}

              el-form-item(label="Systematric Issues")
                el-input(disabled)

            .col-md-4
              el-upload(
                :show-file-list="false"
                :on-success="handleUploadSuccess"
                :on-error="handleUploadError"
                :before-upload="beforeUpload"
                :http-request="handleUpload"
                action=""
              ).img-uploader.pos-r.mb-4
                template(v-if="item.url")
                  img(:src="item.url").img-fluid
                  .pin.pin-bx.d-flex.p-2
                    el-button(type="primary").ms-auto Change
                template(v-else)
                  img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
                  p.upload-picture-text Drop an image file here or
                  div.fake-upload-button Browse For File

              el-form-item(label="Creator")
                el-input(v-model="item.creator" disabled)

              el-form-item
                template(#label)
                  | Post Date
                  span.text-muted.ms-2 Active for 3 Days
                el-input(v-model="item.postDate" disabled)

              el-form-item(label="Post Time")
                el-input(v-model="item.postTime" disabled)

        el-tab-pane(label="Campaign Details" name="campaign-details")
          .row
            .col-md-8
              .row
                .col-md-6
                  el-form-item(label="Fundraising")
                    el-input(disabled)
                .col-md-6
                  el-form-item(label="Payment Account Linked")
                    el-input(disabled)

              el-form-item(label="Goals")
                el-input(disabled)

              el-form-item(label="Actions")
                el-input(disabled)

              el-form-item(label="Teams")
                el-input(disabled)

              el-form-item(label="Roles")
                .bg-light.rounded
                  .p-3(v-for="(item, index) in 2" :key="index").mb-n3
                    .card
                      .card-body
                        h6.mb-4 Role Title
                        p.mb-0.lh-sm
                          | Lorem ipsum, or lipsum as it is sometimes known, is dummy
                          | text used in laying out print, graphic or web designs.

            .col-md-4
              el-form-item(label="Campaign Start Date")
                el-date-picker( v-model="item.dateTime" type="date" ).w-100

              el-form-item(label="Campaign End Date")
                el-date-picker( v-model="item.dateTime" type="date" ).w-100

        el-tab-pane(label="Pages" name="pages")
          el-form-item(label="About")
            el-input(type="textarea")

          el-form-item(label="Resouces")
            el-input(type="textarea")

          el-form-item(label="Stories")
            el-input(type="textarea")

          el-form-item(label="Shop")
            el-input(type="textarea")

          el-form-item(label="Convo")
            el-input(type="textarea")

          el-form-item(label="Documents")
            el-input(type="textarea")
</template>

<script>
import {
  defineComponent, reactive, onMounted, ref,
} from 'vue';
import fileUpload from '@/shared/upload';
import { title } from '@/data/rules';
import shared from './shared';

const staticData = {
  id: '1',
  title: '',
  imgUrl: '',
  creator: '',
  selctedChanneleId: '',
  selcteValueIds: [],
  selctedIntrestIds: [],
  selctedBullhornIds: [],
  postDate: '',
  postTime: '',
};

const valueLookups = [
  { id: '1', title: 'Value 1' },
  { id: '2', title: 'Value 2' },
];
const interestLookups = [
  { id: '1', title: 'Interest 1' },
  { id: '2', title: 'Interest 2' },
];
const bullhornLookups = [
  { id: '1', title: 'Bullhorn 1' },
  { id: '2', title: 'Bullhorn 2' },
];

export default defineComponent({
  name: 'ManageContent_SpotDeail',
  setup() {
    const refForm = ref(null);
    const { route, loading, updateSticker } = shared();
    const { uploadStatus, beforeUpload, handleUploadRequest } = fileUpload('stickers', 'Sticker');

    const { id } = route.params;

    const item = reactive({
      item: {
        id: -1,
        title: '',
        description: '',
        imgUrl: '',
        published: false,
        user: { name: '' },
      },
    });

    async function getEvent(eventId) {
      console.log('id :>> ', eventId);
      return new Promise((resolve) => setTimeout(() => resolve(staticData), 1000));
    }

    async function getItem() {
      item.item = await getEvent(id);
    }

    async function publishItem(opt) {
      const input = JSON.parse(JSON.stringify(item.item));
      console.log(input);
      await updateSticker({
        id: parseInt(input.id, 10),
        title: input.title,
        url: input.url,
        description: input.description,
        published: opt,
      });
      await getItem();
    }

    function removeItem() {
      console.log('remove item - event');
    }

    async function saveItem() {
      loading.value = true;
      // await saveEvent({
      //   id: parseInt(item.id, 10),
      // });
      loading.value = false;
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    async function handleUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.value.url = uploadStatus.resourceUrl;
      }
    }

    function handleUploadSuccess() {
      console.log('success');
    }

    function handleUploadError() {
      console.log('error');
    }

    function handleToggleAddToFav() {
      loading.value = true;
      setTimeout(() => {
        item.isFavourite = !item.isFavourite;
        loading.value = false;
      }, 1000);
    }

    onMounted(async () => {
      await getItem();
    });

    return {
      // data
      item,
      loading,
      rules: { title },
      activeTabName: ref('overview'),

      valueLookups,
      interestLookups,
      bullhornLookups,

      // methods
      publishItem,
      removeItem,

      beforeUpload,
      handleUpload,
      handleUploadSuccess,
      handleUploadError,
      handleToggleAddToFav,

      validateForm,
    };
  },
});
</script>
