<template lang="pug">
app-main-content
    app-main-content-layout(:title='wish' no-padding-w-full)
      div
        .px-30.py-4.border-bottom
          h5.mb-0 Insights
        .row.gx-30.px-30
          .col.py-4
            h5 Page Views
            .text-muted.fs-14.mb-4 May 17 - May 23
            h1 88
          .col.py-4.border-start.border-end
            h5 Page Follows
            .text-muted.fs-14.mb-4 May 17 - May 23
            h1 120
          .col.py-4
            h5 Stickers Purchased
            .text-muted.fs-14.mb-4 May 17 - May 23
            h1 14
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { dashboard, orgAdminMenu } from '@/data/main-menu';

export default defineComponent({
  name: 'Dashboard',
  setup() {
    const store = useStore();

    const menuItems = computed(() => {
      if (store.getters['users/isOrgAdmin']) {
        return orgAdminMenu;
      }
      return dashboard;
    });

    const wish = computed(() => {
      const today = new Date();
      const curHr = today.getHours();

      if (curHr < 12) {
        return 'Good Morning';
      } if (curHr < 18) {
        return 'Good Afternoon';
      }
      return 'Good Evening';
    });

    return {
      // getter
      me: computed(() => store.getters['users/me']),
      wish,

      // data
      user: {},
      menuItems,
    };
  },
});
</script>
