<!-- eslint-disable max-len -->
<template lang="pug">
app-main-content-layout(title='Requests')
  div
    div.filter-container
      span.padding-left-20(@click="headerFilter('All')"
      :class="getClass('All')") All
      span.padding-left-20(@click="headerFilter('Pending')"
        :class="getClass('Pending')") Pending
      span.padding-left-20(@click="headerFilter('Approved')"
        :class="getClass('Approved')") Approved
      span.padding-left-20(@click="headerFilter('Declined')"
        :class="getClass('Declined')") Declined

    .container.align-items-center
      .row.align-items-center
        .col-md
          .row.align-items-center
            el-input(
              v-model="searchQuery"
              placeholder="Filter requests"
              suffix-icon="el-icon-search"
              style="width: 834px;"
            ).input-search-filter
              template(#suffix='')
                img(
                  src="@/assets/icons/searchIcon.svg",
                  style="font-size: 20px;")

            //- el-button(type="info" v-show="isFiltered" @click="clearTextFilter").has-fai
            //-   fai(type="fal" icon="times").size-18.me-2
            //-   span.min-w-110.d-inline-block Clear Filter
            .col-auto()
              VueMultiselect(
                  v-model="sortBy",
                  :multiple="false",
                  :options="sortOptions",
                  :searchable="false",
                  :close-on-select="true",
                  :show-labels="false"
                  label="label"
                  placeholder="Sort"
                  @close="sortTable"
                  style="width: 180px;"
                )

    div.margin-top-20(v-show="selected.length >0" )
      .row.row-sm.align-items-center
        .col {{selected.length}} selected
        .col-auto()
          el-button(type="info" v-show="selected.length >0"
          @click="bulkUpdate('Approved')").has-fai.bulk-options
            fai(type="fal" icon="check").size-18.me-2
            span.min-w-110.d-inline-block Approve
        .col-auto()
          el-button(type="info" v-show="selected.length >0"
          @click="bulkUpdate('Declined')").has-fai.bulk-options
            fai(type="fal" icon="clock").size-18.me-2
            span.min-w-110.d-inline-block Decline
    el-table(
      :data="items.value"
      v-loading="loading"
      @row-click="routeToOrg($event)"
      style="width: 100%"
      @selection-change="onCheckboxChange"
    ).clickable-rows.margin-top-20
      el-table-column(width="15")
      el-table-column(type="selection" width="55")
      el-table-column(label="Name" min-width="135")
        template(#default="scope")
          .lh-sm(v-if="scope.row.claimer")
            .font-black {{ scope.row.claimer.firstname }} {{ scope.row.claimer.lastname }}
          .lh-sm(v-else)
            .font-black N/A
      
      el-table-column(label="Email" min-width="200")
        template(#default="scope")
          .font-medium {{ scope.row.email }}

      el-table-column(label="Page" min-width="230")
        template(#default="scope")
            .fw-meduim.text-decoration-underline {{ scope.row.causeName }}

      el-table-column(label="EIN" min-width="120")
        template(#default="scope")
          .lh-sm.font-medium {{ scope.row.causeId }}
      
      el-table-column(label="Status" min-width="100")
        template(#default="scope")
          div(:class="getButtonClass(scope.row.claimStatus)") {{ scope.row.claimStatus }}

      el-table-column(width="46")
        .el-table__expand-icon
          img(src="@/assets/icons/light/chevron-right-light.svg")

      el-table-column(width="15")
          // space

    div.py-20.justify-center
      el-pagination(
        v-model:current-page="page"
        layout='prev, pager, next'
        :total="totalCount"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      )
</template>

<script>
import {
  defineComponent, ref, onMounted, reactive,
  computed, watch,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import shared from '../shared';

export default defineComponent({
  name: 'ManageContent_Users',
  components: { VueMultiselect },
  setup() {
    const {
      router,
      loading,
      getSyncedBenevityCauses,
      mutateBulkClaimStatus,
    } = shared();
    const items = reactive([]);
    const serverError = ref('');
    const filter = ref('');

    const currentView = ref('All');
    const selected = ref([]);
    const searchQuery = ref('');
    const isFiltered = ref(false);
    const sortOptions = [{ label: 'EIN', value: 'ein' }, { label: 'Page', value: 'lastname' }];
    const setAsOption = [{label:'Archived', value:'archive'}]
    const sortBy = ref('');
    const setAs = ref('');

    const query = ref(null);
    const claimStatus = ref(null);
    const page = ref(1);
    const pageSize = ref(10);
    const totalCount = ref(0);

    const filtered = computed(() => {
      if (filter.value === '') {
        return items.items;
      }
      const regex = new RegExp(`${filter.value}`, 'gi');
      return items.items.filter((x) => {
        if (x.firstname.match(regex)) {
          return true;
        }
        if (x.lastname.match(regex)) {
          return true;
        }
        if (x.phone.match(regex)) {
          return true;
        }
        return false;
      });
    });

    function routeToOrg(itm) {
      router.push(`/manage/benevity-claim/${itm.id}`);
    }

    function sortTable() {
      if (sortBy.value.value) {
        // eslint-disable-next-line max-len
        items.items = items.items.sort((a, b) => a[sortBy.value.value].localeCompare(b[sortBy.value.value]));
      }
    }

    function onCheckboxChange(checkedItems) {
      selected.value = checkedItems;
    }

    function clearSearchQuery() {
      searchQuery.value = '';
      isFiltered.value = false;
    }

    function getButtonClass(currentItem) {
      switch (currentItem) {
        case 'Approved':
          return 'approved-button';
        case 'Pending':
          return 'pending-button';
        case 'Declined':
          return 'notapproved-button';
        default:
          return '';
      }
    }

    function getClass(currentItem) {
      if (currentItem === currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }

    async function findSyncedBenevityCauses() {
      try {
        loading.value = true;
        const benevityCausesPaginationInput = {
          query: query.value,
          page: page.value,
          pageSize: pageSize.value,
          claimStatus: claimStatus.value,
        };
        const response = await getSyncedBenevityCauses(benevityCausesPaginationInput);
        items.value = response.benevityCauses;
        totalCount.value = response.totalCount;
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

  const setPage = (newPage) => {
      page.value = newPage;

    }
    async function bulkUpdate(type) {
      const bulkCauses = [];
      selected.value.forEach((element) => {
        const item = { id: element.id, status: type };
        bulkCauses.push(item);
      });
      await mutateBulkClaimStatus(bulkCauses);
      await findSyncedBenevityCauses();
    }

    async function headerFilter(filterBy) {
      currentView.value = filterBy;
      if (filterBy === 'Pending') {
        page.value = 1;
        query.value = null;
        claimStatus.value = 'Pending';
        await findSyncedBenevityCauses();
      } else if (filterBy === 'Approved') {
        page.value = 1;
        query.value = null;
        claimStatus.value = 'Approved';
        await findSyncedBenevityCauses();
      } else if (filterBy === 'Declined') {
        page.value = 1;
        query.value = null;
        claimStatus.value = 'Declined';
        await findSyncedBenevityCauses();
      } else {
        page.value = 1;
        query.value = null;
        claimStatus.value = null;
        await findSyncedBenevityCauses();
      }
    }

    const handlePageSizeChange = (val) => {
      console.log(`${val} items per page`);
    };

    async function handleCurrentPageChange(pageNumber) {
      page.value = pageNumber;
      await findSyncedBenevityCauses();
    }

    async function searchFilter() {
      page.value = 1;
      claimStatus.value = null;
      await findSyncedBenevityCauses();
    }

    watch(() => searchQuery.value, (newValue) => {
      if (newValue) {
        newValue = newValue.trim();
        if (newValue.length > 2) {
          query.value = newValue;
          searchFilter();
        }
      }
      else {
        query.value = null;
        searchFilter();
      }
    });

    onMounted(async () => {
      await findSyncedBenevityCauses();
    });

    return {
      // data (static)
      loading,

      // data (reactive)
      items,
      totalCount,
      serverError,
      input: ref(),
      filter,
      filtered,
      selected,
      searchQuery,
      isFiltered,
      currentView,
      sortOptions,
      sortBy,
      setAs,
      setAsOption,
      page,
      pageSize,
      // methods
      getClass,
      getButtonClass,
      headerFilter,
      onCheckboxChange,
      bulkUpdate,
      sortTable,
      clearSearchQuery,
      handlePageSizeChange,
      handleCurrentPageChange,
      routeToOrg,
      setPage,
    };
  },
});
</script>
