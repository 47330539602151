<template lang="pug">
app-main-content-layout(title='Segments')
  template(#actions)
    el-button( @click="exportSegments").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Export
    el-button(type="primary" color='#1EBEAF' @click="dialog = true").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Add New

    

  div
    .row.row-sm.align-items-center.mb-4
      .col
        el-input(
          v-model="filter"
          placeholder="Search Segments"
          prefix-icon="el-icon-search"
        )
          template(#prefix='')
              img(
                src="@/assets/icons/light/magnify-light.svg",
                style="font-size: 20px;")
      .col-auto
        el-button(type="info").has-fai
          fai(type="fal" icon="filter").size-18
          span.min-w-110.d-inline-block Filter

    el-table(
      :data="filtered"
      stripe
      v-loading="loading"
      @row-click="$router.push(`segments/${$event.id}`);"
      style="width: 100%"
    ).clickable-rows
      el-table-column(width="15")
        // space

      el-table-column(width="70")
        template(#default="scope")
          img(:src="scope.row.cover").size-50px.obj-f-c
      el-table-column(prop="segment" label="Segment" width="200" sortable)
      el-table-column(prop="description" label="Description" min-width="200")

      el-table-column(width="46")
        .el-table__expand-icon
          img(src="@/assets/icons/light/chevron-right-light.svg")

      el-table-column(width="15")
        // space

    dialog-add-segment(
      v-if="dialog"
      :loading="loading"
      v-model="dialog"
      @save-item="saveItem"
      @close-dialog="dialog = false"
    )
</template>

<script>

// eslint-disable-next-line object-curly-newline
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import shared from './shared';
import DialogAddSegment from './DialogAddSegment.vue';
import globalShared from '@/shared';

export default defineComponent({
  name: 'ManageContent_Directory_Segments',
  components: { DialogAddSegment },
  setup() {
    const {
      loading,
      getSegments,
      saveSegment,
      exportData,
    } = shared();

    const { showSuccessMessage } = globalShared();

    const filter = ref('');
    const items = reactive({ items: [] });
    const dialog = ref(false);

    const filtered = computed(() => {
      if (filter.value === '') {
        return items.items;
      }
      const regex = new RegExp(`${filter.value}`, 'gi');
      return items.items.filter((x) => {
        if (x.segment) {
          return x.segment.match(regex);
        }
        return false;
      });
    });

    function exportSegments() {
      exportData(items.items);
    }

    async function getItems() {
      try {
        loading.value = true;
        items.items = await getSegments('Admin', '');
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    async function saveItem(input) {
      await saveSegment(input);
      showSuccessMessage('Segment added successfully');
      await getItems();
    }

    onMounted(async () => {
      await getItems();
    });

    return {
      items,
      filter,
      filtered,
      loading,
      dialog,
      saveItem,
      exportSegments,
    };
  },
});
</script>
