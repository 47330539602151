import { gql } from '@apollo/client/core';

const fragMedia = gql`fragment media on Media {
  id
  title
  details
  image
  channel {
     id
     channel
  }
}`;

export const mutateMedia = gql`
  ${fragMedia}
  mutation($input: MediaInput!) {
  mutateMedia(input: $input) {
    ...media
 }
}`;

export const getMedia = gql`
    ${fragMedia}
    query($id: String!) {
    getMedia(id: $id) {
      ...media
      createdAt
      createdBy
    }
}`;

export const getAllMedia = gql`
    ${fragMedia}
    query {
    getAllMedia {
      ...media
    }
}`;

export const mutateRemoveMedia = gql`
mutation($id: String!) {
  mutateRemoveMedia(id: $id)
}`;
