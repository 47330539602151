<script setup>
import store from "@/store";
import {computed, ref, watch} from 'vue';
import Fai from "@/plugins/font-awesome/FontAwesomeIcon.vue";
import { VueDraggableNext } from 'vue-draggable-next';

const props = defineProps({
  item: Object,
  disabled: Boolean,
});

const item = computed({
  get: () => store.getters['sticker/GET_STICKER'],
  set: (value) => {
    store.dispatch('sticker/SET_STICKER', value);
  },
});

const preventArrowKeys = (event) => {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    event.preventDefault();
  }
};

const entries = ref([null]);
const entryCosts = ref([null]); 

watch(item, (newValue) => {
  if (newValue && newValue.sweepstakesSticker) {
    entries.value = newValue.sweepstakesSticker.entries || [null];
    entryCosts.value = newValue.sweepstakesSticker.entryCost || [null];
  }
}, { immediate: true });

const onAddTier = (index) => {
  entries.value.splice(index + 1, 0, null);
  entryCosts.value.splice(index + 1, 0, null);

  const updatedItem = {
    ...item.value,
    sweepstakesSticker: {
      ...item.value.sweepstakesSticker,
      entries: entries.value,
      entryCost: entryCosts.value,
    }
  };
  item.value = updatedItem;
};


const onRemoveTier = (index) => {
  entries.value.splice(index, 1);
  entryCosts.value.splice(index, 1);

  const updatedItem = {
    ...item.value,
    sweepstakesSticker: {
      ...item.value.sweepstakesSticker,
      entries: entries.value,
      entryCost: entryCosts.value,
    }
  };
  item.value = updatedItem;
};

const onChange = (event) => {
  const { oldIndex, newIndex } = event.moved;

  if (typeof oldIndex !== 'undefined' && typeof newIndex !== 'undefined' && oldIndex !== newIndex) {

    // Swap for `entryCosts`
    const tempCost = entryCosts.value[oldIndex];
    entryCosts.value[oldIndex] = entryCosts.value[newIndex];
    entryCosts.value[newIndex] = tempCost;

    const updatedItem = {
      ...item.value,
      sweepstakesSticker: {
        ...item.value.sweepstakesSticker,
        entries: [...entries.value],
        entryCost: [...entryCosts.value],
      }
    };

    item.value = updatedItem;
  }
};

</script>

<template>
  <span class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--entries">Donation Tiers</span>
  <section class="c-tiers">
    <vue-draggable-next
      v-model="entries"
      :disabled="disabled"
      handle=".grip"
      @change="onChange"
    >
      <article class="c-tier" v-for="(n, i) in entries" :key="i">
        <div class="c-tier__inputs">
          <article class="c-tier__row c-tier__row--mqcost">
            <div>
              <fai type="fas" icon="grip-vertical" class="size-14 grip" />
            </div>
            <div class="c-tier__column">
              <div class="c-input-container">
                <input 
                  class="c-input"
                  style="padding: 15px;"
                  type="number" 
                  v-model="entries[i]" 
                  placeholder="Number of entries"
                  :disabled="disabled"
                  @keydown="preventArrowKeys"
                  @wheel.prevent
                />
                <span class="c-input__suffix" v-if="entries[i] !== null && entries[i] !== ''">entries</span>
              </div>
            </div>
            <div class="c-tier__column">
              <div class="c-input-container">
                <span class="c-input__prefix" v-if="entryCosts[i] !== null && entryCosts[i] !== ''">$</span>
                <input 
                  class="c-input" 
                  type="number" 
                  v-model="entryCosts[i]" 
                  placeholder="Price" 
                  required
                  :disabled="disabled"
                  @wheel.prevent
                />
              </div>
            </div>
            <div>
              <div>
                <button class="c-tiers__add__button" @click="onAddTier(i)" :disabled="disabled">
                  <fai type="fas" icon="plus" class="size-14" />
                </button>
              </div>
              <div>
                <button 
                  class="c-tiers__add__button" 
                  @click="onRemoveTier(i)" 
                  :disabled="disabled || entryCosts.length === 1"
                >
                  <fai type="fas" icon="trash" class="size-14" />
                </button>
              </div>
            </div>
          </article>
        </div>
      </article>
    </vue-draggable-next>
  </section>
</template>

<style scoped lang="scss">
  .c-tiers {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &__add {
      display: flex;
      align-items: center;

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        background: none;
        border: none;
      }

      &__divider {
        flex: 1;
        border-top: 1px solid #cecece;
      }

      &__text {
        cursor: pointer;
      }
    }
  }

  .c-tier {
    display: flex;
    flex-direction: column;

    &__inputs {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    &__row {
      display: flex;
      flex-direction: row;

      &--mqcost {
        display: flex;
        align-items: center;
        gap: 10px;

        .c-tier__column {
          &:first-child {
            flex: 0 0 auto;
          }
          &:nth-child(2) {
            flex-grow: 1;
          }
          &:nth-child(3) {
            flex: 0 0 85px;
          }
          &:last-child {
            flex-shrink: 0;
          }
        }
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 8px;
      width: 100%;
    }
  }

  .c-input-container {
    position: relative;
  }

  .c-input {
    color: #000000;
    width: 100%;
  }

  .c-input__suffix {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #CCCCCC;
  }

  .c-input__prefix {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #000000;
  }

  .c-input[type="number"]::-webkit-outer-spin-button,
  .c-input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .c-input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Standard property for compatibility */
  } 
  
</style>
