import {
  findInterests,
  findInterest,
  mutateInterest,
} from '@/graphs/gql';

import commonShared from '../shared';

export default function shared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    getAuthToken,
    removeItemGetConfirm,
    makeGraphRequest,
    getTypedCategories,
    saveCategory,
    dateAndTime,
    exportData,
  } = commonShared('INTEREST');

  // console.log(router);
  const loading = ref(false);

  async function saveInterest(input: { [key: string]: any }): Promise<any> {
    await makeGraphRequest(
      'query', mutateInterest, { input }, getAuthToken(),
    );
  }

  async function getInterests(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findInterests, {}, getAuthToken(),
    );
    return response.data.findInterests;
  }

  async function getInterest(id: number): Promise<any> {
    const response = await makeGraphRequest(
      'query', findInterest, { id }, getAuthToken(),
    );
    return response.data.findInterest;
  }

  return {
    loading,
    route,
    router,
    removeItemGetConfirm,
    getTypedCategories,
    getInterests,
    getInterest,
    saveInterest,
    saveCategory,
    dateAndTime,
    exportData,
  };
}
