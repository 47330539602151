<template lang="pug">
.px-3
  .max-w-300.mx-auto.mb-4
    .d-flex.mb-4
      div
        .fs-22.text-primary.lh-n Add To Wallet
        .fs-12 {{ userName() }}
      .ms-auto
        img(:src="avatar()").size-48.obj-f-c.rounded-circle

    .fs-12.mb-2(v-if="showForm") Amount to deposit (USD)

    .pos-r.mb-2(v-if="showForm")
      .row.row-xs
        .col
          el-input(
            v-model="amt"
            prefix-icon="dollar"
            :disabled='!isEditable'
          )
        .col-auto(v-if="!isEditable")
          el-button(type='success' @click='$emit("edit-amt")').px-3 Edit

      .d-flex.align-items-center.pin.pin-tl.h-100.ps-2
        fai(type="fal" icon="dollar-sign").size-18.text-muted

    div(v-if="isEditable && showForm")
      el-button(type='info' size='small' plain @click='amt = 1') $1
      el-button(type='info' size='small' plain @click='amt = 5') $5
      el-button(type='info' size='small' plain @click='amt = 25') $25
      el-button(type='info' size='small' plain @click='amt = 100') $100
</template>

<script>
import {
  ref, defineComponent,
  watch,
} from 'vue';
// import { useRoute } from 'vue-router';
// import shared from './shared';
import defaultProfileImage from '@/assets/images/default-profile.jpeg';
export default defineComponent({
  name: 'AmountInputForm',
  props: {
    isEditable: { type: Boolean, default: false },
    userDetails: { type: Object, default: () => ({}) },
    showForm: { type: Boolean, default: true },
  },
  setup(_, { emit }) {
    const amt = ref();

    function userName() {
      if (this.userDetails.organizationId) {
        return this.userDetails.organizationName;
      }
      if (this.userDetails.id) {
        return `${this.userDetails.firstname} ${this.userDetails.lastname}`;
      }
      return '';
    }

    function cancelIntent() {
      window.location.href = `${import.meta.env.VITE_APP_REDIRECT_BASE_URL}?action=addToWalletCancel`;
    }

    function avatar() {
      if (this.userDetails.organizationId && this.userDetails.organizationAvatar) {
        return this.userDetails.organizationAvatar;
      }
      if (this.userDetails.id && this.userDetails.userAvatar) {
        return this.userDetails.userAvatar;
      }
      return defaultProfileImage;
    }

    watch(
      () => amt.value,
      () => {
        emit('input-amt', amt.value);
      },
    );
    return {
      amt,
      userName,
      cancelIntent,
      avatar,
    };
  },
});
</script>
