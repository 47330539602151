<template lang="pug">
.spotlight_card.fw-b
    amount-input-form(
      :is-editable="editAmt"
      :show-form="!continuePayment"
      @edit-amt='editAmt = true'
      @input-amt='amt = $event'
      :userDetails='details',
    )
    .px-3(v-if="editAmt")
      .max-w-300.mx-auto
        el-button(type='success' round
          @click='makeIntent' v-loading='loading').w-100.mb-2 Continue
  
    .pt-2.bg-gray-100.mb-4(v-if="!continuePayment")
    div(v-show="!editAmt && !continuePayment").bg-white
  
      .px-20.mb-4.fees-title(v-if="showContactInfo") Contact
  
        el-form(
          ref="refForm"
          :rules="rules"
          :model="unregisterInfo"
          status-icon
          label-position="top"
        )
          el-form-item(label="Email" prop="email")
            el-input(v-model="unregisterInfo.email").input-field-payment
  
          el-form-item(label="Mobile phone number" prop="phone")
            el-input(
              v-model="unregisterInfo.phone"
              type="tel"
              placeholder="(123) 456-7890"
              v-mask="'(###) ###-####'"
            ).input-field-payment
      .px-20.mb-4.fees-title Payment
        .max-w-350.mx-auto.mt-3
          p.input-filed-title Credit card
          div#payment-element
          div#card-errors.card_errors(role='alert', v-if='hasCardErrors') {{ cardErrors }}
      .pt-2.bg-gray-100.mb-4
  
      .px-20.py-4
        .max-w-300.mx-auto
          .fs-14.mb-4
            .d-flex.mb-2
              div Your Deposit
              .ms-auto ${{ parseFloat(amt).toFixed(2) }}
            .d-flex.mb-2
              div Convenience Fees
              div(v-if="convenienceFeePercentageValue != -1")
                span &nbsp;({{ convenienceFeePercentageValue }}%)
              .ms-auto ${{ convenienceFee.toFixed(2) }}
            .d-flex.mb-2
              div Credit Card Fees (2.9% + ¢30)
              .ms-auto ${{ stripeFee.toFixed(2) }}
            .d-flex
              div Total
              .ms-auto  ${{ (parseFloat(amt) + convenienceFee + stripeFee).toFixed(2) }}
        .max-w-300.mx-auto
          .mb-4.lh-sm
          i.fs-12( v-html="paymentText")
        .mt-2
          el-button(type='success'
            v-loading='loading'
            round @click="finalizePayment"
            v-if="!hasCardErrors && showPaymentButton").mb-2.donate-button Deposit
      .px-3
        el-button(
          link
          round
          @click.stop='cancelIntent'
          style="display: flex; align-items: center;"
          class="text-muted donate-button"
        ) Cancel

</template>

<script>
import {
  computed,
  ref,
  reactive,
  defineComponent,
  onMounted,
} from 'vue';
import shared from './shared';
import { paymentText } from '../../data/payment-text';
import globalShared from '@/shared';
import { ElLoading } from 'element-plus';
import {email, phone} from '@/data/rules';
import {useStore} from 'vuex';
import {mask} from 'vue-the-mask';
import AmountInputForm from './AmountInputForm.vue';

export default defineComponent({
  name: 'FinalizePaymentStatic',
  components: { AmountInputForm },
  directives: {mask},
  setup() {
    const {
      loading,
      route,
      requestCanIntent,
      requestCanIntentForOrg,
      requestCreatePaymentIntent,
      requestCreatePaymentIntentForOrg,
    } = shared();

    const {
      isWebUser,
    } = route.query;
  
    const { getMobileOperatingSystem, isSafari, handleSafariPrompt } = globalShared();
  
    const { userId, orgId } = route.params;
    const store = useStore();
    const showContactInfo = ref(false);
    const refForm = ref(null);
    const readyToPay = ref(false);
    const hasCardErrors = ref(true);
    const cardErrors = ref('');
    const completeUserInfo = ref(false);
    const unregisterInfo = reactive({
      email: '',
      phone: '',
    });
    let paymentElement;
    let stripe;
    let elements;
  
    const options = {
      layout: {
        type: 'tabs',
        defaultCollapsed: false,
      }
    };

    const userOrOrg = ref('user');
    const amt = ref(0);
    const fee = ref(0);
    const convenienceFee = ref(0);
    const convenienceFeePercentageValue = ref(0);
    const stripeFee = ref(0);
    const editAmt = ref(true);
    const continuePayment = ref(false);
    const details = reactive({});
    const paymentIntent = reactive({});
    const paymentDetails = reactive({});

    async function getUserIntent() {
      loading.value = true;
      const response = await requestCanIntent(userId);
      Object.assign(details, response);
      loading.value = false;
    }

    async function getOrgIntent() {
      loading.value = true;
      const response = await requestCanIntentForOrg(userId, orgId);
      Object.assign(details, response);
      loading.value = false;
    }

    function loadStripe(stripeURL, callback) {
      const obj = document.createElement('script');
      const script = document.getElementsByTagName('script')[0];
      obj.src = `//${stripeURL}`;
      if (callback) {
        obj.addEventListener('load', (e) => {
          callback(null, e);
        }, false);
      }
      script.parentNode.insertBefore(obj, script);
    }

    function continueToPayment(e) {
      e.preventDefault();
      continuePayment.value = true;
    }

    function getRedirectUrl() {
      return import.meta.env.VITE_APP_REDIRECT_BASE_URL;
    }

    function getRedirectIOSUrl() {
      return import.meta.env.VITE_APP_REDIRECT_URL;
    }

    function cancelIntent() {
      window.location.href = `${getRedirectUrl()}?action=addToWalletCancel`;
    }
  
    async function validateForm() {
      if (refForm.value) {
        refForm.value.validate(async (valid) => {
           if (valid) {
            completeUserInfo.value = true;
            return true;
          }
          loading.value = false;
          completeUserInfo.value = false;
          return false;
        });
      }
    }
  
    //_____check if payment button should be shown_____
    const showPaymentButton = computed(() => {
      if (isWebUser !== 'true' || (store.getters['users/me'] != null && unregisterInfo.email === '' && unregisterInfo.phone === '')) {
        return true;
      } else if (store.getters['users/me'] === null && unregisterInfo.email !== '' && unregisterInfo.phone !== '') {
        validateForm();
        if (completeUserInfo.value === true)
          return true;
      } else {
        return false;
      }
    });
  
    async function finalizePayment(e) {
      validateForm();
      loading.value = true;
      localStorage.setItem('unregisterEmail', unregisterInfo.email);
      localStorage.setItem('unregisterPhone', unregisterInfo.phone);
      e.preventDefault();
      await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: '',
        },
        redirect: 'if_required',
      }).then((result) => {
        const amount = parseFloat(amt.value).toFixed(2);
        if (result.error) {
          // Show error to your customer (for example, insufficient funds)
          console.log(result.error.message);
          document.body.innerHTML = 'An error occurred while adding money to the wallet';
          window.location.href = `${getRedirectUrl()}/?`
            + `action=addToWallet&amount=${amount}&success=false`;
        } else if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.

          document.body.innerHTML = 'Thank you for adding money to the wallet';

          redirectMobileApp();
        } else {
          document.body.innerHTML = 'An error occurred while adding money to the wallet';

          const { isWebUser } = route.query;

          if (isWebUser) {
            return window.location.href = `${getRedirectUrl()}/?action=addToWallet&amount=${amount}&success=false`;
          }

          return redirectMobileApp(true);
        }
      }).finally(() => {
        loading.value = false;
      });
    }

    async function redirectMobileApp(failed = false){
      const amount = parseFloat(amt.value).toFixed(2);
      const loadingInstance = ElLoading.service({
          lock: false
      })
      const isAndroid = getMobileOperatingSystem() === "Android";
      let browserFallbackUrlBase = import.meta.env.VITE_WEB_BASE_URL;
      let browserFallbackUrlPath = 'web/dashboard';
      let query =  '';
      if (!failed) {
          query = `?action=addToWallet&amount=${amount}&success=true`
      } else {
        query = `?action=addToWallet&amount=${amount}&success=${failed}`
      }

      if (isAndroid) {
        let appId = import.meta.env.VITE_APP_ID;
        let appHost = import.meta.env.VITE_APP_HOST;
        window.location.href = `intent://${appHost}/${query}#Intent;scheme=${appId};package=${appId};end`;
      } else {
        window.location.href = `${getRedirectIOSUrl()}${query}`;
      }

      // Detect if redirect was successful
      let didSafariRedirect = false;
      if(isSafari()){
          didSafariRedirect = await handleSafariPrompt();
      }

      // we are checking for strong inequality with false, because null indicates that the prompt timed out, not that the redirection was unsuccessful
      // unfortunately this will attempt to redirect the user to the app store if they decline the redirect to the app on Safari
      // currently no better option exists for iOS Safari
      if(!isSafari() || didSafariRedirect !== false){
          setTimeout(function() {
            // redirect user to the dashboard if the document is hidden (i.e. the app launched successfully)
            if(document.hidden){
                window.location.href = `${browserFallbackUrlBase}/${browserFallbackUrlPath}`;
                loadingInstance.close();
            }
            // keep user on site to allow redirect with button
            else{
                loadingInstance.close();
            }
          }, 1500);
      }
      // iOS Safari failed redirect
      else{
          loadingInstance.close();
      }
    }

    function calculateFinalPayment(depositAmount, isProfit) {
      let mTotalAmountWithConvenienceFee = 0;
      let mConvenienceFee = 0;
      let mConvenienceFeePercentageValue = 0;

      if (isProfit === true) {
        if (depositAmount > 0 && depositAmount <= 999) {
          mConvenienceFeePercentageValue = 8.0;
          mConvenienceFee = parseFloat((0.08 * depositAmount).toFixed(2));
          mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
        } else if (depositAmount > 999 && depositAmount <= 2500) {
          mConvenienceFeePercentageValue = 7.0;
          mConvenienceFee = parseFloat((0.07 * depositAmount).toFixed(2));
          mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
        } else if (depositAmount > 2500 && depositAmount <= 5000) {
          mConvenienceFeePercentageValue = 6.5;
          mConvenienceFee = parseFloat((0.065 * depositAmount).toFixed(2));
          mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
        } else if (depositAmount > 5000 && depositAmount <= 10000) {
          mConvenienceFeePercentageValue = 6.0;
          mConvenienceFee = parseFloat((0.06 * depositAmount).toFixed(2));
          mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
        } else {
          mConvenienceFeePercentageValue = 5.0;
          mConvenienceFee = parseFloat((0.05 * depositAmount).toFixed(2));
          mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (depositAmount > 0 && depositAmount <= 250) {
          mConvenienceFeePercentageValue = 5.0;
          mConvenienceFee = parseFloat((0.05 * depositAmount).toFixed(2));
        } else if (depositAmount > 250 && depositAmount <= 500) {
          mConvenienceFeePercentageValue = 4.0;
          mConvenienceFee = parseFloat((0.04 * depositAmount).toFixed(2));
        } else if (depositAmount > 500 && depositAmount <= 1000) {
          mConvenienceFeePercentageValue = 3.5;
          mConvenienceFee = parseFloat((0.035 * depositAmount).toFixed(2));
        } else {
          mConvenienceFeePercentageValue = -1;
          mConvenienceFee = parseFloat((30 + depositAmount).toFixed(2));
        }

        if (mConvenienceFee > 30) {
          mConvenienceFeePercentageValue = -1;
          mConvenienceFee = 30;
        }
        mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
      }

      // ([Amount] + [Convenience Fee] + [0.30 stripe fixed fee]) / (1 - [2.9 stripe % fee])
      // eslint-disable-next-line max-len
      const totalAmount = parseFloat(((mTotalAmountWithConvenienceFee + 0.30) / (1 - 0.029)).toFixed(2));
      const mStripeFee = parseFloat(totalAmount - mTotalAmountWithConvenienceFee).toFixed(2);

      const result = {
        total_amount: totalAmount,
        deposit_amount: depositAmount,
        convenienceFee: mConvenienceFee,
        convenienceFeePercentageValue: mConvenienceFeePercentageValue,
        creditCardFee: mStripeFee,
      };
      return result;
    }

    async function makeIntent() {
      const amount = parseFloat(parseFloat(amt.value).toFixed(2));
      if (amount > 0) {
        let totalAmount = parseFloat(parseFloat(amount + fee.value).toFixed(2));
        let response = '';
        if (userOrOrg.value === 'user') {
          loading.value = true;
          // convenienceFee.value = parseFloat((0.02 * amount).toFixed(2));
          // stripeFee.value = parseFloat((0.03 * amount).toFixed(2));
          // convenienceFeePercentageValue.value = 2.0;
          const finalPaymentCalculation = calculateFinalPayment(amount, false);
          convenienceFee.value = finalPaymentCalculation.convenienceFee;
          // eslint-disable-next-line max-len
          stripeFee.value = parseFloat(parseFloat(finalPaymentCalculation.creditCardFee).toFixed(2));
          fee.value = parseFloat(parseFloat(convenienceFee.value + stripeFee.value).toFixed(2));
          totalAmount = parseFloat(parseFloat(amount + fee.value).toFixed(2));
          // eslint-disable-next-line max-len
          convenienceFeePercentageValue.value = finalPaymentCalculation.convenienceFeePercentageValue;
          response = await requestCreatePaymentIntent(details.id,
            {
              amount: totalAmount,
              walletDeposit: amount,
              totalFee: fee.value,
              convenienceFee: convenienceFee.value,
              creditCardFee: stripeFee.value,
            });
          loading.value = false;
        } else if (userOrOrg.value === 'org') {
          loading.value = true;
          // eslint-disable-next-line no-unneeded-ternary
          const isProfit = details.organizationType === 'FORPROFIT' ? true : false;
          const finalPaymentCalculation = calculateFinalPayment(amount, isProfit);
          convenienceFee.value = finalPaymentCalculation.convenienceFee;
          // eslint-disable-next-line max-len
          stripeFee.value = parseFloat(parseFloat(finalPaymentCalculation.creditCardFee).toFixed(2));
          fee.value = parseFloat(parseFloat(convenienceFee.value + stripeFee.value).toFixed(2));
          totalAmount = parseFloat(parseFloat(amount + fee.value).toFixed(2));
          // eslint-disable-next-line max-len
          convenienceFeePercentageValue.value = finalPaymentCalculation.convenienceFeePercentageValue;
          response = await requestCreatePaymentIntentForOrg(
            details.id,
            details.organizationId,
            {
              amount: totalAmount,
              walletDeposit: amount,
              totalFee: fee.value,
              convenienceFee: convenienceFee.value,
              creditCardFee: stripeFee.value,
            },
          );
          loading.value = false;
        } else {
          console.log('error');
        }
        if (response !== '') {
          Object.assign(paymentDetails, JSON.parse(atob(response)));
          paymentDetails.amount = totalAmount;
          Object.assign(paymentIntent, paymentDetails);
          editAmt.value = false;
          loadStripe('js.stripe.com/v3/', () => {
            stripe = window.Stripe(paymentDetails.stripe_pub_key);
            elements = stripe.elements({clientSecret: paymentDetails.client_secret});
            paymentElement = elements.create('payment');
            paymentElement.mount('#payment-element');
            paymentElement.on('change', (e) => {
              if (e.complete) {
                readyToPay.value = true;
                hasCardErrors.value = false;
              } else if (e.error) {
                hasCardErrors.value = true;
                readyToPay.value = false;
                cardErrors.value = e.error.message;
              }
            });
          });
        }
      }
    }

    onMounted(async () => {
      if (userId && orgId) {
        userOrOrg.value = 'org';
        await getOrgIntent();
      } else if (userId && !orgId) {
        userOrOrg.value = 'user';
        await getUserIntent();
      } else {
        userOrOrg.value = 'false';
        console.log('error');
      }
    });

    return {
      refForm,
      amt,
      fee,
      convenienceFee,
      stripeFee,
      convenienceFeePercentageValue,
      paymentIntent,
      editAmt,
      continuePayment,
      loading,
      makeIntent,
      requestCanIntent,
      requestCanIntentForOrg,
      readyToPay,
      hasCardErrors,
      cardErrors,
      paymentDetails,
      finalizePayment,
      cancelIntent,
      details,
      continueToPayment,
      paymentText,
      validateForm,
      rules: {
        email,
        phone,
      },
      showContactInfo,
      showPaymentButton,
      isWebUser,
    };
  },
});
</script>
  
<style>
.spotlight_card .mt-2 {
  display: flex;
  padding: 0 8px 0 8px;
}

.donate-button {
  margin: 0 auto !important;
  display: block;
}
</style>
