import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { makeRequest as makeGraphRequest } from '@/graphs/index';
import {
  validateInvite,
  updateOrgAndAdmin,
  updateOrgAttributes,
  verifyPhoneNumber,
  findBullhorns,
  findChannels,
  findValues,
  resendPhoneCode,
  createOrgFromInvite,
  returningUser,
  makeOrgAdmin as queryMakeOrgAdmin,
} from '@/graphs/gql';

export default function useOnboardingRoute(page: string): { [key: string]: any } {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const serverError = ref('');
  const submitting = ref(false);

  const { orgId } = route.params;

  const initData = store.getters['onboarding/onboarding'][page];
  const prevRoute = initData.prevRoute ? `/onboarding/${orgId}/${initData.prevRoute}` : '';

  const checkHasData = Object.keys(initData).includes('data');

  function getUserToken() {
    const { token } = store.getters['onboarding/onboarding'].verifyCode.data;
    return token;
  }

  async function createOrg(input: any): Promise<any> {
    const result = await makeGraphRequest(
      'mutation',
      createOrgFromInvite,
      { input },
      null,
    );
    return result;
  }

  async function makeUserOrgAdmin(input: any): Promise<any> {
    const result = await makeGraphRequest(
      'mutation',
      queryMakeOrgAdmin,
      { input },
      null,
    );
    return result;
  }

  async function makeOrgAdmin(input: any): Promise<any> {
    const result = await makeGraphRequest(
      'mutation',
      updateOrgAndAdmin,
      { input },
      null,
    );
    return result;
  }

  async function saveOrgAttributes(input: any): Promise<any> {
    const result = await makeGraphRequest(
      'mutation',
      updateOrgAttributes,
      { input },
      getUserToken(),
    );
    return result;
  }

  async function getPhoneCode(): Promise<boolean> {
    const { phone } = store.getters['onboarding/onboarding'].orgDetail.data;
    const result = await makeGraphRequest(
      'query',
      resendPhoneCode,
      { phone },
      null,
    );
    return result.data.resendPhoneCode;
  }

  async function getReturningUser(): Promise<any> {
    const result = await makeGraphRequest(
      'query',
      returningUser,
      { inviteId: orgId },
      null,
    );
    return result.data.returningUser;
  }

  function gotoNextRoute() {
    router.push(`/onboarding/${orgId}/${initData.nextRoute}`);
  }

  function resetState() {
    store.commit('onboarding/SET_ONBOARDING_INIT', null);
  }

  async function commitAndGotoNextRoute(data: any) {
    // vuex stuff
    serverError.value = '';
    if (page === 'orgDetail') {
      try {
        // Create and Store Organization
        const response = await createOrg({
          name: data.name,
          email: data.email,
          phone: data.phone,
          dob: `${data.dob}T00:00:00.000Z`,
          invite_code: orgId,
        });
        store.commit('onboarding/SET_ONBOARDING_DATA', { page, data: response.data.createOrgFromInvite });

        // Create and Admin account with org email and store it
        if (data.returnUser) {
          submitting.value = true;
          await makeUserOrgAdmin({
            invite_code: data.invite_code,
            user_id: data.user.id,
          });
          submitting.value = false;
          store.commit(
            'onboarding/SET_ONBOARDING_DATA',
            {
              page,
              data: data.user,
            },
          );
          gotoNextRoute();
        } else {
          const companyDetails = store.getters['onboarding/onboarding'].orgDetail;
          const { dob } = companyDetails.data;
          const input = {
            invite_code: orgId,
            org_name: companyDetails.data.name,
            org_email: companyDetails.data.email,
            org_phone: companyDetails.data.phone,
            org_dob: `${dob}T00:00:00.000Z`,
            firstname: companyDetails.data.name,
            lastname: '',
            title: companyDetails.data.name,
            email: companyDetails.data.email,
            age: 0,
            phone: companyDetails.data.phone,
            password: data.password,
          };
          try {
            submitting.value = true;
            await makeOrgAdmin(input);
            store.commit(
              'onboarding/SET_ONBOARDING_DATA',
              {
                page,
                data: {
                  firstname: companyDetails.data.name,
                  lastname: '',
                  title: companyDetails.data.name,
                  phone: companyDetails.data.phone,
                  age: 0,
                },
              },
            );
            gotoNextRoute();
          } catch (err) {
            serverError.value = err.message;
          } finally {
            submitting.value = false;
          }
        }
      } catch (err) {
        serverError.value = err.message;
      }
    } else if (page === 'whyJoiningSpotlight') {
      // const companyDetails = store.getters['onboarding/onboarding'].orgDetail;
      const params: { [key: string]: any } = {};
      const getter = store.getters['onboarding/onboarding'];
      params.invite_code = orgId;
      params.organization_type = getter.orgType.data.orgType;
      params.parent_organization = (getter.orgType.data.parentOfOrg === 'true');
      params.profit_type = getter.orgType.data.forProfitTypes;
      params.nonprofit_type = getter.orgType.data.nonProfitTypes;
      params.bullhorns = getter.causes.data.join(',');
      params.channels = getter.channels.data.join(',');
      params.values = getter.orgValues.data.join(',');
      params.whyjoin = data.value.whyjoin.join(',');
      params.whyjoin_other = data.value.whyjoin_other;
      try {
        await saveOrgAttributes(params);
        store.commit('onboarding/SET_ONBOARDING_DATA', { page, data });
        resetState();
        gotoNextRoute();
      } catch (err) {
        serverError.value = err.message;
      }
    } else {
      store.commit('onboarding/SET_ONBOARDING_DATA', { page, data });
      gotoNextRoute();
    }
  }

  function validateForm(refForm: any, data: any) {
    // commitAndGotoNextRoute({
    //   returnUser: true,
    //   invite_code: orgId,
    //   user,
    // });
    refForm.value.validate((valid: boolean) => {
      if (valid) return commitAndGotoNextRoute(data);
      console.log('error submit!!');
      return false;
    });
  }
  // graphcalls
  async function validateInviteCode() {
    const result = await makeGraphRequest(
      'query',
      validateInvite,
      { code: orgId },
      null,
    );
    return result;
  }

  async function validatePhoneToken(token: string) {
    const { phone } = store.getters['onboarding/onboarding'].orgDetail.data;
    try {
      const result = await makeGraphRequest(
        'mutation',
        verifyPhoneNumber,
        { input: { phone, token } },
        null,
      );
      await commitAndGotoNextRoute(result.data.verifyPhoneNumber);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function getBullhorns(getfavourite: boolean) {
    try {
      const result = await makeGraphRequest(
        'query',
        findBullhorns,
        { getfavourite },
        getUserToken(),
      );
      return result.data.findBullhorns;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async function getChannels(getfavourite: boolean) {
    try {
      const result = await makeGraphRequest(
        'query',
        findChannels,
        { getfavourite },
        getUserToken(),
      );
      return result.data.findChannels;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async function getValues(getfavourite: boolean) {
    try {
      const result = await makeGraphRequest(
        'query',
        findValues,
        { getfavourite },
        getUserToken(),
      );
      return result.data.findValues;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  const camelCase = (str: string) => str.replace(/-./g, (match) => match[1].toUpperCase());

  function canOnBoard() {
    if (store.getters['users/isMeSignedIn']) {
      return false;
    }
    if (page !== 'orgDetail') {
      const prevData = store.getters['onboarding/onboarding'][camelCase(initData.prevRoute)];
      if (!prevData.passed) {
        router.push(prevRoute);
      }
    }

    return true;
  }

  async function userUpgrade(user: any) {
    commitAndGotoNextRoute({
      returnUser: true,
      invite_code: orgId,
      user,
    });
  }
  // end graphcalls

  // kinda onMounted
  store.commit('onboarding/SET_PREV_ROUTE', prevRoute);

  return {
    canOnBoard,
    validateForm,
    validateInviteCode,
    validatePhoneToken,
    gotoNextRoute,
    commitAndGotoNextRoute,
    getValues,
    getBullhorns,
    getChannels,
    getPhoneCode,
    getUserToken,
    getReturningUser,
    serverError,
    resetState,
    userUpgrade,
    makeUserOrgAdmin,
    submitting,
    initData: checkHasData ? JSON.parse(JSON.stringify(initData.data)) : {},
  };
}
