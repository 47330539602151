<template lang="pug">
el-dialog(
  title="Add Value"
  top="5vh" width="100%"
  class="el-dialog__max-w-700"
  :close-on-click-modal="false"
)
  el-form(
    ref="refForm"
    :rules="rules"
    :model="item"
    status-icon
    label-position="top"
  )
    el-form-item(label="Value Name" prop="value")
      el-input(v-model="item.value")

    el-form-item(label="Description" prop="description")
      el-input(v-model="item.description" type="textarea")

  template(#footer)
    el-button(type="success" @click="validateForm" v-loading="loading") Add
</template>

<script>
import { defineComponent, ref, reactive } from 'vue';
import { value } from '@/data/rules';

export default defineComponent({
  name: 'DialogAddValue',

  props: {
    loading: { type: Boolean, default: false },
  },

  emits: ['close-dialog', 'save-item'],

  setup(props, { emit }) {
    // data
    const refForm = ref(null);
    const item = reactive({
      value: '',
      description: '',
    });

    function submitForm() {
      emit('save-item', item);
      emit('close-dialog');
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) return submitForm();
        console.log('error submit!!');
        return false;
      });
    }

    return {
      // data
      refForm,
      rules: { value },
      item,
      // methods
      validateForm,
    };
  },
});
</script>
