<template>
  <div class="d-flex align-items-center">
    <VueMultiselect
      id="ajax"
      v-model="filter"
      :multiple="false"
      :options="items.items"
      :searchable="true"
      :close-on-select="true"
      :show-labels="false"
      label="name"
      placeholder="Search Organization"
      :options-limit="300"
      @select="routeToOrg"
      @remove="handleDeselect"
      track-by="id"
      :loading="loading"
    >
      <template #option="{ option }">
        <img
          class="rounded-circle w-40 h-40"
          v-if="option.avatar"
          :src="option.avatar"
        />
        <div class="avatar-placeholder w-40 h-40" v-else></div>
        <span class="option__title px-10">{{ option.name }}</span>
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import {
  defineComponent, ref, onMounted, reactive, computed, watch,
} from 'vue';
import shared from '@/views/manage-users-and-organizations/shared';
import { useStore } from 'vuex';
import VueMultiselect from 'vue-multiselect';
import globalShared from '@/shared';

export default defineComponent({
  name: 'OrgsDropdown',
  components: { VueMultiselect },
  setup() {
    const store = useStore();
    const {
      router,
      loading,
      getAllOrganizations,
      getPendingOrganizations,
    } = shared();

    const {
      isOrgAdmin
    } = globalShared();

    const filter = ref('');
    const items = reactive({ items: [] });
    const serverError = ref('');
    const sendingInvite = ref(false);
    const previousSelection = ref(null);

    const filtered = computed(() => {
      if (filter.value === '') return items.items;
      const regex = new RegExp(`${filter.value}`, 'gi');
      return items.items.filter((x) => (x.name ? x.name.match(regex) : false));
    });

    function handleDeselect() {
      previousSelection.value = null;
      filter.value = null;
      localStorage.removeItem("selectedOrganization");
      store.dispatch("organizations/SET_SELECTED_ORGANIZATION", null);
      router.push(`/manage/orgs/`);
    }

    function routeToOrg(itm) {
      if (itm.onboarded) {
        router.push(`/manage/orgs/${itm.id}/edit`);
      }
    }

    async function findOrganizations(name) {
      if (store.getters['users/isOrgAdmin'] || store.getters['users/isAdmin']) {
        loading.value = true;
        try {
          if (name === 'ManageOrganizations') {
            items.items = await getAllOrganizations();
          } else {
            items.items = await getPendingOrganizations();
          }
        } catch (err) {
          console.log(err);
        } finally {
          loading.value = false;
          items.items.sort((a, b) => (a.name > b.name ? 1 : -1));
          items.items = items.items.filter((x) => x.onboarded);
        }
      }
    }

    function showOnboardedStatus(item) {
      return item.onboarded ? 'Onboarded' : '--';
    }

    function showActiveStatus(item) {
      return item.active ? 'Yes' : 'No';
    }

    onMounted(async () => {
      await findOrganizations('ManageOrganizations');
      const savedOrganization = localStorage.getItem('selectedOrganization');
      if (savedOrganization) {
        try {
          const parsedOrganization = JSON.parse(savedOrganization);
          
          const isValidOrganization = items.items.find((org) => org.id === parsedOrganization.id);
          if (isValidOrganization) {
            filter.value = parsedOrganization;
            previousSelection.value = parsedOrganization;
            await store.dispatch("organizations/SET_SELECTED_ORGANIZATION", parsedOrganization);
          } else {
            // console.warn("Saved organization is no longer valid.");
            localStorage.removeItem("selectedOrganization");
          }
        } catch (err) {
          console.error("Error parsing saved organization:", err);
          localStorage.removeItem("selectedOrganization");
        }
      }
    });

    watch(filter, (newVal) => {
      if (newVal && newVal.id && (!previousSelection.value || previousSelection.value.id !== newVal.id)) {
        store.dispatch("organizations/SET_SELECTED_ORGANIZATION", newVal);
        previousSelection.value = newVal;

        localStorage.setItem("selectedOrganization", JSON.stringify(newVal));
      }
    });

    return {
      loading,
      filter,
      items,
      serverError,
      sendingInvite,
      filtered,
      isOrgAdmin,
      showOnboardedStatus,
      showActiveStatus,
      routeToOrg,
      handleDeselect,
    };
  },
});
</script>
