<template lang="pug">
.card
  .card-body(v-if="editForm || isNewItem")
    el-form(
      ref="refForm"
      :rules="rules"
      :model="cItem"
      status-icon
      label-position="top"
    )
      el-form-item(label="Author" prop="author")
        el-input(v-model="cItem.author")

      el-form-item(label="Quote" prop="quote").pt-4
        el-input(v-model="cItem.quote" type="textarea")

      .pt-4
        // space

      el-button(@click="handleCancel") Cancel
      el-button(type="success" @click="validateForm" v-loading="loading") Save

  .card-body.pt-4(v-else)
    h6.mb-4 {{ cItem.author }}
    p.mb-0.lh-sm {{ cItem.quote }}
    .pin.pin-tr.p-3
      el-button(type="danger" plain @click="deleteQuote(cItem)" size="mini").has-fai
        fai(type="fal" icon="trash").size-14.me-2
        | Delete
      el-button(@click="editForm = true" size="mini").has-fai
        fai(type="fal" icon="edit").size-14.me-2
        | Edit
</template>

<script>
import { defineComponent, ref } from 'vue';

import { requiredRule } from '@/data/rules';

export default defineComponent({
  name: 'Quote',
  props: {
    isNewItem: { type: Boolean, default: false },
    item: { type: Object, default: () => ({}) },
  },

  emits: ['save-item'],

  setup(props, { emit }) {
    const refForm = ref(null);
    const editForm = ref(false);
    const loading = ref(false);
    const cItem = ref({ ...props.item });

    function saveItem() {
      loading.value = true;
      setTimeout(() => {
        emit('save-item', cItem);

        editForm.value = false;
        loading.value = false;
      }, 500);
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    function deleteQuote(item) {
      emit('delete-item', item);
    }

    function handleCancel() {
      editForm.value = false;
      emit('cancel-item');
    }

    return {
      // data (static)
      rules: {
        author: requiredRule('author'),
        quote: requiredRule('quote'),
      },

      // data (dynamic)
      cItem,
      refForm,
      loading,
      editForm,

      // methods
      validateForm,
      deleteQuote,
      handleCancel,
    };
  },
});
</script>
