<template lang="pug">
el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(v-loading="loading" title='Events' back-to='/manage-content/events')
    template(#actions)
      el-button(type="danger" plain @click="removeItem").has-fai
        fai(type="fal" icon="trash").size-18.me-2
        span.min-w-110.d-inline-block Delete
      el-button(type="primary" @click="validateForm" v-loading="loading").has-fai
        fai(type="fal" icon="edit").size-18.me-2
        span.min-w-110.d-inline-block Save

    template(#col-left)
      div
        .row
          .col-sm-6
            el-form-item(label="Title" prop="title")
              el-input(v-model="item.title")

          .col-sm-6
            el-form-item(label="Date and Time")
              el-date-picker(
                v-model="item.date"
                type="datetime"
                placeholder="Select Date and Time"
              ).w-100

        el-form-item(label="Description" prop="description")
          el-input(v-model="item.description" type="textarea")

        .row
          .col-sm-6
            el-form-item(label="Event Type")
              el-select(v-model="item.eventType" placeholder="Select").w-100
                el-option(
                  v-for="type in eventTypeLookups"
                  :key="type.id"
                  :label="type.title"
                  :value="type.value"
                )
          .col-sm-6
            el-form-item(label="Source")
              el-input(v-model="item.source")

        .row
          .col-sm-6
            el-form-item(label="Channel")
              el-select(
                v-model="item.channels"
                placeholder="Select"
                multiple
              ).w-100
                el-option(
                  v-for="type in channels.items"
                  :key="type.id"
                  :label="type.value"
                  :value="type.entityId"
                )
          .col-sm-6
            el-form-item(label="Values")
              el-select(v-model="item.values" placeholder="Select" multiple).w-100
                el-option(
                  v-for="type in values.items"
                  :key="type.id"
                  :label="type.value"
                  :value="type.entityId"
                )

        el-form-item(label="Interests")
          el-select(v-model="item.interests" placeholder="Select" multiple).w-100
            el-option(
              v-for="interest in interests.items"
              :key="interest.id"
              :label="interest.value"
              :value="interest.entityId"
            )

        el-form-item(label="Bullhorns")
          el-select(v-model="item.bullhorns" placeholder="Select" multiple).w-100
            el-option(
              v-for="bullhorn in bullhorns.items"
              :key="bullhorn.id"
              :label="bullhorn.value"
              :value="bullhorn.entityId"
            )

    template(#col-right)
      div
        el-upload(
          :show-file-list="false"
          :on-success="handleUploadSuccess"
          :on-error="handleUploadError"
          :before-upload="beforeUpload"
          :http-request="handleUpload"
          v-loading="uploading"
          action=""
        ).img-uploader.pos-r.mb-4
          template(v-if="item.img")
            img(:src="item.img").img-fluid
            .pin.pin-bx.d-flex.p-2
              el-button(type="primary").ms-auto Change
          template(v-else)
            img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
            p.upload-picture-text Drop an image file here or
            div.fake-upload-button Browse For File

        el-form-item(label="Creator")
          el-input(v-model="item.user.name" disabled)

        el-form-item
          template(#label)
            | Post Date
            span.text-muted.ms-2 Active for 3 Days
          el-input(v-model="item.postDate" disabled)

        el-form-item(label="Post Time")
          el-input(v-model="item.postTime" disabled)
</template>

<script>
import {
  defineComponent, reactive, onMounted, ref,
} from 'vue';
import fileUpload from '@/shared/upload';
import { requiredRule } from '@/data/rules';
import shared from './shared';

const eventTypeLookups = [
  { value: 'Holiday', title: 'Holiday' },
  { value: 'Spot Event', title: 'Spot Event' },
  { value: 'Check-In', title: 'Check-In' },
  { value: 'On Radar', title: 'On Radar' },
];
export default defineComponent({
  name: 'ManageContent_EventDeail',
  setup() {
    const refForm = ref(null);
    const {
      route,
      router,
      loading,
      updateSticker,
      getEvent,
      getBullhorns,
      getChannels,
      getInterests,
      getValues,
      updateEvent,
      dateAndTime,
      removeEvent,
      removeItemGetConfirm,
    } = shared();
    const {
      uploading, uploadStatus,
      beforeUpload, handleUploadRequest,
    } = fileUpload('stickers', 'Sticker');

    const { id } = route.params;

    const item = reactive({
      id: -1,
      title: '',
      description: '',
      source: '',
      published: false,
      user: { name: '' },
    });

    const bullhorns = reactive({ items: [] });
    const channels = reactive({ items: [] });
    const values = reactive({ items: [] });
    const interests = reactive({ items: [] });

    async function getItem() {
      const itm = await getEvent(id);
      const attributes = itm.eventAttributes;
      delete itm.eventAttributes;
      Object.assign(item, itm);
      [item.postDate, item.postTime] = dateAndTime(itm.createdAt);
      attributes.forEach((x) => {
        [
          ['Channel', 'channels'],
          ['Bullhorn', 'bullhorns'],
          ['Interest', 'interests'],
          ['Value', 'values'],
        ].forEach((t) => {
          if (x.entity === t[0]) {
            if (!item[t[1]]) {
              item[t[1]] = [];
            }
            item[t[1]].push(x.entityId);
          }
        });
      });
    }

    async function publishItem(opt) {
      const input = JSON.parse(JSON.stringify(item.item));
      await updateSticker({
        id,
        title: input.title,
        url: input.url,
        description: input.description,
        source: input.source,
        published: opt,
      });
      await getItem();
    }

    async function saveItem() {
      if (item.delete) {
        // console.log('will be deleting...');
        return;
      }
      loading.value = true;
      const input = {};
      ['id', 'title', 'description', 'source', 'img', 'date', 'eventType'].forEach((x) => {
        input[x] = item[x];
        if (x === 'id') {
          input.id = item.id;
        }
      });
      const attrs = [];
      [
        { key: 'Bullhorn', value: 'bullhorns' },
        { key: 'Value', value: 'values' },
        { key: 'Interest', value: 'interests' },
        { key: 'Channel', value: 'channels' },
      ].forEach(({ key, value }) => {
        if (item[value]) {
          item[value].forEach((itm) => {
            attrs.push({ entity: key, entityId: itm });
          });
        }
      });
      input.eventAttributes = attrs;
      await updateEvent(input);
      loading.value = false;
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    async function handleUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.img = uploadStatus.resourceUrl;
      }
    }

    function handleUploadSuccess() {
      console.log('success');
    }

    function handleUploadError() {
      console.log('error');
    }

    onMounted(async () => {
      bullhorns.items = await getBullhorns();
      bullhorns.items = bullhorns.items
        .map((x) => ({ entity: 'Bullhorn', entityId: parseInt(x.id, 10), value: x.bullhorn }));

      values.items = await getValues();

      values.items = values.items
        .map((x) => ({ entity: 'Value', entityId: parseInt(x.id, 10), value: x.value }));

      interests.items = await getInterests();
      interests.items = interests.items
        .map((x) => ({ entity: 'Value', entityId: parseInt(x.id, 10), value: x.interest }));

      channels.items = await getChannels();
      channels.items = channels.items
        .map((x) => ({ entity: 'Channel', entityId: parseInt(x.id, 10), value: x.channel }));

      await getItem();
    });

    function removeItem() {
      removeItemGetConfirm(async () => {
        await removeEvent(item.id);
        router.push('/manage-content/events');
      });
    }

    return {
      // data
      refForm,
      item,
      loading,
      uploading,
      rules: {
        title: requiredRule('title'),
      },

      eventTypeLookups,
      bullhorns,
      values,
      interests,
      channels,

      // methods
      publishItem,
      removeItem,

      beforeUpload,
      handleUpload,
      handleUploadSuccess,
      handleUploadError,

      validateForm,
    };
  },
});
</script>
