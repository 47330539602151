<template lang="pug">
div.signin-container
 .d-flex.flex-column
   div.d-flex.mb-2.mx-auto.spot-logo-container.align-items-center
      img(src="@/assets/images/logos/logo_circle.png").w-40.h-40
      img(
      src="@/assets/images/logos/logo-text.png"
        srcset="@/assets/images/logos/text_logo_white.png"
      ).h-30
   div.d-flex.flex-column.min-vh-100.signin-form-container
      h1.mb-0.mx-auto Welcome Back
      .fs-14.lh-2.mx-auto
        span.fc-gray Don't have an account?
        router-link(:to="`/auth/web/signup${queryString}`")
          span.el-button.el-button--text.mb-1 &nbsp; Get Started
      div.px-20.py-4
        el-form(
          ref="refForm"
          :rules="rules"
          :model="item"
          status-icon
          label-position="top"
        ).signin-form-elements
          .alert.alert-danger(v-if="error")
           | phone or password incorrect

          el-form-item(prop="phone" label="Phone" class="signin-label")
            el-input(
              v-model="item.phone"
              type="tel"
              placeholder="(123) 456-7890"
              v-mask="'(###) ###-####'"
            ).sign-in-inputs  

          el-form-item( prop="password" label="Password" class="signin-label")
            el-input(v-model="item.password" type="password").sign-in-inputs 

          .py-4.text-center
            el-button(round type="success"
              @click="validateForm" v-loading="loading").sign-in-button Login

          .p-4.text-center
              el-button(link @click="gotoForgotPassword").ms-auto
                span.fc-gray Forgot your password?
</template>

<script>
import {
  defineComponent, reactive, ref, onMounted,
} from 'vue';
import { mask } from 'vue-the-mask';
import { checkUserExists, signIn } from '@/graphs/gql';
import shared from '../../../shared';
// import gqlRequest from '../dashboard/gql-request';

// Cache implementation
export default defineComponent({
  name: 'WebAppSignIn',
  directives: { mask },
  setup() {
    const {
      route,
      router,
      store,
      makeGraphRequest,
    } = shared();
    const item = reactive({
      phone: '',
      password: '',
    });
    const loading = ref(false);
    const refForm = ref(null);
    const error = ref(false);
    const { stickerId } = route.query;
    const queryString = ref('');

    function checkAuth() {
      if (store.getters['users/isMeSignedIn']) {
        if (route.path !== '/web/') {
        // router.push('web/dashboard');
        }
      }
    }
    async function submitForm() {
      try {
        loading.value = true;
        error.value = false;
        const checkUser = await makeGraphRequest('query', checkUserExists, { phone: item.phone, email: '', userId: '' }, null);
        if (checkUser.data != null) {
          const { phone } = checkUser.data.checkUserExists;
          const response = await makeGraphRequest(
            'mutation',
            signIn,
            { input: { phone, password: item.password } },
            null,
          );
          store.commit('users/SET_IS_ME_SIGNED_IN', true);
          store.commit('users/SET_ME', response.data.signIn);
          if (stickerId !== undefined) {
            router.push(`/web/sticker/${stickerId}`);
          } else {
            router.push('/web/');
          }
        } else {
          error.value = true;
          console.log(checkUser.error);
        }
      } catch (err) {
        error.value = true;
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) return submitForm();
        console.log('error submit!!');
        return false;
      });
    }

    function gotoForgotPassword() {
      router.push('/auth/forgot-password');
    }

    onMounted(() => {
      queryString.value = location?.search ?? '';
      store.commit('shared/SET_MAIN_MENU_COLLAPSE', false);
      checkAuth();
    });

    return {
      // data (static)
      refForm,
      rules: { },

      // data (dynamic)
      loading,
      error,
      item,
      queryString,
      // methods
      validateForm,
      gotoForgotPassword,
    };
  },
});
</script>
<style>
.signin-container {
    background-color: #4ccbbf;
    background-image: linear-gradient(to right, #1EBEAF, #57D3C7);
}
.spot-logo-container {
    padding: 50px 0px;
}
.spot-logo-container > img{
    margin: 0px 6px;
}
.signin-form-container {
    background-color: #fff;
    border-radius: 30px 30px 0px 0px;
    padding: 40px 0px;
    box-shadow: 0px -24px 0px -15px #ffffff80;
}
</style>
