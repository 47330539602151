export default {
  namespaced: true,

  state: {
    selectedOrganization: null
  },

  mutations: {
    MUTATE_SELECTED_ORGANIZATION(state, organization) {
      state.selectedOrganization = organization;
    },
  },

  // use `actions` for ajax request or to just follow vuex pattern
  actions: {
    SET_SELECTED_ORGANIZATION({ commit }, organization) {
      commit('MUTATE_SELECTED_ORGANIZATION', organization);
    },
  },

  getters: {
    GET_SELECTED_ORGANIZATION: (state) => state.selectedOrganization,
  },
};
