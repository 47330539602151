<script setup lang="ts">
import { defineProps } from 'vue';
import type { PaymentLogType } from 'src/data/types';
import type {PaymentLog} from "@/views/manage-content/payment-log/types";

const props = defineProps<{
  paymentLog: PaymentLogType | null;
}>();

const { paymentLog } = props;

const renderStickerDetailsCost = (paymentLog: PaymentLog) => {
  if (paymentLog.stripeTransaction?.amount > 0) {
    return '$' + paymentLog.stripeTransaction.amount.toFixed(2);
  }
  if (paymentLog.sticker.cost === 0) {
    return 'FREE'
  }
  return '$' + paymentLog.sticker.cost.toFixed(2);
}
</script>

<template>
  <section
    class="row row-xs align-items-center"
    v-if="paymentLog?.sticker"
  >
    <div class="px-2">
      <div class="img-uploader pos-r list-image-container">
        <template v-if="paymentLog?.sticker.url">
          <img
            :src="paymentLog?.sticker.url"
            class="img-fluid list-image-item"
          />
          <div class="pin pin-bx d-flex p-2">
            <h6
              class="mb-0 sticker-tag-text ms-auto"
              v-if="paymentLog?.sticker.campaignType != null"
            >
              {{ paymentLog?.sticker.campaignType }}
            </h6>
          </div>
        </template>
      </div>
    </div>

    <div>
      <div class="lh-sm" v-if="paymentLog?.sticker.user">
        <div class="row row-xs align-items-center">
          <div class="col-auto">
            <span>Creator:</span>
          </div>
          <div class="col-auto">
            <img
              v-if="paymentLog?.sticker.user.avatar"
              :src="paymentLog?.sticker.user.avatar"
              class="d-block size-20 obj-f-c rounded-circle"
            />
            <img
              v-else
              src="@/assets/images/default-profile.jpeg"
              class="d-block size-20 obj-f-c rounded-circle"
            />
          </div>
          <div class="col lh-3">
            <span>{{ paymentLog?.sticker.user.firstname }}</span>
            <span>{{ paymentLog?.sticker.user.lastname }}</span>
          </div>
        </div>
        <div class="fw-m">{{ paymentLog?.sticker.user.email }}</div>
        <div class="fw-m">{{ paymentLog?.sticker.user.phone }}</div>
        <div class="fw-m">Entity: User</div>
        <div class="fw-m">EntityId: {{ paymentLog?.sticker.user.id }}</div>
      </div>

      <div class="lh-sm" v-else-if="paymentLog?.sticker.organization">
        <div class="row row-xs align-items-center py-3">
          <div class="col-auto">
            <span>Creator:</span>
          </div>
          <div class="col-auto">
            <img
              v-if="paymentLog?.sticker.organization.avatar"
              :src="paymentLog?.sticker.organization.avatar"
              class="d-block size-20 obj-f-c rounded-circle"
            />
            <img
              v-else
              src="@/assets/images/default-profile.jpeg"
              class="d-block size-20 obj-f-c rounded-circle"
            />
          </div>
          <div class="col lh-1">{{ paymentLog?.sticker.organization.name }}</div>
        </div>
        <div class="fw-m">{{ paymentLog?.sticker.organization.email }}</div>
        <div class="fw-m">{{ paymentLog?.sticker.organization.phone }}</div>
        <div class="fw-m">Entity: Organization</div>
        <div class="fw-m">EntityId: {{ paymentLog?.sticker.organization.id }}</div>
        <div class="fw-m">Stripe Account Id: {{ paymentLog?.sticker.organization.stripeAccountId }}</div>
      </div>

      <hr class="my-2" />

      <div class="row row-xs align-items-center">
        <div class="col px-2">
          <div>
            <div class="fw-m">Title: {{ paymentLog?.sticker.title }}</div>
            <div class="fw-m" v-if="paymentLog?.sticker.campaignType != null">
              campaignType: {{ paymentLog?.sticker.campaignType }}
            </div>
            <div class="fw-m">StickerId: {{ paymentLog?.sticker.id }}</div>
            <div class="fw-m" v-text="'Description: ' + (paymentLog?.sticker?.description || '')"></div>
            <div class="fs-14 lh-2 el-button el-button--text">
              Cost:
              <span>&nbsp;{{ renderStickerDetailsCost(paymentLog) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
