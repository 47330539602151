<template lang="pug">
.spotlight_card.fw-b(v-if="paymentCompleteMobile === null")
    sticker-input-form(
      v-if="stickerDetails.campaignType !== 'Ticket'"
      :is-editable="editAmt"
      :show-form="!continuePayment"
      @edit-amt='editAmt = true'
      @input-amt='amt = $event'
      :userDetails='details',
      :stickerDetails='stickerDetails',
      :validationDetails='validationDetails'
    )
    ticket-input-form(
      v-else
      :is-editable="editAmt"
      :show-form="!continuePayment"
      @edit-amt='editAmt = true'
      @input-amt='amt = $event'
      :userDetails='details',
      :stickerDetails='stickerDetails',
      :validationDetails='validationDetails'
    )
    .pt-2.bg-gray-100.mb-4(v-if="!continuePayment")
    div(v-show="!editAmt && !continuePayment").bg-white

      .px-20.mb-4.fees-title(v-if="showContactInfo") Contact

        el-form(
          ref="refForm"
          :rules="rules"
          :model="unregisterInfo"
          status-icon
          label-position="top"
        )
          el-form-item(label="Email" prop="email")
            el-input(v-model="unregisterInfo.email").input-field-payment

          el-form-item(label="Mobile phone number" prop="phone")
            el-input(
              v-model="unregisterInfo.phone"
              type="tel"
              placeholder="(123) 456-7890"
              v-mask="'(###) ###-####'"
            ).input-field-payment
      .px-20.mb-4.fees-title Payment
        .max-w-350.mx-auto.mt-3
          p.input-filed-title Credit card
          div#payment-element
          div#card-errors.card_errors(role='alert', v-if='hasCardErrors') {{ cardErrors }}
      .pt-2.bg-gray-100.mb-4

      .px-20.py-4
        .max-w-300.mx-auto
          .fs-14.mb-4
            .d-flex.mb-2.fees-title
              div Subtotal
              .ms-auto ${{ parseFloat(amt).toFixed(2) }}
            .d-flex.mb-2.fees-title(v-if="amt >0")
              div Credit Card Fees (2.9% + ¢30)
              .ms-auto ${{ stripeFee.toFixed(2) }}
            .d-flex.mb-2.fees-title
              div Convenience Fees
              div(v-if="convenienceFeePercentageValue != -1")
                span &nbsp;({{ convenienceFeePercentageValue }}%)
              .ms-auto ${{ convenienceFee.toFixed(2) }}

            .d-flex.fees-total-title
              div Total
              .ms-auto  ${{ (parseFloat(amt) + convenienceFee + stripeFee).toFixed(2) }}
        .max-w-300.mx-auto
          .mb-4.lh-sm
          i.fs-12.fw-m.opacity-50(v-if="stickerDetails.campaignType && stickerDetails.campaignType === 'Sweepstakes' && stickerDetails.sweepstakesSticker.checkoutPageLegal != null")  {{ stickerDetails.sweepstakesSticker.checkoutPageLegal.replace(/<[^>]*>/g, '') }}
        .max-w-300.mx-auto
          .mb-4.lh-sm
          i.fs-12.fw-r.opacity-50(v-if="stickerDetails.campaignType && stickerDetails.campaignType === 'Campaign'" v-html="paymentText")
          i.fs-12.fw-r.opacity-50(v-else-if="stickerDetails.campaignType && stickerDetails.campaignType === 'Sweepstakes'" v-html="sweepstakesPaymentText")
          i.fs-12.fw-m.opacity-50(v-else v-html="paymentTextPaid")

          .mt-2
            el-button(type='success'
              v-loading='loading'
              round @click="finalizePayment"
              v-if="!hasCardErrors && showPaymentButton").mb-2.donate-button
              span(v-if="stickerDetails.campaignType && stickerDetails.campaignType === 'Campaign'") Confirm Donation
              span(v-else) Confirm Purchase
          .alert.alert-danger(v-if="error")
            | {{ errorMessage }}
        .px-3
          .max-w-300.mx-auto
            el-button(link round, @click.stop='cancelIntent').text-muted.donate-button Cancel
div(v-else)
    p {{ paymentSuccess === true ? (stickerDetails.campaignType && stickerDetails.campaignType === 'Campaign' ? "Thank you for the donation." : "Thank you for purchasing the sticker.") : "An Error occurred while processing the payment." }}
      p You may now
        el-button(@click="redirectMobileApp") Return to the App
        span(v-if="paymentSuccess === false")  and try again.
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import shared from './shared';
import {paymentText, paymentTextPaid, sweepstakesPaymentText} from '../../data/payment-text';
import {useStore} from 'vuex';
import {mask} from 'vue-the-mask';
import StickerInputForm from './StickerInputForm.vue';
import TicketInputForm from './TicketInputForm.vue';
import {ElLoading} from 'element-plus';
import globalShared from '@/shared';
import {email, phone} from '@/data/rules';

export default defineComponent({
  name: 'StickerPurchase',
  components: {StickerInputForm, TicketInputForm},
  directives: {mask},
  setup() {
    const {
      loading,
      route,
      requestCanIntent,
      requestCanIntentForOrg,
      requestCreateStickerPurchaseIntent,
      requestCanIntentForSticker,
      requestValidateIntentForSticker,
      requestValidateIntentForStickerPublic,
      requestCreateAnonymousStickerTransaction,
      requestStickerTicketEmailConfirmation
    } = shared();

    const {getMobileOperatingSystem, isSafari, handleSafariPrompt} = globalShared();

    const {
      userId, orgId, stickerId, causeId, variantIds, quantities,
    } = route.params;
    const {
      variantId,
      isWebUser,
      // variantIds,
      // quantities,
    } = route.query;

    const stickerDetails = reactive({});
    const validationDetails = reactive({});
    const refForm = ref(null);
    const readyToPay = ref(false);
    const hasCardErrors = ref(true);
    const cardErrors = ref('');
    const unregisterInfo = reactive({
      email: '',
      phone: '',
    });
    let paymentElement;
    let stripe;
    let elements;

    const userOrOrg = ref('user');
    const amt = ref(0);

    const fee = ref(0);
    const convenienceFee = ref(0);
    const convenienceFeePercentageValue = ref(0);
    const stripeFee = ref(0);
    const editAmt = ref(true);
    const continuePayment = ref(false);
    const details = reactive({});
    const paymentIntent = reactive({});
    const paymentDetails = reactive({});
    const store = useStore();
    const paymentCompleteMobile = ref(null);
    const paymentSuccess = ref(false);
    const showContactInfo = ref(false);
    const completeUserInfo = ref(false);

    function loadStripe(stripeURL, callback) {
      const obj = document.createElement('script');
      const script = document.getElementsByTagName('script')[0];
      obj.src = `//${stripeURL}`;
      if (callback) {
        obj.addEventListener('load', (e) => {
          callback(null, e);
        }, false);
      }
      // console.log(obj.src);
      script.parentNode.insertBefore(obj, script);
    }

    function calculateFinalPayment(depositAmount, isProfit) {
      let mTotalAmountWithConvenienceFee = 0;
      let mConvenienceFee = 0;
      let mConvenienceFeePercentageValue = 0;

      if (isProfit === true) {
        if (depositAmount > 0 && depositAmount <= 999) {
          mConvenienceFeePercentageValue = 8.0;
          mConvenienceFee = parseFloat((0.08 * depositAmount).toFixed(2));
          mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
        } else if (depositAmount > 999 && depositAmount <= 2500) {
          mConvenienceFeePercentageValue = 7.0;
          mConvenienceFee = parseFloat((0.07 * depositAmount).toFixed(2));
          mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
        } else if (depositAmount > 2500 && depositAmount <= 5000) {
          mConvenienceFeePercentageValue = 6.5;
          mConvenienceFee = parseFloat((0.065 * depositAmount).toFixed(2));
          mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
        } else if (depositAmount > 5000 && depositAmount <= 10000) {
          mConvenienceFeePercentageValue = 6.0;
          mConvenienceFee = parseFloat((0.06 * depositAmount).toFixed(2));
          mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
        } else {
          mConvenienceFeePercentageValue = 5.0;
          mConvenienceFee = parseFloat((0.05 * depositAmount).toFixed(2));
        }

        mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (depositAmount > 0 && depositAmount <= 250) {
          mConvenienceFeePercentageValue = 5.0;
          mConvenienceFee = parseFloat((0.05 * depositAmount).toFixed(2));
        } else if (depositAmount > 250 && depositAmount <= 500) {
          mConvenienceFeePercentageValue = 4.0;
          mConvenienceFee = parseFloat((0.04 * depositAmount).toFixed(2));
        } else if (depositAmount > 500 && depositAmount <= 1000) {
          mConvenienceFeePercentageValue = 3.5;
          mConvenienceFee = parseFloat((0.035 * depositAmount).toFixed(2));
        } else {
          mConvenienceFeePercentageValue = -1;
          mConvenienceFee = parseFloat((30 + depositAmount).toFixed(2));
        }

        if (mConvenienceFee > 30) {
          mConvenienceFeePercentageValue = -1;
          mConvenienceFee = 30;
        }

        mTotalAmountWithConvenienceFee = depositAmount + mConvenienceFee;
      }

      // eslint-disable-next-line max-len
      const totalAmount = parseFloat(((mTotalAmountWithConvenienceFee + 0.30) / (1 - 0.029)).toFixed(2));
      const mStripeFee = parseFloat(totalAmount - mTotalAmountWithConvenienceFee).toFixed(2);

      const result = {
        total_amount: totalAmount,
        deposit_amount: depositAmount,
        convenienceFee: mConvenienceFee,
        convenienceFeePercentageValue: mConvenienceFeePercentageValue,
        creditCardFee: mStripeFee,
      };
      return result;
    }

    async function makeIntent() {
      let amount = parseFloat(parseFloat(amt.value).toFixed(2));
      if (route.query.cost && route.query.cost !== 'NA') {
        amount = parseFloat(route.query.cost);
      }

      if (amount >= 0) {
        let totalAmount = amount + fee.value;
        let response = '';
        let purchasedBy = 'User';
        let isProfit = false;

        if (userOrOrg.value === 'user') {
          purchasedBy = 'User';
          isProfit = false;
        } else if (userOrOrg.value === 'org') {
          purchasedBy = 'Organization';
          isProfit = details.organizationType === 'FORPROFIT';
        } else {
          console.log('error');
        }

        loading.value = true;
        const finalPaymentCalculation = calculateFinalPayment(parseFloat(amount), isProfit);
        convenienceFee.value = finalPaymentCalculation.convenienceFee;
        stripeFee.value = parseFloat(parseFloat(finalPaymentCalculation.creditCardFee).toFixed(2));
        fee.value = parseFloat(parseFloat(convenienceFee.value + stripeFee.value).toFixed(2));
        convenienceFeePercentageValue.value = finalPaymentCalculation.convenienceFeePercentageValue;
        totalAmount = parseFloat(parseFloat(amount + fee.value).toFixed(2));

        if (amount === 0) {
          convenienceFee.value = 0;
          stripeFee.value = 0;
        }

        response = await requestCreateStickerPurchaseIntent(
          {
            purchaseBy: purchasedBy ?? 'User',
            stickerId: stickerDetails.id,
            userId: details.id ?? import.meta.env.VITE_ANONYMOUS_USER_ID,
            orgId: details.organizationId,
            causeId,
            variantId: variantIds +", " + quantities,
            cost: parseInt(amount),
            totalCost: totalAmount,
            totalFee: fee.value,
            convenienceFee: convenienceFee.value,
            creditCardFee: stripeFee.value,
          },
        );
        loading.value = false;

        if (await response !== '') {
          Object.assign(paymentDetails, JSON.parse(atob(response)));
          paymentDetails.amount = totalAmount;
          Object.assign(paymentIntent, paymentDetails);
          editAmt.value = false;
          loadStripe('js.stripe.com/v3/', () => {
            stripe = window.Stripe(paymentDetails.stripe_pub_key);
            elements = stripe.elements({clientSecret: paymentDetails.client_secret});
            paymentElement = elements.create('payment');
            paymentElement.mount('#payment-element');
            paymentElement.on('change', (e) => {
              if (e.complete) {
                readyToPay.value = true;
                hasCardErrors.value = false;
              } else if (e.error) {
                hasCardErrors.value = true;
                readyToPay.value = false;
                cardErrors.value = e.error.message;
              }
            });
          });
        }
      }
    }

    function getRedirectUrl() {
      if (isWebUser === 'true') {
        return import.meta.env.VITE_WEB_BASE_URL;
      } else {
        return import.meta.env.VITE_APP_REDIRECT_BASE_URL;
      }
    }

    function getRedirectIOSUrl() {
      if (isWebUser === 'true') {
        return import.meta.env.VITE_WEB_BASE_URL;
      } else {
        return import.meta.env.VITE_APP_REDIRECT_URL;
      }
    }

    async function finalizePayment(e) {
      validateForm();
      loading.value = true;
      localStorage.setItem('unregisterEmail', unregisterInfo.email);
      localStorage.setItem('unregisterPhone', unregisterInfo.phone);
      await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: '',
        },
        redirect: 'if_required',
      }).then(async (result) => {
        const amount = parseFloat(amt.value).toFixed(2);
        if (result.error) {
          // Show error to your customer (for example, insufficient funds)
          console.log(result.error.message);
        }

        const paymentIntent = result.paymentIntent;

        await requestCreateAnonymousStickerTransaction(
          paymentIntent.id,
          paymentIntent.client_secret,
          unregisterInfo.email,
          unregisterInfo.phone.replace(/[^\d]/g, '')
        );

        // await sendEmail(paymentIntent);

        if (result?.paymentIntent?.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.

          paymentSuccess.value = true;
          if (isWebUser === 'true') {
            if (stickerDetails.campaignType && stickerDetails.campaignType === 'Campaign') {
              document.body.innerHTML = 'Thank you for the donation';
            } else {
              document.body.innerHTML = 'Thank you for purchasing the sticker';
            }
            window.location.href = store.getters['users/me'] != null ? `${getRedirectUrl()}/web/stickers/purchased?`
              + `action=purchaseSticker&amount=${amount}&success=true`
              : `${getRedirectUrl()}/auth/web/complete-profile`;
          } else {
            redirectMobileApp();
          }
        } else {
          document.body.innerHTML = 'Sticker purchase failed.';
          if (isWebUser === 'true') {
            window.location.href = `${getRedirectUrl()}/web/sticker/${stickerId}?action=purchaseSticker&amount=${amount}&success=false`;
          } else {
            redirectMobileApp();
          }
        }
      }).finally(() => {
        loading.value = false;
      });
    }

    async function redirectMobileApp() {
      const amount = parseFloat(amt.value).toFixed(2);
      const loadingInstance = ElLoading.service({
        lock: false
      })
      const isAndroid = getMobileOperatingSystem() === "Android";
      let appId = import.meta.env.VITE_APP_ID;
      let appHost = import.meta.env.VITE_APP_HOST;
      let browserFallbackUrlBase = import.meta.env.VITE_WEB_BASE_URL;
      let browserFallbackUrlPath = 'web/dashboard';
      const success = (paymentSuccess.value).toString();

      if (isAndroid) {
        let encodedBrowserFallbackUrl = encodeURIComponent(`${browserFallbackUrlBase}/${browserFallbackUrlPath}`);
        window.location.href = `intent://${appHost}/?action=purchaseSticker&amount=${amount}&success=${success}#Intent;scheme=${appId};package=${appId};S.browser_fallback_url=${encodedBrowserFallbackUrl};end`;
      }

      // iOS redirect
      else {
        window.location.href = `${getRedirectIOSUrl()}?action=purchaseSticker&amount=${amount}&success=${success}`;
      }

      paymentCompleteMobile.value = true;

      // Detect if redirect was successful
      let didSafariRedirect = false;
      if (isSafari()) {
        didSafariRedirect = await handleSafariPrompt();
      }

      // we are checking for strong inequality with false, because null indicates that the prompt timed out, not that the redirection was unsuccessful
      // unfortunately this will attempt to redirect the user to the app store if they decline the redirect to the app on Safari
      // currently no better option exists for iOS Safari
      if (!isSafari() || didSafariRedirect !== false) {
        setTimeout(function () {
          // redirect user to the dashboard if the document is hidden (i.e. the app launched successfully)
          if (document.hidden) {
            window.location.href = `${browserFallbackUrlBase}/${browserFallbackUrlPath}`;
            loadingInstance.close();
          }
          // keep user on site to allow redirect with button
          else {
            loadingInstance.close();
          }
        }, 1500);
      }
      // iOS Safari failed redirect
      else {
        loadingInstance.close();
      }
    }

    function continueToPayment(e) {
      e.preventDefault();
      continuePayment.value = true;
    }

    async function getUserIntent() {
      loading.value = true;
      const response = await requestCanIntent(userId);
      Object.assign(details, response);
      loading.value = false;
    }

    async function getOrgIntent() {
      loading.value = true;
      const response = await requestCanIntentForOrg(userId, orgId);
      Object.assign(details, response);
      loading.value = false;
    }

    async function getStickerIntent() {
      loading.value = true;
      const response = await requestCanIntentForSticker(stickerId);
      
      Object.assign(stickerDetails, response);
      const entityType = userOrOrg.value === 'user'
        ? 'User' : 'Organization';
      const entityId = userOrOrg.value === 'user'
        ? userId : orgId;
      if (store.getters['users/me'] !== null || isWebUser !== 'true') {
        const validateResponse = await requestValidateIntentForSticker(
          stickerId, entityType, entityId,
        );

        Object.assign(validationDetails, validateResponse);
      } else {
        const validateResponse = await requestValidateIntentForSticker(
          stickerId, 'User', import.meta.env.VITE_ANONYMOUS_USER_ID
        );
        showContactInfo.value = true;
        Object.assign(validationDetails, validateResponse);
      }
      loading.value = false;
    }

    function cancelIntent() {
      if (isWebUser === 'true') {
        window.location.href = `${getRedirectUrl()}/web/sticker/${stickerId}`;
      } else {
        window.location.href = `${getRedirectUrl()}?action=StickerPurchaseCancel`;
      }
    }

    const sendEmail = async (paymentIntent) => {
      const descriptions = createEmailDescriptions();
      let email = route.query?.email || '';
      email = email.replace(/ /g, '+');
      const name = details.firstname || '';
      const userId = route.params?.userId ?? '';
      const stickerId = route.params?.stickerId ?? '';

      console.log(userId);

      return requestStickerTicketEmailConfirmation(descriptions, email, name, paymentIntent.id, paymentIntent.client_secret, userId, stickerId);
    }


    const createEmailDescriptions = () => {
      // description[ticket description]  | unit price  | quantity | amount [total]
      if (stickerDetails.campaignType === 'Ticket') {
        return buildTicketDescriptions();
      }
      return buildStickerDescriptions();
    }

    const buildTicketDescriptions = () => {
      const variantIds = route.params.variantIds?.split(',');
      const quantities = route.params.quantities?.split(',');
      let descriptions = [];

      stickerDetails.varients.map((variant) => {
        console.log(variant);
        if (variantIds?.includes(variant.id)) {
          const index = variantIds.indexOf(variant.id);
          const quantity = Number(quantities[index]);
          const totalAmount = variant.cost * quantity;

          const description = {
            title: stickerDetails.title,
            description: stickerDetails.description,
            ticketDescription: variant.description,
            unitPrice: variant.cost,
            quantity,
            totalAmount
          };
          descriptions.push(description)
        }
      })
      return descriptions;
    }

    const buildStickerDescriptions = () => {
      console.log('stickerr');
      return [{
        title: stickerDetails.title,
        description: stickerDetails.description,
        ticketDescription: '',
        unitPrice: stickerDetails.cost,
        quantity: 1,
        totalAmount: stickerDetails.cost,
      }]
    }

    async function validateForm() {
      if (refForm.value) {
        refForm.value.validate(async (valid) => {
          if (valid) {
            completeUserInfo.value = true;
            return true;
          }
          loading.value = false;
          completeUserInfo.value = false;
          return false;
        });
      }
    }

    //_____check if payment button should be shown_____
    const showPaymentButton = computed(() => {
      if (isWebUser !== 'true' || (store.getters['users/me'] != null && unregisterInfo.email === '' && unregisterInfo.phone === '')) {
        return true;
      } else if (store.getters['users/me'] === null && unregisterInfo.email !== '' && unregisterInfo.phone !== '') {
        validateForm();
        if (completeUserInfo.value === true)
          return true;
      } else {
        return false;
      }
    });

    function calculateAmount() {
      let calculatedAmt = 0;
      for (let i = 0; i < variantIds.length; i++) {
        const variantId = variantIds[i];
        const quantity = quantities[i];

        const variant = stickerDetails.variants.find(v => v.id === variantId);
        if (variant) {
          calculatedAmt += variant.price * quantity;
        }
      }
      if (route.query.cost && route.query.cost !== 'NA') {
        calculatedAmt = parseFloat(route.query.cost);
      }
      console.log(amt.value);
      amt.value = calculatedAmt;
    }

    onMounted(async () => {
      if (userId && orgId) {
        userOrOrg.value = 'org';
        await getOrgIntent();
      } else if (userId && !orgId) {
        userOrOrg.value = 'user';
        await getUserIntent();
      } else {
        userOrOrg.value = 'false';
        console.log('error');
      }
      await getStickerIntent();
      await makeIntent();

      if (route.query.cost && route.query.cost !== 'NA') {
        amt.value = parseFloat(route.query.cost);
      }
      // calculateAmount ();
    });

    return {
      refForm,
      amt,
      fee,
      convenienceFee,
      stripeFee,
      convenienceFeePercentageValue,
      paymentIntent,
      editAmt,
      continuePayment,
      unregisterInfo,
      showContactInfo,
      loading,
      makeIntent,
      requestCanIntent,
      requestCanIntentForOrg,
      readyToPay,
      hasCardErrors,
      cardErrors,
      paymentDetails,
      finalizePayment,
      redirectMobileApp,
      cancelIntent,
      details,
      continueToPayment,
      paymentText,
      paymentTextPaid,
      sweepstakesPaymentText,
      stickerDetails,
      validationDetails,
      isWebUser,
      paymentCompleteMobile,
      paymentSuccess,
      validateForm,
      rules: {
        email,
        phone,
      },
      showPaymentButton
    };
  },
});
</script>
