import { getActivityLogs } from "@/graphs/activity";
import globalShared from '@/shared';

const { makeGraphRequest, getAuthToken } = globalShared();

const actions = {
  SET_SORT({ commit}, value) {
    commit('MUTATE_SORT', value);
  },
  SET_DATE({ commit }, value) {
    commit('MUTATE_DATE', value);
  },
  SET_SEARCH({ commit }, value) {
    commit('MUTATE_SEARCH', value);
  },
  async FETCH_ACTIVITY_LOGS({ commit, getters, rootGetters }, { entity = "", entityId = "" }) {
    commit('MUTATE_ACTIVITY_LOGS_LOADING', true);
    commit('MUTATE_ACTIVITY_LOGS_ERROR', false);

    const token = rootGetters['users/getToken'];
    const variables = {
      entity,
      entityId,
    };

    try {
      const response = await makeGraphRequest(
        'query', getActivityLogs, variables, token,
      )
      if (response.errors?.length) {
        throw new Error('There has been an error');
      }
      commit('MUTATE_ACTIVITY_LOGS', response.data.GetPaymentsLogsForActivity)
      commit('MUTATE_ACTIVITY_LOGS_LOADING', false);
      commit('MUTATE_ACTIVITY_LOGS_ERROR', false);
      return true;
    } catch (e) {
      commit('MUTATE_ACTIVITY_LOGS_ERROR', e);
      commit('MUTATE_ACTIVITY_LOGS_LOADING', false);
      return false;
    }
  },

  async SET_SELECTED_LOGS({ commit }, value) {
    commit('MUTATE_SELECTED_LOGS', value);
  }
}

export default actions;
