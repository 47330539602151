<!-- eslint-disable prefer-template -->
<template lang="pug">
app-main-content-layout(title='Payment Logs')
  div
    el-table(
      :data="items.items"
      stripe
      v-loading="loading"
      style="width: 100%"
      @row-click="ShowPaymentLogDetails($event)"
    ).clickable-rows
      el-table-column(width="10")
        // space

      el-table-column(label="Entity" min-width="140")
        template(#default="scope")
          .lh-sm(v-if="scope.row.user")
            .fw-b User
            .fw-b
            | {{ scope.row.user.firstname }} {{ scope.row.user.lastname }}
            .fw-b {{ scope.row.user.email }}
          .lh-sm(v-else-if="scope.row.organization")
            .fw-b Organization
            .fw-b {{ scope.row.organization.name }}
            .fw-b {{ scope.row.organization.email }}

      el-table-column(label="Payment Info" min-width="280")
        template(#default="scope")
          .lh-sm
            .fw-b {{ scope.row.paymentInfo }}

      el-table-column(label="Amount" min-width="65")
        template(#default="scope")
          .lh-sm
            .fw-b $ {{ processRowAmount(scope) }}

      el-table-column(label="Time" min-width="65")
        template(#default="scope")
          .lh-sm
            .fw-b {{ getformattedDateTime(scope.row.createdAt) }}

      el-table-column(label="Status" min-width="65")
        template(#default="scope")
          .lh-sm
            .fw-b {{ processRowStatus(scope) }}

      el-table-column(width="10")
        // space

    payment-log-details(
      v-if="showDetails"
      v-model="showDetails"
      :loading="loading"
      :paymentLog="paymentLog"
      @close-dialog="showDetails = false"
    )
</template>

<script lang="ts">

import {
  reactive, ref, onMounted, defineComponent,
} from 'vue';
import shared from './shared/index';
import PaymentLogDetails from './Details.vue';
import type { ScopeType } from "@/views/manage-content/payment-log/types";


export default defineComponent({
  name: 'Org_Payment_Logs',
  components: { PaymentLogDetails },
  setup() {
    const {
      route,
      findOrgAdminPaymentsLogV2,
      loading,
      dialog,
    } = shared();
    const items = reactive({ items: [] });
    const showDetails = ref(false);
    const paymentLog = reactive({});
    const { orgId } = route.params;

    async function ShowPaymentLogDetails(paymentLogItem) {
      Object.assign(paymentLog, paymentLogItem);
      showDetails.value = true;
    }

    async function getItems() {
      try {
        loading.value = true;
        items.items = await findOrgAdminPaymentsLogV2('Organization', orgId);
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    function getformattedDateTime(timeOffset) {
      const mydate = new Date(timeOffset).toLocaleString().split(',');
      return `${mydate[0]} ${mydate[1]}`;
    }

    function timeSince(timeOffset) {
      const mydate = new Date(timeOffset);
      return `on ${mydate.toDateString()} at ${mydate.getHours()}:${mydate.getMinutes()}`;
    }

    const processRowStatus = (scope: ScopeType) => {
      // NOTE: we do this check because backend is a bit too complex. We've inherited spaghetti code
      if (scope.row.stripeTransaction === null) {
        return scope.row.status;
      }
      if (scope.row.stripeTransaction.processed) {
        return 'Success'
      }
      return 'Failed';
    }

    const processRowAmount = (scope: ScopeType) => {
      const amount = scope.row.stripeTransaction === null ? scope.row.amount : scope.row.stripeTransaction.amount
      return parseFloat(String(amount)).toFixed(2);
    }

    onMounted(async () => {
      await getItems();
    });

    return {
      items,
      loading,
      dialog,
      // methods
      getformattedDateTime,
      timeSince,
      showDetails,
      ShowPaymentLogDetails,
      paymentLog,
      processRowStatus,
      processRowAmount
    };
  },
});
</script>
