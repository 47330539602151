import globalShared from '@/shared';
import {
  findEvents,
  findEvent,
  mutateEvent,
  mutateRemoveEvent,
} from '@/graphs/gql';

import bullhornsShared from '../../directory/bullhorns/shared';
import channelsShared from '../../directory/channels/shared';
import valuesShared from '../../directory/values/shared';
import interestsShared from '../../directory/interests/shared';

export default function commonShared(): { [key: string]: any } {
  const {
    ref, route, router, removeItemGetConfirm,
    dateAndTime, makeGraphRequest, getAuthToken,
    preSignedUrl,
  } = globalShared();

  const {
    getBullhorns,
  } = bullhornsShared();

  const {
    getChannels,
  } = channelsShared();

  const {
    getValues,
  } = valuesShared();

  const {
    getInterests,
  } = interestsShared();

  async function getEvents(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findEvents, {}, getAuthToken(),
    );
    return response.data.findEvents;
  }

  async function getEvent(id: number): Promise<any> {
    const response = await makeGraphRequest(
      'query', findEvent, { id }, getAuthToken(),
    );
    return response.data.findEvent;
  }

  async function updateEvent(input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation', mutateEvent, { input }, getAuthToken(),
    );
    return response.data.mutateEvent;
  }

  async function removeEvent(id: number) {
    await makeGraphRequest(
      'mutation', mutateRemoveEvent, { id }, getAuthToken(),
    );
  }

  return {
    ref,
    route,
    router,
    loading: ref(false),
    dialog: ref(false),
    removeItemGetConfirm,
    preSignedUrl,
    getEvents,
    getEvent,
    updateEvent,
    removeEvent,
    dateAndTime,

    getBullhorns,
    getChannels,
    getValues,
    getInterests,
  };
}
