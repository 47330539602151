<script setup lang="ts">
import {computed, defineProps, ref, watch, onMounted, defineEmits } from 'vue';
import VueMultiselect from 'vue-multiselect';
import GlowPicker from "@/components/StickerTIcket/GlowPicker.vue";
import store from "@/store";
import Tiers from "@/views/manage-content/media/add-sticker/components/Tiers.vue";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '../../../../../assets/styles/qill.scss';
import {timezones} from "@/data/timezones";
import {ElTimePicker} from "element-plus";
import moment from 'moment-timezone';

import { combineDateAndTime } from '@/shared/sticker-date-combos'

const props = defineProps<{
  causes: string[];
  availableTags: string[];
  causeLoading: boolean;
}>()

const emit = defineEmits(['onFetchCauses']);

const item = computed({
  get: () => store.getters['sticker/GET_STICKER'],
  set: (value) => {
    store.dispatch('sticker/SET_STICKER', value);
  }
})

const dateEvent = ref();
const timeEvent = ref();
const dateStart = ref();
const timeStart = ref();
const dateEnd = ref();
const timeEnd = ref();
const dateTimeTimezone = ref();

const combineTime = () => {
  const eventDate = combineDateAndTime(dateEvent.value, timeEvent.value)
  const startDate = combineDateAndTime(dateStart.value, timeStart.value)
  const endDate = combineDateAndTime(dateEnd.value, timeEnd.value)
  const targetTimeZone = dateTimeTimezone.value;

  const convertToUTC = (date, timeZone) => {
    const localDate = moment.tz(date, timeZone);
    return localDate.utc().format();
  };

  if (targetTimeZone != null) {
    const startDateInUTC = convertToUTC(startDate, targetTimeZone);
    const endDateInUTC = convertToUTC(endDate, targetTimeZone);
    const eventDateInUTC = convertToUTC(eventDate, targetTimeZone);

    store.dispatch("sticker/SET_STICKER", {
      startDate: startDateInUTC,
      endDate: endDateInUTC,
      eventDate: eventDateInUTC,
      startDateTimeZone: targetTimeZone,
      endDateTimeZone: targetTimeZone,
      eventDateTimeZone: targetTimeZone,
    });
  }
}

watch([dateStart, timeStart, dateEnd, timeEnd, dateEvent, timeEvent, dateTimeTimezone], () => {
  combineTime()
})


onMounted(async () => {
  if (
    !item.value.endDate ||
    !item.value.eventDate ||
    !item.value.startDate
  ) {
    return;
  }

  const receivedStartDate = item.value.startDate;
  const timezone = item.value.startDateTimeZone;

  dateTimeTimezone.value = timezone;
  const userTimezone = moment.tz.guess();

  // START DATE
  const start = moment.tz(receivedStartDate, 'YYYY-MM-DDTHH:mm:ssZ', userTimezone)
    .clone()
    .tz(timezone);

  dateStart.value = start.format('YYYY-MM-DD');
  timeStart.value = start.format('hh:mm A');

  // END DATE
  const receivedEndDate = item.value.endDate;
  const end = moment.tz(receivedEndDate, 'YYYY-MM-DDTHH:mm:ssZ', userTimezone)
    .clone()
    .tz(timezone);

  dateEnd.value = end.format('YYYY-MM-DD');
  timeEnd.value = end.format('hh:mm A');

  // EVENT DATE
  const receivedEventDate = item.value.eventDate;
  const event = moment.tz(receivedEventDate, 'YYYY-MM-DDTHH:mm:ssZ', userTimezone)
      .clone()
      .tz(timezone);

  dateEvent.value = event.format('YYYY-MM-DD');
  timeEvent.value = event.format('hh:mm A');
})

const preventArrowKeys = (event: KeyboardEvent) => {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    event.preventDefault();
  }
};

</script>

<template>
  <section class="c-ticket">
    <article class="c-ticket__row c-ticket__row--column">
      <span class="c-ticket__label">Sticker Title*</span>
      <input class="c-input" type="text" required placeholder="Title" v-model="item.title" />
    </article>

    <article class="c-ticket__row c-ticket__row--column">
      <span class="c-ticket__label">Location</span>
      <input class="c-input" type="text" required placeholder="Los Angeles, CA 90012" v-model="item.location" />
    </article>

    <article class="c-ticket__row c-ticket__row--column">
      <span class="c-ticket__label">Details*</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.description" content-type="html" />
    </article>

    <article class="c-ticket__row c-ticket__row--column">
        <span class="c-ticket__label">Tags*</span>
        <vue-multiselect
            class="c-input-multiselect"
            v-model="item.tags"
            :multiple="true"
            :options="availableTags"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Tag"
        />
    </article>

    <article class="c-ticket__row c-ticket__row--column">
      <span class="c-ticket__label">Waiver URL (optional)</span>
      <input class="c-input" type="text" required placeholder="https://waiver.com" v-model="item.waiverURL" />
    </article>

    <article class="c-ticket__row c-ticket__row--column">
      <span class="c-ticket__label">Cause (optional)</span>
      <vue-multiselect
        class="c-input-multiselect"
        :multiple="true"
        :options="causes"
        :searchable="true"
        :close-on-select="true"
        :show-labels="false"
        placeholder="Non profit"
        v-model="item.causes"
        :loading="causeLoading"
        @search-change="(val) => emit('onFetchCauses', val)"
      />
    </article>

    <article class="c-ticket__row c-ticket__row--column">
        <div class="c-ticket__row c-ticket__row--column">
            <span class="c-ticket__label">Max Quantity (optional)</span>
            <input class="c-input" type="number" min="0" v-model="item.maxQuantity" @keydown="preventArrowKeys" @wheel.prevent/>
        </div>

        <!-- <div class="c-ticket__row c-ticket__row--column">
            <span class="c-ticket__label">Cost</span>
            <input class="c-input" type="text" required placeholder="Cost" v-model="item.cost" />
        </div> -->
    </article>

    <article class="c-ticket__row c-ticket__row--column">
        <span class="c-ticket__label">Event Date & Time</span>
        <article class="c-glow-picker__group">
          <div class="c-glow-picker__group__inner">
            <el-date-picker class="c-glow-picker__input" v-model="dateEvent" format="MM/DD/YY" />
            <div class="position-relative">
              <el-time-picker
                arrow-control
                class="c-custom-time"
                format="hh:mm A"
                v-model="timeEvent"
                value-format="hh:mm A"
              />
            </div>
          </div>
        </article>
    </article>


    <article class="c-ticket__row c-ticket__row--column">
        <span class="c-ticket__label">Sale Start Date & Time</span>
        <article class="c-glow-picker__group">
          <div class="c-glow-picker__group__inner">
            <el-date-picker class="c-glow-picker__input" v-model="dateStart" format="MM/DD/YY" />
            <div class="position-relative">
                <el-time-picker
                  arrow-control
                  class="c-custom-time"
                  format="hh:mm A"
                  v-model="timeStart"
                  value-format="hh:mm A"
                />
            </div>
          </div>
        </article>
    </article>

    <article class="c-ticket__row c-ticket__row--column">
      <span class="c-ticket__label">Sale End Date & Time</span>
      <article class="c-glow-picker__group">
        <div class="c-glow-picker__group__inner">
          <el-date-picker class="c-glow-picker__input" v-model="dateEnd" format="MM/DD/YY" />
          <div class="position-relative">
            <el-time-picker
              arrow-control
              class="c-custom-time"
              format="hh:mm A"
              v-model="timeEnd"
              value-format="hh:mm A"
            />
          </div>
        </div>
      </article>
    </article>


    <el-select class="c-glow-picker__input" placeholder="Timezone" v-model="dateTimeTimezone">
      <el-option
        v-for="timezone in timezones"
        :key="timezone.value"
        :label="timezone.label"
        :value="timezone.value">
      </el-option>
    </el-select>

    <glow-picker ref="glowPickerRef" />
    <tiers />

    <article class="c-ticket__row c-ticket__row--column">
      <span class="c-ticket__label">Message for Confirmation Email</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.confirmationEmail" content-type="html" />
    </article>

    <article class="c-ticket__row c-ticket__row--column">
      <span class="c-ticket__label">Message for Reminder Email</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.reminderEmail" content-type="html" />
    </article>

  </section>

</template>

<style lang="scss">
.c-ticket {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 340px;

  .el-select {
    width: 100%;
    max-width: 340px;
    &__placeholder {
      color: #000000;
      font-family: "Red Hat Display";
      font-size: 14px;
      font-weight: 500;
    }
    &__wrapper {
      height: 100%;
      border: none;
      box-shadow: none;
      width: 338px;
    }
  }

  .multiselect  {
    max-width: 340px;
  }

  &__label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }

  &__row {
    display: flex;
    &--column {
      flex-direction: column;
    }

    &--quantity-cost {
      gap: 20px;
      >div {
        width: calc(50% - 10px);
      }
    }
  }
  /* Hide the arrows in number input fields */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Standard property for compatibility */
  }
}
</style>
