<template lang="pug">
app-main-content-layout(title='Stripe Connect')
  div
    .card-header.p-4.bg-transparent
      .row.row-sm.align-items-center
        .col
          h6.mb-0(v-for="message in responseMessage") {{message}}
</template>

<script>

import {
  defineComponent,
  onMounted,
} from 'vue';
import shared from '../shared';

export default defineComponent({
  name: 'ConfirmOrgOnboard',
  components: {},
  setup() {
    const {
      ref,
      route,
      router,
      doCompleteStripeOnboarding,
    } = shared();
    const { code, state } = route.query;
    const loading = ref(true);
    const organization = ref({});
    const responseMessage = ref([]);

    async function completeStripeOnboarding() {
      responseMessage.value.push('Trying to connect the organization with the stripe account....');
      try {
        const response = await doCompleteStripeOnboarding(
          code,
          state,
        );
        organization.value = response;
        console.log('organization.value', organization.value);
        if (organization.value.stripe_onboarded) {
          // eslint-disable-next-line max-len
          responseMessage.value.push(`Stripe account succesfully connected for organization ${organization.value.name}`);
          responseMessage.value.push(`Redirecting to ${organization.value.name} organization...`);
          router.push(`/manage/orgs/${organization.value.id}/edit`);
        } else {
          // eslint-disable-next-line max-len
          responseMessage.value.push(`Failed to connect Stripe account for organization ${organization.value.name}, Please try again.`);
        }
      } catch (err) {
        // eslint-disable-next-line max-len
        responseMessage.value.push('Error occoured while confirming Stripe account for organization.');
      }
    }

    onMounted(async () => {
      loading.value = true;
      responseMessage.value.push('awaiting response from Stripe....');
      await completeStripeOnboarding();
      loading.value = false;
    });

    return {
      loading,
      organization,
      responseMessage,
    };
  },
});
</script>
