<script>
import {
  defineComponent, reactive, ref, onMounted,
} from 'vue';
import { email } from '@/data/rules';
import { adminSignIn } from '@/graphs/gql';
import shared from '../shared';

// Cache implementation
export default defineComponent({
  name: 'Authenticator',
  setup() {
    const {
      route,
      router,
      store,
      makeGraphRequest,
    } = shared();
    const item = reactive({
      email: '',
      password: '',
    });
    const loading = ref(false);
    const refForm = ref(null);
    const error = ref(false);
    const emailInput = ref(null);
    const passwordInput = ref(null);


    function checkAuth() {
      if (store.getters['users/isMeSignedIn']) {
        if (route.path !== '/') router.push('/');
      }
    }
    async function submitForm() {
      try {
        loading.value = true;
        error.value = false;
        const response = await makeGraphRequest('mutation', adminSignIn, { input: item }, null);
        store.commit('users/SET_IS_ME_SIGNED_IN', true);
        store.commit('users/SET_ME', response.data.adminSignIn);
        if (store.getters['users/isOrgAdmin']) {
          router.push('/manage/orgs');
        }
        else{
          router.push('/');
        }
      } catch (err) {
        error.value = true;
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) return submitForm();
        console.log('error submit!!');
        return false;
      });
    }

    function gotoForgotPassword() {
      router.push('/auth/forgot-password');
    }

    function gotoWebApp() {
      router.push('/auth/web/signin');
    }

    onMounted(() => checkAuth());

    return {
      refForm,
      rules: { email },
      loading,
      error,
      item,
      emailInput,
      passwordInput,
      validateForm,
      gotoForgotPassword,
    };
  },
});
</script>

<template xmlns="http://www.w3.org/1999/html">
    <el-form
    class="c-login-form"
    ref="refForm"
    :rules="rules"
    :model="item"
    status-icon
    label-position="top"
    @keydown.enter="validateForm"
  >
    <div class="c-login-form__heading">Welcome Back</div>
    <div v-if="error" class="alert alert-danger">
      Username or password incorrect
    </div>

    <section class="c-login-form__items">
      <el-form-item prop="email" @click="emailInput.focus()">
        <label>Email</label>
        <input v-model="item.email" type="email" ref="emailInput" placeholder="abc@spotlight.xyz"/>
      </el-form-item>

      <el-form-item prop="password" @click="passwordInput.focus()">
        <label>Password</label>
        <input v-model="item.password" type="password" ref="passwordInput" />
      </el-form-item>
    </section>

    <el-button class="c-login-form__submit" type="success" @click="validateForm" v-loading="loading">
      Login
    </el-button>

    <el-button class="c-login-form__forgot" link @click="gotoForgotPassword">
      Forgot your password?
    </el-button>
  </el-form>
</template>

<style lang="scss">
.c-login-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  &__heading {
    font-family: 'Red Hat Display';
    color: #333333;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }

  &__submit.el-button.el-button--success {
    width: 100%;
    height: 48px;
    font-size: 16px;
    border-radius: 56px;
    background-color: #005C6B;
    border-color: #005C6B;
  }

  &__forgot {
    text-align: center;
    width: 100%;

    span {
      color: #666666;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .el-form-item {
    margin: 0;
  }

  &__items {
    gap: 20px;
    display: flex;
    flex-direction: column;

    .el-form-item__content {
      height: 75px;
      border: 1px solid #DEDEDE;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      label {
        font-family: "Red Hat Display";
        font-size: 12px;
        color: #666666;
        line-height: 12px;
        font-weight: 400;
      }

      input {
        box-shadow: none;
        border: 0 none transparent;
        outline: none;

        &::placeholder {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>

