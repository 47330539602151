<!-- eslint-disable max-len -->
<template lang="pug">
div.stickers-list-container
    div(v-for="item in stickers" @click="gotoStickerDetails(item.id)")
      div
        .img-uploader.pos-r.list-image-container
          template(v-if="item.url")
            img(:src="item.url").img-fluid.list-image-item
            .pin.pin-bx.d-flex.pb-2
              h6.mb-0.sticker-tag-text(v-if="item.campaignType")
                | {{ item.campaignType }}
      div.py-2
        .row.row-xs.align-items-center
          .col-auto
            img(
              v-if="item.entity==='User' && item.user && item.user.avatar"
              :src="item.user.avatar"
            ).d-block.size-24.obj-f-c.rounded-circle
            img(
              v-else-if="item.entity==='Organization' && item.organization && item.organization.avatar"
              :src="item.organization.avatar"
            ).d-block.size-24.obj-f-c.rounded-circle
            img(
              v-else
              src="@/assets/images/default-profile.jpeg"
            ).d-block.size-24.obj-f-c.rounded-circle
          .col.lh-1
            span(v-if="item.entity==='User' && item.user").sticker-author {{ item.user.firstname }} {{ item.user.lastname }}
            span(v-else-if="item.entity==='Organization' && item.organization").sticker-author {{ item.organization.name }}
            span(v-else).sticker-author Spotlight
      div
        h6.mb-0 {{ item.title }}
      div
        .fs-14.lh-2.el-button.el-button--text
          span
            span(v-if="item.cost == 0") FREE
            span(v-else) ${{ item.cost.toFixed(2) }}
</template>
  
  <script lang="ts">
  import {
    defineComponent, onMounted,
  } from 'vue';
  import gqlRequest from '../dashboard/gql-request';
  
  export default defineComponent({
    name: 'WebAppStickersGridTemplate',
    props: {
      stickers: { type: Array, default: () => [{}] },
    },
    setup() {
      const {
        route, router, loading,
      } = gqlRequest();
  
      function gotoStickerDetails(stickerId: any) {
        router.push(`/web/sticker/${stickerId}`);
      }
  
      onMounted(async () => {
        loading.value = true;
        loading.value = false;
      });
  
      return {
        route,
        router,
        loading,
        // data
        gotoStickerDetails,
      };
    },
  });
  </script>
  <style>
  .stickers-list-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
  }
  .list-image-item {
    max-height: 200px;
    display: flex;
  }
  .list-image-container {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.2px solid grey;
    border-radius: 6px;
    min-width: 150px;
  }
  .sticker-author {
    color: #767676;
    font-size: small;
    font-weight: 500;
  }
  </style>
  