interface Channel {
  id: string,
  name: string;
}

const channels: Channel[] = [
  { id: '1', name: 'Culture' },
  { id: '2', name: 'Entertainment' },
  { id: '3', name: 'Lifestyle' },
  { id: '4', name: 'Uprising' },
  { id: '5', name: 'Knowledge' },
  { id: '6', name: 'Society' },
  { id: '7', name: 'Science' },
  { id: '8', name: 'Ego' },
  { id: '9', name: 'Urgent' },
  { id: '10', name: 'Trending' },
];

interface Bullhorn {
  id: string,
  name: string;
}

const bullhorns: Bullhorn[] = [
  { id: '1', name: 'Animals' },
  { id: '2', name: 'Children\'s Rights' },
  { id: '3', name: 'Civil Rights' },
  { id: '4', name: 'Community Development' },
  { id: '5', name: 'Criminal Justice' },
  { id: '6', name: 'Culture, Art & Humanities' },
  { id: '7', name: 'Democracy' },
  { id: '8', name: 'Disaster Relief' },
  { id: '9', name: 'Diversity & Inclusion' },
  { id: '10', name: 'Economy & Small Business' },
];

interface OrgValue {
  id: string,
  name: string;
}

const orgValues: OrgValue[] = [
  { id: '1', name: 'Adventure' },
  { id: '2', name: 'Advocacy' },
  { id: '3', name: 'Ambition' },
  { id: '4', name: 'Attitude' },
  { id: '5', name: 'Calmness' },
  { id: '6', name: 'Caring' },
  { id: '7', name: 'Challenge' },
  { id: '8', name: 'Compassion' },
  { id: '9', name: 'Cooperation' },
  { id: '10', name: 'Courage' },
];

interface WhyUs {
  id: string,
  name: string;
}

const whyUsItems: WhyUs[] = [
  { id: '1', name: 'Content Publishing Services' },
  { id: '2', name: 'Community Relationship Management' },
  { id: '3', name: 'E-Commerce Solutions' },
  { id: '4', name: 'CTA and Advocacy Management' },
  { id: '5', name: 'Building Public Awareness and Education' },
  { id: '6', name: 'Volunteer Management Solutions' },
  { id: '7', name: 'Productivity Tools' },
  { id: '8', name: 'Fundraising Services' },
];

export {
  channels, bullhorns, orgValues, whyUsItems,
};
