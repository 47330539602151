<template lang="pug">
el-dialog(
  title="Add User"
)
  h6.mb-0.py-2 Hello World POP
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DialogPromptDownloadApp',
  props: {
    modelValue: { type: Boolean, default: false },
  },
  emits: ['close-dialog'],
  setup() {
    return {
    };
  },
});
</script>
