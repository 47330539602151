import { useRouter } from 'vue-router';
import { ref, reactive } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { makeRequest } from '@/graphs/index';
import { getPresignedUrl } from '@/graphs/gql';

export default function upload(entityKey: string, entity: string): { [key: string]: any } {
  const store = useStore();
  const filename = ref('');
  const filetype = ref('');
  const router = useRouter();
  const uploading = ref(false);
  const uploadStatus = reactive({ status: 'ok', message: '', resourceUrl: '' });

  function getAuthToken(): string {
    const { token } = store.getters['users/me'];
    return token;
  }

  async function preSignedUrl(key: string, folder: string, makePublic: boolean): Promise<string> {
    const response = await makeRequest(
      'query',
      getPresignedUrl,
      { key, folder, makePublic },
      getAuthToken(),
    );
    if (response && response.errors) {
      if (response.errors[0].message === 'Unauthorized') {
        store.dispatch('users/resetMe');
        router.push('/auth');
      }
    }
    return response.data.getPresignedUrl;
  }

  function beforeUpload(file: File) {
    uploading.value = true;
    const isValidType = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/heic');
    const isValidSize = file.size / 1024 / 1024 < 20; // 20MB
    filename.value = file.name;
    filetype.value = file.type;
    if (!isValidType) {
      uploadStatus.status = 'failed';
      uploadStatus.message = `${entity} must be either JPG or PNG format.`;
    } else if (!isValidSize) {
      uploadStatus.status = 'failed';
      uploadStatus.message = `'${entity} size can not exceed 20MB.`;
    }
    return isValidType && isValidSize;
  }

  function generateShortUniqueFileName(originalName) {
    const extension = originalName.split('.').pop();
    const shortId = Math.random().toString(36).substring(2, 8);
    const timestamp = Date.now();
    return `${shortId}-${timestamp}.${extension}`;
  }

  async function handleUploadRequest(payload: any) {
    uploadStatus.status = 'loading';
    const headers = {
      'Content-Type': filetype.value,
      'x-amz-acl': 'public-read',
    };
    const uniqueFileName = generateShortUniqueFileName(payload.file.name);

    const postUrl = await preSignedUrl(uniqueFileName, entityKey, true);
    try {
      await axios.put(postUrl, payload.file, { headers });
      uploadStatus.status = 'ok';
      [uploadStatus.resourceUrl] = postUrl.split('?');
    } catch (err) {
      uploadStatus.status = 'failed';
      uploadStatus.message = err.getMessage();
    } finally {
      setTimeout(() => {
        uploading.value = false;
      }, 1000);
    }
  }

  return {
    filename,
    filetype,
    uploading,
    uploadStatus,
    beforeUpload,
    handleUploadRequest,
  };
}
