<template lang="pug">
.card
  //- we don't have `id` but just defined here item-key='id' to avoide error
  .card-header.align-items-center.bg-transparent.border-0.py-3.fs-14.fw-bold
    div {{sectionTitle}}
    .row
      .col-6
        el-input(v-model="filter" placeholder="Search" suffix-icon="el-icon-search")
            template(#suffix='')
              img(
                src="@/assets/icons/searchIcon.svg",
                style="font-size: 20px;")  
      .col-2            
        slot(name='action')


  draggable(
    v-model='filteredItems'
    @choose='dragChoose'
    @unchoose='dragUnchoose'
    @start='dragStart'
    @end='dragEnd'
    @change='changeEvent'
    item-key='order'
    handle='.icon-bars'
  ).list-group.list-group-flush
    template(#item='{ element, index }')
      .list-group-item.mx-3
        .row.row-sm.align-items-center.flex-nowrap
          .col-auto.ps-0.icon-bars
            svg.size-14.cursor-move
              use(href='#fas-grip-vertical')

          .col-auto.w-40.pt-1 {{ element.order + 1 }}.

          .col-auto
            img(:src="element.image").size-50px.obj-f-c.rounded

          .col {{element.title}}

          .col-auto.pe-0
            el-button(link circle @click='handleDelete(element)' size='small')
              img(
                src="@/assets/icons/deleteGreen.svg",
                style="font-size: 30px;")
  slot(name='dialog')
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import draggable from 'vuedraggable';
import DialogAddSpotItem from './DialogAddToFeaturedItems.vue';

export default defineComponent({
  name: 'HomePage_Spots',
  components: {
    draggable,
    DialogAddSpotItem,
  },
  props: {
    sectionTitle: { type: String, default: '' },
    items: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },

  setup(props) {
    const filter = ref('');
    const drag = ref(null);

    const filteredItems = computed(() => {
      // const featuredItems = Array.from(props.items);
      // console.log('featuredItems', featuredItems);
      // return featuredItems;
      const featuredItems = props.items;
      const mfeaturedItems = [];
      if (featuredItems) {
        featuredItems.forEach((x) => {
          const mfeaturedItem = {
            id: x.id,
            title: x.title,
            image: x.image,
            order: x.order,
            featured: 0,
          };
          mfeaturedItems.push(mfeaturedItem);
        });
      }

      // return mfeaturedItems;
      if (filter.value === '') return featuredItems;
      const regex = new RegExp(`${filter.value}`, 'gi');
      return featuredItems.filter((x) => (x.title ? x.title.match(regex) : false));
    });



    const dragChoose = () => {
      drag.value = true;
    };

    const dragUnchoose = () => {
      drag.value = false;
    };

    const dragStart = () => {
      drag.value = true;
    };

    const dragEnd = () => {
      drag.value = false;
    };

    const handleDelete = (element) => {
      // eslint-disable-next-line vue/no-mutating-props
      props.items.splice(props.items.indexOf(element), 1);
    };

    const changeEvent = (element) => {
      const propItems = props.items;
      const movedItem = element.moved;
      if (element.moved.newIndex > element.moved.oldIndex) {
        propItems.forEach((x) => {
          if (x.order > movedItem.oldIndex && x.order <= movedItem.newIndex) {
            // eslint-disable-next-line no-plusplus, no-param-reassign
            x.order--;
          }
        });
        propItems.find((x) => x.id === movedItem.element.id).order = movedItem.newIndex;
      } else {
        propItems.forEach((x) => {
          if (x.order < movedItem.oldIndex && x.order >= movedItem.newIndex) {
            // eslint-disable-next-line no-plusplus, no-param-reassign
            x.order++;
          }
        });
        propItems.find((x) => x.id === movedItem.element.id).order = movedItem.newIndex;
      }

      // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
      props.items.value = propItems.sort((a, b) => (a.order - b.order));
    };

    return {
      filter,
      filteredItems,
      dragChoose,
      dragUnchoose,
      dragStart,
      dragEnd,
      changeEvent,
      handleDelete,
    };
  },
});

</script>
