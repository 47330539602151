<template lang="pug">
web-app-layout(:title="'Purchase History'")
  .px-30.py-4
    div.input-wrapper(style="height: 48px;")
        el-input(
        v-model="state.searchTerm"
        placeholder="Search Purchases"
        style="height: 100%; width:calc(100% - 50px); --el-input-bg-color: #edf3f7;"
        @keyup="searchPurchases()"
            ).input-search-filter
          template(#prefix ='')
            img(
              src="@/assets/icons/searchIcon.svg",
              style="font-size: 20px;")
        img(v-if="state.listLayout === 'grid'"
          src="@/assets/icons/iconList.svg",
          style="margin-left:15px;cursor: pointer; height: 20px;"
          @click="changeLayout"
        )
        img(v-if="state.listLayout === 'list'"
          src="@/assets/icons/iconGrid.svg",
          style="margin-left:15px;cursor: pointer; height: 20px;"
          @click="changeLayout"
        )
  stickers-grid-template(v-if="state.listLayout === 'grid'" :stickers="state.filteredStickers")
  stickers-list-template(v-if="state.listLayout === 'list'" :stickers="state.filteredStickers")
</template>

<script lang="ts">
import {
  defineComponent, onMounted, reactive,
} from 'vue';
import { useStore } from 'vuex';
import WebAppLayout from '../dashboard/WebAppLayout.vue';
import StickersListTemplate from './StickersListTemplate.vue';
import StickersGridTemplate from './StickersGridTemplate.vue';
import gqlRequest from '../dashboard/gql-request';

export default defineComponent({
  name: 'WebAppSticker',
  components: { WebAppLayout, StickersListTemplate, StickersGridTemplate },
  setup() {
    const {
      route, router, loading, getPurchasedStickers,
    } = gqlRequest();
    const state = reactive({ 
      stickers: <any[]>([]),
      filteredStickers: <any[]>([]),
      searchTerm:'',
      listLayout:'',
     });
    const store = useStore();

    async function getStickers() {
      const stickers = await getPurchasedStickers('User', store.getters['users/me'].id);
      Object.assign(state.stickers, stickers);
      Object.assign(state.filteredStickers, stickers);
    }

    function searchPurchases() {
      const searchTerm = state.searchTerm.toLowerCase();
      const searchedList = state.stickers.filter(sticker =>
        sticker.title?.toLowerCase().includes(searchTerm)
      );
      state.filteredStickers = <any[]>([]);
      Object.assign(state.filteredStickers, searchedList);
    }  
    
    function changeLayout(){
      state.listLayout = state.listLayout === "grid" ? "list" : "grid";
      localStorage.setItem('stickersLayout', state.listLayout);
    }

    onMounted(async () => {
      state.listLayout = localStorage.getItem('stickersLayout') ?? 'grid';
      loading.value = true;
      await getStickers();
      loading.value = false;
    });

    return {
      route,
      router,
      loading,
      // data
      state,
      searchPurchases,
      changeLayout,
    };
  },
});
</script>
