import { gql } from '@apollo/client/core';

export const findTodos = gql`
  query {
   findTodos {
      id
      todo
      protip
   }
}`;

export const mutateTodo = gql`
  mutation($input: TodoInput!){
  mutateTodo(input:$input){
    id
    todo
    protip
  }
}`;

export const findTodo = gql`query($id: String!) {
    findTodo(id: $id){
      id
      todo
      protip
      createdAt
      updatedAt
  }
}`;
