<template lang="pug">
div
  app-main-menu
    //- deafult nav items render here

  app-main-content
    .card-header.p-4.bg-transparent
      .row.row-sm.align-items-center
        .col
          h4.mb-0 Settings

    .p-4
      h5 Change Password
      .card.max-w-350
        .card-body
          el-upload(
            class="avatar-uploader"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="handleUploadRequest"
          ) Upload

        //- form renders here
</template>

<script>
import { ref, reactive, defineComponent } from 'vue';
import axios from 'axios';
import shared from '../shared';

export default defineComponent({
  name: 'FileUpload',
  setup() {
    const { preSignedUrl } = shared();

    const imageUrl = ref('');
    const message = reactive({ error: '' });
    const filename = ref('');
    const filetype = ref('');
    function handleAvatarSuccess() {
      // console.log('handleAvatarSuccess');
    }

    function beforeAvatarUpload(file) {
      const isValidType = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/heic');
      const isValidSize = file.size / 1024 / 1024 < 20; // 20MB
      filename.value = file.name;
      filetype.value = file.type;
      if (!isValidType) {
        message.error = 'Image must be either JPG or PNG format.';
      } else if (!isValidSize) {
        message.error = 'Image must be either JPG or PNG format.';
      }
      return isValidType && isValidSize;
    }

    async function handleUploadRequest(payload) {
      const headers = {
        'Content-Type': filetype.value,
        'x-amz-acl': 'public-read',
      };
      const key = `avatar/${filename.value}`;
      const postUrl = await preSignedUrl(key, 'avatars', true);
      axios.put(postUrl, payload.file, { headers }).catch((err) => {
        console.log(err);
      }).finally(() => {
        // console.log('complted');
      });
    }

    return {
      imageUrl,
      handleAvatarSuccess,
      beforeAvatarUpload,
      handleUploadRequest,
    };
  },
});
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
