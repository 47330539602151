<template lang="pug">
div
  .text-center.mb-5
    h3 We Sent You A
      span.text-success  Code
    div Enter it below to verify your phone number.

  .row.input-text-center
    .col(v-for="i in 6" :key="i")
      .el-input
        input(
          :ref="refInputs[`input${i}`]"
          v-model="item[`input${i}`]"
          maxlength="1"
          @keyup="setItemFocus($event, i+1)"
        ).el-input__inner

    .alert.alert-danger.text-center(v-if="invalidCode")
      | Invalide Code

    .p-4.text-center
      p.mb-5 Didn't receive the code?
        el-button(link @click="resendCode").ms-2 Resend
      el-button(
        round
        type="success"
        @click="validateCode"
        v-loading="loading"
        :disabled="btnNextDsiabled"
      ) Next
</template>

<script>
import {
  ref,
  reactive,
  computed,
  defineComponent,
  onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import { email, name, phone } from '@/data/rules';
import useOnboardingRoute from './composables/useOnboardingRoute';

export default defineComponent({
  name: 'Onboarding_VerifyCode',
  setup() {
    const loading = ref(false);
    const {
      validatePhoneToken,
      getPhoneCode,
      canOnBoard,
    } = useOnboardingRoute('verifyCode');
    const invalidCode = ref(false);
    const router = useRouter();

    const refInputs = {
      input1: ref(null),
      input2: ref(null),
      input3: ref(null),
      input4: ref(null),
      input5: ref(null),
      input6: ref(null),
    };

    const item = reactive({
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      input6: '',
    });

    const btnNextDsiabled = computed(() => {
      const condation = item.input1
        && item.input2
        && item.input3
        && item.input4
        && item.input5
        && item.input6;
      return !condation; // true/false
    });

    function setItemFocus(e, refNextInput) {
      if (refNextInput === 7) return;
      if (e.target.value) {
        const nextInput = refInputs[`input${refNextInput}`].value;
        nextInput.focus();
        nextInput.select();
      }
    }
    async function validateCode() {
      loading.value = true;
      invalidCode.value = false;
      const head = `${item.input1}${item.input2}${item.input3}`;
      const code = `${head}${item.input4}${item.input5}${item.input6}`;
      const result = await validatePhoneToken(code);
      invalidCode.value = !result;
      loading.value = false;
    }

    onMounted(async () => {
      try {
        if (!canOnBoard()) {
          router.push('/');
        }
      } catch (err) {
        console.log(err);
      }
    });

    async function resendCode() {
      loading.value = true;
      try {
        await getPhoneCode();
      } catch (err) {
        console.log(err);
      }
      loading.value = false;
    }

    return {
      // data (static)
      rules: { email, phone, name },

      // data (dynamic)
      refInputs,
      loading,
      item,
      btnNextDsiabled,
      invalidCode,
      // methods
      validateCode,
      setItemFocus,
      resendCode,
    };
  },
});
</script>
