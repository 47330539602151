import { gql } from '@apollo/client/core';

const fragEvent = gql`fragment event on Event {
   id
   title
   date
   img
   description
   source
   eventType
   eventAttributes {
     id
     entity
     entityId
   }
   createdAt
   user {
     firstname
     lastname
   }
}`;

export const findEvents = gql`
${fragEvent}
query {
   findEvents {
      ...event
   }
}`;

export const findEvent = gql`
${fragEvent}
query($id: String!) {
   findEvent(id: $id) {
      ...event
   }
}`;

export const mutateEvent = gql`
${fragEvent}
mutation($input: EventInput!) {
   mutateEvent(input: $input) {
      ...event
   }
}`;

export const mutateRemoveEvent = gql`
mutation($id: String!) {
  mutateRemoveEvent(id: $id)
}`;
