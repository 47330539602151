<template lang="pug">
app-main-content-layout(title='Bullhorns')
  template(#actions)
      el-button(@click="exportBullhorns").has-fai
        fai(type="fal" icon="plus-circle").size-18.me-2
        span.min-w-110.d-inline-block Export
      el-button(type="primary" color='#1EBEAF' @click="dialog = true").has-fai
        fai(type="fal" icon="plus-circle").size-18.me-2
        span.min-w-110.d-inline-block Add New
  div
    .row.row-sm.align-items-center.mb-4.mx-5.justify-content-between
      .col-auto
        button(@click="exportBullhorns").exportDirectoryBullhorns 
            img(
                
                src="@/assets/icons/exportIcon.svg",
                style="font-size: 5px; padding-right:20px")
            span Export 
      .col-auto 
       .row         
        .col
          el-input(
            v-model="filter"
            placeholder="Search Bullhorns"
            suffix-icon="el-icon-search"
            style="width: 250px; min-height: 40px"
          )
            template(#suffix='')
                img(
                  src="@/assets/icons/searchIcon.svg",
                  style="font-size: 20px;")
        .col-auto()
          VueMultiselect(
              v-model="sortBy",
              :multiple="false",
              :searchable="false",
              :options="sortOptions",
              :close-on-select="true",
              @close="sortTable"
              :show-labels="false"
              label="label"
              placeholder="Sort By"
              style="width: 180px;"
            )

    el-table(
      :data="pagedTableData"
      stripe
      v-loading="loading"
      @row-click="$router.push(`bullhorns/${$event.id}`);"
      style="width: 100%"
    ).clickable-rows
      el-table-column(width="15")
        // space

      el-table-column(width="100")
        template(#default="scope")
          div(@click.stop="handleToggleAddToFav(scope.row)").cp
            img(v-if="scope.row.favorite" src="@/assets/icons/star-green.svg").size-32
            img(v-else src="@/assets/icons/star.svg").size-32

      el-table-column(prop="bullhorn" label="Bullhorn" width="400" sortable)
      el-table-column(prop="category.category" label="Category" min-width="200" sortable)
        template(#default="scope") {{ scope.row.category ? scope.row.category.category : '' }}
      el-table-column(prop="description" label="Description" min-width="200")

      el-table-column(width="15")
        // space
    div.margin-top-20.d-flex.justify-content-center   
      el-pagination(layout="prev, pager, next"  :current-page.sync="page" :total="filtered.length" @current-change="setPage")  

    dialog-add-bullhorn(
      v-if="dialog"
      :loading="loading"
      v-model="dialog"
      :categories="categories.categories"
      @save-item="saveItem"
      @save-category="saveCategoryItem"
      @close-dialog="dialog = false"
      )
</template>

<script>

// eslint-disable-next-line object-curly-newline
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import shared from './shared';
import DialogAddBullhorn from './DialogAddBullhorn.vue';
import VueMultiselect from 'vue-multiselect';
import globalShared from '@/shared';

export default defineComponent({
  name: 'ManageContent_Directory_Bullhorns',
  components: { DialogAddBullhorn, VueMultiselect },
  setup() {
    const {
      loading,
      getTypedCategories,
      getBullhorns,
      saveBullhorn,
      saveCategory,
      exportData,
    } = shared();

    const { showSuccessMessage } = globalShared();

    const sortBy = ref('');
    const sortOptions = [{ label: 'Bullhorn', value: 'bullhorn' }, { label: 'Category', value: 'category' }];

    const filter = ref('');
    const items = reactive({ items: [] });
    const categories = reactive({ categories: [] });
    const dialog = ref(false);
    const page = ref(1);
    const pageSize = 10;
    const currentView = ref('bullhorns');


    async function getItems() {
      try {
        loading.value = true;
        items.items = await getBullhorns(false);
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    function getClass(currentItem) {
      if(currentItem == currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }

    async function saveItem(input) {
        await saveBullhorn(input);
        showSuccessMessage('Bullhorn added successfully');
      getItems();
    }

    function sortTable() {
      const sortByValue = sortBy.value.value;

      items.items.sort((a, b) => {
        if (sortByValue === 'bullhorn') {
          return a.bullhorn.localeCompare(b.bullhorn);
        } else if (sortByValue === 'category') {
          return a.category.category.localeCompare(b.category.category);
        }
      });
    }

    const filtered = computed(() => {
      if (filter.value === '') {
        return items.items;
      }
      const regex = new RegExp(`${filter.value}`, 'gi');
      return items.items.filter((x) => {
        if (x.bullhorn) {
          return x.bullhorn.match(regex);
        }
        return false;
      });
    });

    async function saveCategoryItem(input) {
      await saveCategory(input);
      categories.categories = await getTypedCategories();
    }

    function exportBullhorns() {
      exportData(items.items);
    }

    //________create function for pagination datatable________
    const pagedTableData = computed(() => {
      const start = (page.value - 1) * pageSize;
      const end = start + pageSize;
      if (filter.value !== '') {
        return filtered.value.slice(start, end);
      }

      return items.items.slice(start, end);
    })

    const setPage = (newPage) => {
        page.value = newPage;
      }
    
    onMounted(async () => {
      categories.categories = await getTypedCategories();
      await getItems();
    });

    async function handleToggleAddToFav(row) {
      const itemToFav = filtered.value.find((obj) => obj.id === row.id);
      itemToFav.favorite = !itemToFav.favorite;
      await saveBullhorn({
        id: parseInt(itemToFav.id, 10),
        favorite: itemToFav.favorite,
      });
    }

    return {
      categories,
      items,
      filter,
      filtered,
      loading,
      dialog,
      sortBy,
      sortOptions,
      page,
      pageSize,
      pagedTableData,
      currentView,
      // methods
      saveItem,
      saveCategoryItem,
      exportBullhorns,
      handleToggleAddToFav,
      setPage,
      getClass,
      sortTable,
    };
  },
});
</script>
