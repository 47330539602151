<template lang="pug">
.text-center
  h4.mb-4 What are your organizational
    span.text-success   values?

  .mb-4
    | We display your values on your profile so that others can know what guides
    | you when you take action towards building the future you want.
    span(:class="{ 'text-danger fw-b': validNoOfItems }")  Pick up to 10:

  hr

  el-checkbox-group(v-model="items").bnt-round
    el-checkbox-button(
      v-for="item in values.items"
      :label="item.id"
      :key="item.id"
    ) {{ item.value }}

  .text-center.py-4(v-if="isOnlyFavourite")
    el-button(round type="success" @click="showAllValues(false)") Show All Values
  .text-center.py-4(v-if="!isOnlyFavourite")
    el-button(round type="success" @click="showAllValues(true)") Show Recommended
  hr

  .text-center.py-4
    el-button(round type="success" @click="submitForm" :disabled="inValidNoOfItems") Next
</template>

<script lang="ts">

import {
  defineComponent,
  ref,
  onMounted,
  computed,
  reactive,
} from 'vue';
import { useRouter } from 'vue-router';
// import { orgValues as lookups } from '@/data/onboarding-static';
import useOnboardingRoute from './composables/useOnboardingRoute';

export default defineComponent({
  name: 'Onboarding_OrgValues',
  setup() {
    const {
      initData,
      commitAndGotoNextRoute,
      getValues,
      canOnBoard,
    } = useOnboardingRoute('orgValues');
    const items = ref(initData);
    const values = reactive({ items: [] });
    const router = useRouter();
    const isOnlyFavourite = ref(true);

    onMounted(async () => {
      try {
        if (!canOnBoard()) {
          router.push('/');
        }
        const result = await getValues(true);
        values.items = result;
      } catch (err) {
        console.log(err);
      }
    });

    async function showAllValues(getfavourite: boolean) {
      const result = await getValues(getfavourite);
      values.items = result;
      isOnlyFavourite.value = getfavourite;
    }

    return {
      // data (static)
      values,

      // data (dynamic)
      items,

      // computed
      inValidNoOfItems: computed(() => items.value.length > 10),

      // methods
      submitForm: () => commitAndGotoNextRoute(items),
      showAllValues,
      isOnlyFavourite,
    };
  },
});
</script>
