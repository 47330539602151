import {differenceInCalendarDays, format, isToday, isYesterday} from "date-fns";

const debounce = <T extends (...args: any[]) => void>(fn: T, delay: number): T => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  return function (this: unknown, ...args: Parameters<T>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  } as T;
};

const formatDateSevenDaysBack = (dateString: string): string => {
  const date = new Date(dateString);
  const daysDifference = differenceInCalendarDays(new Date(), date);

  if (daysDifference === 0) {
    return `Today at ${format(date, 'hh:mm a').toLowerCase()}`;
  } else if (daysDifference === 1) {
    return `Yesterday at ${format(date, 'hh:mm a').toLowerCase()}`;
  } else if (daysDifference <= 7) {
    return `${format(date, 'EEEE')} at ${format(date, 'hh:mm a').toLowerCase()}`;
  } else {
    return format(date, 'MM/dd/yy');
  }
};

const joinDateAndTime = (dateStart, timeStart) => {
  const date = new Date(dateStart);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  let hours = parseInt(timeStart.hh);
  const minutes = timeStart.mm;
  const period = timeStart.a;

  if (period === 'pm' && hours < 12) {
    hours += 12;
  } else if (period === 'am' && hours === 12) {
    hours = 0;
  }

  hours = String(hours).padStart(2, '0');

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
  return formattedDateTime;
}

const unJoinDateAndTime = (isoString) => {
  const date = new Date(isoString);

  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = String(date.getUTCFullYear()).slice(-2); // Get last 2 digits of the year

  const theDate = `${month}/${day}/${year}`;

  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  const period = hours >= 12 ? 'pm' : 'am';
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  const time = { hh: String(hours).padStart(2, '0'), mm: minutes, a: period };
  return { theDate, time };
}


function validateTime(time) {
  console.log(time);
  return time && typeof time === 'object' &&
    'hh' in time && 'mm' in time && 'a' in time &&
    time.hh.length > 0 && time.mm.length > 0 && time.a.length > 0;
}


export { debounce, formatDateSevenDaysBack, joinDateAndTime, validateTime, unJoinDateAndTime };
