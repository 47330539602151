<template lang="pug">
el-dialog(
  :title="paymentLog.paymentInfo",
  top="5vh" width="100%"
  :close-on-click-modal="false"
  class="el-dialog__max-w-700"
)
  .row
    div.filter-container
      span.padding-left-20(@click="currentView = 'Entity'"
      :class="getClass('Entity')") Entity
      span.padding-left-20(@click="currentView = 'Details'"
      :class="getClass('Details')") Sticker details
      span.padding-left-20(@click="currentView = 'Sticker_transaction'"
      :class="getClass('Sticker_transaction')") Sticker transaction
      span.padding-left-20(@click="currentView = 'Stripe_transaction'"
      :class="getClass('Stripe_transaction')") Stripe transaction
  div.row.row-xs.align-items-center(v-if="currentView == 'Entity'" )
    .lh-sm(v-if="paymentLog.user")
      .row.row-xs.align-items-center
        .col-auto
          img(:src="paymentLog.user.avatar"
            v-if="paymentLog.user.avatar").d-block.size-24.obj-f-c.rounded-circle
          img(src="@/assets/images/default-profile.jpeg"
            v-if="!paymentLog.user.avatar").d-block.size-24.obj-f-c.rounded-circle
        .col.lh-1.py-2 {{ paymentLog.user.firstname }} {{ paymentLog.user.lastname }}
      .fw-m.py-1 {{ paymentLog.user.email }}
      .fw-m.py-1 {{ paymentLog.user.phone }}
      .fw-m.py-1 Entity: User
      .fw-m.py-1 EntityId: {{ paymentLog.user.id }}
    .lh-sm(v-else)
      .row.row-xs.align-items-center
        .col-auto
          img(:src="paymentLog.organization.avatar"
            v-if="paymentLog.organization.avatar").d-block.size-24.obj-f-c.rounded-circle
          img(src="@/assets/images/default-profile.jpeg"
            v-if="!paymentLog.organization.avatar").d-block.size-24.obj-f-c.rounded-circle
        .col.lh-1.py-2 {{ paymentLog.organization.name }}
      .fw-m.py-1 {{ paymentLog.organization.email }}
      .fw-m.py-1 {{ paymentLog.organization.phone }}
      .fw-m.py-1 Entity: Organization
      .fw-m.py-1 EntityId: {{ paymentLog.organization.id }}
  div.row.row-xs.align-items-center(v-if="paymentLog.sticker && currentView == 'Details'" )
    div.px-2
        .img-uploader.pos-r.list-image-container
          template(v-if="paymentLog.sticker.url")
            img(:src="paymentLog.sticker.url").img-fluid.list-image-item
            .pin.pin-bx.d-flex.p-2
              h6.mb-0.sticker-tag-text.ms-auto(v-if="paymentLog.sticker.campaignType != null")
                | {{ paymentLog.sticker.campaignType }}
    div
      .lh-sm(v-if="paymentLog.sticker.user")
        .row.row-xs.align-items-center
          .col-auto
            span Creator:
          .col-auto
            img.d-block.size-20.obj-f-c.rounded-circle(
              :src="paymentLog.sticker.user.avatar"
              v-if="paymentLog.sticker.user.avatar")
            img.d-block.size-20.obj-f-c.rounded-circle(
              src="@/assets/images/default-profile.jpeg"
              v-if="!paymentLog.sticker.user.avatar")
          .col.lh-3
            span {{ paymentLog.sticker.user.firstname }}
            span {{ paymentLog.sticker.user.lastname }}
        .fw-m {{ paymentLog.sticker.user.email }}
        .fw-m {{ paymentLog.sticker.user.phone }}
        .fw-m Entity: User
        .fw-m EntityId: {{ paymentLog.sticker.user.id }}
      .lh-sm(v-else-if="paymentLog.sticker.organization")
        .row.row-xs.align-items-center.py-3
          .col-auto
            span Creator:
          .col-auto
            img.d-block.size-20.obj-f-c.rounded-circle(
              :src="paymentLog.sticker.organization.avatar"
              v-if="paymentLog.sticker.organization.avatar")
            img.d-block.size-20.obj-f-c.rounded-circle(
              src="@/assets/images/default-profile.jpeg"
              v-if="!paymentLog.sticker.organization.avatar")
          .col.lh-1
            span {{ paymentLog.sticker.organization.name }}
        .fw-m {{ paymentLog.sticker.organization.email }}
        .fw-m {{ paymentLog.sticker.organization.phone }}
        .fw-m Entity: Organization
        .fw-m EntityId: {{ paymentLog.sticker.organization.id }}
        .fw-m Stripe Account Id: {{ paymentLog.sticker.organization.stripeAccountId }}
    hr.my-2
    div.row.row-xs.align-items-center
      div.col.px-2
        div
          .fw-m Title: {{ paymentLog.sticker.title }}
          .fw-m(v-if="paymentLog.sticker.campaignType != null")
            | campaignType: {{ paymentLog.sticker.campaignType }}
          .fw-m StickerId: {{ paymentLog.sticker.id }}
          .fw-m Description: {{ paymentLog.sticker.description }}
          .fs-14.lh-2.el-button.el-button--text
            | Cost:
            span
              span &nbsp; {{ renderStickerDetailsCost(paymentLog) }}
  div.row.row-xs.align-items-center(v-if="paymentLog.stickerTransaction && currentView == 'Sticker_transaction'")
    .col-auto
      .fw-m.py-1 Sticker Transaction Id: {{ paymentLog.stickerTransaction.id }}
      .fw-m.py-1 isProcessed: {{ paymentLog.stickerTransaction.isProcessed }}
      .fw-m.py-1 Amount: {{ renderStickerTransactionAmount(paymentLog) }}
      .fw-m.py-1 Amount Donated: {{ renderStickerTransactionDonated(paymentLog) }}
      .fw-m.py-1 Amount Rebate: {{ paymentLog.stickerTransaction.amount_rebate }}
      .fw-m.py-1 Cause Id: {{ paymentLog.stickerTransaction.causeId }}
      .fw-m.py-1 variant Id: {{ paymentLog.stickerTransaction.variantId }}
      .fw-m.py-1 Purchase Type: {{ paymentLog.stickerTransaction.purchaseType }}
      .fw-m.py-1 Benevity Transaction Id: {{ paymentLog.stickerTransaction.benevity_transaction_Id }}
      .fw-m.py-1 Stripe Transaction Id: {{ paymentLog.stickerTransaction.stripe_transaction_Id }}
      .fw-m.py-1 orgStripeAccountId: {{ paymentLog.stickerTransaction.orgStripeAccountId }}
      .fw-m.py-1 Org Payment Stripe Transaction Id:
        | {{ paymentLog.stickerTransaction.Org_Payment_stripe_transaction_Id }}
      .fw-m.py-1 Metadata: {{ paymentLog.stickerTransaction.transactionMeta }}
  div.row.row-xs.align-items-center(v-if="paymentLog.stripeTransaction && currentView == 'Stripe_transaction'")
    .col-auto
      .fw-m.py-1 Stripe Transaction Id: {{ paymentLog.stripeTransaction.id }}
      .fw-m.py-1 isProcessed: {{ paymentLog.stripeTransaction.processed }}
      .fw-m.py-1 transactionType: {{ paymentLog.stripeTransaction.transactionType }}
      .fw-m.py-1 intentId: {{ paymentLog.stripeTransaction.intentId }}
      .fw-m.py-1 Total Amount: {{ paymentLog.stripeTransaction.amount }}
      .fw-m.py-1 Wallet Deposit: {{ paymentLog.stripeTransaction.walletDeposit }}
      .fw-m.py-1 Convenience Fee: {{ paymentLog.stripeTransaction.fee_Convenience }}
      .fw-m.py-1 Credit Card Fee: {{ paymentLog.stripeTransaction.fee_CreditCard }}
      .fw-m.py-1 Metadata: {{ paymentLog.stripeTransaction.meta }}
  div.row.row-xs.align-items-center(v-if="paymentLog.benevityTransaction && currentView == 'Entity'")
    .col-auto
      h6.mb-0.py-2 Benevity Transaction
      .fw-b Benevity Transaction Id: {{ paymentLog.benevityTransaction.id }}
      .fw-b Amount: {{ paymentLog.benevityTransaction.amount }}
      .fw-b transactionType: {{ paymentLog.benevityTransaction.transactionType }}
      .fw-b cause Id: {{ paymentLog.benevityTransaction.causeId }}
      .fw-b cause Name: {{ paymentLog.benevityTransaction.causeName }}
      .fw-b Metadata: {{ paymentLog.benevityTransaction.meta }}
  div.row.row-xs.align-items-center(v-if="paymentLog.orgPaymentStripeTransaction && currentView == 'Entity'")
    .col-auto
      h6.mb-0.py-2 Creator Payout Stripe Transaction
      .fw-b Stripe Transaction Id: {{ paymentLog.orgPaymentStripeTransaction.id }}
      .fw-b isProcessed: {{ paymentLog.orgPaymentStripeTransaction.processed }}
      .fw-b transactionType: {{ paymentLog.orgPaymentStripeTransaction.transactionType }}
      .fw-b intentId: {{ paymentLog.orgPaymentStripeTransaction.intentId }}
      .fw-b Total Amount: {{ paymentLog.orgPaymentStripeTransaction.amount }}
      .fw-b Wallet Deposit: {{ paymentLog.orgPaymentStripeTransaction.walletDeposit }}
      .fw-b Convenience Fee: {{ paymentLog.orgPaymentStripeTransaction.fee_Convenience }}
      .fw-b Credit Card Fee: {{ paymentLog.orgPaymentStripeTransaction.fee_CreditCard }}
      .fw-b Metadata: {{ paymentLog.orgPaymentStripeTransaction.meta }}
  div.row.row-xs.align-items-center(v-if="paymentLog.benevityW2WCreditTransaction && currentView == 'Entity'")
    .col-auto
        h6.mb-0.py-2 Benevity W2W Credit Transaction
        .fw-b Benevity W2W Credit Transaction Id: {{ paymentLog.benevityW2WCreditTransaction.id }}
        .fw-b Amount: {{ paymentLog.benevityW2WCreditTransaction.credits }}
        .fw-b Notes: {{ paymentLog.benevityW2WCreditTransaction.notes }}
        .fw-b Message: {{ paymentLog.benevityW2WCreditTransaction.message }}
        .fw-b Meta: {{ paymentLog.benevityW2WCreditTransaction.meta }}
        div.row.row-xs.align-items-center
          div.col-6.px-2
            div.paymentlog-block
              h6.mb-0.py-2 From
              .lh-sm(v-if="paymentLog.benevityW2WCreditTransaction.fromUser")
                .row.row-xs.align-items-center
                  .col-auto
                    img.d-block.size-24.obj-f-c.rounded-circle(
                      :src="paymentLog.benevityW2WCreditTransaction.fromUser.avatar"
                      v-if="paymentLog.benevityW2WCreditTransaction.fromUser.avatar")
                    img.d-block.size-24.obj-f-c.rounded-circle(
                      src="@/assets/images/default-profile.jpeg"
                      v-if="!paymentLog.benevityW2WCreditTransaction.fromUser.avatar")
                  .col.lh-1
                    span {{ paymentLog.benevityW2WCreditTransaction.fromUser.firstname }}
                    span {{ paymentLog.benevityW2WCreditTransaction.fromUser.lastname }}
                .fw-b {{ paymentLog.benevityW2WCreditTransaction.fromUser.email }}
                .fw-b {{ paymentLog.benevityW2WCreditTransaction.fromUser.phone }}
                .fw-b Entity: User
                .fw-b EntityId: {{ paymentLog.benevityW2WCreditTransaction.fromUser.id }}
              .lh-sm(v-else)
                .row.row-xs.align-items-center
                  .col-auto
                    img.d-block.size-24.obj-f-c.rounded-circle(
                      :src="paymentLog.benevityW2WCreditTransaction.fromOrganization.avatar"
                      v-if="paymentLog.benevityW2WCreditTransaction.fromOrganization.avatar")
                    img.d-block.size-24.obj-f-c.rounded-circle(
                      src="@/assets/images/default-profile.jpeg"
                      v-if="!paymentLog.benevityW2WCreditTransaction.fromOrganization.avatar")
                  .col.lh-1 {{ paymentLog.benevityW2WCreditTransaction.fromOrganization.name }}
                .fw-b {{ paymentLog.benevityW2WCreditTransaction.fromOrganization.email }}
                .fw-b {{ paymentLog.benevityW2WCreditTransaction.fromOrganization.phone }}
                .fw-b Entity: Organization
                .fw-b EntityId: {{ paymentLog.benevityW2WCreditTransaction.fromOrganization.id }}
          div.col-6.px-2
            div.paymentlog-block
              h6.mb-0.py-2 To
              .lh-sm(v-if="paymentLog.benevityW2WCreditTransaction.toUser")
                .row.row-xs.align-items-center
                  .col-auto
                    img.d-block.size-24.obj-f-c.rounded-circle(
                      :src="paymentLog.benevityW2WCreditTransaction.toUser.avatar"
                      v-if="paymentLog.benevityW2WCreditTransaction.toUser.avatar")
                    img.d-block.size-24.obj-f-c.rounded-circle(
                      src="@/assets/images/default-profile.jpeg"
                      v-if="!paymentLog.benevityW2WCreditTransaction.toUser.avatar")
                  .col.lh-1
                    span {{ paymentLog.benevityW2WCreditTransaction.toUser.firstname }}
                    span {{ paymentLog.benevityW2WCreditTransaction.toUser.lastname }}
                .fw-b {{ paymentLog.benevityW2WCreditTransaction.toUser.email }}
                .fw-b {{ paymentLog.benevityW2WCreditTransaction.toUser.phone }}
                .fw-b Entity: User
                .fw-b EntityId: {{ paymentLog.benevityW2WCreditTransaction.toUser.id }}
              .lh-sm(v-else)
                .row.row-xs.align-items-center
                  .col-auto
                    img.d-block.size-24.obj-f-c.rounded-circle(
                      :src="paymentLog.benevityW2WCreditTransaction.toOrganization.avatar"
                      v-if="paymentLog.benevityW2WCreditTransaction.toOrganization.avatar")
                    img.d-block.size-24.obj-f-c.rounded-circle(
                      src="@/assets/images/default-profile.jpeg"
                      v-if="!paymentLog.benevityW2WCreditTransaction.toOrganization.avatar")
                  .col.lh-1 {{ paymentLog.benevityW2WCreditTransaction.toOrganization.name }}
                .fw-b {{ paymentLog.benevityW2WCreditTransaction.toOrganization.email }}
                .fw-b {{ paymentLog.benevityW2WCreditTransaction.toOrganization.phone }}
                .fw-b Entity: Organization
                .fw-b EntityId: {{ paymentLog.benevityW2WCreditTransaction.toOrganization.id }}
</template>

<script lang="ts">
import {
  defineComponent, onMounted, ref, PropType
} from 'vue';

import type { PaymentLog } from './types';

export default defineComponent({
  name: 'Payment_Log_Details',
  props: {
    loading: { type: Boolean, default: false },
    paymentLog: { type: Object as PropType<PaymentLog>, default: () => ({}) },
  },
  setup(props) {
    onMounted(async () => {
      console.log('paymentLog', props.paymentLog);
    });

    const renderStickerDetailsCost = (paymentLog: PaymentLog) => {
      if (paymentLog.stripeTransaction?.amount > 0) {
        return '$' + paymentLog.stripeTransaction.amount.toFixed(2);
      }
      if (paymentLog.sticker.cost === 0) {
        return 'FREE'
      }
      return '$' + paymentLog.sticker.cost.toFixed(2);
    }

    const renderStickerTransactionAmount = (paymentLog: PaymentLog) => {
      let amount = paymentLog.stickerTransaction.cost;
      if (paymentLog.stripeTransaction?.amount > 0) {
        amount = paymentLog.stripeTransaction.amount - paymentLog.stripeTransaction.fee_Convenience - paymentLog.stripeTransaction.fee_CreditCard
      }
      return amount.toFixed(2);
    }

    const renderStickerTransactionDonated = (paymentLog: PaymentLog) => {
      let amount = paymentLog.stickerTransaction?.amount_donated;
      const isCause = paymentLog.stickerTransaction?.causeId?.length;
      if (isCause && paymentLog.stripeTransaction?.amount > 0) {
        amount = paymentLog.stripeTransaction.amount - paymentLog.stripeTransaction.fee_Convenience - paymentLog.stripeTransaction.fee_CreditCard;
      }
      return amount.toFixed(2);
    }

    const currentView = ref('Entity');

    function getClass(currentItem: string) {
      if (currentItem === currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }


    return {
      currentView,
      getClass,
      renderStickerDetailsCost,
      renderStickerTransactionAmount,
      renderStickerTransactionDonated
    };
  },
});
</script>
