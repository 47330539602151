export interface RuleItem {
  required?: boolean;
  message: string;
  trigger: string;
  type?: string;
  pattern?: RegExp;
  min?: number;
}

const name: RuleItem = {
  required: true,
  //  pattern: /^[a-zA-Z& ]{1,25}$/,
  message: 'Name is required',
  trigger: 'blur',
};

const title: RuleItem = {
  required: true,
  //  pattern: /^[a-zA-Z& ]{1,25}$/,
  message: 'Title is required',
  trigger: 'blur',
};

const bullhorn: RuleItem = {
  required: true,
  // pattern: /^[a-zA-Z& ]{1,25}$/,
  message: 'Bullhorn Name is required',
  trigger: 'blur',
};

const category: RuleItem = {
  required: true,
  message: 'Category is required',
  trigger: 'blur',
};

const interest: RuleItem = {
  required: true,
  pattern: /^[a-zA-Z& ]{1,25}$/,
  message: 'Interesx Name is required',
  trigger: 'blur',
};

const channel: RuleItem = {
  required: true,
  // pattern: /^[a-zA-Z& ]{1,25}$/,
  message: 'Channel Name is required',
  trigger: 'blur',
};

const value: RuleItem = {
  required: true,
  // pattern: /^[a-zA-Z& ]{1,25}$/,
  message: 'Value is required',
  trigger: 'blur',
};

const phone: RuleItem[] = [
  {
    required: true,
    message: 'Phone Number is required',
    trigger: 'blur',
  },

  // Min 14 length bcoz that includes brackets and space and hyphen. eg. (###) ###-####
  { min: 14, message: 'Length should be 10 digits', trigger: 'blur' },
];

const email: RuleItem[] = [
  {
    required: true,
    message: 'Email address is required',
    trigger: 'blur',
  }, {
    type: 'email',
    message: 'Please enter a valid Email address',
    trigger: 'blur',
  },
];

const password: RuleItem[] = [
  {
    required: true,
    message: 'Password',
    trigger: 'blur',
  },
];

function requiredRule(item: string): RuleItem[] {
  return [
    {
      required: true,
      message: `${item} is required`,
      trigger: 'blur',
    },
  ];
}

export {
  name,
  title,
  value,
  bullhorn,
  interest,
  channel,
  phone,
  email,
  password,
  category,
  requiredRule,
};
