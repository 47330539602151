<template lang="pug">
el-dialog(
  title="Add",
  top="5vh" width="100%"
  class="el-dialog__max-w-700"
)
  el-form(
    ref="refForm"
    :rules="rules"
    :model="item"
    status-icon
    label-position="top"
  )
    .row
      .col-4
        el-upload(
          :show-file-list="false"
          :on-success="handleSuccess"
          :on-error="handleError"
          :before-upload="beforeUpload"
          :http-request="handleMediaUpload"
          action=""
        ).img-uploader.pos-r
          template(v-if="item.image")
            img(:src="item.image").img-fluid
            .pin.pin-bx.d-flex.p-2
              el-button(type="primary").ms-auto Change
          template(v-else)
            img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
            p.upload-picture-text Drop an image file here or
            div.fake-upload-button Browse For File
      .col-8
        el-form-item(label="Title" prop="title")
          el-input(v-model="item.title")
        el-form-item(label="Channel" prop="channel.id")
          VueMultiselect(
            v-model="item.channel",
            label="channel",
            track-by="id",
            :options="channels.channels",
            :searchable="true",
            :close-on-select="true",
            :show-labels="false"
            placeholder="Pick a value"
          )

        el-form-item(label="Details" prop="details")
          el-input(v-model="item.details" type="textarea")
  template(#footer)
    el-button(type="success" @click="validateForm" v-loading="loading") Add
</template>

<script>

import {
  ref,
  reactive,
  defineComponent,
  onMounted,
  computed,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import { requiredRule } from '@/data/rules';
import fileUpload from '@/shared/upload';
import categoryShared from '../../directory/shared/index';
import channelShared from '../../directory/channels/shared';

export default defineComponent({
  name: 'DialogAddLibraryItem',
  components: { VueMultiselect },
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['close-dialog', 'save-item'],

  setup(_, { emit }) {
    const { uploadStatus, beforeUpload, handleUploadRequest } = fileUpload('media', 'Media');

    const {
      getTypedCategories,
    } = categoryShared('CHANNEL');
    const {
      getChannels,
    } = channelShared();
    const refForm = ref(null);
    const item = reactive({
      id: null,
      title: '',
      image: null,
      details: '',
      channel: {},
    });
    const categories = reactive({ categories: [] });
    const channels = reactive({ channels: [] });

    function submitForm() {
      item.channel = { id: item.channel.id };
      item.channel.id = parseInt(item.channel.id, 10);
      console.log(item.channel);
      emit('save-item', item);
      emit('close-dialog');
    }

    function validateForm() {
      console.log('in validate with ', refForm.value);
      item.channel = { id: item.channel.id };
      item.channel.id = parseInt(item.channel.id, 10);
      refForm.value.validate((valid) => {
        if (valid) return submitForm();
        console.log('error submit!!');
        return false;
      });
    }

    async function handleMediaUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.image = uploadStatus.resourceUrl;
      }
    }

    function handleSuccess() {
      console.log('succc');
    }

    function handleError() {
      console.log('error');
    }

    onMounted(async () => {
      categories.categories = await getTypedCategories();
      channels.channels = await getChannels(false);
    });

    const computedChannels = computed(() => Array.from(channels.channels));

    return {
      refForm,
      rules: {
        image: requiredRule('url'),
        title: requiredRule('title'),
        details: requiredRule('details'),
        channel: {
          id: requiredRule('channel'),
        },
      },
      item,

      // methods
      validateForm,
      channels,
      beforeUpload,
      handleMediaUpload,
      handleSuccess,
      handleError,
      computedChannels,
    };
  },
});
</script>
