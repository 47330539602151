<template lang="pug">
.text-center
  h4.mb-4 What
    span.text-success  channels
    |   are you interested in?

  .mb-2.text-success Channels
  .mb-4
    | Help to focus content like articles, quizzes, campaigns and
    | SPOTS around what matters and why.

  .mb-2.text-success Spots
  .mb-4
    | Our signature tool. These let you create rad compilations for the topics that interest you.
    | Blog, bookmark resources and content, share social action, and more.
    | They expand to meet your beat

  hr

  el-checkbox-group(v-model="items").bnt-round
    el-checkbox-button(
      v-for="item in channels.items"
      :label="item.id"
      :key="item.id"
    ) {{ item.channel }}

  .text-center.py-4(v-if="isOnlyFavourite")
    el-button(round type="success" @click="showAllChannels(false)") Show All Channels
  .text-center.py-4(v-if="!isOnlyFavourite")
    el-button(round type="success" @click="showAllChannels(true)") Show Recommended

  hr

  .text-center.py-4
    el-button(round type="success" @click="submitForm") Next
</template>

<script lang="ts">

import {
  defineComponent,
  reactive,
  onMounted,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';
import useOnboardingRoute from './composables/useUserOnboardingRoute';

export default defineComponent({
  name: 'Onboarding_Channels',
  setup() {
    const {
      initData,
      getChannels,
      canOnBoard,
      commitAndGotoNextRoute,
    } = useOnboardingRoute('channels');
    const items = ref(initData);
    const channels = reactive({ items: [] });
    const router = useRouter();
    const isOnlyFavourite = ref(true);

    onMounted(async () => {
      try {
        if (!canOnBoard()) {
          router.push('/');
        }
        const result = await getChannels(true);
        channels.items = result;
      } catch (err) {
        console.log(err);
      }
    });

    async function showAllChannels(getfavourite: boolean) {
      const result = await getChannels(getfavourite);
      channels.items = result;
      isOnlyFavourite.value = getfavourite;
    }

    return {
      // data (static)
      channels,
      // data (dynamic)
      items,
      // methods
      submitForm: () => commitAndGotoNextRoute(items),
      showAllChannels,
      isOnlyFavourite,
    };
  },
});
</script>
