<template lang="pug">
main(
  role="main"
  v-loading="loading"
  :class="{ 'pl-main-menu-width': isWebAppMenu }"
).pos-r.web-app-container
  .main-content_inner-wrap.web-app-menu.web-app-body
    div
      web-app-menu(v-if="isWebAppMenu" :items="WebAppMenuItems" :isWebAppMenu="isWebAppMenu")
      div.overlay(v-if="isWebAppMenu" @click="setMainMenuCollapse")
      .row.row-sm.align-items-center.mb-3
        .col
          .d-flex.align-items-center
            //- router-link(v-if='backTo' :to="backTo")
            //-   fai(type="fal" icon="angle-left").size-28.me-2.min-size-28
            fai(type="fal" icon="bars"
              @click="setMainMenuCollapse").size-28.me-2.min-size-28
            div(v-if="showLogo").mb-0.ml-2
              img(
                src="@/assets/images/logos/spotlight-full-logo-lockup-web.png"
              ).logo-image
            h3(v-else).mb-0.mx-auto {{ title }}
        .col-auto
          slot(name="actions")
            //- actions slot

      .grid-2-cols(v-if="$slots['col-left'] || $slots['col-right']")
        .col-has-child.main-col-left
          slot(name="col-left")
            //- col-left slot

        .col-has-child.main-col-right(v-if="$slots['col-right']")
          slot(name="col-right")
            //- col-right slot

      .col-has-child(v-else :class="{ 'p-0': false }")
        slot
</template>

<script lang="ts">
import {
  defineComponent, computed, ref, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import WebAppMenu from '../side-menu/WebAppMenu.vue';
import { WebAppMenuItems } from '../../../data/main-menu';

export default defineComponent({
  name: 'WebAppLayout',
  components: { WebAppMenu },
  props: {
    title: { type: String, default: '' },
    showLogo: { type: Boolean, default: false },
  },
  setup() {
    const store = useStore();
    const isWebAppMenu = ref(false);
    const loading = ref(false);

    function setMainMenuCollapse() {
      isWebAppMenu.value = !isWebAppMenu.value;
      store.commit('shared/SET_MAIN_MENU_COLLAPSE', !isWebAppMenu.value);
    }

    onMounted(() => {
      store.commit('shared/SET_MAIN_MENU_COLLAPSE', false);
    });

    return {
      // getter
      me: computed(() => store.getters['users/me']),

      // data
      user: {},
      isWebAppMenu,
      WebAppMenuItems,
      setMainMenuCollapse,
      loading,
    };
  },
});
</script>
<style>
.web-app-container {
    background: white;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.4);
}
.logo-image {
    height: 22px;
}
</style>
