<template lang="pug">
el-dialog(
  title="Add Organization"
  top="5vh" width="100%"
  :close-on-click-modal="false"
  class="el-dialog__max-w-700"
)
  el-form(
    ref="refForm"
    :rules="rules"
    :model="item"
    status-icon
    label-position="top"
  )
    el-form-item(label="Organization's Name" prop="name")
      el-input(v-model="item.name")

    el-form-item(label="Email" prop="email")
      el-input(v-model="item.email")
      .el-form-item__error(v-if="serverError === 'Email Already exists'")
        | Email already exits
    el-form-item(label="Country Code" prop="country_code" v-if="false")
      el-select(v-model="item.country_code" placeholder="Country Code").w-100
        el-option(:key="us", label="+1 (US)" value="+1")
        el-option(:key="in", label="+91 (IN)" value="+91")

    el-form-item(label="Phone Number" prop="phone")
      el-input(
        v-model="item.phone"
        v-mask="'(###) ###-####'"
        type="tel"
        masked="true"
        placeholder="(123) 456-7890"
        @blur="inviteCode"
      )
      .el-form-item__error(v-if="serverError === 'Phone Already exists'")
         | Phone already exits
    el-form-item(label="Invite Code" prop="inviteCode" )
      el-input(v-model="item.invite_code" :readonly="true")

  template(#footer)
    el-button(type="success" @click="validateForm" v-loading="sendingInvite") Send Invite
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import { mask } from 'vue-the-mask';
import { email, name, phone } from '@/data/rules';
import shared from './shared';

export default defineComponent({
  name: 'DialoAaddUser',
  directives: { mask },
  props: {
    serverError: { type: String, default: '' },
    sendingInvite: { type: Boolean, default: false },
  },
  emits: ['close-dialog', 'save-item'],
  setup(_, { emit }) {
    const {
      loading,
      getInviteCode,
    } = shared();
    const refForm = ref(null);
    const item = reactive({
      name: '',
      email: '',
      phone: '',
      country_code: '+1',
      invite_code: '',
    });

    async function inviteCode() {
      item.invite_code = await getInviteCode();
    }

    async function submitForm() {
      emit('save-item', item);
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) return submitForm();
        console.log('error submit!!');
        return false;
      });
    }

    return {
      // data (static)
      refForm,
      rules: { email, phone, name },
      item,
      loading,
      // methods
      validateForm,
      inviteCode,
    };
  },
});
</script>
