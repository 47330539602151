<script setup lang="ts">
import {ref, defineProps, onMounted} from 'vue';
import globalShared from "@/shared";

import EntityTab from "@/views/activity/ActivityDetails/EntityTab.vue";
import StickerDetailsTab from "@/views/activity/ActivityDetails/StickerDetailsTab.vue";
import StickerTransactionTab from "@/views/activity/ActivityDetails/StickerTransactionTab.vue";
import StripeTransactionTab from "@/views/activity/ActivityDetails/StripeTransactionTab.vue";
import {getActivityDetails} from "@/graphs/activity";

const { makeGraphRequest, getAuthToken } = globalShared();

const currentView = ref('Entity');
const paymentLog = ref(null);

const props = defineProps({
  transactionId: { type: Number, default: '' },
});
const { transactionId } = props;

const onSwitchTab = (tab: string) => {
  currentView.value = tab
}

const getDetails = async () => {
  const variables = {
    transactionId
  };

  try {
    const response = await makeGraphRequest(
      'query', getActivityDetails, variables, getAuthToken(),
    )
    paymentLog.value = response.data.GetDetailsAboutTransaction;
  } catch (e) {
    // console.log(e)
  }
}

onMounted( () => {
  getDetails();
})

</script>

<template>
  <el-dialog
    :title="paymentLog?.paymentInfo ?? ''"
    top="5vh"
    width="100%"
    class="el-dialog__max-w-700"
    :z-index="100000"
  >
    <div class="row">
      <div class="filter-container">
      <span
        class="padding-left-20 filter-active"
        :class="{'filter-inactive' : currentView !== 'Entity'}"
        @click="onSwitchTab('Entity')"
      >
        Entity
      </span>
        <span
          class="padding-left-20 filter-active"
          :class="{'filter-inactive' : currentView !== 'Details'}"
          @click="onSwitchTab('Details')"
        >
        Sticker details
      </span>
        <span
          class="padding-left-20 filter-active"
          :class="{'filter-inactive' : currentView !== 'StickerTransaction'}"
          @click="onSwitchTab('StickerTransaction')"
        >
        Sticker transaction
      </span>
        <span
          class="padding-left-20 filter-active"
          :class="{'filter-inactive' : currentView !== 'StripeTransaction'}"
          @click="onSwitchTab('StripeTransaction')"
        >
        Stripe transaction
      </span>
      </div>
    </div>

    <template v-if="paymentLog">
      <EntityTab v-if="currentView === 'Entity'" :paymentLog="paymentLog" />
      <StickerDetailsTab v-if="currentView === 'Details'" :paymentLog="paymentLog" />
      <StickerTransactionTab v-if="currentView === 'StickerTransaction'" :paymentLog="paymentLog"/>
      <StripeTransactionTab v-if="currentView === 'StripeTransaction'" :paymentLog="paymentLog" />
    </template>
    <template v-else>
      <div class="c-loading-spinner" />
    </template>

  </el-dialog>
</template>

<style scoped>

.filter-container {
  gap: 20px;
  align-items: center;
  white-space: nowrap;
  padding: 0 0 10px 0;
}

.filter-active {
  cursor: pointer;
  padding: 5px 10px;
  transition: all 0.3s 
}

.filter-inactive {
  opacity: 0.8;
}

.filter-container span {
  flex-shrink: 0;
}


</style>
