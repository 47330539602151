const buildTicketStickerFromItem = (item) => {
  // this is from api
  return {
    id: item.id,
    title: item.title,
    campaignType: item.campaignType,
    causes: item.causes,
    confirmationEmail: item.confirmationEmail,
    cost: item.cost,
    description: item.description,
    donationMultiplier: item.donationMultiplier,
    endDate: item.endDate,
    eventDate: item.eventDate,
    glow: item.glow,
    hasOptions: item.hasOptions,
    impact: item.impact,
    isCausesSearchAllowed: item.isCausesSearchAllowed,
    isFeatured: item.isFeatured,
    location: item.location,
    maxDonation: item.maxDonation,
    maxQuantity: item.maxQuantity,
    quickCosts: item.quickCosts,
    reminderEmail: item.reminderEmail,
    startDate: item.startDate,
    tags: item.tags,
    url: item.url,
    varients: item.varients,
    waiverURL: item.waiverURL
  }
}

const uploadDefaultStickerMap = (sticker) => {
  const quickCosts = [];
  const qc = sticker.quickCosts || [];
  qc.map((q) => {
    if (typeof q === 'number') {
      quickCosts.push(q);
    }
  })
  const updatedItem = {
    campaignType: null, // cause it's default sticker
    causes: sticker.causes || "",
    confirmationEmail: sticker.confirmationEmail || "",
    reminderEmail: sticker.reminderEmail || "",
    cost: parseFloat(parseFloat(sticker.cost).toFixed(2)),
    description: sticker.description || "",
    glow: sticker.glow,
    id: sticker.id || null,
    impact: sticker.impact || "",
    quickCosts: quickCosts,
    title: sticker.title || "",
    url: sticker.url || "",
    varients: [],
    hasOptions: sticker.hasOptions || null,
    isCausesSearchAllowed: sticker.isCausesSearchAllowed || false,
    isFeatured: false,
    waiverURL: '',
    donationMultiplier: 0,
    location: "",
    maxDonation: 0,
    maxQuantity: 0,
  };

  if (Array.isArray(sticker.tags)) {
    updatedItem.tags = sticker.tags.join(' |=| ');
  } else if (typeof sticker.tags === 'string') {
    updatedItem.tags = sticker.tags.split(',').join(' |=| ');
  }

  if (Array.isArray(sticker.causes)) {
    updatedItem.causes = sticker.causes.join(' |=| ');
  } else if (typeof sticker.causes === 'string') {
    updatedItem.causes = sticker.causes.split(',').join(' |=| ');
  } else {
    updatedItem.causes = '';
  }

  return updatedItem;
}

const uploadTicketStickerMap = (sticker) => {
  let varients = sticker.varients || []
  if (sticker.varients) {
    varients = varients.map(({ __typename, ...rest }) => rest);
  }
  const updatedItem = {
    campaignType: 'Ticket',
    causes: sticker.causes || "",
    confirmationEmail: sticker.confirmationEmail || "",
    reminderEmail: sticker.reminderEmail || "",
    cost: parseFloat(parseFloat(sticker.cost).toFixed(2)),
    description: sticker.description || "",
    glow: sticker.glow,
    id: sticker.id || null,
    impact: sticker.impact || "",
    title: sticker.title || "",
    url: sticker.url || "",
    varients: varients,
    donationMultiplier: sticker.donationMultiplier || 0,
    endDate: sticker.endDate || null,
    startDate: sticker.startDate || null,
    eventDate: sticker.eventDate || null,
    hasOptions: sticker.hasOptions || null,
    isCausesSearchAllowed: sticker.isCausesSearchAllowed || false,
    isFeatured: sticker.isFeatured || false,
    location: sticker.location || '',
    maxDonation: sticker.maxDonation || 0,
    maxQuantity: sticker.maxQuantity || 0,
    waiverURL: sticker.waiverURL || "",
    startDateTimeZone: sticker.startDateTimeZone || null,
    endDateTimeZone: sticker.endDateTimeZone || null,
    eventDateTimeZone: sticker.eventDateTimeZone || null,
  };

  if (Array.isArray(sticker.tags)) {
    updatedItem.tags = sticker.tags.join(' |=| ');
  } else if (typeof sticker.tags === 'string') {
    updatedItem.tags = sticker.tags.split(',').join(' |=| ');
  }

  if (Array.isArray(sticker.causes)) {
    updatedItem.causes = sticker.causes.join(' |=| ');
  } else if (typeof sticker.causes === 'string') {
    updatedItem.causes = sticker.causes.split(',').join(' |=| ');
  } else {
    updatedItem.causes = '';
  }

  return updatedItem;
}

export { buildTicketStickerFromItem, uploadDefaultStickerMap, uploadTicketStickerMap }
