<script setup>
import { computed, ref } from 'vue';
import Fai from "@/plugins/font-awesome/FontAwesomeIcon.vue";
import store from "@/store";

const tiers = ref([]);

const item = computed({
  get: () => store.getters['sticker/GET_STICKER'],
  set: (value) => {
    store.dispatch('sticker/SET_STICKER', value);
  }
});

const updateVariants = (variants) => {
  const updatedItem = {
    ...item.value,
    varients: variants.length > 0 ? variants : null,
  };
  item.value = updatedItem;
};

const onAddTier = () => {
  const newTier = {
    id: null,
    cost: null,
    title: "",
    sortOrder: 0,
    stickerId: null,
    description: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
  };
  tiers.value.push(newTier);

  const newVariant = {
    id: null,
    cost: null,
    title: "",
    sortOrder: 0,
    stickerId: null,
    description: null,
    isActive: true,
    createdAt: null,
    updatedAt: null,
  };

  const updatedVariants = [...(item.value.varients || []), newVariant];
  updateVariants(updatedVariants);
};

const onRemoveTier = (index) => {
  tiers.value.splice(index, 1);

  const updatedVariants = [...(item.value.varients || [])];
  updatedVariants.splice(index, 1);

  updateVariants(updatedVariants);
};

const preventArrowKeys = (event) => {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    event.preventDefault();
  }
};

</script>

<template>
  <section class="c-tiers">
    <article class="c-tier" v-for="(n, i) in item.varients">
        <div class="c-tier__remove">
          <div class="c-tier__remove__button">
            <fai type="fas" icon="trash" class="size-14" />
            <div @click="onRemoveTier(i)">Remove tier</div>
          </div>
        </div>
        <div class="c-tier__inputs">
          <div class="c-tier__column">
            <span class="c-tier__label">Tier Title</span>
            <input class="c-input" type="text" required placeholder="Tier Title" v-model="n.title"/>
          </div>

          <div class="c-tier__column">
            <span class="c-tier__label">Tier Description</span>
            <input class="c-input" type="text" required placeholder="Tier Description"  v-model="n.description"/>
          </div>

          <div class="c-tier__column">
            <span class="c-tier__label">Cost (optional)</span>
            <input class="c-input" type="number" required placeholder="15" min="0" v-model="n.cost" @keydown="preventArrowKeys" @wheel.prevent/>
          </div>
        </div>
      </article>

    <section class="c-tiers__add">
      <div class="c-tiers__add__button" @click="onAddTier">
        <fai type="fas" icon="plus" class="size-14" />
        Add Tier
      </div>
      <div class="c-tiers__add__divider" />
    </section>
  </section>

</template>

<style scoped lang="scss">
.c-tiers {
  gap:30px;
  display: flex;
  flex-direction: column;

  &__add {
    display: flex;
    align-items: center;
    margin-top: 31px;

    &__button {
      width: 100px;
      height: 30px;
      color: white;
      background: #1EBEAF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 900;
      font-family: 'Red Hat Display', sans-serif;
      gap: 11px;
    }
    &__divider {
      border-top: 1px #cecece solid;
      flex: 1;
    }
    &__text {
      cursor: pointer;
    }
  }
}
.c-tier {
  display: flex;
  flex-direction: column;
    &__label {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      font-family: "Red Hat Display";
    }
    &__inputs {
      gap: 30px;
      display: flex;
      flex-direction: column;
    }
    &__remove {
      border-top: 1px solid #CCCCCC;
      margin-bottom: 20px;
      &__button {
        background: #F1351B;
        color: white;
        width: 128px;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 6px 9px;
        border-radius: 0 0 5px 5px;
        font-family: "Red Hat Display", serif;
        font-weight: 900;
        font-size: 14px;
        gap: 11px;
        cursor: pointer;
        line-height: 14px;
      }
    }
    &__row {
      display: flex;
      flex-direction: row;
    }
    &__column {
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 8px;
    }
    /* Hide the arrows in number input fields */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Standard property for compatibility */
  }
}
</style>
