<script setup lang="ts">
  import {ref, defineExpose, watch, onMounted} from 'vue';
  import 'vue3-timepicker/dist/VueTimepicker.css'
  import store from "@/store";
  import { useRoute } from "vue-router";
  import { ElTimePicker } from 'element-plus'
  import { timezones } from '@/data/timezones'
  import { combineDateAndTime } from '@/shared/sticker-date-combos'

  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';
  import moment from 'moment-timezone';
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const item = store.getters['sticker/GET_STICKER'];
  const route = useRoute();

  const color = ref('#F8E587');

  const glow = ref(false);
  const dateStart = ref();
  const dateEnd = ref();
  const timeStart = ref();
  const timezoneStart = ref();
  const timeEnd = ref();
  const timezoneEnd = ref();


  defineExpose({
    color,
    glow,
    dateStart,
    dateEnd,
    timeStart,
    timeEnd
  });

  onMounted(async () => {
    if (
      !item?.glow?.glowColor ||
      !item?.glow?.glowStartDate ||
      !item?.glow?.glowEndDate ||
      !item?.glow?.glowStartDateTimeZone ||
      !item?.glow?.glowEndDateTimeZone
    ) {
      return;
    }

    color.value = item.glow.glowColor;
    glow.value = true;

    timezoneStart.value = item.glow.glowStartDateTimeZone;
    timezoneEnd.value = item.glow.glowEndDateTimeZone;

    const receivedStartDate = item.glow.glowStartDate;
    const receivedEndDate = item.glow.glowEndDate;

    const start = moment
      .utc(receivedStartDate, 'YYYY-MM-DDTHH:mm:ssZ')
      .tz(timezoneStart.value);

    if (start && typeof start.format === 'function') {
      dateStart.value = start.format('YYYY-MM-DD');
      timeStart.value = start.format('hh:mm A');
    }

    const end = moment
      .utc(receivedEndDate, 'YYYY-MM-DDTHH:mm:ssZ')
      .tz(timezoneEnd.value);

    if (end && typeof end.format === 'function') {
      dateEnd.value = end.format('YYYY-MM-DD');
      timeEnd.value = end.format('hh:mm A');
    }
  });


  const combineGlow = () => {

    const startDate = combineDateAndTime(dateStart.value, timeStart.value);
    const endDate = combineDateAndTime(dateEnd.value, timeEnd.value);
    const targetTimeZoneStart = timezoneStart.value;
    const targetTimeZoneEnd = timezoneEnd.value;

    const convertToUTC = (date, timeZone) => {
      const localDate = moment.tz(date, timeZone);
      return localDate.utc().format();
    };

    const startDateInUTC = convertToUTC(startDate, targetTimeZoneStart);
    const endDateInUTC = convertToUTC(endDate, targetTimeZoneEnd);

    const glow = {
      glowStartDate: startDateInUTC,
      glowEndDate: endDateInUTC,
      glowStartDateTimeZone: targetTimeZoneStart,
      glowEndDateTimeZone: targetTimeZoneEnd,
      glowColor: color.value,
    }
    store.dispatch('sticker/SET_STICKER', { glow });
  }

  watch([dateStart, timeStart, dateEnd, timeEnd, color, timezoneStart, timezoneEnd], () => {
    combineGlow()
  })

  watch (glow, () => {
    if (glow.value) {
      combineGlow();
    } else {
      // reset
      store.dispatch('sticker/SET_STICKER', {
        glow: {
          glowStartDate: null,
          glowEndDate: null,
          glowColor: null,
          glowStartDateTimeZone: null,
          glowEndDateTimeZone: null,
        }
      });
    }
  })
</script>

<template>
  <section class="c-glow-picker">

    <article class="c-glow-picker__check">
      <el-checkbox v-model="glow" type="success" />
      <input class="c-glow-picker__color" type="color" v-model="color" />
      Add Glow
    </article>
    <template v-if="glow">
      <div class="c-glow-picker__label">Glow Start Date & Time</div>
      <article class="c-glow-picker__group c-glow-picker__group--middle">
        <div class="c-glow-picker__group__inner">
          <el-date-picker class="c-glow-picker__input" v-model="dateStart" format="MM/DD/YY" />
          <el-time-picker
            arrow-control
            class="c-custom-time"
            format="hh:mm A"
            v-model="timeStart"
            value-format="hh:mm A"
          />
        </div>
        <el-select class="c-glow-picker__input" v-model="timezoneStart" placeholder="Timezone">
          <el-option
            v-for="timezone in timezones"
            :key="timezone.value"
            :label="timezone.label"
            :value="timezone.value">
          </el-option>
        </el-select>
      </article>

      <div class="c-glow-picker__label">Glow End Date & Time</div>
      <article class="c-glow-picker__group">
        <div class="c-glow-picker__group__inner">
          <el-date-picker class="c-glow-picker__input" v-model="dateEnd" format="MM/DD/YY" />
          <el-time-picker
            v-model="timeEnd"
            class="c-custom-time"
            arrow-control
            format="hh:mm A"
            value-format="hh:mm A"
          />
        </div>
        <el-select class="c-glow-picker__input" v-model="timezoneEnd" placeholder="Timezone">
          <el-option
            v-for="timezone in timezones"
            :key="timezone.value"
            :label="timezone.label"
            :value="timezone.value">
          </el-option>
        </el-select>
      </article>
    </template>
  </section>
</template>

<style lang="scss">
  $iHeight: 56px;

  .c-glow-picker {
    font-family: "Red Hat Display";
    font-weight: 500;

    &__label {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      font-family: "Red Hat Display";
      margin-bottom: 8px;
    }


    .vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul
    li:not([disabled]).active:focus, .vue__time-picker .dropdown ul
    li:not([disabled]).active:hover {
      background: #1EBEAF;
    }

    &__group {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &__inner {
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
      &--middle {
        margin-bottom: 26px;
      }

      .el-input__wrapper {
        box-shadow: none;
        border: 1px solid #ccc;
      }

      .el-input--prefix .el-input__inner {
        padding-left: 40px;
        color: #000000;
        font-family: "Red Hat Display";
        font-size: 14px;
        font-weight: 500;
      }
    }

    &__icon {
      font-size: 16px;
      margin-right: 8px;
    }

    .el-select {
      width: 100%;
      max-width: 340px;
      &__placeholder {
        color: #000000;
        font-family: "Red Hat Display";
        font-size: 14px;
        font-weight: 500;
      }
      &__wrapper {
        height: 100%;
        border: none;
        box-shadow: none;
        width: 338px;
      }
    }

    &__input {
      width: 100%;
      max-width: 160px;
      height: $iHeight;
      border: 1px solid #CCCCCC;
      border-radius: 5px;


      &--clock-icon {
        position: absolute;
        z-index: 1;
        top: 21px;
        left: 24px;
      }

      &--time {
        input.vue__time-picker-input {
          height: 100%;
          width: 100%;
          border: none;
          border-radius: 5px;
          padding-left: 52px;
          color: #000000;
          font-family: "Red Hat Display";
          font-size: 14px;
          font-weight: 500;
        }
      }

      &.el-date-editor.el-input {
        height: $iHeight;
        box-shadow: none;
        border: none;
      }
    }

    &__check {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 22px;
    }

    &__color {
      border: 1px solid #CCCCCC;
      width: 40px;
      height: 40px;
      padding: 0;
      appearance: none;
      background-color: var(--color);
      border-radius: 100%;
      cursor: pointer;
    }
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
  }

  input[type="color"]::-moz-color-swatch {
    border: none;
    border-radius: 50%;
  }

  input[type="color"]::-ms-color-swatch {
    border: none;
    border-radius: 50%;
  }

  input[type="color"]::-ms-fill {
    border: none;
    border-radius: 50%;
  }

  .el-date-editor.c-custom-time.el-input {
    border-radius: 5px;
    color: #000000;
    font-family: "Red Hat Display",sans-serif;
    font-size: 14px;
    font-weight: 500;
    width: 160px;
    padding: 0;
    height: 56px;
    box-shadow: none;

    .el-input__wrapper {
      box-shadow: none;
    }

    &:focus {
      outline: none;
    }
  }
</style>
