<script setup lang="ts">
import { defineProps } from 'vue';
import type { PaymentLogType } from 'src/data/types';
import type {PaymentLog} from "@/views/manage-content/payment-log/types";

const props = defineProps<{
  paymentLog: PaymentLogType | null;
}>();

const { paymentLog } = props;

const renderStickerTransactionAmount = (paymentLog: PaymentLog) => {
  let amount = paymentLog.stickerTransaction.cost;
  if (paymentLog.stripeTransaction?.amount > 0) {
    amount = paymentLog.stripeTransaction.amount - paymentLog.stripeTransaction.fee_Convenience - paymentLog.stripeTransaction.fee_CreditCard
  }
  return amount.toFixed(2);
}

const renderStickerTransactionDonated = (paymentLog: PaymentLog) => {
  let amount = paymentLog.stickerTransaction?.amount_donated;
  const isCause = paymentLog.stickerTransaction?.causeId?.length;
  if (isCause && paymentLog.stripeTransaction?.amount > 0) {
    amount = paymentLog.stripeTransaction.amount - paymentLog.stripeTransaction.fee_Convenience - paymentLog.stripeTransaction.fee_CreditCard;
  }
  return amount.toFixed(2);
}

</script>

<template>
  <section
    class="row row-xs align-items-center"
    v-if="paymentLog.stickerTransaction"
  >
    <div class="col-auto">
      <div class="fw-m py-1">Sticker Transaction Id: {{ paymentLog.stickerTransaction.id }}</div>
      <div class="fw-m py-1">isProcessed: {{ paymentLog.stickerTransaction.isProcessed }}</div>
      <div class="fw-m py-1">Amount: {{ renderStickerTransactionAmount(paymentLog) }}</div>
      <div class="fw-m py-1">Amount Donated: {{ renderStickerTransactionDonated(paymentLog) }}</div>
      <div class="fw-m py-1">Amount Rebate: {{ paymentLog.stickerTransaction.amount_rebate }}</div>
      <div class="fw-m py-1">Cause Id: {{ paymentLog.stickerTransaction.causeId }}</div>
      <div class="fw-m py-1">variant Id: {{ paymentLog.stickerTransaction.variantId }}</div>
      <div class="fw-m py-1">Purchase Type: {{ paymentLog.stickerTransaction.purchaseType }}</div>
      <div class="fw-m py-1">Benevity Transaction Id: {{ paymentLog.stickerTransaction.benevity_transaction_Id }}</div>
      <div class="fw-m py-1">Stripe Transaction Id: {{ paymentLog.stickerTransaction.stripe_transaction_Id }}</div>
      <div class="fw-m py-1">orgStripeAccountId: {{ paymentLog.stickerTransaction.orgStripeAccountId }}</div>
      <div class="fw-m py-1">
        Org Payment Stripe Transaction Id:
        {{ paymentLog.stickerTransaction.Org_Payment_stripe_transaction_Id }}
      </div>
      <div class="fw-m py-1">Metadata: {{ paymentLog.stickerTransaction.transactionMeta }}</div>
    </div>
  </section>
</template>

<style scoped>

</style>
