<template lang="pug">
div(v-if="hasUser")
  el-form(
    ref="refDummyForm"
    :rules="rules"
    status-icon
    label-position="top"
  )
    el-form-item(label="Administrator's First Name" prop="name")
      el-input(v-model="returnUser.firstname" disabled)
    el-form-item(label="Administrator's Last Name" prop="name")
      el-input(v-model="returnUser.lastname" disabled)

    el-form-item(label="Cell Phone" prop="phone")
      el-input(
        v-model="returnUser.phone"
        v-mask="'(###) ###-####'"
        type="tel"
        disabled
        placeholder="(123) 456-7890"
      )
    el-form-item(label="Email" prop="email")
      el-input(v-model="returnUser.email" type="email" disabled)
    .p-4.text-center
      el-button(round type="success" v-loading="submitting" @click="userUpgrade") Continue

div(v-else)
  el-form(
    ref="refForm"
    :rules="rules"
    :model="item"
    status-icon

    label-position="top"
  )
    el-form-item(label="Administrator's First Name" prop="name")
      el-input(v-model="item.firstname")
    el-form-item(label="Administrator's Last Name" prop="name")
      el-input(v-model="item.lastname")

    el-form-item(label="Title")
      el-input(v-model="item.title")

    el-form-item(label="Cell Phone" prop="phone")
      el-input(
        v-model="item.phone"
        v-mask="'(###) ###-####'"
        type="tel"
        placeholder="(123) 456-7890"
      )
      .el-form-item__error(v-if="serverError === 'Phone Already exists'")
        | Phone already exits

    el-form-item(label="Age" prop="age")
      el-input(
        v-model="item.age"
        type="number"
        min=18
        max=100
        placeholder='18-100'
      ).w-100

    el-form-item(label="Email" prop="email")
      el-input(v-model="item.email" type="email" disabled)
      .el-form-item__error(v-if="serverError === 'Email Already exists'")
        | Email already exits

    el-form-item(label="Password" prop="password")
      el-input(v-model="item.password" type="password")

    el-form-item(label="Confirm Password" prop="confirmPassword")
      el-input(v-model="item.confirmPassword" type="password")

    .p-4.text-center
      el-button(round type="success" @click="validateForm" v-loading="submitting") Continue

</template>

<script>

import {
  defineComponent,
  reactive, onMounted,
  ref,
} from 'vue';
import { mask } from 'vue-the-mask';
import { useRouter } from 'vue-router';
import { email, name, phone } from '@/data/rules';
import useUserOnboardingRoute from './composables/useUserOnboardingRoute';

export default defineComponent({
  name: 'Onboarding_UserDetail',

  directives: { mask },

  setup() {
    const {
      initData,
      validateForm,
      validateInviteCode,
      canOnBoard,
      serverError,
      getReturningUser,
      userUpgrade,
      submitting,
    } = useUserOnboardingRoute('userDetail');
    const refForm = ref(null);
    const refDummyForm = ref(null);
    const router = useRouter();
    const hasUser = ref(false);
    const item = reactive(initData);
    const returnUser = reactive({});

    function checkConfirmPassword(rule, value, callback) {
      if (value !== item.password) {
        callback('Confirm Password should match password');
      } else {
        callback();
      }
    }

    function checkAge(rule, value, callback) {
      const age = parseInt(value, 10);
      if (age >= 18 && age <= 100) {
        callback();
      } else {
        callback('Age should be between 18 and 100');
      }
    }

    const passwordRules = [
      {
        required: true,
        pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}/,
        message: 'Password is required, (at least 6 characters at least one number, one lowercase and one upper case)',
      },
    ];

    const ageRules = [
      { required: true },
      {
        validator: checkAge, trigger: 'blur',
      },
    ];

    const confirmPasswordRules = [
      { validator: checkConfirmPassword, trigger: 'blur' },
    ];

    onMounted(async () => {
      try {
        if (!canOnBoard()) {
          router.push('/');
        }
        const result = await validateInviteCode();
        const itm = result.data.validateInvite;
        if (itm.returning_user) {
          // hasUser.value = true;
          const usr = await getReturningUser();
          // Object.assign(returnUser, usr);
          console.log(usr);
        }
        item.email = itm.email;
        item.phone = itm.phone;
        item.firstname = itm.name;
        if (result.errors && result.errors.length > 0) {
          router.push('/');
        }
      } catch (err) {
        router.push('/');
      }
    });

    return {
      // data (static)
      refForm,
      refDummyForm,
      hasUser,
      rules: {
        email,
        phone,
        firstname: name,
        lastname: name,
        password: passwordRules,
        age: ageRules,
        confirmPassword: confirmPasswordRules,
      },
      submitting,
      item,
      serverError,
      returnUser,
      userUpgrade: () => userUpgrade(returnUser),
      // data (dynamic)
      // methods
      validateForm: () => validateForm(refForm, initData),
    };
  },
});
</script>
