<template lang="pug">
div
  .card-header.p-4.bg-transparent
    .row.row-sm.align-items-center
      .col
        h4.mb-0 Spots
      .col-auto
        el-button(type="primary" @click="dialog = true").has-fai
          fai(type="fal" icon="plus-circle").size-18.me-2
          span.min-w-110.d-inline-block Add New

  el-table(
    :data="items"
    stripe
    v-loading="loading"
    @row-click="$router.push(`spots/${$event.id}`);"
    style="width: 100%"
  ).clickable-rows
    el-table-column(width="15")
      // space

    el-table-column(width="70")
      template(#default="scope")
        div(@click.stop="handleToggleAddToFav(scope.row)").cp
          svg(v-if="scope.row.isFavourite").size-32
            use(xlink:href="#fas-star")
          fai(v-else type="fal" icon="star").size-32

    el-table-column(width="70")
      template(#default="scope")
        img(:src="scope.row.imgUrl").size-50px.obj-f-c

    el-table-column(prop="name" label="Name" width="200" sortable)
    el-table-column(prop="creator" label="Creator" width="200" sortable)
    el-table-column(prop="channel" label="Channel" min-width="200" sortable)
    el-table-column(prop="adPartner" label="Ad Partner" min-width="200" sortable)

    el-table-column(width="46")
      .el-table__expand-icon
        img(src="@/assets/icons/light/chevron-right-light.svg")

    el-table-column(width="15")
      // space

  el-pagination(layout='prev, pager, next' :total="50")

  dialog-add-spot(
    v-if="dialog"
    :loading="loading"
    v-model="dialog"
    @save-item="saveItem"
    @close-dialog="dialog = false"
  )
</template>

<script>
import { defineComponent, onMounted } from 'vue';
import DialogAddSpot from './DialogAddSpot.vue';
import shared from './shared';

const staticData = [{
  id: '1',
  name: 'Spot Name',
  imgUrl: '//placekitten.com/g/50/50',
  creator: 'Usre or Organiztion Name',
  channel: 'Channel',
  adPartner: 'Long Advertiser Name',
  isFavourite: true,
}, {
  id: '2',
  name: 'A Very Long Spot Name',
  imgUrl: '//placekitten.com/g/50/50',
  creator: 'Usre or Organiztion Name',
  channel: 'Channel',
  adPartner: 'Long Advertiser Name',
  isFavourite: false,
}];

export default defineComponent({
  name: 'ManageContent_Spots',
  components: { DialogAddSpot },
  setup() {
    const { loading, dialog, ref } = shared();

    const items = ref([]);

    async function getSpots() {
      // simulation of Ajax
      return new Promise((resolve) => setTimeout(() => resolve(staticData), 1000));
    }

    async function getItems() {
      try {
        loading.value = true;
        items.value = await getSpots();
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    function handleToggleAddToFav(row) {
      const itemToFav = staticData.find((obj) => obj.id === row.id);
      loading.value = true;
      setTimeout(() => {
        itemToFav.isFavourite = !itemToFav.isFavourite;
        loading.value = false;
      }, 1000);
    }

    async function saveItem(input) {
      // save stuff here
      console.log('input :>> ', input);
    }

    onMounted(async () => {
      await getItems();
    });

    return {
      items,
      loading,
      dialog,

      // methods
      saveItem,
      handleToggleAddToFav,
    };
  },
});
</script>
