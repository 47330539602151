<template lang='pug'>
#app
  svg-sprite
  router-view
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import SvgSprite from '@/components/SvgSprite.vue';

export default defineComponent({
  name: 'App',
  components: { SvgSprite },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      appObj: '',
      appInit: false,
    };
  },
  computed: {
    ...mapGetters('users', ['me', 'isMeSignedIn']),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created() {
    this.setMe();
  },

  methods: {
    ...mapActions('users', ['setMe', 'resetMe', 'setMeIsSignedIn']),
  },
});
</script>

<style lang='scss'>
@import './assets/styles/plugins';
@import './assets/styles/main';
</style>
