<template lang="pug">
app-main-content-layout(title='Interests')
  template(#actions)
    el-button(@click="exportInterests").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Export

    el-button(type="primary" color='#1EBEAF' @click="dialog = true").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Add New

  div
    .row.row-sm.align-items-center.mb-4
      .col
        el-input(
          v-model="filter"
          placeholder="Search Interests"
          prefix-icon="el-icon-search"
        )
          template(#prefix='')
              img(
                src="@/assets/icons/light/magnify-light.svg",
                style="font-size: 20px;")
      .col-auto
        el-button(type="info").has-fai
          fai(type="fal" icon="filter").size-18.me-2
          span.min-w-110.d-inline-block Filter

    el-table(
      :data="paginatedItems"
      stripe
      v-loading="loading"
      @row-click="rowClick($event)"
      @row-dblclick="rowClick($event)"
      style="width: 100%"
    ).clickable-rows.mb-3
      el-table-column(width="15")
        // space

      el-table-column(width="70")
        template(#default="scope")
          div(@click.stop="handleToggleAddToFav(scope.row)").cp
            svg(v-if="scope.row.favorite").size-32
              use(xlink:href="#fas-star")
            fai(v-else type="fal" icon="star").size-32

      el-table-column(prop="interest" label="interest" min-width="200" sortable)
      el-table-column(prop="category" label="Category" min-width="200" sortable)
        template(#default="scope") {{ scope.row.category ? scope.row.category.category : '' }}

      el-table-column(width="46")
        .el-table__expand-icon
          img(src="@/assets/icons/light/chevron-right-light.svg")
      el-table-column(width="15")
        // space

    el-pagination(
      layout='prev, pager, next'
      :page-size="pageSize"
      hide-on-single-page=true
      :total="filtered.length"
      :current-page="currentPage"
      @update:current-page="pageChange"
      v-model="currentPage"
    )

    dialog-add-interest(
      v-if="dialog"
      :loading="loading"
      v-model="dialog"
      :categories="categories.categories"
      @save-item="saveItem"
      @save-category="saveCategoryItem"
      @close-dialog="dialog = false"
    )
</template>

<script lang="js">

// eslint-disable-next-line object-curly-newline
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import shared from './shared';
import DialogAddInterest from './DialogAddInterest.vue';
import globalShared from '@/shared';

export default defineComponent({
  name: 'ManageContent_Directory_Interests',
  components: { DialogAddInterest },
  setup() {
    const {
      router,
      loading,
      getTypedCategories,
      getInterests,
      saveInterest,
      saveCategory,
      exportData,
    } = shared();

    const { showSuccessMessage } = globalShared();

    const filter = ref('');
    const currentPage = ref(1);
    const pageSize = ref(25);
    const items = reactive({ items: [] });
    const categories = reactive({ categories: [] });
    const dialog = ref(false);

    async function getItems() {
      try {
        loading.value = true;
        items.items = await getInterests();
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    async function saveItem(input) {
      await saveInterest(input);
      showSuccessMessage('Interest added successfully');
      getItems();
    }

    async function saveCategoryItem(input) {
      await saveCategory(input);
      categories.categories = await getTypedCategories();
    }

    const filtered = computed(() => {
      if (filter.value === '') {
        return items.items;
      }
      const regex = new RegExp(`${filter.value}`, 'gi');
      return items.items.filter((x) => {
        if (x.interest) {
          return x.interest.match(regex);
        }
        return false;
      });
    });

    const paginatedItems = computed(() => {
      if (filtered.value.length > 0) {
        return filtered.value.slice(
          (currentPage.value - 1) * pageSize.value,
          (currentPage.value) * pageSize.value,
        );
      }
      return [];
    });

    async function handleToggleAddToFav(row) {
      const itemToFav = filtered.value.find((obj) => obj.id === row.id);
      itemToFav.favorite = !itemToFav.favorite;
      loading.value = true;
      await saveInterest({
        id: parseInt(itemToFav.id, 10),
        favorite: itemToFav.favorite,
      });
      loading.value = false;
    }

    onMounted(async () => {
      categories.categories = await getTypedCategories();
      await getItems();
    });

    function pageChange(page) {
      currentPage.value = page;
    }

    function rowClick(evt) {
      router.push(`/manage-content/directory/interests/${evt.id}`);
    }

    function exportInterests() {
      exportData(items.items);
    }

    return {
      categories,
      items,
      filter,
      filtered,
      paginatedItems,
      input: ref(),
      loading,
      dialog,
      rowClick,
      saveCategoryItem,
      saveItem,
      handleToggleAddToFav,
      pageSize,
      currentPage,
      pageChange,
      exportInterests,
    };
  },
});
</script>
