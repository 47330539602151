<template lang="pug">
app-main-content-layout(title='Add Item')

el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
  hide-required-asterisk=true
)

  div
    .row.row-sm.align-items-top
      div.filter_container
       span.filter-active.px-10(@click="selectedForm='bullhorn'") Bullhorn
       span.filter-inactive.px-10(@click="$router.push(`/manage-content/media/add-channel`)") Channel
       span.filter-inactive.px-10(@click="$router.push(`/manage-content/media/add-value`)") Value
       span.filter-inactive.px-10(@click="$router.push(`/manage-content/media/add-interest`)") Interest
      //-  div current is {{item}}

      .col.mb-6
        el-form-item(label="Title *" prop="bullhorn")
          el-input(v-model="item.bullhorn" placeholder="Title")

        el-form-item(label="Category *" prop="categoryId")
            VueMultiselect(
              v-model="item.categoryId",
              :multiple="false",
              label="category",
              track-by="id",
              :options="categories.categories",
              :searchable="true",
              :close-on-select="true",
              :show-labels="false"
              placeholder="Pick a value"
            )

        el-form-item(label="Status")
          VueMultiselect(
            v-model="item.status",
            :multiple="false",
            :options="dropdown",
            :searchable="false",
            :close-on-select="true",
            :show-labels="false"
            placeholder="Pick a value"
          )
      .col.mb-3
        el-form-item(label="Details" prop="description")
          el-input(v-model="item.description" type="textarea" :rows="12" placeholder="Details")
        .py-4.text-center
          el-button(type="success" @click="validateForm" color="#00BFB0" size="large" style="width: 100%") Save To Spotlight

</template>

<script>
import {
  defineComponent, reactive,
  ref, onMounted,
} from 'vue';
import { bullhorn, category } from '@/data/rules';
import VueMultiselect from 'vue-multiselect';
// import abc from '../directory/bullhorns/Detail.vue';
import shared from '../directory/bullhorns/shared';

export default defineComponent({
  name: 'AddBullhorn',
  components: { VueMultiselect },
  setup() {
    const {
      route,
      router,
      loading,
      getTypedCategories,
      getBullhorn,
      saveBullhorn,
    } = shared();

    const refForm = ref(null);
    const categories = reactive({ categories: [] });
    const item = reactive({
      id: null,
      bullhorn: '',
      category: 0,
      description: '',
    });
    const selectedForm = ref('bullhorn');
    const dropdown = ['Active', 'Inactive'];

    function select(selection) {
      // console.log('selected ', selection);
      // item.type = selection;
      router.push(`/manage-content/media/add-${selection}`);
    }

    async function saveItem() {
      loading.value = true;
      await saveBullhorn({
        id: parseInt(item.id, 10),
        bullhorn: item.bullhorn,
        description: item.description,
        categoryId: parseInt(item.categoryId.id, 10),
      });
      loading.value = false;
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    onMounted(async () => {
      categories.categories = await getTypedCategories();
    });

    return {
      // data
      input: ref(''),
      user: {},
      item,
      select,
      selectedForm,
      dropdown,

      // data
      refForm,
      rules: { bullhorn, categoryId: category },
      loading,
      categories,

      // methods
      validateForm,
    };
  },
});
</script>
