 <template>
  <div class='spotlight_card'>
    <div id="card-element"></div>
    <div class='card_errors'
        id="card-errors" role="alert" v-if="hasCardErrors"
    >
    {{ cardErrors }}
    </div>
    <div class='btn--basic' v-if="readyToPay"
         @click='finalizePayment'>
      Pay ${{ paymentDetails.amount / 100}}.00
    </div>
    <div class='btn--danger' @click='cancelIntent'>Cancel</div>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'FinalizePayment',
  setup() {
    const readyToPay = ref(false);
    const hasCardErrors = ref(false);
    const cardErrors = ref('');
    const route = useRoute();
    let card;
    let stripe;
    const paymentDetails = JSON.parse(atob(route.params.transactionCode));
    function loadStripe(stripeURL, callback) {
      const obj = document.createElement('script');
      const script = document.getElementsByTagName('script')[0];
      obj.src = `//${stripeURL}`;
      if (callback) {
        obj.addEventListener('load', (e) => {
          callback(null, e);
        }, false);
      }
      console.log(obj.src);
      script.parentNode.insertBefore(obj, script);
    }
    function getRedirectUrl() {
      return import.meta.env.VITE_APP_REDIRECT_BASE_URL;
    }

    function finalizePayment(e) {
      e.preventDefault();
      stripe.confirmCardPayment(paymentDetails.client_secret, {
        payment_method: {
          card,
          billing_details: {
            name: paymentDetails.username,
          },
        },
      }).then((result) => {
        const amount = paymentDetails.amount / 100;
        if (result.error) {
          // Show error to your customer (for example, insufficient funds)
          console.log(result.error.message);
          window.location.href = `${getRedirectUrl()}?action=addToWallet`
            + `&amount=${amount}&success=false`;
        } else if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          window.location.href = `${getRedirectUrl()}`
            + `?action=addToWallet&amount=${amount}&success=true`;
        } else {
          console.log(result);
          window.location.href = `${getRedirectUrl()}`
            + `?action=addToWallet&amount=${amount}&success=false`;
        }
      });
    }

    function cancelIntent() {
      window.location.href = `${getRedirectUrl()}?action=addToWalletCancel`;
    }

    onMounted(() => {
      loadStripe('js.stripe.com/v3/', () => {
        stripe = window.Stripe(paymentDetails.stripe_pub_key);
        const elements = stripe.elements();
        const style = {
          base: {
            fontSize: '20px',
            color: '#32325d',
          },
        };
        card = elements.create('card', { style });
        card.mount('#card-element');
        console.log('will configure script');
        card.on('change', (e) => {
          if (e.complete) {
            readyToPay.value = true;
            hasCardErrors.value = false;
          } else if (e.error) {
            hasCardErrors.value = true;
            readyToPay.value = false;
            cardErrors.value = e.error.message;
          }
        });
      });
    });
    return {
      readyToPay,
      hasCardErrors,
      cardErrors,
      paymentDetails,
      finalizePayment,
      cancelIntent,
    };
  },
});
</script>

<style scoped>
.spotlight_card {
  max-width: 800px;
  border: 1px solid black;
  /* Center the container in middle on horizontal axis */
  margin: 0 auto;
  /* Add empty space above the container (20% of the view height) */
  margin-top: 20vh;
  padding: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.card_errors {
  padding-top: 10px;
  color: red;
}
[class^="btn"] {
    width: 100%;
    display: inline-block;
    border: 0;
    padding: 10px;
    color: white;
    font-size: 16px;
    border-radius: 3px;
    font-family: inherit;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 10px;
}

.btn--basic {
      background-color: #529490;
}

.btn--success {
    background-color: #4abb6b;
}

.btn--error {
    background-color: #ad3d41;
}

.btn--danger {
    background-color: #ff8d00;
}
</style>
