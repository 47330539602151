<template lang="pug">
el-dialog(
  title="Add Stickers",
  top="5vh" width="100%"
  class="el-dialog__max-w-700"
  :close-on-click-modal="false"
)
  .row
    .col-4
      el-upload(
        :show-file-list="false"
        :on-success="handleSuccess"
        :on-error="handleError"
        :before-upload="beforeUpload"
        :http-request="handleStickerUpload"
        action=""
      ).img-uploader.pos-r
        template(v-if="item.url")
          img(:src="item.url").img-fluid
          .pin.pin-bx.d-flex.p-2
            el-button(type="primary").ms-auto Change
        template(v-else)
          img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
          p.upload-picture-text Drop an image file here or
          div.fake-upload-button Browse For File

    .col-7
      el-form(
        ref="refForm"
        :rules="rules"
        :model="item"
        status-icon
        label-position="top"
      )
        el-form-item(label="Sticker Title" prop="title")
          el-input(v-model="item.title")

        .row
          .col-6
            el-form-item(label="Tags" prop="tags")
              VueMultiselect(
                    v-model="item.tags",
                    :multiple="true",
                    :options="tagsArray",
                    :searchable="true",
                    :close-on-select="true",
                    :show-labels="false"
                    :loading="tagsLoading"
                    placeholder="Please enter a tag"
                  )
          .col-6
            el-form-item(label="Cost (optional)" prop="cost")
              el-input-number(
                  v-if="item.campaignType"
                  v-model="item.cost"
                  type="number"
                  :min=1
              )
              el-input-number(
                v-else
                v-model="item.cost"
                type="number"
                :min=0
              )

        el-form-item(label="Details" prop="description")
          el-input(v-model="item.description" type="textarea")

        //- .d-flex.mb-2
        //-   div Match Campaign
        //-   el-switch.ms-auto(
        //-     v-model="item.campaignType"
        //-     active-color="#13ce66"
        //-     active-value="MatchCampaign"
        //-     inactive-value=null)

        .d-flex.mb-2
          div Campaign
          el-switch.ms-auto(
            v-model="item.campaignType"
            active-color="#13ce66"
            active-value="Campaign"
            inactive-value=null)

        //- .d-flex.mb-2
        //-   div Sweepstake Campaign
        //-   el-switch.ms-auto(
        //-     v-model="item.campaignType"
        //-     active-color="#13ce66"
        //-     active-value="SweepstakeCampaign"
        //-     inactive-value=null)

        div(v-if="item.campaignType")
          div(v-if="item.campaignType !== 'MatchCampaign'")
            el-form-item(label="Non-Profit Suggestions (optional)")
                VueMultiselect(
                  v-model="item.causes",
                  :multiple="true",
                  :options="causeOptionsArray",
                  :searchable="true",
                  :close-on-select="true",
                  :show-labels="false"
                  :loading="causeLoading"
                  placeholder="Please enter a tag"
                  @search-change="getCausesDropdownOptions"
                )
            //- el-form-item(label="Non-Profit Suggestions (optional)" prop="cause")
            //-   el-select(
            //-     v-model="item.causes"
            //-     multiple
            //-     filterable
            //-     remote
            //-     reserve-keyword
            //-     placeholder="Please enter a cause"
            //-     :remote-method="getCausesDropdownOptions"
            //-     :loading="causeLoading"
            //-     v-on:change="onCauseValueChanged"
            //-   )
            //-     el-option(
            //-       v-for="cause in causeOptions"
            //-       :key="cause.value"
            //-       :label="cause.label"
            //-       :value="cause.value"
            //-     )
            //- el-form-item(label="Non-Profit Suggestions (optional)" prop="nonProfitSuggestions")
            //-   el-input(v-model="item.nonProfitSuggestions")
            el-form-item(label="Max Quantity (optional)" prop="maxQuantity")
              el-input-number(v-model="item.maxQuantity")

          div(v-if="item.campaignType === 'MatchCampaign'")
            .row
              .col-6
                el-form-item(label="Max Donation" prop="maxDonation")
                  el-input(v-model="item.maxDonation" type="number")
              .col-6
                el-form-item(label="Donation Multiplier" prop="donationMultiplier")
                  el-input-number(v-model="item.donationMultiplier")
            el-form-item(label="Non-Profit Suggestions (optional)" prop="cause")
              el-select(
                v-model="item.causes"
                multiple
                filterable
                remote
                reserve-keyword
                placeholder="Please enter a cause"
                :remote-method="getCausesDropdownOptions"
                :loading="causeLoading"
                v-on:change="onCauseValueChanged"
              )
                el-option(
                  v-for="cause in causeOptions"
                  :key="cause.value"
                  :label="cause.label"
                  :value="cause.value"
                )
            el-form-item(label="Impact" prop="impact")
              el-input(v-model="item.impact")

          el-form-item(label="Start Date" prop="startDate")
            input( v-model="item.startDate" type="date" ).w-100
          el-form-item(label="End Date" prop="endDate")
            input( v-model="item.endDate" type="date" ).w-100

  template(#footer)
    .alert.alert-danger.margin-top-20(v-if="error.message") {{ error.message }}
    el-button(type="success" @click="validateForm" v-loading="loading") Add
</template>

<script>

import {
  ref,
  reactive,
  defineComponent,
  onMounted,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import { requiredRule } from '@/data/rules';
import fileUpload from '@/shared/upload';
import shared from '../shared';

export default defineComponent({
  name: 'DialogAddSticker',
  components: { VueMultiselect },
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['close-dialog', 'save-item'],

  setup(_, { emit }) {
    const { uploadStatus, beforeUpload, handleUploadRequest } = fileUpload('stickers', 'Sticker');
    const {
      getBullhornsAsTags,
      getcausesAsDropdown,
      getBenevityCauseDetails,
    } = shared();

    const refForm = ref(null);
    const tagsList = ref([]);
    const tagsOptions = ref([]);
    const tagsLoading = ref(false);
    const causeList = ref([]);
    const causeOptions = ref([]);
    const causeOptionsArray = ref([]);
    const causeLoading = ref(false);
    const error = reactive({ message: '' });
    const tagsArray = ref([]);

    const item = reactive({
      id: null,
      title: '',
      url: null,
      description: '',
      tags: ref([]),
      cost: 0,
      campaignType: null,
      causes: ref([]),
      // nonProfitSuggestions: ref([]),
      maxQuantity: 0,
      maxDonation: null,
      donationMultiplier: 1,
      impact: null,
      startDate: null,
      endDate: null,
    });

    async function getBullhornsAsTagsList() {
      const bullhornTags = await getBullhornsAsTags();
      bullhornTags.forEach((element) => {
        const data = { value: `${element.bullhorn}`, label: `${element.bullhorn}` };
        tagsList.value.push(data);
        // tagsArray is used to render the correct values for the new dropdown
        tagsArray.value.push(element.bullhorn);
      });
    }

    // eslint-disable-next-line no-unused-vars
    async function getTagsDropdownOptions(query) {
      if (query) {
        tagsLoading.value = true;
        setTimeout(() => {
          tagsLoading.value = false;
          // eslint-disable-next-line arrow-body-style
          tagsOptions.value = tagsList.value.filter((dropdownItem) => {
            return dropdownItem.label.toLowerCase().includes(query.toLowerCase());
          });
        }, 200);
      } else {
        tagsOptions.value = [];
      }
    }

    async function getCausesList(query) {
      const benevityCauses = await getcausesAsDropdown(query);
      benevityCauses.forEach((element) => {
        const data = {
          value: `${element.name} : ${element.id}`,
          label: `${element.name} : ${element.id}`,
        };
        causeList.value.push(data);
      });
    }

    // eslint-disable-next-line no-unused-vars
    async function getCausesDropdownOptions(query) {
      if (query) {
        causeLoading.value = true;
        const benevityCauses = await getcausesAsDropdown(query);
        setTimeout(() => {
          causeLoading.value = false;
          causeOptions.value = [];
          benevityCauses.forEach((element) => {
            const data = {
              value: `${element.name} : ${element.id}`,
              label: `${element.name} : ${element.id}`,
            };
            causeOptions.value.push(data);
            causeOptionsArray.value.push(`${element.name} : ${element.id}`);
          });
        }, 200);
      } else {
        causeOptions.value = [];
      }
    }

    function submitForm() {
      item.cost = parseFloat(parseFloat(item.cost).toFixed(2));
      item.tags = item.tags.join(' |=| ');
      item.causes = item.causes.join(' |=| ');
      item.maxDonation = parseFloat(parseFloat(item.maxDonation).toFixed(2));
      item.campaignType = item.campaignType ? item.campaignType : null;
      emit('save-item', item);
      emit('close-dialog');
    }

    function validateForm() {
      if (item.url) {
        refForm.value.validate((valid) => {
          if (valid) return submitForm();
          console.log('error submit!!');
          return false;
        });
      } else {
        error.message = 'Please upload a Sticker before continuing.';
      }
    }

    async function handleStickerUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.url = uploadStatus.resourceUrl;
        if (item.url) {
          error.message = null;
        }
      }
    }

    function handleSuccess() {
      console.log('succc');
    }

    function handleError() {
      console.log('error');
    }

    onMounted(async () => {
      await getBullhornsAsTagsList();
      await getCausesList('');
    });

    return {
      // data
      refForm,
      rules: {
        url: requiredRule('url'),
        title: requiredRule('title'),
        description: requiredRule('description'),
        tags: requiredRule('tags'),
        startDate: requiredRule('startDate'),
        endDate: requiredRule('endDate'),
      },
      item,

      // methods
      validateForm,
      beforeUpload,
      handleStickerUpload,
      handleSuccess,
      handleError,
      tagsList,
      tagsOptions,
      tagsLoading,
      causeList,
      causeOptions,
      causeLoading,
      getBullhornsAsTagsList,
      getTagsDropdownOptions,
      getCausesList,
      getCausesDropdownOptions,
      getBenevityCauseDetails,
      error,
      tagsArray,
      causeOptionsArray,
    };
  },
});
</script>
<style>
.el-select {
  display: block !important;
}
</style>
