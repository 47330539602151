<template lang="pug">
.text-center
  h4.max-w-300.mx-auto.mb-2 Thanks for your
    span.text-success  submission

  .mb-5 Keep changing the world.

  .text-center.pb-5
    router-link(:to="appStoreLink")
      img(src="@/assets/images/download-ios.png")

  .p-4.text-center
    el-button(link @click="gotoWebAppSignIn").ms-auto
      span.mb-5.before-button-text Use it on Browser?
      span.el-button.el-button--text &nbsp; SignIn
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import useOnboardingRoute from './composables/useUserOnboardingRoute';

export default defineComponent({
  name: 'Onboarding_Onboarded',
  setup() {
    useOnboardingRoute('onboarded');

    function gotoWebAppSignIn() {
      window.open(`${window.location.origin}/auth/web/signin`, '_blank');
    }

    const appStoreLink = import.meta.env.VITE_APP_APPLE_STORE_LINK;

    return {
      // data (dynamic)
      loading: ref(false),
      gotoWebAppSignIn,
      appStoreLink,
    };
  },
});
</script>
