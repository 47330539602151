<template lang="pug">
transition(name="fade" mode="out-in")
  component(:is="orgEdit")
</template>

<script>
import {
  defineComponent,
  computed,
} from 'vue';

import ManageContentOrgEdit from './OrgEdit.vue';
import OrgAdminOrgEdit from './OrgAdminOrgEdit.vue';
import shared from '../shared';

export default defineComponent({
  name: 'OrganizationEdit',
  components: { ManageContentOrgEdit, OrgAdminOrgEdit },
  setup() {
    const { isOrgAdmin } = shared();
    const orgEdit = computed(() => {
      if (isOrgAdmin()) {
        return 'OrgAdminOrgEdit';
      }
      return 'ManageContentOrgEdit';
    });
    return {
      orgEdit,
    };
  },
});
</script>
