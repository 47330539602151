import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref } from 'vue';
import { DocumentNode } from '@apollo/client/core';
import { DateTime } from 'luxon';
import { ElMessageBox, ElMessage } from 'element-plus';
import { makeRequest } from '@/graphs/index';
import axios from 'axios';

export default function globalShared(): { [key: string]: any } {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const iOSSafariCheckInterval = 500; //miliseconds
  const iOSSafariMaxTries = 20; //20 * 0.5 = 10 seconds

  function getAuthToken(): string {
    const { token } = store.getters['users/me'];
    return token;
  }

  function removeItemGetConfirm(toInvoke: () => void) {
    ElMessageBox.confirm('Are you sure that you want to delete?', 'Warning', { type: 'warning' })
      .then(async () => {
        await toInvoke();
        ElMessage.info('Successfully Deleted.');
      })
      .catch((ee) => {
        console.log(ee);
        ElMessage.info('Delete canceled');
      });
  }

  function getConfirm(message: string, toInvoke: () => void) {
    ElMessageBox.confirm(message, 'Warning', { type: 'warning' })
      .then(async () => {
        await toInvoke();
        ElMessage.info('Successfully completed.');
      })
      .catch((ee) => {
        console.log(ee);
        ElMessage.info('Cancelled');
      });
  }

  function showSuccessMessage(message: string) {
    ElMessage.success(message);
  }

  function dateAndTime(str: string): [string, string] {
    const t = DateTime.fromISO(str);
    return [
      t.toFormat('MM/dd/yyyy'),
      t.toFormat('hh:mm a'),
    ];
  }

  function dobDate(str: string): string {
    const t = DateTime.fromISO(str);
    // console.log(t.toISODate());
    return t.toFormat('MM/dd/yyyy');
  }

  async function makeGraphRequest(
    type: string, gql: DocumentNode,
    variables: { [key: string]: any },
    token: string | null,
  ): Promise<any> {
    const results = await makeRequest(type, gql, variables, token);
    if (results && results.errors) {
      if (results.errors[0].message === 'Unauthorized') {
        store.dispatch('users/resetMe');
        router.push('/auth');
      }
    }
    return results;
  }

  function isOrgAdmin(): boolean {
    return store.getters['users/isOrgAdmin'];
  }

  async function getRating(mobileOS: string): Promise<number | null>{

    const baseURL = import.meta.env.VITE_API_BASE_URL;

    try{
      let response = await axios.get(`${baseURL}/rating/${mobileOS}`);

      return Number(response.data["rating"]);
    } catch (_){
      console.error(_)
      return null
    }
  }

  function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent;

      if (/android/i.test(userAgent)) {
          return "Android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent)) {
          return "iOS";
      }

      return "unknown";
  }

  function isSafari() {
      var userAgent = navigator.userAgent;

      var iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
      var webkit = !!userAgent.match(/WebKit/i);
      var iOSSafari = iOS && webkit && !userAgent.match(/CriOS/i);

      return iOSSafari;
  }

  async function handleSafariPrompt(iteration: number = 0): Promise<boolean | null> {
      // keep returning promise while the condition is satisfied (document visible and unfocused, iterations below maximum)
      // iOS Safari will natively prompt the user if they want to open the sticker in the proposed App
      // while the safari dialog is active, the document is NOT hidden, and is NOT focused
      // wait until either the document is hidden (user got redirected to app)
      // or until document regained focus (user declined redirect)

      return new Promise(function(resolve, reject){
          //wait delay between each check
          setTimeout(resolve, iOSSafariCheckInterval)
      }).then(async function(){
          if(iteration > iOSSafariMaxTries){
          return null;
          }
          else if(!document.hidden && !document.hasFocus()){
          return await handleSafariPrompt(iteration + 1);
          }
          else {
          // determines if the redirection was successful (true) or not (false)
          return document.hidden;
          }
      });
  }



  return {
    ref,
    route,
    router,
    store,
    ElMessage,
    ElMessageBox,
    dateAndTime,
    dobDate,
    removeItemGetConfirm,
    getConfirm,
    makeGraphRequest,
    getAuthToken,
    isOrgAdmin,
    getRating,
    getMobileOperatingSystem,
    isSafari,
    handleSafariPrompt,
    showSuccessMessage
  };
}
