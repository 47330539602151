import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import AuthView from '@/layouts/Auth.vue';
import DashView from '@/layouts/Dash.vue';
import OnboardingView from '@/layouts/Onboarding.vue';
import UserOnboardingView from '@/layouts/UserOnboarding.vue';
import PaymentView from '@/layouts/Payment.vue';

import OrgDetail from '@/views/onboarding/OrgDetail.vue';
import VerifyCode from '@/views/onboarding/VerifyCode.vue';
import OrgType from '@/views/onboarding/OrgType.vue';
import AgreeRules from '@/views/onboarding/AgreeRules.vue';
import CausesBullhorns from '@/views/onboarding/Bullhorns.vue';
import Channels from '@/views/onboarding/Channels.vue';
import OrgValues from '@/views/onboarding/OrgValues.vue';
import WhyJoiningSpotlight from '@/views/onboarding/WhyJoiningSpotlight.vue';
import Onboarded from '@/views/onboarding/Onboarded.vue';

import UserDetail from '@/views/user-onboarding/UserDetail.vue';
import VerifyUserCode from '@/views/user-onboarding/VerifyCode.vue';
import AgreeUserRules from '@/views/user-onboarding/AgreeRules.vue';
import UserCauses from '@/views/user-onboarding/Bullhorns.vue';
import UserChannels from '@/views/user-onboarding/Channels.vue';
import UserValues from '@/views/user-onboarding/Values.vue';
import UserWhyJoiningSpotlight from '@/views/user-onboarding/WhyJoiningSpotlight.vue';
import UserOnboarded from '@/views/user-onboarding/Onboarded.vue';

import Authenticator from '@/views/Authenticator.vue';
import ForgotPassword from '@/views/forgot-password/ForgotPassword.vue';
import VerifyResetCode from '@/views/forgot-password/VerifyResetCode.vue';
import UpdatePassword from '@/views/forgot-password/UpdatePassword.vue';
import Dashboard from '../views/Dashboard.vue';

import ManageUsersAndOrganizations from '../views/manage-users-and-organizations/Index.vue';
import ManageUsers from '../views/manage-users-and-organizations/ManageUsers.vue';
import ManageOrganizations from '../views/manage-users-and-organizations/ManageOrganizations.vue';
import ManageUsersEdit from '../views/manage-users-and-organizations/UsersEdit.vue';
import ManageOrganizationsEdit from '../views/manage-users-and-organizations/org-edit/Index.vue';
import ConfirmOrgOnboard from '../views/manage-users-and-organizations/org-edit/ConfirmOrgOnboard.vue';
import OrgSticker from '../views/manage-users-and-organizations/org-edit/OrgSticker.vue';
import ManageOrgStickers from '../views/manage-users-and-organizations/org-edit/OrgStickersUpdated.vue';
import OrgPageAddSticker from '../views/manage-users-and-organizations/org-edit/OrgPageAddSticker.vue';
// sticker redesigned
import AddSticker from '../views/manage-content/media/add-sticker/AddSticker.vue';

import Invites from '../views/manage-users-and-organizations/Invites.vue';
import InviteDetail from '../views/manage-users-and-organizations/InviteDetail.vue';

import ManageBenevityClaim from '../views/manage-users-and-organizations/benevity-claim/index.vue';

import ManageContent from '../views/manage-content/Index.vue';

import ManageContentSpots from '../views/manage-content/spots/Index.vue';
import ManageContentSpotDetail from '../views/manage-content/spots/Detail.vue';

import ManageContentEvents from '../views/manage-content/events/Index.vue';
import ManageContentEventDetail from '../views/manage-content/events/Detail.vue';

import ActivityLogs from '../views/activity/ActivityLogs.vue';

// Directory
import ManageContentDirectory from '../views/manage-content/directory/Index.vue';

import ManageContentDirectoryBullhorns from '../views/manage-content/directory/bullhorns/Index.vue';
import ManageContentDirectoryBullhornDetail from '../views/manage-content/directory/bullhorns/Detail.vue';

import ManageContentDirectoryChannels from '../views/manage-content/directory/channels/Index.vue';
import ManageContentDirectoryChannelDetail from '../views/manage-content/directory/channels/Detail.vue';

import ManageContentDirectoryValues from '../views/manage-content/directory/values/Index.vue';
import ManageContentDirectoryValueDetail from '../views/manage-content/directory/values/Detail.vue';

import ManageContentDirectoryInterests from '../views/manage-content/directory/interests/Index.vue';
import ManageContentDirectoryInterestDetail from '../views/manage-content/directory/interests/Detail.vue';

import ManageContentDirectoryDictionary from '../views/manage-content/directory/dictionary/Index.vue';
import ManageContentDirectoryDictionaryDetail from '../views/manage-content/directory/dictionary/Detail.vue';

import ManageContentDirectorySegments from '../views/manage-content/directory/segments/Index.vue';
import ManageContentDirectorySegmentDetail from '../views/manage-content/directory/segments/Detail.vue';

import PaymentLogs from '../views/manage-content/payment-log/Index.vue';
import OrgPaymentLogs from '../views/manage-content/payment-log/OrgIndex.vue';
import PaymentLogDetails from '../views/manage-content/payment-log/Details.vue';

// Resources
import ManageResources from '../views/manage-resources/Index.vue';
import Todos from '../views/manage-resources/todo/Index.vue';
import Todo from '../views/manage-resources/todo/Todo.vue';

import Workflows from '../views/manage-resources/workflow/Index.vue';
import Workflow from '../views/manage-resources/workflow/Workflow.vue';

// Media
import ManageContentMedia from '../views/manage-content/media/Index.vue';

import ManageContentMediaStickers from '../views/manage-content/media/stickers/Index.vue';
import ManageContentMediaStickerDetail from '../views/manage-content/media/stickers/Detail.vue';
import PageAddSticker from '../views/manage-content/media/stickers/PageAddSticker.vue';

import ManageContentMediaLibrary from '../views/manage-content/media/library/Index.vue';
import ManageContentMediaLibraryItemDetail from '../views/manage-content/media/library/Detail.vue';
import ManageContentMediaHomePage from '../views/manage-content/media/home-page/Index.vue';
import ManageContentAddBullhorn from '../views/manage-content/add-item/AddBullhorn.vue';
import ManageContentAddChannel from '../views/manage-content/add-item/AddChannel.vue';
import ManageContentAddValue from '../views/manage-content/add-item/AddValue.vue';
import ManageContentAddInterest from '../views/manage-content/add-item/AddInterest.vue';

import Marketing from '../views/marketing/Index.vue';
import Civics from '../views/civics/Index.vue';

import Settings from '../views/Settings.vue';
import FileUpload from '../views/FileUpload.vue';

import authGuard from './authGuard';

import PaymentStatic from '../views/payments/PaymentStatic.vue';
import FinalizePayment from '../views/payments/FinalizePayment.vue';
import PaymentIntent from '../views/payments/PaymentIntent.vue';
import StickerPurchase from '../views/payments/StickerPurchase.vue';
import TicketPurchase from '../views/payments/TicketPurchase.vue';
import InitiateWalletPaymentIntent from '../views/payments/InitiateWalletPaymentIntent.vue';

import WebAppHome from '../views/web-app/home/WebAppHome.vue';
import WebAppDashboard from '../views/web-app/dashboard/WebAppDashboard.vue';
import WebAppSticker from '../views/web-app/sticker/StickerDetails.vue';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import WebAppStickerPublic from '../views/web-app/sticker/StickerDetailsPublic.vue';
import WebAppSignIn from '../views/web-app/auth/SignIn.vue';
import WebAppSignUp from '../views/web-app/auth/SignUp.vue';
import WebCompleteProfile from '../views/web-app/auth/CompleteProfile.vue';
import WebAppPurchasedStickers from '../views/web-app/sticker/PurchasedStickers.vue';
import ClaimRequestDetailsVue from '@/views/manage-users-and-organizations/benevity-claim/ClaimRequestDetails.vue';

const routes: Array<RouteRecordRaw>  = [
  // Route Examples
  // { path: '/', name: 'Home', component: Home, },
  {
    path: '/auth',
    name: 'AuthView',
    component: AuthView,
    children: [
      {
        path: '/sign-in',
        alias: '/auth',
        name: 'Authentpicator',
        component: Authenticator,
      },
      {
        path: '/auth/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },
      {
        path: '/auth/forgot-password/:userId/verify-resetcode',
        name: 'VerifyResetCode',
        component: VerifyResetCode,
      },
      {
        path: '/auth/forgot-password/update-password/:token',
        name: 'UpdatePassword',
        component: UpdatePassword,
      },
    ],
  }, {
    path: '/payment',
    name: 'PaymentView',
    component: PaymentView,
    children: [
      {
        path: ':transactionCode/make', name: 'FinalizePayment', component: FinalizePayment,
      },
      {
        path: '/payment-static', name: 'PaymentStatic', component: PaymentStatic,
      },
      {
        path: 'intent/user/:userId', name: 'PaymentIntent', component: PaymentIntent,
      },
      {
        path: 'intent/user/:userId/org/:orgId', name: 'OrgPaymentIntent', component: PaymentIntent,
      },
      {
        path: 'intent/user/:userId/sticker/:stickerId/cause/:causeId', name: 'StickerPurchase', component: StickerPurchase,
      },
      {
        path: 'intent/sticker/:stickerId/cause/:causeId/variants/:variantIds/quantities/:quantities', name: 'StickerVariant', component: StickerPurchase,
      },
      {
        path: '/payment/intent/sticker/:stickerId/cause/:causeId/variants/:variantIds/quantities/:quantities(.*)', component: StickerPurchase,
      },      
      {
        path: 'intent/user/:userId/org/:orgId/sticker/:stickerId/cause/:causeId/variants/:variantIds/quantities/:quantities',
        name: 'StickerVariant',
        component: StickerPurchase,
      },
      {
        path: 'intent/user/:userId/sticker/:stickerId/cause/:causeId/variants/:variantIds/quantities/:quantities', name: 'StickerVariantPurchase', component: StickerPurchase,
      },
      {
        path: 'intent/sticker/:stickerId/cause/:causeId', name: 'StickerPurchasePublic', component: StickerPurchase,
      },
      {
        path: 'intent/user/:userId/org/:orgId/sticker/:stickerId/cause/:causeId', name: 'OrgStickerPurchase', component: StickerPurchase,
      },
      {
        path: 'intent/initiatewallet/user/:userId/org/:orgId/walletPricing/:walletPricingId', name: 'InitiateWalletPaymentIntent', component: InitiateWalletPaymentIntent,
      },
    ],
  }, {
    path: '/onboarding',
    name: 'OnboardingView',
    component: OnboardingView,
    children: [
      { path: ':orgId/org-detail', name: 'OrgDetail', component: OrgDetail },
      { path: ':orgId/verify-code', name: 'VerifyCode', component: VerifyCode },
      { path: ':orgId/org-type', name: 'OrgType', component: OrgType },
      { path: ':orgId/agree-rules', name: 'AgreeRules', component: AgreeRules },
      { path: ':orgId/causes', name: 'CausesBullhorns', component: CausesBullhorns },
      { path: ':orgId/channels', name: 'Channels', component: Channels },
      { path: ':orgId/org-values', name: 'OrgValues', component: OrgValues },
      { path: ':orgId/why-joining-spotlight', name: 'WhyJoiningSpotlight', component: WhyJoiningSpotlight },
      { path: ':orgId/onboarded', name: 'Onboarded', component: Onboarded },
    ],
  }, {
    path: '/user-onboarding',
    name: 'UserOnboardingView',
    component: UserOnboardingView,
    children: [
      { path: ':inviteId/user-detail', name: 'UserDetail', component: UserDetail },
      { path: ':inviteId/verify-code', name: 'VerifyUserCode', component: VerifyUserCode },
      { path: ':inviteId/agree-rules', name: 'AgreeUserRules', component: AgreeUserRules },
      { path: ':inviteId/causes', name: 'UserCauses', component: UserCauses },
      { path: ':inviteId/channels', name: 'UserChannels', component: UserChannels },
      { path: ':inviteId/values', name: 'UserValues', component: UserValues },
      { path: ':inviteId/why-joining-spotlight', name: 'UserWhyJoiningSpotlight', component: UserWhyJoiningSpotlight },
      { path: ':inviteId/onboarded', name: 'UserOnboarded', component: UserOnboarded },
    ],
  },
  {
    path: '/',
    name: 'DashView',
    beforeEnter: authGuard,
    component: DashView,
    children: [
      // eslint-disable-next-line object-curly-newline
      { path: '/dashboard', alias: '/', name: 'Dashboard', component: Dashboard },
      {
        path: '/activity',
        name: 'ActivityLogs',
        component: ActivityLogs,
      },
      {
        path: '/activity/:id',
        name: 'ActivityLogsForOrganizations',
        component: ActivityLogs,
      },
      {
        path: '/manage',
        name: 'ManageUsersAndOrganizations',
        component: ManageUsersAndOrganizations,
        children: [
          { path: 'users', name: 'ManageUsers', component: ManageUsers },
          { path: 'users/:userId/edit', name: 'ManageUsersEdit', component: ManageUsersEdit },
          { path: 'orgs', name: 'ManageOrganizations', component: ManageOrganizations },
          { path: 'orgs-pending', name: 'ManagePendingOrganizations', component: ManageOrganizations },
          { path: 'orgs/:orgId/edit', name: 'ManageOrganizationsEdit', component: ManageOrganizationsEdit },
          { path: 'orgs/stripe-onboard', name: 'OrgStripeOnboard', component: ConfirmOrgOnboard },
          { path: 'orgs/:orgId/stickers/:id', name: 'ManageOrgSticker', component: OrgSticker },
          { path: 'invites', name: 'Invites', component: Invites },
          { path: 'invites/:inviteId/info', name: 'InviteDetail', component: InviteDetail },
          { path: 'orgs/:orgId/stickers', name: 'ManageOrgStickers', component: ManageOrgStickers },
          { path: 'orgs/:orgId/add-stickers', name: 'AddOrgSticker', component: OrgPageAddSticker },
          { path: 'orgs/:orgId/payment-logs', name: 'Org_Payment_Logs', component: OrgPaymentLogs },
          { path: 'benevity-claim', name: 'BenevityClaim', component: ManageBenevityClaim },
          { path: 'benevity-claim/:claimRequestId', name: 'BenevityClaimDetail', component: ClaimRequestDetailsVue },
        ],
      },

      // ManageContent
      {
        path: '/manage-content',
        name: 'ManageContent',
        component: ManageContent,
        children: [
          { path: 'spots', name: 'ManageContent_Spots', component: ManageContentSpots },
          { path: 'spots/:id', name: 'ManageContent_SpotDetail', component: ManageContentSpotDetail },

          { path: 'events', name: 'ManageContent_Events', component: ManageContentEvents },
          { path: 'events/:id', name: 'ManageContent_EventDetail', component: ManageContentEventDetail },

          // { path: 'payment-logs', name: 'Payment_Logs', component: PaymentLogs },
          { path: 'payment-log/details', name: 'Payment_Log_Details', component: PaymentLogDetails },
        ],
      },
      {
        path: '/manage-content/directory',
        name: 'ManageContent_Directory',
        component: ManageContentDirectory,
        children: [
          { path: 'bullhorns', name: 'ManageContent_Directory_Bullhorns', component: ManageContentDirectoryBullhorns },
          { path: 'bullhorns/:id', name: 'ManageContent_Directory_BullhornDetail', component: ManageContentDirectoryBullhornDetail },

          { path: 'channels', name: 'ManageContent_Directory_Channels', component: ManageContentDirectoryChannels },
          { path: 'channels/:id', name: 'ManageContent_Directory_ChannelDetail', component: ManageContentDirectoryChannelDetail },

          { path: 'values', name: 'ManageContent_Directory_Values', component: ManageContentDirectoryValues },
          { path: 'values/:id', name: 'ManageContent_Directory_ValueDetail', component: ManageContentDirectoryValueDetail },

          { path: 'interests', name: 'ManageContent_Directory_Interests', component: ManageContentDirectoryInterests },
          { path: 'interests/:id', name: 'ManageContent_Directory_InterestDetail', component: ManageContentDirectoryInterestDetail },

          { path: 'dictionary', name: 'ManageContent_Directory_Dictionary', component: ManageContentDirectoryDictionary },
          { path: 'dictionary/:id', name: 'ManageContent_Directory_DictionaryDetail', component: ManageContentDirectoryDictionaryDetail },

          { path: 'segments', name: 'ManageContent_Directory_Segments', component: ManageContentDirectorySegments },
          { path: 'segments/:id', name: 'ManageContent_Directory_SegmentDetail', component: ManageContentDirectorySegmentDetail },
        ],
      },
      {
        path: '/manage-resources',
        name: 'ManageResources',
        component: ManageResources,
        children: [
          { path: 'todos', name: 'Todos', component: Todos },
          { path: 'todos/:id', name: 'Todo', component: Todo },
          { path: 'workflows', name: 'Workflows', component: Workflows },
          { path: 'workflows/:id', name: 'Workflow', component: Workflow },
        ],
      },
      {
        path: '/manage-content/media',
        name: 'ManageContent_Media',
        component: ManageContentMedia,
        children: [
          { path: 'stickers', name: 'ManageContent_Media_Stickers', component: ManageContentMediaStickers },
          { path: 'add-stickers', name: 'ManageContent_Page_AddSticker', component: PageAddSticker },
          { path: 'add-stickers-v2', name: 'AddSticker', component: AddSticker },
          { path: 'stickers/:id', name: 'ManageContent_Media_StickerDetail', component: ManageContentMediaStickerDetail },
          { path: 'stickers-v2/:id', name: 'EditStickers', component: AddSticker },

          { path: 'library', name: 'ManageContent_Media_Library', component: ManageContentMediaLibrary },
          { path: 'library/:id', name: 'ManageContent_Media_LibraryItemDetail', component: ManageContentMediaLibraryItemDetail },

          { path: 'home-page', name: 'ManageContent_Media_HomePage', component: ManageContentMediaHomePage },
          { path: 'add-bullhorn', name: 'ManageContent_Add_Bullhorn', component: ManageContentAddBullhorn },
          { path: 'add-channel', name: 'ManageContent_Add_Channel', component: ManageContentAddChannel },
          { path: 'add-value', name: 'ManageContent_Add_Value', component: ManageContentAddValue },
          { path: 'add-interest', name: 'Interest', component: ManageContentAddInterest },

        ],
      },

      { path: '/marketing', name: 'Marketing', component: Marketing },
      { path: '/civics', name: 'Civics', component: Civics },
      { path: '/settings', name: 'Settings', component: Settings },
      { path: '/fileupload', name: 'FileUpload', component: FileUpload },
      // {
      //   path: '/about',
      //   name: 'About',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../views/about.vue'),
      // },
      // {
      //   path: '/web',
      //   children: [
          { path: '/web/', name: 'WebAppHome', component: WebAppHome },
          { path: '/web/dashboard', name: 'WebAppDashboard', component: WebAppDashboard },
          { path: '/web/stickers/purchased', name: 'WebAppPurchasedStickers', component: WebAppPurchasedStickers },
          { path: '/web/sticker/:stickerId', name: 'WebAppSticker', component: WebAppSticker },
      //   ],
      // },
      { path: '/auth/web/signin', name: 'WebAppSignIn', component: WebAppSignIn },
      { path: '/auth/web/signup', name: 'WebAppInvite', component: WebAppSignUp },
      { path: '/auth/web/complete-profile', name: 'CompleteProfile', component: WebCompleteProfile },
      // children property is required to avoid Type error
      { path: '/auth/web/sticker/:stickerId', name: 'WebAppStickerPublic', component: WebAppStickerPublic,
      redirect: to => ({
        name: "WebAppSticker",
      }),
      children: [], },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeResolve(authGuard);

router.beforeEach((to, from) => {
  const id = from.params.id ?? from.params.orgId
  if (to.name === 'AddOrgSticker' && to.params.orgId === 'ORG_ID' && id) {
    router.push(`/manage/orgs/${id}/add-stickers`);
  }
  if (to.name === 'ManageOrgStickers' && to.params.orgId === 'ORG_ID' && id) {
    router.push(`/manage/orgs/${id}/stickers`);
  }
  if (to.name === 'Org_Payment_Logs' && to.params.orgId === 'ORG_ID' && id) {
    router.push(`/manage/orgs/${id}/payment-logs`);
  }
  if (to.params.orgId === 'ORG_ID' && !id) {
    //console.log('missing org id');
  }
});

export default router;
