<template lang="pug">
  
app-main-content
    router-view(v-slot="{ Component }")
      transition(name="fade" mode="out-in")
        div(:key="$route.name")
          component(:is="Component")
          //- views render here
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { dashboard as menuItems } from '@/data/main-menu';

export default defineComponent({
  name: 'ManageContent_Media',
  setup() {
    // moutned
    // re-routing the user - kinda hack to set default route
    if (useRoute().path === '/manage-content/media') {
      useRouter().push('/manage-content/media/stickers');
    }

    return {
      // data
      menuItems,
    };
  },
});
</script>
