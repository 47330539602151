<template lang="pug">
draggable(
v-model='item.varients'
@choose='dragChoose'
@unchoose='dragUnchoose'
@start='dragStart'
@end='dragEnd'
@change='changeEvent'
item-key='sortOrder'
handle='.icon-bars'
).list-group.list-group-flush
  template(#item='{ element, index }')
    .list-group-item
      .row.row-sm.align-items-center.flex-nowrap
        .col-auto.ps-0.icon-bars
          svg.size-14.cursor-move
            use(href='#fas-grip-vertical')
        .col-auto.pt-1 {{ element.sortOrder + 1 }}.
        .col-auto
          el-input(v-model="element.title" @change="addPrice(element.sortOrder)" placeholder="Add another")
        .col-auto
          el-input(
            v-model="element.cost"                            
            placeholder="Price $"
            type="number"                           
            ).max-w-100
      .row.mt-2.ml-35p
        .col-auto.flex-1
            el-input(
              v-model="element.description"
              placeholder="Description (max 150 chars)"
              type="Text"
              :maxlength="150"
              ).w-100
        .col-auto
          el-button(v-if="item.varients.length > 1 && element.sortOrder === item.varients.length - 2" type="danger" circle @click="removePrice()")
            i.el-icon.el-icon-delete
</template>

<script>
import {
  defineComponent, reactive, onMounted, toRef, ref
} from 'vue';
import draggable from 'vuedraggable';
import shared from '../shared';


export default defineComponent({
  name: 'CostVarientsList',
  components: {
    draggable,
  },
  props: {
    loading: { type: Boolean, default: false },
    item: { type: Object, default: () => ({}) },
  },
  emits: ['emitAddPrice', 'emitRemovePrice'],
  setup(props, { emit }) {
    const { loading } = shared();
    const state = reactive({
      drag: null,
    });

    const addPrice = (sortOrder) => {
      emit('emitAddPrice',sortOrder);
    }

    const removePrice = () => {
      emit('emitRemovePrice');
    }
    const changeEvent = (element) => {;
      emit('emitChangeEvent', element);
    }

    return {
      filter: toRef(state, 'filter'),
      items: toRef(state, 'items'),
      addPrice,
      removePrice,
      changeEvent
    };
  },
});

</script>
