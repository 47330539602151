<template lang="pug">
el-dialog(
  title="Add Todo"
  top="5vh" width="100%"
  class="el-dialog__max-w-700"
  :close-on-click-modal="false"
)
  el-form(
    ref="refForm",
    :rules="rules",
    :model="item"
    status-icon
    label-position="top"
  )
    .row
      .col
        el-form-item(
          label="Todo"
          prop="todo"
        )
          el-input(v-model="item.todo" type="textarea")
    .row
      .col
        el-form-item(
          label="Protip"
          prop="protip"
        )
          el-input(v-model="item.protip" type="textarea")
  template(#footer)
    el-button(type="success" @click="validateForm" v-loading="loading") Add Todo

</template>

<script>
import {
  defineComponent,
  ref, reactive,
} from 'vue';
// import shared from './shared';
import { requiredRule } from '@/data/rules';

export default defineComponent({
  name: 'DialogAddTodo',
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['close-dialog', 'save-item'],
  setup(_, { emit }) {
    const refForm = ref(null);
    const item = reactive({
      id: null,
      todo: '',
      protip: '',
    });
    const rules = {
      todo: requiredRule('todo'),
      protip: requiredRule('protip'),
    };

    function submitForm() {
      emit('save-item', item);
      emit('close-dialog');
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) return submitForm();
        console.log('error on submiit!!');
        return false;
      });
    }
    return {
      refForm,
      rules,
      item,
      validateForm,
    };
  },
});

</script>
