<template lang="pug">
web-app-layout(:title='"Marketplace"')
  div
    .px-30.py-4
    div.input-wrapper(style="height: 48px;")
        el-input(
        v-model="state.searchTerm"
        placeholder="Search Stickers"
        style="height: 100%; width:calc(100% - 50px); --el-input-bg-color: #edf3f7;"
        @keyup="searchStickers()"
            ).input-search-filter
          template(#prefix ='')
            img(
              src="@/assets/icons/searchIcon.svg",
              style="font-size: 20px;")
        img(
          src="@/assets/icons/filterIcon.svg",
          style="margin-left:15px;cursor: pointer; height: 20px; filter: grayscale(50%);"
          @click="openFilterModal"
        )
  stickers-grid-template(:stickers="state.filteredStickers")
  el-dialog(
    center=true
    top="5vh" width="100%"
    class="el-dialog__max-w-700"
    title="Filter Stickers"
    v-model="state.modalVisible"
    @close="resetFilters"   
  )
    label.px-20
      el-checkbox(v-model="state.freeFilter") Free
    label.px-20 
      el-checkbox(v-model="state.paidFilter") Paid
    label.px-20
      el-checkbox(v-model="state.campaignFilter") Campaign
    hr
    el-button(type="success" @click="applyFilters") Apply Filters

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import StickersGridTemplate from '../sticker/StickersGridTemplate.vue';
import gqlRequest from './gql-request';
import WebAppLayout from './WebAppLayout.vue';
export default defineComponent({
  name: 'WebAppDashboard',
  components: { WebAppLayout, StickersGridTemplate },
  setup() {
    const {
      route, router, loading, getPublishedStickers, getPublishedStickersWithFiltering,
    } = gqlRequest();
    const state = reactive({ 
      stickers: <any[]>([]),
      filteredStickers: <any[]>([]),
      searchTerm:'',
      modalVisible: false,
      freeFilter: false,
      paidFilter: false,
      campaignFilter: false,
     });

    async function getStickers() {
      const stickers = await getPublishedStickers();
      Object.assign(state.stickers, stickers);
      Object.assign(state.filteredStickers, stickers);
    }

    
    function searchStickers() {
      // Filter stickers based on the search term
      const searchTerm = state.searchTerm.toLowerCase();
      const newListofStickers = state.stickers.filter(sticker =>        
        sticker.title !== null && sticker.title !== 'null' && sticker.title !== undefined && sticker.title !== '' && sticker.title.toLowerCase().includes(searchTerm)      
      );
      state.filteredStickers = <any[]>([]);
      Object.assign(state.filteredStickers, newListofStickers);
    }    
    function openFilterModal() {
      state.modalVisible = true;
    }

    async function applyFilters() {
      const filter = [];      
      if(state.campaignFilter){
        filter.push("Campaign");
      }
      if(state.paidFilter){
        filter.push("Paid");
      }
      if(state.freeFilter){
        filter.push("Free");
      }
      const stickers = await getPublishedStickersWithFiltering(filter);
      state.stickers = <any[]>([]);
      state.filteredStickers = <any[]>([]);
      Object.assign(state.stickers, stickers);
      Object.assign(state.filteredStickers, stickers);
      state.modalVisible = false;
    }

    onMounted(async () => {
      loading.value = true;
      await getStickers();
      loading.value = false;
    });

    return {
      route,
      router,
      loading,
      // data
      state,
      searchStickers,
      openFilterModal,
      applyFilters,
    };
  },
});
</script>