<template lang="pug">
web-app-layout(:showLogo="true")
  div(v-if="!loading")
    Carousel(:items-to-show="1" wrapAround=true)
      Slide(v-for="slide in state.slides" :key="slide")
        img(:src="slide.featuredImage").border.rounded.overflow-hidden.w-100.obj-f-c

    .row.mb-4.mt-2
      .col-6
        el-button(type="success" color="#00BFB0" size="large" class="full-width" @click="openMobileAppLink") Setup Profile
      .col-6
        el-button(type="success" color="#00BFB0" size="large" class="full-width" @click="openMobileAppLink") Create Wallet
    h2.section-caption Featured sticker campaigns
    stickers-grid-template(:stickers="state.stickers")
</template>
    
<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import StickersGridTemplate from '../sticker/StickersGridTemplate.vue';
import gqlRequest from '../dashboard/gql-request';
import WebAppLayout from '../dashboard/WebAppLayout.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default defineComponent({
  name: 'WebAppHome',
  components: { WebAppLayout, StickersGridTemplate, Carousel, Slide },
  setup() {
    const {
      route, router, loading, fetchHomeScreenAppData,
    } = gqlRequest();
    const state = reactive({ stickers: [], slides: [] });

    async function getStickers() {
      const homeScreenData = await fetchHomeScreenAppData();
      state.stickers = homeScreenData.stickersData;
      state.slides = homeScreenData.sliderData;
    }

    onMounted(async () => {
      loading.value = true;
      await getStickers();
      loading.value = false;
    });

    return {
      route,
      router,
      loading,
      // data
      state,
    };
  },
  methods: {
    openMobileAppLink() {
      const appStoreUrl = 'https://apps.apple.com/us/app/spotlight-xyz/id1566652200';
      const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.spotlight.xyz';

      const userAgent = navigator.userAgent;

      // Check for Android
      if (/android/i.test(userAgent)) {
        window.open(playStoreUrl, '_blank');
      }
      // Check for iOS
      else if (/iPad|iPhone|iPod/.test(userAgent)) {
        window.open(appStoreUrl, '_blank');
      }
      // Fallback
      else {
        window.open(appStoreUrl, '_blank');
      }
    }
  },
});
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}

.section-caption {
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

/* Carousel overrides */
.carousel__slide {
    padding: 0px !important;
}

.deleteIconHomePage
{
  display: none;
}
</style>
    