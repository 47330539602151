<!-- eslint-disable max-len -->
<template lang="pug">
.px-3
  .max-w-300.mx-auto.mb-4
    .d-flex.mb-4
      div
        .fs-22.text-primary.lh-n Wallet Subscription
        .fs-12 {{ userName() }}
      .ms-auto
        img(:src="avatar()").size-48.obj-f-c.rounded-circle

    .row.row-xs.align-items-center.margin-bottom-10
      .col.lh-1
        .ms-auto {{ walletPricingDetails.walletType }}
        .fs-12 {{ walletPricingDetails.employeesRange }} Employees
      .col-auto
        .ms-auto ${{ walletPricingDetails.cost }}

    .row.row-xs.align-items-center(v-if='isMarketPlaceUpgradeEnabled.value')
      .col.lh-1
        .ms-auto MarketPlace Upgrade
        .fs-12 annual subscription
      .col-auto
        .ms-auto ${{ curatedMarketPlaceUpgrade.cost }}

    .row.row-xs.align-items-center.walletPricingMarketPlaceUpgradeCard(
      v-if='isMarketPlaceCardEnabled && isEditable'
    )
      .d-flex.marketPlaceUpgradeCardTitle
        .fs-14.fc-white MarketPlace Upgrade
        .ms-auto.marketPlaceUpgradeCardCloseBtn
          el-button(link round, @click.stop='hideMaketPlaceUpgradeCard()') X
      .fs-24.lh-n.fc-white.margin-bottom-10 + ${{ curatedMarketPlaceUpgrade.cost }}/year
      .row.align-items-center(v-for="feature in curatedMarketPlaceUpgrade.features")
        .fs-12.fc-white.margin-bottom-10 ✓ {{ feature }}
      div.margin-top-10
        el-button(link
          round @click="enableMaketPlaceUpgrade()").w-100.mb-2.addUpgradeBtn
          | Add Upgrade

    .max-w-300.mx-auto.margin-bottom-10
     div
       .fs-12.lh-2.paymentText
        | By subscribing to either Giving Wallet or Marketplace, I accept Spotlight’s Terms and Conditions, Privacy Policy, Collective Rules, Fees, and Boneyard Marketplace Policy. The payment is non-refundable.
</template>

<script>
import {
  ref,
  defineComponent,
  onMounted,
} from 'vue';

export default defineComponent({
  name: 'InitiateWalletInputForm',
  props: {
    isEditable: { type: Boolean, default: false },
    userDetails: { type: Object, default: () => ({}) },
    showForm: { type: Boolean, default: true },
    walletPricingDetails: { type: Object, default: () => ({}) },
    curatedMarketPlaceUpgrade: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: true },
    amt: { type: Object, default: () => ({}) },
    isMarketPlaceUpgradeEnabled: { type: Object, default: () => ({}) },
  },

  setup(props) {
    const isMarketPlaceCardEnabled = ref(true);

    function userName() {
      if (this.userDetails.organizationId) {
        return this.userDetails.organizationName;
      }
      if (this.userDetails.id) {
        return `${this.userDetails.firstname} ${this.userDetails.lastname}`;
      }
      return '';
    }

    function avatar() {
      if (this.userDetails.organizationId && this.userDetails.organizationAvatar) {
        return this.userDetails.organizationAvatar;
      }
      if (this.userDetails.id && this.userDetails.userAvatar) {
        return this.userDetails.userAvatar;
      }
      return '@/assets/images/default-profile.jpeg';
    }

    function cancelIntent() {
      window.location.href = `${import.meta.env.VITE_APP_REDIRECT_BASE_URL}?action=initiateWalletCancel`;
    }

    function openBoneyardMarketplaceTerms() {
      window.open(`${window.location.origin}/rules/boneyard-marketplace-terms.html`, '_blank');
    }

    function hideMaketPlaceUpgradeCard() {
      isMarketPlaceCardEnabled.value = false;
    }

    // function showMaketPlaceUpgradeCard() {
    //   isMarketPlaceCardEnabled.value = true;
    // }

    function enableMaketPlaceUpgrade() {
      isMarketPlaceCardEnabled.value = false;
      // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
      props.isMarketPlaceUpgradeEnabled.value = true;
      // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
      props.amt.value += parseFloat(parseFloat(props.curatedMarketPlaceUpgrade.cost).toFixed(2));
    }

    onMounted(async () => {
      // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
      // props.amt.value = parseFloat(parseFloat(props.walletPricingDetails.cost).toFixed(2));
    });

    return {
      userName,
      cancelIntent,
      avatar,
      hideMaketPlaceUpgradeCard,
      isMarketPlaceCardEnabled,
      enableMaketPlaceUpgrade,
      openBoneyardMarketplaceTerms,
    };
  },
});

</script>
