<template lang="pug">
el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(title='Organization Details' back-to='/manage/orgs')
    template(#actions)
      el-button(type="info").has-fai
        fai(type="fal" icon="edit").size-18.me-2
        span.min-w-110.d-inline-block Send Message
      el-button(type="primary" @click="validateForm" v-loading="loading").has-fai
        fai(type="fal" icon="edit").size-18.me-2
        span.min-w-110.d-inline-block Save

    template(#col-left)
      div
        .row
          .col-md-6
            el-form-item(label="Organization Name" prop="name")
              el-input(v-model="item.name")
          .col-md-6(v-if="item.stripe_onboarded")
            el-form-item(label="Payment Integration" prop="stripe_onboarded")
              el-input(v-model="item.isStripeOnboarded"
                prefix-icon="stripe-wordmark"
                suffix-icon="el-icon-circle-close" circle
                @click="removeStripeConnection"
                readonly)

        el-form-item
          template(#label)
            | About
            el-tooltip(
              v-if="item.organization_type !== 'NONPROFIT'"
              effect="customized"
              content="Introduce your brand and primary reason for existing. \
                Consider leaning into your brand voice to include the founding backstory,\
                your vision for tomorrow, and why your fruits of labor matter."
              placement="top"
              popper-class="max-w-350"
            )
              fai(type="fal" icon="info-circle").size-18.ms-2
          el-input(v-model="item.about" type="textarea")

        el-form-item
          template(#label)
            //- earlier the label was `Our Purpose`
            | Our Ethos
            el-tooltip(
              v-if="item.organization_type !== 'NONPROFIT'"
              effect="customized"
              :content='`Define your brand\'s "purpose statement" to capture your catalyst, \
                commitment, and responsibility. Consider including what you want to accomplish, \
                who that will be for, and how you plan to make stuff better.`'
              placement="top"
              popper-class="max-w-350"
            )
              fai(type="fal" icon="info-circle").size-18.ms-2
          el-input(v-model="item.purpose" type="textarea"  maxlength="125" show-word-limit)

      div
        .row
          .col-md-6
            h5 Avatar
            el-upload(
                :show-file-list="false"
                :on-success="handleSuccess"
                :on-error="handleError"
                :before-upload="beforeUpload"
                :http-request="handleAvatarUpload"
                v-loading="uploading.avatar"
                action=""
            ).img-uploader.pos-r.mb-4
                template(v-if="item.avatar")
                  img(:src="item.avatar").uploaded-image
                  .pin.pin-bx.d-flex.p-2
                    el-button(type="primary").ms-auto Change
                template(v-else)
                  img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
                  p.upload-picture-text Drop an image file here or
                  div.fake-upload-button Browse For File

          .col-md-6
            h5 Background
            el-upload(
                :show-file-list="false"
                :on-success="handleSuccess"
                :on-error="handleError"
                :before-upload="bUploads.beforeUpload"
                :http-request="handleBackgroundUpload"
                v-loading="uploading.background"
                action=""
            ).img-uploader.pos-r.mb-4
                template(v-if="item.background")
                  img(:src="item.background").uploaded-image
                  .pin.pin-bx.d-flex.p-2
                    el-button(type="primary").ms-auto Change
                template(v-else)
                  img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
                  p.upload-picture-text Drop an image file here or
                  div.fake-upload-button Browse For File
      div
        user-common-sections(:item="item")

      div(v-if="item.is_creator")
        .row
          org-stickers(:stripe_onboarded="item.stripe_onboarded")

    template(#col-right)
      div
        el-form-item(label="Category")
          .p-1.bg-light.rounded
            .el-tag-wrapper
              el-tag(
                type="info" :key="index"
                v-for="(category, index) in item.categories"
              ) {{ category }}

        el-form-item(label="Organization Type")
          el-input(v-model="item.orgType" disabled)

        el-form-item(label="Email")
          el-input(v-model="item.email" type="email" disabled)

        el-form-item(label="Date Established")
          el-date-picker(
            v-model="item.estdDate"
            type="date"
            placeholder="MM / DD/ YYYY"
            format="MM/DD/YYYY"
            disabled
          ).w-100

        el-form-item(label="Referral Code Used")
          el-input(v-model="item.referralCodeUsed" disabled)

        el-form-item
          template(#label) Created
            span.text-muted.ms-2
          el-input(v-model="item.createdAt" disabled)

        el-form-item(v-if="item.admins.length")
          template(#label) Admins
            //- el-button(link @click="item.isAddAdmin = !item.isAddAdmin").ms-auto
            //-      | Add User
          .py-1.px-2.bg-light.rounded
            .row.row-xs.align-items-center(v-for="admin in item.admins" :key="admin.id")
              .col-auto
                img(:src="admin.avatar"
                  v-if="admin.avatar").d-block.size-32.obj-f-c.rounded-circle
                img(src="@/assets/images/default-profile.jpeg"
                  v-if="!admin.avatar").d-block.size-32.obj-f-c.rounded-circle
              .col.lh-1 {{ admin.firstname }} {{ admin.lastname }}
              .col-auto
                el-button(
                  link
                  :disabled="item.admins.length == 1"
                  @click="removeAdminUserFromOrg(admin.id, $event)").ms-auto
                  | Remove
              .col-auto
                el-button(link @click="viewUser(admin.id, $event)").ms-auto
                  | View Details

            el-button(link @click="item.isAddAdmin = !item.isAddAdmin").ms-auto
                  | Add User

            el-form-item(v-if="item.isAddAdmin").margin-bottom-10
              template(#label)
                .col-md-9
                  el-input(v-model="item.adminEmail" placeholder="ex: abcd@spotlight.com")
                .col-md-3
                  el-button(type="primary" @click="addAdminUserToOrg").margin-left-10
                    span.d-inline-block Add
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
} from 'vue';
import { mask } from 'vue-the-mask';
import { requiredRule } from '@/data/rules';
import { bullhorns, orgValues, channels } from '@/data/onboarding-static';
import fileUpload from '@/shared/upload';
import UserCommonSections from '../UserCommonSections.vue';
import OrgStickers from './OrgStickers.vue';
import shared from '../shared';

const itemData = {
  name: '',
  bullhorns,
  about: '',
  purpose: '',
  allies: bullhorns,
  values: orgValues,
  channels,
  interests: [],
  accountStatus: '1',
  categories: [],
  orgType: '501(c)',
  location: '90068  Los Angeles, CA',
  email: 'emailname@provider.com',
  phone: '(123) 456-789',
  estdDate: new Date(),
  twoFactorAuthentication: 'Phone',
  personalReferralCode: '94le384ngle',
  betaTester: 'Yes',
  referralCodeUsed: '234uf22iMX3',
  avatar: '',
  background: '',
  createdAt: '',
  primaryAdmin: {
    name: 'FirstName LastName',
    imgUrl: '//picsum.photos/200/300?random=2',
  },
  adminEmail: '',
  isAddAdmin: false,
  admins: [],
  isStripeOnboarded: '',
};

export default defineComponent({
  name: 'ManageContentOrgEdit',
  components: { UserCommonSections, OrgStickers },
  directives: { mask },

  setup() {
    const {
      route,
      router,
      loading,
      getOrganization,
      dateAndTime,
      dobDate,
      doUpdateOrgProfile,
      addAdminUserToOrgRequest,
      removeAdminUserFromOrgRequest,
      reqRemoveStripeAccount,
      getConfirm,
    } = shared();
    const bUploads = fileUpload('backgrounds', 'Background');
    const { uploadStatus, beforeUpload, handleUploadRequest } = fileUpload('avatars', 'Avatar');

    const { orgId } = route.params;
    const item = reactive(itemData);
    const refForm = ref(null);
    const uploading = reactive({
      avatar: false,
      background: false,
    });
    async function getOrganizationDetails() {
      const organization = await getOrganization(orgId);
      item.name = organization.name;
      item.about = organization.about;
      item.purpose = organization.purpose;
      item.bullhorns = organization.bullhorns;
      item.values = organization.values;
      item.channels = organization.channels;
      item.email = organization.email;
      item.background = organization.background;
      item.avatar = organization.avatar;
      item.categories = organization.organizationTypes;
      item.estdDate = dobDate(organization.dob);
      item.active = organization.active;
      item.is_creator = organization.is_creator;
      item.stripeAccountid = organization.stripeAccountid;
      item.stripe_onboarded = organization.stripe_onboarded;
      [item.createdAt] = dateAndTime(organization.createdAt);
      if (item.categories.length > 0) {
        item.orgType = organization.organizationTypes.join(',');
      }
      item.referralCodeUsed = organization.inviteCode;
      item.primaryAdmin = organization.primaryAdmin;
      item.admins = organization.admins;
      item.isStripeOnboarded = item.stripe_onboarded ? 'Connected' : 'Not Connected';
      item.interests = organization.interests;
    }

    onMounted(async () => {
      await getOrganizationDetails();
    });

    async function removeStripeConnection() {
      const msg = 'Do you wish to remove your stripe account connection. Are you Sure?';
      getConfirm(msg, async () => {
        loading.value = true;
        const organizationId = orgId;
        await reqRemoveStripeAccount({
          orgId: organizationId,
        });
        await getOrganizationDetails();
        loading.value = false;
      });
    }

    async function updateProfileForOrg() {
      loading.value = true;
      await doUpdateOrgProfile(orgId, {
        name: item.name,
        about: item.about,
        purpose: item.purpose,
        avatar: item.avatar,
        background: item.background,
      });
      await getOrganizationDetails();
      loading.value = false;
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) {
          await updateProfileForOrg();
        }
        return false;
      });
    }

    function viewUser(id, event) {
      event.preventDefault();
      router.push(`/manage/users/${id}/edit`);
    }

    async function handleAvatarUpload(payload) {
      uploading.avatar = true;
      await handleUploadRequest(payload);
      uploading.avatar = false;
      if (uploadStatus.status === 'ok') {
        item.avatar = uploadStatus.resourceUrl;
      }
    }

    async function handleBackgroundUpload(payload) {
      uploading.background = true;
      await bUploads.handleUploadRequest(payload);
      uploading.background = false;
      if (bUploads.uploadStatus.status === 'ok') {
        item.background = bUploads.uploadStatus.resourceUrl;
      }
    }

    async function addAdminUserToOrg() {
      loading.value = true;
      const organizationId = orgId;
      const result = await addAdminUserToOrgRequest({
        orgId: organizationId,
        userEmail: item.adminEmail,
      });
      if (result.addAdminUserToOrg === true) {
        item.isAddAdmin = false;
        item.adminEmail = '';
      }
      await getOrganizationDetails();
      loading.value = false;
    }

    async function removeAdminUserFromOrg(id, event) {
      event.preventDefault();
      loading.value = true;
      const organizationId = orgId;
      await removeAdminUserFromOrgRequest({
        orgId: organizationId,
        userId: id,
      });
      await getOrganizationDetails();
      loading.value = false;
    }

    function handleSuccess() {
      console.log('success');
    }

    function handleError() {
      console.log('error');
    }

    return {
      // data
      loading,
      rules: {
        name: requiredRule('Name'),
      },
      item,
      refForm,
      // methods
      validateForm,
      viewUser,
      beforeUpload,
      handleSuccess,
      handleError,
      handleAvatarUpload,
      handleBackgroundUpload,
      bUploads,
      uploading,
      addAdminUserToOrg,
      removeAdminUserFromOrg,
      removeStripeConnection,
    };
  },
});
</script>

<style lang="scss" scoped>
.uploaded-image {
  object-fit: cover;
  height: 20vh;
  width: 100%;
}
</style>
