<template>
  <div>
    <div class="text-center mb-5">
      <h3>
        We Sent You A
        <span class="text-success">Code</span>
      </h3>
      <div>
        {{ getText() }}
      </div>
    </div>

    <div class="row input-text-center">
      <div
        class="col"
        v-for="i in 6"
        :key="i"
      >
        <input
          :ref="refInputs[`input${i}`]"
          v-model="item[`input${i}`]"
          maxlength="1"
          @keyup="setItemFocus($event, i + 1)"
          class="el-input__inner"
        />
      </div>
    </div>

    <div
      v-if="invalidCode"
      class="alert alert-danger text-center margin-top-20"
    >
      Invalid Code
    </div>

    <div class="p-4 text-center">
      <p class="mb-5">
        Didn't receive the code?
        <el-button link @click="resendCode" class="ms-2">Resend</el-button>
      </p>
      <el-button
        round
        type="success"
        @click="validateCode"
        v-loading="loading"
        :disabled="btnNextDsiabled"
        class="c-button-next"
      >
        Next
      </el-button>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  defineComponent,
  onMounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { makeRequest as makeGraphRequest } from '@/graphs/index';
import {
  verifyPhoneNumber,
  verifyEmailOtp,
  resendPhoneCode,
  resendEmailCode,
  checkUserExists,
} from '@/graphs/gql';

export default defineComponent({
  name: 'VerifyResetCode',
  setup() {
    const loading = ref(false);
    const invalidCode = ref(false);
    const router = useRouter();
    const route = useRoute();
    const { userId } = route.params;
    const { authType } = route.query;
    let phone = '';
    let email = '';

    const refInputs = {
      input1: ref(null),
      input2: ref(null),
      input3: ref(null),
      input4: ref(null),
      input5: ref(null),
      input6: ref(null),
    };

    const item = reactive({
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      input6: '',
    });

    const getText = () => {
      return route.query.authType === 'email' ? 'Enter the email code below to proceed.' : 'Enter it below to verify your phone number.'
    }

    const btnNextDsiabled = computed(() => {
      const condation = item.input1
        && item.input2
        && item.input3
        && item.input4
        && item.input5
        && item.input6;
      return !condation; // true/false
    });

    function setItemFocus(e, refNextInput) {
      if (refNextInput === 7) return;
      if (e.target.value) {
        const nextInput = refInputs[`input${refNextInput}`].value;
        nextInput[0].focus();
        nextInput[0].select();
      }
    }

    async function validateEmailToken(token) {
      try {
        const result = await makeGraphRequest(
          'mutation',
          verifyEmailOtp,
          { input: { email, token } },
          null,
        );
        if (result.data.verifyEmailOtp) {
          // console.log('Email verified');
          // eslint-disable-next-line max-len
          router.push(`/auth/forgot-password/update-password/${result.data.verifyEmailOtp.token}`);
        }
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    }

    async function validatePhoneToken(token) {
      try {
        const result = await makeGraphRequest(
          'mutation',
          verifyPhoneNumber,
          { input: { phone, token } },
          null,
        );
        if (result.data.verifyPhoneNumber) {
          // console.log('Phone verified');
          // eslint-disable-next-line max-len
          router.push(`/auth/forgot-password/update-password/${result.data.verifyPhoneNumber.token}`);
        }
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    }

    async function validateCode() {
      loading.value = true;
      invalidCode.value = false;
      const head = `${item.input1}${item.input2}${item.input3}`;
      const code = `${head}${item.input4}${item.input5}${item.input6}`;
      let result = null;
      if (authType === 'phone') {
        result = await validatePhoneToken(code);
      } else if (authType === 'email') {
        result = await validateEmailToken(code);
      }
      invalidCode.value = !result;
      loading.value = false;
    }

    async function getPhoneCode() {
      const result = await makeGraphRequest(
        'query',
        resendPhoneCode,
        { phone },
        null,
      );
      return result.data.resendPhoneCode;
    }

    async function getEmailCode() {
      const result = await makeGraphRequest(
        'query',
        resendEmailCode,
        { email },
        null,
      );
      return result.data.resendPhoneCode;
    }

    async function resendCode() {
      loading.value = true;
      try {
        if (authType === 'phone') {
          await getPhoneCode();
        } else if (authType === 'email') {
          await getEmailCode();
        }
      } catch (err) {
        console.log(err);
      }
      loading.value = false;
    }

    async function findUserAndSendCode() {
      loading.value = true;
      try {
        const response = await makeGraphRequest('query', checkUserExists, { phone: '', email: '', userId: userId.toString() }, null);
        phone = response.data.checkUserExists.phone;
        email = response.data.checkUserExists.email;
        resendCode();
      } catch (err) {
        console.log(err);
      }
      loading.value = false;
    }

    onMounted(async () => {
      try {
        findUserAndSendCode();
      } catch (err) {
        console.log(err);
      }
    });

    return {
      // data (static)
      // data (dynamic)
      refInputs,
      loading,
      item,
      btnNextDsiabled,
      invalidCode,
      // methods
      validateCode,
      setItemFocus,
      resendCode,
      email,
      authType,
      getText
    };
  },
});
</script>

<style lang="scss" scoped>
.c-button-next.el-button, .c-button-next.el-button.is-disabled {
  background-color: #005C6B;
  border-color: #005C6B;
}
</style>
