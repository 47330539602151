<template lang="pug">
el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(title='User Detail' back-to='/manage/users')
    template(#actions)
      el-button(type="plain").button-green
        fai(type="fal" icon="edit").size-18.me-2
        span.min-w-110.d-inline-block Send Message

      el-button(type="danger" plain @click="removeItem").has-fai
        fai(type="fal" icon="trash").size-18.me-2
        span.min-w-110.d-inline-block Delete

      el-button(type="primary"  color="#1EBEAF" @click="validateForm" v-loading="loading").has-fai
        fai(type="fal" icon="edit").size-18.me-2
        span.min-w-110.d-inline-block Save

    template(#col-left)
      div
        .row
          .col-md-6
            el-form-item(label="First Name" prop="name")
              el-input(v-model="item.firstname")
          .col-md-6
            el-form-item(label="Last Name" prop="name").mb-0
              el-input(v-model="item.lastname")

        el-form-item
          template(#label)
            | My Why
            el-tooltip(
              effect="customized"
              :content="myWhyTip"
              placement="top"
              popper-class="max-w-350"
            )
              fai(type="fal" icon="info-circle").size-18.ms-2

          el-input(v-model="item.why" type="textarea")

        el-form-item.mb-0
          template(#label)
            | Bio
            el-tooltip(
              effect="customized"
              :content="bioTip"
              placement="top"
              popper-class="max-w-350"
            )
              fai(type="fal" icon="info-circle").size-18.ms-2
          el-input(v-model="item.bio" type="textarea")

      div
        user-common-sections(:item="item")
      
    template(#col-right)
      div
        el-form-item(label="Account Status")
          VueMultiselect(
              v-model="item.active",
              :multiple="false",
              label="type",
              track-by="value",
              :options="accountStatus.items",
              :searchable="false",
              :close-on-select="true",
              :show-labels="false"
              placeholder="Select"
            )
           

        el-form-item(label="Permissions")
        .d-flex.mb-2
          div Creator
          el-switch.ms-auto(
            v-model="item.is_creator"
            active-color="#13ce66"
            active-value=true
            inactive-value=false)
        //- el-form-item(label="Permissions")
        //- .d-flex.mb-2
        //-   div Creator
        //-   el-switch.ms-auto(
        //-     v-model="item.is_creator"
        //-     active-color="#13ce66"
        //-     active-value=true
        //-     inactive-value=false)

        div
        el-form-item(label="Email")
          el-input(v-model="item.email" type="email" disabled)

        el-form-item(label="Phone")
          el-input(
            v-model="item.phone"
            v-mask="'(###) ###-####'"
            type="tel"
            placeholder="(123) 456-7890"
            disabled
          )

        el-form-item(label="Age")
          el-input(
            v-model="item.age"
            disabled
          )

        el-form-item
          template(#label) Created
            span.text-muted.ms-2
          el-input(v-model="item.createdAt" disabled)

        el-form-item(v-if="item.organizations.length")
          template(#label) Organizations
          .py-1.px-2.bg-light.rounded
            .row.row-xs.align-items-center(v-for="org in item.organizations" :key="org.orgId")
              .col-auto
                img(:src="org.avatar"
                  v-if="org.avatar").d-block.size-32.obj-f-c.rounded-circle
                img(src="@/assets/images/default-profile.jpeg"
                  v-if="!org.avatar").d-block.size-32.obj-f-c.rounded-circle
              .col.lh-1 {{ org.name }}
              .col-auto
                el-button(link @click="viewOrganization(org.orgId, $event)").ms-auto
                | View Details
</template>

<script>

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';
import { mask } from 'vue-the-mask';
import VueMultiselect from 'vue-multiselect';
// import { bullhorns, orgValues, channels } from '@/data/onboarding-static';
import shared from './shared';
import UserCommonSections from './UserCommonSections.vue';
import { ElMessage } from 'element-plus'



const itemData = {
  id: '',
  firstname: 'User Name',
  lastname: 'User Name',
  bio: '',
  why: '',
  active: true,
  bullhorns: [],
  channels: [],
  values: [],
  interests: [],
  status: '',
  email: 'emailname@provider.com',
  phone: '(123) 456-789',
  age: 0,
  createdAt: '02 / 19 / 2021',
  organizations: [],
};

export default defineComponent({
  name: 'ManageContent_UserEdit',
  components: { UserCommonSections, VueMultiselect },
  directives: { mask },

  setup() {
    const {
      route,
      router,
      loading,
      getUserProfile,
      dateAndTime,
      updateUserProfileByAdmin,
      removeItemGetConfirm,
      removeUser,
    } = shared();
    const { userId } = route.params;
    const item = reactive(itemData);
    const accountStatus = reactive({
      items: [
        {
          type: 'Active',
          value: true,
        },
        {
          type: 'Archived',
          value: false,
        },
      ],
    });

    onMounted(async () => {
      const response = await getUserProfile(userId);
      const { user } = response;
      item.firstname = user.firstname;
      item.lastname = user.lastname;
      item.phone = user.phone;
      item.email = user.email;
      item.bio = response.bio;
      item.why = response.why;
      item.avatar = response.avatar;
      item.active = { type: user.active ? 'Active' : 'Archived', value: user.active };
      item.age = user.age;
      item.is_creator = user.is_creator;
      item.bullhorns = response.bullhorns;
      item.channels = response.channels;
      item.values = response.values;
      item.interests = response.interests;
      [item.createdAt] = dateAndTime(user.createdAt);
      item.organizations = response.organizations;
    });
    const refForm = ref(null);

    function validateForm() {
      console.log('validateForm');
      loading.value = true;
      refForm.value.validate(async (valid) => {
        console.log(valid);
        if (valid) {
          await updateUserProfileByAdmin({
            userId,
            firstname: item.firstname,
            lastname: item.lastname,
            bio: item.bio,
            why: item.why,
            active: item.active.value,
            is_creator: item.is_creator,  
          });
          loading.value = false;
        }
        return false;
      });
    }

    function removeItem(){
      removeItemGetConfirm(async () => {
        await removeUser(userId);
        router.push('/manage/users');
      });
    }

    function viewOrganization(id, event) {
      event.preventDefault();
      router.push(`/manage/orgs/${id}/edit`);
    }

    return {
      loading,
      refForm,
      rules: {},
      item,
      ElMessage,
      validateForm,
      bioTip: 'Gush about yourself. Consider sharing your north star, what makes you laugh, and whatever else about the earned trophies in work, life, and cosmos that make up your special sauce',
      myWhyTip: 'Define your "my purpose statement" to capture your catalyst and commitment. Consider including what you want to accomplish, who that will be for, and how you plan to make stuff better',
      viewOrganization,
      removeItem,
      accountStatus,
    };
  },
});
</script>
