<!-- eslint-disable max-len -->
<template lang="pug">
div(v-if="visible" id="smart-banner").smart-banner
    div.smart-banner-close(@click="closeBanner")
        img(src="@/assets/icons/close.svg")
    div.smart-banner-app-icon
        img(src="@/assets/images/logos/bolt-square.png")
    div.smart-banner-info-column
        p.smart-banner-app-name Spotlight XYZ
        p.smart-banner-developer-name Spotlight XYZ
        el-rate(v-if="!loading && rating != null" v-model="rating" text-color="#ff9900" size="small")
        p(v-if="!loading && mobileOS != null").smart-banner-store-quote {{ mobileOS === "iOS" ? "FREE — On the App Store" : mobileOS === "Android" ? "FREE - In Google Play" : "" }}
        span(v-if="loading").smart-banner-loader
    div.smart-banner-action
        p.smart-banner-action-text(@click="openAppLink") OPEN
</template>
    
<script lang="ts">
import {
    Ref,
    defineComponent, onMounted, ref,
} from 'vue';
import { ElLoading } from 'element-plus';
import globalShared from '@/shared';

export default defineComponent({
    name: 'WebAppSmartBanner',
    setup() {
        const visible = ref(true);
        const loading = ref(true);
        const rating: Ref<number | null> = ref(null);
        const mobileOS: Ref<string | null> = ref(null);
        const { getRating, getMobileOperatingSystem, isSafari, handleSafariPrompt } = globalShared();

        function closeBanner() {
            visible.value = false;
        }

        async function openAppLink() {
            const googlePlayIntent = import.meta.env.VITE_APP_PLAY_STORE_INTENT;
            //const appStoreIntent = "itms-apps://itunes.apple.com/developer/id1566652200"
            const googlePlayLink = import.meta.env.VITE_APP_PLAY_STORE_LINK;
            const appStoreLink = import.meta.env.VITE_APP_APPLE_STORE_LINK;
            const appId = import.meta.env.VITE_APP_ID;
            const appHost = import.meta.env.VITE_APP_HOST;
            const loadingInstance = ElLoading.service({
                lock: false
            })

            const mobileOS = getMobileOperatingSystem();
            if (mobileOS === "iOS") {
                //example: https://app.spotlight.xyz/web/sticker/adf45-afbb33-423523a-253235
                window.location.href = `${import.meta.env.VITE_APP_REDIRECT_BASE_URL}${window.location.pathname}`;
            }
            else {
                //example: com.spotlight.xyz://spotlight.xyz/web/sticker/adf45-afbb33-423523a-253235
                window.location.href = `${appId}://${appHost}${window.location.pathname}`;
            }

            let didSafariRedirect: boolean | null = false;

            if (isSafari()) {
                didSafariRedirect = await handleSafariPrompt();
            }

            // we are checking for strong equality with false, because null indicates that the prompt timed out, not that the redirection was unsuccessful
            // unfortunately this will attempt to redirect the user to the app store if they decline the redirect to the app on Safari
            // currently no better option exists for iOS Safari
            if (didSafariRedirect === false) {
                setTimeout(function () {
                    // redirect user to the app store directly if the document is not hidden (i.e. the app didn't launch)
                    if (!document.hidden) {
                        if (mobileOS !== "unknown") {
                            window.location.href = mobileOS === "Android" ? googlePlayIntent : appStoreLink;
                            setTimeout(function () {
                                // redirect user to the app store web view if the document is not hidden (i.e. the direct store didn't launch)
                                if (mobileOS === "Android" && !document.hidden) {
                                    window.location.href = googlePlayLink;
                                }
                                loadingInstance.close();
                            }, 1500);
                        }
                        else {
                            loadingInstance.close();
                        }
                    }
                    else {
                        loadingInstance.close();
                    }
                }, 1500);
            }
            else {
                loadingInstance.close();
            }
        }

        async function getAppRating(): Promise<number | null> {

            if (mobileOS.value === "Android" || mobileOS.value === "iOS") {
                return await getRating(mobileOS.value.toLowerCase());
            }
            return null;
        }

        onMounted(async () => {
            loading.value = true;
            mobileOS.value = getMobileOperatingSystem();
            if (mobileOS.value !== "unknown") {
                rating.value = await getAppRating();
            }
            loading.value = false;
        });

        return {
            visible,
            loading,
            rating,
            mobileOS,
            closeBanner,
            getMobileOperatingSystem,
            openAppLink,
        };
    },
});
</script>
<style></style>
