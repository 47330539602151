<script lang="ts" setup>

import GiftASticker from "@/views/manage-content/media/stickers/GiftASticker.vue";
import {onMounted, reactive, ref, watch} from "vue";
import shared from "@/views/manage-content/media/shared";
import { useRoute } from "vue-router";
import store from "@/store";

import StickerInsights from '@/views/manage-content/media/stickers/StickerInsights.vue'

const props = defineProps({
  isStickerInsights: Boolean,
  loading: Boolean,
  stickerInsights: {
    type: Array, // Change Array to Object
    default: () => ({}),
  },
});

const route = useRoute();
const loading = ref(false);
const {findStickerInsights, getWinners} = shared();

const item = store.getters['sticker/GET_STICKER'];

const stickerInsights = reactive({});
const isStickerInsights = ref(false);
const winners = ref<Winner[]>([]);
const winnerName = ref<string | null>(null);


interface Winner {
  id: string;
  stickerId: string;
  entityId: string;
  participantName: string;
  participantAvatar: string;
  eligible: boolean;
  dateCreated: string;
  dateUpdated: string;
}

async function getStickerInsights() {
  loading.value = true;
  const mStickerInsights = await findStickerInsights(route.params.id);
  Object.assign(stickerInsights, mStickerInsights);
  loading.value = false;
}

const getWinnersList = async () => {
    try {
        const sweepstakesStickerId = item.id;
        const response = await getWinners(sweepstakesStickerId);
        winners.value = response;
        if (winners.value) {
            winnerName.value = winners.value.map(winner => winner.participantName).join(', ');
        } else {
            winnerName.value = '';
        }

    } catch (error) {
        console.error('Error fetching winners:', error);
    }
};


onMounted(() => {
  getStickerInsights();
  getWinnersList();
})

watch(isStickerInsights, (newValue) => {
  if (newValue) {
    getWinnersList();
  }
});

</script>

<template>
  <div class="c-add-sticker__extra__type">
  <div v-if="!loading">
    <div class="c-add-sticker__extra__title mb-2">Insights</div>
    <div>
      <p>
        Sold {{ stickerInsights.unitsSold }} Units to {{ stickerInsights.purchasedUsersCount }}
        customers
        for ${{ parseFloat(stickerInsights.totalRevenue).toFixed(2) }} in net sales
      </p>
      <el-button class="text-success p-0" link @click="isStickerInsights = !isStickerInsights">View Details</el-button>
    </div>
    <gift-a-sticker v-if="item.campaignType === 'Ticket'" :stickerId="item.id"/>
  </div>

  <div>
    <sticker-insights
    :isStickerInsights="isStickerInsights"
    :loading="loading"
    :stickerInsights="stickerInsights"
    :winners="winners"
    :winnerName="winnerName"
    @close-dialog="isStickerInsights = !isStickerInsights"
  />
  </div>
</div>
</template>

<style lang="scss" scoped>

</style>
