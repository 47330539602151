<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';

import store from '@/store';
import VueMultiselect from 'vue-multiselect';
import { ElTimePicker } from 'element-plus';
import {timezones} from "@/data/timezones";
import { QuillEditor } from '@vueup/vue-quill';
import moment from 'moment-timezone';
import {combineDateAndTime} from "@/shared/sticker-date-combos";

import GlowPicker from "@/components/StickerTIcket/GlowPicker.vue";
import EntriesSweepstakes from "@/views/manage-content/media/add-sticker/components/EntriesSweepstakes.vue";

// Dates
const dateStart = ref();
const timeStart = ref();
const dateEnd = ref();
const timeEnd = ref();

const drawingDate = ref();
const experienceDate = ref();
const drawingTime = ref();
const experienceTime = ref();

const dateTimeTimezone = ref();

const color = ref('#F8E587');
const glow = ref(false);

const props = defineProps({
  item: Object,
  disabled: { type: Boolean, default: false }, 
  causes: Array<string>,
  causeLoading: Boolean,
});

const emit = defineEmits(['onFetchCauses']);

const item = computed({
  get: () => store.getters['sticker/GET_STICKER'],
  set: (value) => store.dispatch('sticker/SET_STICKER', value),
});

// Watch for all relevant changes to automatically update the store
watch([dateStart, timeStart, dateEnd, timeEnd, dateTimeTimezone, drawingDate, drawingTime, experienceDate, experienceTime, item], () => {
  updateStore();
});

onMounted(async () => {

  const receivedStartDate = item.value.startDate;
  const timezone = item.value.startDateTimeZone;

  dateTimeTimezone.value = timezone;

  const userTimezone = moment.tz.guess();

  // START DATE
  const start = moment.tz(receivedStartDate, 'YYYY-MM-DDTHH:mm:ssZ', userTimezone)
    .clone()
    .tz(timezone);

    if (start && typeof start.format === 'function') {
      dateStart.value = start.format('YYYY-MM-DD');
      timeStart.value = start.format('hh:mm A');
    }
    

  // END DATE
    const receivedEndDate = item.value.endDate;
    const end = moment.tz(receivedEndDate, 'YYYY-MM-DDTHH:mm:ssZ', userTimezone)
      .clone()
      .tz(timezone);

    if (end && typeof end.format === 'function') {
      dateEnd.value = end.format('YYYY-MM-DD');
      timeEnd.value = end.format('hh:mm A');
    }

    // DRAW DATE
    const receivedDrawingDate = item.value.sweepstakesSticker.drawingDateTime;
    const draw = moment.tz(receivedDrawingDate, 'YYYY-MM-DDTHH:mm:ssZ', userTimezone)
      .clone()
      .tz(timezone);

    if (draw && typeof draw.format === 'function') {
      drawingDate.value = draw.format('YYYY-MM-DD');
      drawingTime.value = draw.format('hh:mm A');
    }
    
    // EXPERIENCE DATE
    const receivedExperienceDate = item.value.sweepstakesSticker.experienceDateTime;
    const experience = moment.tz(receivedExperienceDate, 'YYYY-MM-DDTHH:mm:ssZ', userTimezone)
      .clone()
      .tz(timezone);

    if (experience && typeof experience.format === 'function') {
      experienceDate.value = experience.format('YYYY-MM-DD');
      experienceTime.value = experience.format('hh:mm A');
    }

  });

defineExpose({
  color,
  glow,
  dateStart,
  timeStart,
  dateEnd,
  timeEnd,
  dateTimeTimezone,
  drawingDate,
  drawingTime,
  experienceDate,
  experienceTime
});

const updateStore = () => {

  const startDate = combineDateAndTime(dateStart.value, timeStart.value);
  const endDate = combineDateAndTime(dateEnd.value, timeEnd.value);
  const drawingD = combineDateAndTime(drawingDate.value, drawingTime.value);
  const experience = combineDateAndTime(experienceDate.value, experienceTime.value)
  const targetTimeZone = dateTimeTimezone.value;

  const convertToUTC = (date, timeZone) => {
    const localDate = moment.tz(date, timeZone);
    return localDate.utc().format();
  };

  const startDateInUTC = convertToUTC(startDate, targetTimeZone);
  const endDateInUTC = convertToUTC(endDate, targetTimeZone);
  const drawingDateInUTC = convertToUTC(drawingD, targetTimeZone);
  const experienceDateInUTC = convertToUTC(experience, targetTimeZone);

  const newSticker = {
    startDate: startDateInUTC,
    endDate : endDateInUTC,
    startDateTimeZone: targetTimeZone,
    endDateTimeZone: targetTimeZone,
    sweepstakesSticker: {
      drawingDateTime: drawingDateInUTC,
      drawingDateTimeTimezone: dateTimeTimezone.value,
      experienceDateTime: experienceDateInUTC, 
      experienceDateTimeTimezone: dateTimeTimezone.value,
      aboutTheCause: item.value.sweepstakesSticker.aboutTheCause,
      approximateRetailValue: parseFloat(item.value.sweepstakesSticker.approximateRetailValue),
      numberOfPrizesAndWinners: parseInt(item.value.sweepstakesSticker.numberOfPrizesAndWinners, 10),
      prizes: item.value.sweepstakesSticker.prizes,
      nonProfitBeneficiary: item.value.sweepstakesSticker.nonProfitBeneficiary,
      sweepstakesConfirmation: item.value.sweepstakesSticker.sweepstakesConfirmation,
      sweepstakesWinnerMessage: item.value.sweepstakesSticker.sweepstakesWinnerMessage,
      sweepstakesLoserMessage: item.value.sweepstakesSticker.sweepstakesLoserMessage,
      checkoutPageLegal: item.value.sweepstakesSticker.checkoutPageLegal,
      entries: Array.isArray(item.value.sweepstakesSticker.entries) ? item.value.sweepstakesSticker.entries : [],
      entryCost: Array.isArray(item.value.sweepstakesSticker.entryCost) ? item.value.sweepstakesSticker.entryCost : [],
    },
    title: item.value.title,
    description: item.value.description,
    location: item.value.location,
  };
  store.dispatch('sticker/SET_STICKER', newSticker);
};

const preventArrowKeys = (event: KeyboardEvent) => {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    event.preventDefault();
  }
};

</script>

<template>
  <section 
    class="s-sweepstakes-sticker" 
    :class="{ 'disble-and-opacity': props.disabled }"
  >
    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Sticker Title</span>
      <input class="c-input" type="text" v-model="item.title" required placeholder="" :disabled="props.disabled"/>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Description</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.description" content-type="html" :disabled="props.disabled"/>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">About the cause</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.sweepstakesSticker.aboutTheCause" content-type="html" :disabled="props.disabled"/>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Number of Prizes and Winners</span>
      <input class="c-input" type="number" v-model="item.sweepstakesSticker.numberOfPrizesAndWinners" :disabled="props.disabled" min="0" @keydown="preventArrowKeys" @wheel.prevent/>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Prize(s)</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.sweepstakesSticker.prizes" content-type="html" :disabled="props.disabled"/>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Nonprofit Beneficiary</span>
      <vue-multiselect
      class="s-input-multiselect"
        :multiple="true"
        :options="causes"
        :searchable="true"
        :close-on-select="true"
        :show-labels="false"
        v-model="item.causes"
        :loading="causeLoading"@search-change="(val) => emit('onFetchCauses', val)"
        :disabled="props.disabled"
      />
    </article>

  <!-- DATES START-->
    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Start Date & Time</span>
      <article class="c-tier__row c-tier__row--mqcost">
        <el-date-picker class="c-glow-picker__input" v-model="dateStart" format="MM/DD/YY" :disabled="props.disabled"/>
        <div class="position-relative" style="padding-right: 2px;">
          <el-time-picker
                arrow-control
                class="c-glow-picker__input c-glow-picker__input--time"
                format="hh:mm A"
                v-model="timeStart"
                value-format="hh:mm A"
                :disabled="props.disabled"
              />
        </div>
      </article>
    </article>
    

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">End Date & Time</span>
      <article class="c-tier__row c-tier__row--mqcost">
        <el-date-picker class="c-glow-picker__input" v-model="dateEnd" format="MM/DD/YY" :disabled="props.disabled"/>
        <div class="position-relative">
          <el-time-picker
                arrow-control
                class="c-glow-picker__input c-glow-picker__input--time"
                format="hh:mm A"
                v-model="timeEnd"
                value-format="hh:mm A"
                :disabled="props.disabled"
              />
        </div>
      </article>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Drawing Date & Time</span>
      <article class="c-tier__row c-tier__row--mqcost">
        <el-date-picker class="c-glow-picker__input" v-model="drawingDate" format="MM/DD/YY" :disabled="props.disabled"/>
        <div class="position-relative">
          <el-time-picker
            arrow-control
            class="c-glow-picker__input c-glow-picker__input--time"
            format="hh:mm A"
            v-model="drawingTime"
            value-format="hh:mm A"
            :disabled="props.disabled"
          />
        </div>
      </article>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Experience Date & Time</span>
      <article class="c-tier__row c-tier__row--mqcost">
        <el-date-picker class="c-glow-picker__input" v-model="experienceDate" format="MM/DD/YY" :disabled="props.disabled"/>
        <div class="position-relative">
          <el-time-picker
            arrow-control
            class="c-glow-picker__input c-glow-picker__input--time"
            format="hh:mm A"
            v-model="experienceTime"
            value-format="hh:mm A"
            :disabled="props.disabled"
          />
        </div>
      </article>
    </article>
    <!-- DATES END-->

    <el-select class="c-glow-picker__input" placeholder="Timezone" v-model="dateTimeTimezone">
      <el-option
        v-for="timezone in timezones"
        :key="timezone.value"
        :label="timezone.label"
        :value="timezone.value">
      </el-option>
    </el-select>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Location of Experience</span>
      <input class="c-input" type="text" v-model="item.location" :disabled="props.disabled"/>
    </article>

    <EntriesSweepstakes :disabled="props.disabled"/>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Approximate Retail Value</span>
      <div class="c-input-container">
        <span class="c-input__prefix" >$</span>
        <input class="c-input" type="number" v-model.number="item.sweepstakesSticker.approximateRetailValue" :disabled="props.disabled" min="0" @keydown="preventArrowKeys" @wheel.prevent/>
      </div>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Sweepstakes Confirmation</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.sweepstakesSticker.sweepstakesConfirmation" content-type="html" :disabled="props.disabled"/>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Sweepstakes Winner Message</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.sweepstakesSticker.sweepstakesWinnerMessage" content-type="html" :disabled="props.disabled"/>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Sweepstakes Loser Message</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.sweepstakesSticker.sweepstakesLoserMessage" content-type="html" :disabled="props.disabled"/>
    </article>

    <article class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column">
      <span class="s-sweepstakes-sticker__label">Checkout Page Legal</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.sweepstakesSticker.checkoutPageLegal" content-type="html" :disabled="props.disabled"/>
    </article>

    <glow-picker ref="glowPickerRef" :disabled="props.disabled"/>
  </section>
</template>

<style lang="scss">
.s-sweepstakes-sticker {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .el-select {
    width: 100%;
    max-width: 340px;
    &__placeholder {
      color: #000000;
      font-family: "Red Hat Display";
      font-size: 14px;
      font-weight: 500;
    }
    &__wrapper {
      height: 100%;
      border: none;
      box-shadow: none;
      width: 338px;
    }
  }

  .multiselect  {
    max-width: 340px;
  }

  &__label {
    margin-bottom: 8px;
    font-size: 16px;
    color: #000000;

    &__underline {
      text-decoration: underline;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;

    &--entries {
      gap: 20px;

      > div {
        width: 190px;
      }
    }

    &--trash-icon {
      position: absolute;
      z-index: 1;
      top: 21px;
      left: 24px;
    }
  }

  .c-input {
    color: #000000;
  }

  .label-large {
    height: 150px;
    border: 1px solid #CCCCCC;
    outline: none;
    padding: 10px;
    line-height: 1.5;
    box-sizing: border-box;
    vertical-align: top;
    color: #000000;
  }

  .input-container {
    position: relative;
    width: 100%;
  }

  .fixed-text {
    position: absolute; 
    right: 10px;
    pointer-events: none;
    color: #999;
    line-height: 56px;
    font-size: 14px;
  }

  .icon-container {
    display: flex;
    align-items: center;
  }

  .icon-size-14 {
    width: 14px;
    height: 14px;
  }

  .custom-input { 
    height: 56px;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: 1px solid #CCCCCC;
    outline: none;
    border-radius: 4px;
    &:focus {
      border-color: none;
    }
  }

  .c-glow-picker__input--time .el-input__inner {
    padding-right: 2px;
  }
  
  .c-glow-picker__group {
    display: flex;
    gap: 10px;

    .c-glow-picker__input {
      height: 56px;
      box-sizing: border-box;
      outline: none;
      border-radius: 4px;
      width: 100%;

      &:focus {
        border-color: none;
      }
    }
  }

  .c-tier {
    display: flex;
    flex-direction: column;

    &__inputs {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    &__row {
      display: flex;
      flex-direction: row;

      &--mqcost {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 8px;
      width: 100%;
    }
  }

   .disble-and-opacity {
    opacity: 0.5;
    pointer-events: none;
  }

  .c-input__prefix {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #000000;
  }
  .c-input-container {
    position: relative;
  }

  .s-input-multiselect {
    font-size: small;
  }

  /* Hide the arrows in number input fields */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Standard property for compatibility */
  }
}
</style>
