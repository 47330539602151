<template lang="pug">
el-form(
  ref="refForm"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(title='Manage Todo' back-to='/manage-resources/todos')
    template(#actions)
      el-button(type="primary" @click="handleRemove()" v-loading="removing").has-fai
        fai(type="fal" icon="trash").size-18.me-2
        span.min-w-110.d-inline-block Delete

    div
      .row
        .col-sm-6
          el-form-item(label="Todo")
            el-input(v-model="item.todo" type="textarea" )

          el-form-item(label="Protip").mb-0
            el-input(v-model="item.protip" type="textarea" )
</template>

<script>
import {
  ref,
  reactive,
  defineComponent,
  onMounted,
} from 'vue';
import shared from '../shared';

const itemData = {
  id: -1,
  todo: '',
  protip: '',
};
export default defineComponent({
  name: 'Todo',
  setup() {
    const {
      route,
      // router,
      doFindTodo,
    } = shared();
    const { id } = route.params;
    console.log(id);
    const loading = ref(false);
    const item = reactive(itemData);

    onMounted(async () => {
      loading.value = true;
      const response = await doFindTodo(id);
      loading.value = false;
      Object.assign(item, response);
    });

    return {
      loading,
      item,
    };
  },
});
</script>
