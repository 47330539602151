const mutations = {
  MUTATE_SORT(state, value) {
    state.sort = value;
  },
  
  MUTATE_DATE(state, value) {
    state.date = value;
  },

  MUTATE_SEARCH(state, value) {
    state.search = value;
  },

  MUTATE_ACTIVITY_LOGS(state, value) {
    state.logs = value;
  },

  MUTATE_ACTIVITY_LOGS_LOADING(state, value) {
    state.logsLoading = value;
  },

  MUTATE_ACTIVITY_LOGS_ERROR(state, value) {
    state.logsError = value;
  },

  MUTATE_SELECTED_LOGS(state, value) {
    state.selectedLogs = value;
  }
}

export default mutations
