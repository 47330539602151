<template lang="pug">
el-dialog(
  title="Add User"
  top="5vh" width="100%"
  :close-on-click-modal="false"
  class="el-dialog__max-w-700"
)
  el-form(
    ref="refForm"
    :rules="rules"
    :model="item"
    status-icon
    label-position="top"
  )
    el-form-item(label="User's Name" prop="name")
      el-input(v-model="item.name")

    el-form-item(label="Email" prop="email")
      el-input(v-model="item.email") {{ serverError }}
      .el-form-item__error(v-if="serverError")
        | {{ serverError }}

  template(#footer)
    el-button(type="success"  color="#1EBEAF" @click="validateForm" v-loading="sendingInvite") Send Invite
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import { mask } from 'vue-the-mask';
import { email, name, phone } from '@/data/rules';
import shared from './shared';

export default defineComponent({
  name: 'DialogAddUserInvite',
  directives: { mask },
  props: {
    serverError: { type: String, default: '' },
    sendingInvite: { type: Boolean, default: false },
  },
  emits: ['close-dialog', 'save-item'],
  setup(_, { emit }) {
    const {
      loading,
      getInviteCode,
    } = shared();
    const refForm = ref(null);
    const item = reactive({
      name: '',
      email: '',
      phone: '',
      country_code: '+1',
      invite_code: '',
    });

    async function inviteCode() {
      item.invite_code = await getInviteCode();
    }

    async function submitForm() {
      emit('save-item', item);
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) return submitForm();
        console.log('error submit!!');
        return false;
      });
    }

    return {
      // data (static)
      refForm,
      rules: { email, phone, name },
      item,
      loading,
      // methods
      validateForm,
      inviteCode,
    };
  },
});
</script>
