<template lang="pug">
app-main-content-layout(title='Dictionary')
  template(#actions)
    el-button(@click="exportDictionary").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Export

    el-button(type="primary" color='#1EBEAF' @click="dialog = true").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Add New

  div
    .row.row-sm.align-items-center.mb-4
      .col
        el-input(
          v-model="filter"
          placeholder="Search Dictionary"
          prefix-icon="el-icon-search"
        )
          template(#prefix='')
              img(
                src="@/assets/icons/light/magnify-light.svg",
                style="font-size: 20px;")
      .col-auto
        el-button(type="info").has-fai
          fai(type="fal" icon="filter").size-18.me-2
          span.min-w-110.d-inline-block Filter

    el-table(
      :data="filtered"
      stripe
      v-loading="loading"
      @row-click="$router.push(`dictionary/${$event.id}`)"
      style="width: 100%"
    ).clickable-rows
      el-table-column(width="15")
        // space
      el-table-column(prop="term" label="term" width="200" sortable)
      el-table-column(prop="description" label="Description" min-width="200")
      el-table-column(prop="source" label="Source" min-width="200")
      el-table-column(width="46")
        .el-table__expand-icon
          img(src="@/assets/icons/light/chevron-right-light.svg")

      el-table-column(width="15")
        // space

    dialog-add-dictionary(
      v-if="dialog"
      :loading="loading"
      v-model="dialog"
      :bullhorns="bullhorns.bullhorns"
      @save-item="saveItem"
      @close-dialog="dialog = false"
    )
</template>

<script lang="js">
import {
  defineComponent, ref,
  onMounted, reactive,
  computed,
} from 'vue';
import shared from './shared';
import DialogAddDictionary from './DialogAddDictionary.vue';
import globalShared from '@/shared';

export default defineComponent({
  name: 'ManageContent_Directory_Dictionary',
  components: { DialogAddDictionary },
  setup() {
    const {
      loading,
      getDictionaryItems,
      getBullhorns,
      saveDictionaryItem,
      exportData,
    } = shared();

    const { showSuccessMessage } = globalShared();

    const filter = ref('');
    const items = reactive({ items: [] });
    const bullhorns = reactive({ bullhorns: [] });
    const dialog = ref(false);

    async function getItems() {
      try {
        loading.value = true;
        items.items = await getDictionaryItems();
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    async function saveItem(input) {
      loading.value = true;
      await saveDictionaryItem(input);
      showSuccessMessage('Dictionary item saved successfully');
      getItems();
      loading.value = false;
    }

    const filtered = computed(() => {
      if (filter.value === '') {
        return items.items;
      }
      const regex = new RegExp(`${filter.value}`, 'gi');
      return items.items.filter((x) => {
        if (x.term) {
          return x.term.match(regex);
        }
        return false;
      });
    });

    function exportDictionary() {
      exportData(items.items);
    }

    onMounted(async () => {
      await getItems();
      bullhorns.bullhorns = await getBullhorns(false);
    });

    return {
      items,
      bullhorns,
      filter,
      filtered,
      input: ref(),
      loading,
      dialog,
      saveItem,
      exportDictionary,
    };
  },
});
</script>
