<template lang="pug">
el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(
    title='Interests'
    back-to='/manage-content/directory/values'
    v-loading="loading"
  )
    template(#actions)
      .row.row-sm.align-items-center
        .col-auto
          div(@click.stop="handleToggleAddToFav").cp
            svg(v-if="item.favorite").size-32
              use(xlink:href="#fas-star")
            fai(v-else type="fal" icon="star").size-32

        .col-auto
          el-button(type="danger" plain @click="removeInterestItem").has-fai
            fai(type="fal" icon="trash").size-18.me-2
            span.min-w-110.d-inline-block Delete

          el-button(type="primary" @click="validateForm").has-fai
            fai(type="fal" icon="edit").size-18.me-2
            span.min-w-110.d-inline-block Save

    template(#col-left)
      div
        el-form-item(label="Interest" prop="interest")
          el-input(v-model="item.interest")

        el-form-item(label="Category", prop="categoryId").mb-0
          VueMultiselect(
            v-model="item.categoryId",
            :multiple="false",
            label="category",
            track-by="id",
            :options="categories.categories",
            :searchable="true",
            :close-on-select="true",
            :show-labels="false"
            placeholder="Pick a value"
            )

    template(#col-right)
      div
        el-form-item(label="Creator")
          el-input(v-model="item.creator" disabled)

        el-form-item
          template(#label)
            | Post Date
            span.text-muted.ms-2 Active for 3 Days
          el-input(v-model="item.postDate" disabled)

        el-form-item(label="Post Time").mb-0
          el-input(v-model="item.postTime" disabled)
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
} from 'vue';
import { requiredRule, category } from '@/data/rules';
import VueMultiselect from 'vue-multiselect';
import shared from './shared';

export default defineComponent({
  name: 'ManageContent_Directory_InterestDeail',
  components: { VueMultiselect },
  setup() {
    const {
      route,
      router,
      loading,
      getTypedCategories,
      getInterest,
      saveInterest,
      removeItemGetConfirm,
      dateAndTime,
    } = shared();
    const refForm = ref(null);
    const { id } = route.params;
    const categories = reactive({ categories: [] });
    const item = reactive({
      id: -1,
      name: 'Very Long Interest Name',
      category: {

      },
      favorite: false,
      postDate: '02/12/2021',
      postTime: '2:30 PM',
    });

    async function getItem() {
      loading.value = true;
      const itm = await getInterest(parseInt(id, 10));
      item.id = itm.id;
      item.creator = itm.createdBy;
      item.categoryId = itm.category;
      ['interest', 'description', 'favorite'].forEach((attr) => {
        item[attr] = itm[attr];
      });
      [item.postDate, item.postTime] = dateAndTime(itm.createdAt);
      loading.value = false;
    }

    async function saveItem() {
      await saveInterest({
        id: parseInt(item.id, 10),
        interest: item.interest,
        description: item.description,
        categoryId: parseInt(item.categoryId.id, 10),
      });
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    onMounted(async () => {
      categories.categories = await getTypedCategories();
      await getItem();
    });

    async function removeItem() {
      await saveInterest({
        id: parseInt(item.id, 10),
        delete: true,
      });
      router.push('/manage-content/directory/interests');
    }

    function removeInterestItem() {
      removeItemGetConfirm(removeItem);
    }

    async function handleToggleAddToFav() {
      item.favorite = !item.favorite;
      loading.value = true;
      await saveInterest({
        id: parseInt(item.id, 10),
        favorite: item.favorite,
      });
      loading.value = false;
    }

    return {
      // data
      refForm,
      rules: { interest: requiredRule('Interest'), categoryId: category },
      item,
      loading,
      categories,

      // methods
      validateForm,
      removeInterestItem,
      handleToggleAddToFav,
    };
  },
});
</script>
