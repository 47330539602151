<template lang="pug">
el-dialog(
  title="Add Segment",
  top="5vh" width="100%"
  class="el-dialog__max-w-700"
  :close-on-click-modal="false"
)
  .row
    .col-4
      el-upload(
        :show-file-list="false"
        :on-success="handleSuccess"
        :on-error="handleError"
        :before-upload="beforeUpload"
        :http-request="handleSegmentCoverUpload"
        action=""
      ).img-uploader.pos-r
        template(v-if="item.cover")
          img(:src="item.cover").img-fluid
          .pin.pin-bx.d-flex.p-2
            el-button(type="primary").ms-auto Change
        template(v-else)
          img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
          p.upload-picture-text Drop an image file here or
          div.fake-upload-button Browse For File

    .col-7
      el-form(
        ref="refForm"
        :rules="rules"
        :model="item"
        status-icon
        label-position="top"
      )
        el-form-item(label="Segment Title" prop="segment")
          el-input(v-model="item.segment")

        el-form-item(label="Details" prop="description")
          el-input(v-model="item.description" type="textarea")

  template(#footer)
    el-button(type="success" @click="validateForm" v-loading="loading") Add
</template>

<script>

import {
  ref,
  reactive,
  defineComponent,
} from 'vue';
import { requiredRule } from '@/data/rules';
import fileUpload from '@/shared/upload';

export default defineComponent({
  name: 'DialogAddSegment',
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['close-dialog', 'save-item'],

  setup(_, { emit }) {
    const { uploadStatus, beforeUpload, handleUploadRequest } = fileUpload('segments', 'Segment');

    const refForm = ref(null);

    const item = reactive({
      cover: null,
      segment: '',
      description: '',
      entity: null,
      entityId: null,
    });

    function submitForm() {
      emit('save-item', item);
      emit('close-dialog');
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) {
          item.entity = 'Admin';
          item.entityId = '';
          return submitForm();
        }
        console.log('error submit!!');
        return false;
      });
    }

    async function handleSegmentCoverUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.cover = uploadStatus.resourceUrl;
      }
    }

    function handleSuccess() {
      console.log('succc');
    }

    function handleError() {
      console.log('error');
    }

    return {
      // data
      refForm,
      rules: {
        cover: requiredRule('cover'),
        segment: requiredRule('segment'),
        description: requiredRule('description'),
      },
      item,
      beforeUpload,
      validateForm,
      handleSegmentCoverUpload,
      handleSuccess,
      handleError,
    };
  },
});
</script>
