<template lang="pug">
el-dialog(
  title="Add Spot"
  top="5vh" width="100%"
  class="el-dialog__max-w-700"
)
  .row
    .col-4
      el-upload(
        :show-file-list="false"
        :on-success="handleSuccess"
        :on-error="handleError"
        :before-upload="beforeUpload"
        :http-request="handleEventUpload"
        action=""
      ).img-uploader
        img.img(v-if="item.url" :src="item.url")
        template(v-else)
          img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
          p.upload-picture-text Drop an image file here or
          div.fake-upload-button Browse For File

    .col-8
      el-form(
        ref="refForm"
        :rules="rules"
        :model="item"
        status-icon
        label-position="top"
      )
        el-form-item(label="Spot Name" prop="name")
          el-input(v-model="item.name")

        el-form-item(label="Details" prop="description")
          el-input(v-model="item.description" type="textarea")

  template(#footer)
    el-button(type="success" @click="validateForm" v-loading="loading") Add
</template>

<script>

import {
  ref,
  reactive,
  defineComponent,
} from 'vue';
import { requiredRule } from '@/data/rules';
import fileUpload from '@/shared/upload';

export default defineComponent({
  name: 'DialogAddSpot',
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['close-dialog', 'save-item'],

  setup(_, { emit }) {
    const { uploadStatus, beforeUpload, handleUploadRequest } = fileUpload('stickers', 'Sticker');

    const refForm = ref(null);

    const item = reactive({
      id: -1,
      name: '',
      url: null,
      description: '',
    });

    function submitForm() {
      emit('save-item', item);
      emit('close-dialog');
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) return submitForm();
        console.log('error submit!!');
        return false;
      });
    }

    async function handleEventUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.url = uploadStatus.resourceUrl;
      }
    }

    function handleSuccess() {
      console.log('succc');
    }

    function handleError() {
      console.log('error');
    }

    return {
      // data
      beforeUpload,
      handleEventUpload,
      handleSuccess,
      handleError,

      refForm,
      rules: {
        url: requiredRule('url'),
        name: requiredRule('name'),
        description: requiredRule('description'),
      },
      item,
      // methods
      validateForm,

    };
  },
});
</script>
