<template lang="pug">
.card
    .card-header.d-flex.align-items-center.bg-white.border-0.py-3.fs-14.fw-bold.px-0
        div Gift A sticker
    div.bg-white
        div(v-for="(input, index) in inputs" :key="index").mb-2
            el-input(
                v-model="inputs[index]"
                @input="addInputField(index)"
                @blur="validateEmail(index)"
                placeholder="Email"
                class="w-75"
                :class="{ 'border-red-500': !isValid[index] && inputs[index] !== '' }"
                )
            el-button(v-if="index !== inputs.length - 1" type="danger" circle @click="removeInputField(index)").mx-1
                i.el-icon.el-icon-delete
        el-button(@click="sendSticker" type="success") Send Sticker
</template>S

<script>
import { ref  } from 'vue';
import shared from '../shared';
import { ElMessage } from 'element-plus';

export default {
    props: {
    stickerId: String // Define the prop 'stickerId'
  },
  setup(props) {
    const {
      giftSticker,
    } = shared();
    const inputs = ref(['']); // Initial input field
    const isValid = ref([]); // Array to track email validity for each input

    const addInputField = (index) => {
      // Check if the last input field has content
      if (index === inputs.value.length - 1 && inputs.value[index].trim() !== '') {
        inputs.value.push(''); // Add a new empty input field if the last one is not empty
        isValid.value.push(true); // Add a corresponding entry in isValid array
      }
    };

    const removeInputField = (index) => {
      if (inputs.value.length > 1) {
        inputs.value.splice(index, 1);
        isValid.value.splice(index, 1);
      }
    };

    const isEmail = (value) => {
      // Basic email validation check
      const emailRegex = /\S+@\S+\.\S+/;
      return emailRegex.test(value);
    };

    const validateEmail = (index) => {
      isValid.value[index] = isEmail(inputs.value[index]);
    };

    const sendSticker = async () => {
        const data = {emails: inputs.value,
          stickerId: props.stickerId};
        const gift = await giftSticker(data);
        if(gift.giftSticker !== null && gift.giftSticker.length>0){
            const allEmails = gift.giftSticker.join(', ');
            const successMessage = `Sticker sent successfully to: ${allEmails}`;
            ElMessage.success({
            message: successMessage,
            duration: 6000,
            showClose:true,
        });
        } else {
            ElMessage.warning({
            message: "Sticker not sent",
            duration: 6000,
            showClose:true,
            });
        }
    };
    return {
        inputs,
        isValid,
        addInputField,
        removeInputField,
        validateEmail,
        sendSticker
    };
  }
};
</script>
