<script setup lang="ts">
import { defineProps } from 'vue';
import fileUpload from "@/shared/upload";
const { uploadStatus, beforeUpload, handleUploadRequest, uploading } = fileUpload('stickers', 'Sticker');

const props = defineProps({
  url: String,
  onAddImage: Function
})

</script>

<template>
  <section class="c-sticker-content">
    <el-upload
      drag
      :show-file-list="false"
      :before-upload="beforeUpload"
      :http-request="onAddImage"
      action=""
    >

      <img v-if="props.url" :src="props.url" class="img-fluid" />

      <template v-else>
        <fai type="fas" icon="file-image" height="90px" width="60px" color="#B2B2B2" />
        <p class="c-sticker-content__drop-text">Drop an image file here or</p>

        <button class="c-sticker-content__btn-upload">
          <fai type="fas" icon="images" width="16px" height="14px" />
          Browse For File
        </button>
      </template>

    </el-upload>
  </section>
</template>

<style lang="scss">
.c-sticker-content {
    .el-upload {
      .el-upload-dragger {
        height: 433px;
        width: 335px;
        padding: 30px;
        border: 1px solid #ccc !important;
        border-radius: 5px;
      }
    }

  &__drop-text {
    font-weight: 700;
    font-size: 16px;
    color: #666666;
    margin: 30px 0;
  }

  &__btn-upload {
    background: #333333;
    border-radius: 56px;
    border: none;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    gap: 10px;
    min-height: 48px;
  }
}
</style>
