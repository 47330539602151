export interface MenuItem {
  path: string;
  icon: string;
  label: string;
  hasSubNav: boolean;
  faIcon?: string;
  subNav?: MenuItem[];
}

const manageUsersAndOrganizations: MenuItem[] = [
  {
    path: '/manage/users',
    icon: 'user',
    label: 'Users',
    hasSubNav: false,
  }, {
    path: '/manage/orgs',
    icon: 'users',
    label: 'Organizations',
    hasSubNav: false,
  }, {
    path: '/manage/orgs-pending',
    icon: 'clock',
    label: 'Organizations',
    hasSubNav: false,
    faIcon: 'clock',
  }, {
    path: '/manage/invites',
    icon: 'user-plus',
    label: 'Invitations',
    hasSubNav: false,
  },
];

const manageContentMedia: MenuItem[] = [
  {
    path: '/manage-content/media/add-bullhorn',
    icon: 'circle-plus',
    label: 'Add item',
    hasSubNav: false,
    faIcon:'circle-plus',
  },
  {
    path: '/manage-content/media/stickers',
    icon: 'sticky-note',
    label: 'Stickers',
    hasSubNav: false,
  },
  {
    path: '/manage-content/media/library',
    icon: 'image',
    label: 'Library',
    hasSubNav: false,
  },
  {
    path: '/manage-content/directory/bullhorns',
    icon: 'list',
    label: 'Directory',
    hasSubNav: false,
  },
  {
    path: '/manage-content/media/home-page',
    icon: 'home',
    label: 'Home Page',
    hasSubNav: false,
  },
  // {
  //   path: '/manage-content/payment-logs',
  //   icon: 'users',
  //   label: 'Payment Logs',
  //   hasSubNav: false,
  // },
];

const dashboard: MenuItem[] = [
  {
    path: '/manage',
    icon: 'user-group',
    label: 'Users',
    hasSubNav: true,
    faIcon: 'users',
    subNav: manageUsersAndOrganizations,
  },
  {
    path: '/manage-content',
    icon: 'note-sticky',
    label: 'Content',
    hasSubNav: true,
    subNav: manageContentMedia,
  },
  {
    path: '/manage/benevity-claim',
    icon: 'envelope',
    label: 'Requests',
    hasSubNav: false,
    faIcon: 'envelope',
  },
  {
    path: '/activity',
    icon: 'chart-line',
    label: 'Activity',
    hasSubNav: false,
    faIcon: 'chart-line'
  }
];


const manageContent: MenuItem[] = [
  // {
  //   path: '/manage-content/spots',
  //  'users',
  //   label: 'Spots`',
  //   hasSubNav: false,
  // },
  {
    path: '/manage-content/directory',
    icon: 'users',
    label: 'Directory',
    hasSubNav: true,
  }, {
    path: '/manage-resources',
    icon: 'users',
    label: 'Resources',
    hasSubNav: true,
  }, {
    path: '/manage-content/events',
    icon: 'users',
    label: 'Events',
    hasSubNav: false,
  }, {
    path: '/manage-content/media',
    icon: 'users',
    label: 'Media',
    hasSubNav: true,
  }, {
    path: '/manage-content/products',
    icon: 'users',
    label: 'Products',
    hasSubNav: true,
  }, {
    path: '/manage-content/roles',
    icon: 'users',
    label: 'Roles',
    hasSubNav: false,
  }, {
    path: '/manage-content/security',
    icon: 'users',
    label: 'Security',
    hasSubNav: true,
  },
  // {
  //   path: '/manage-content/payment-logs',
  //   icon: 'users',
  //   label: 'Payment Logs',
  //   hasSubNav: true,
  // },
];

const manageContentDirectory: MenuItem[] = [
  {
    path: '/manage-content/directory/bullhorns',
    icon: '',
    label: 'Bullhorns',
    hasSubNav: false,
  }, {
    path: '/manage-content/directory/channels',
    icon: '',
    label: 'Channels',
    hasSubNav: false,
  }, {
    path: '/manage-content/directory/values',
    icon: '',
    label: 'Values',
    hasSubNav: false,
  }, {
    path: '/manage-content/directory/interests',
    icon: '',
    label: 'Interests',
    hasSubNav: false,
  },
  // {
  //   path: '/manage-content/directory/goals',
  //  '',
  //   label: 'Goals',
  //   hasSubNav: false,
  // }, {
  //   path: '/manage-content/directory/quotes',
  //  '',
  //   label: 'Quotes',
  //   hasSubNav: false,
  // },
  {
    path: '/manage-content/directory/dictionary',
    icon: '',
    label: 'Dictionary',
    hasSubNav: false,
  },
  {
    path: '/manage-content/directory/segments',
    icon: '',
    label: 'Segments',
    hasSubNav: false,
  },
  // {
  //   path: '/manage-content/directory/tasks',
  //  '',
  //   label: 'Tasks',
  //   hasSubNav: false,
  // },
];


const manageResources: MenuItem[] = [
  {
    path: '/manage-resources/todos',
    icon: 'users',
    label: 'To Do',
    hasSubNav: false,
  },
  {
    path: '/manage-resources/workflows',
    icon: 'users',
    label: 'Workflow',
    hasSubNav: false,
  },
];

const orgAdminMenu: MenuItem[] = [
   {
    path: '/manage/orgs',
    icon: 'users',
    label: 'Organizations',
    hasSubNav: false,
  },
  {
    path: '/manage/orgs/ORG_ID/stickers',
    icon: 'image',
    label: 'Stickers',
    hasSubNav: false,
  },
  // {
  //   path: '/manage/orgs/ORG_ID/payment-logs',
  //   icon: 'image',
  //   label: 'Payment Logs',
  //   hasSubNav: false,
  // },
  {
    path: '/activity/:id',
    icon: 'chart-line',
    label: 'Activity',
    hasSubNav: false,
  },
];

const WebAppMenuItems: MenuItem[] = [
  {
    path: '/web/dashboard',
    icon: 'users',
    label: 'Marketplace',
    hasSubNav: false,
  }, {
    path: '/web/stickers/purchased',
    icon: 'users',
    label: 'Purchase History',
    hasSubNav: false,
  },
  // {
  //   path: '/auth/web/signin',
  //   icon: 'users',
  //   label: 'Login',
  //   hasSubNav: false,
  // },
];

export {
  dashboard, manageUsersAndOrganizations, manageContent,
  manageContentDirectory, manageContentMedia, orgAdminMenu,
  manageResources, WebAppMenuItems,
};
