<template lang="pug">
app-main-content-layout(title='Add Sticker')
  .row
    .col-4
      el-upload(
        :show-file-list="false"
        :on-success="handleSuccess"
        :on-error="handleError"
        :before-upload="beforeUpload"
        :http-request="handleStickerUpload"
        action=""
      ).img-uploader.pos-r
        template(v-if="item.url")
          img(:src="item.url").img-fluid
          .pin.pin-bx.d-flex.p-2
            el-button(type="primary").ms-auto Change
        template(v-else)
          img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
          p.upload-picture-text Drop an image file here or
          div.fake-upload-button Browse For File

    .col-7
      el-form(
        ref="refForm"
        :rules="rules"
        :model="item"
        status-icon
        label-position="top"
      )
        el-form-item(label="Sticker Title" prop="title")
          el-input(v-model="item.title")

        .row
          .col-6
            el-form-item(label="Tags" prop="tags")
              VueMultiselect(
                    v-model="item.tags",
                    :multiple="true",
                    :options="tagsArray",
                    :searchable="true",
                    :close-on-select="true",
                    :show-labels="false"
                    :loading="tagsLoading"
                    placeholder="Please enter a tag"
                  )
          .col-6
            el-form-item(label="Cost (optional)" prop="cost" v-if="!item.hasOptions")
              el-input-number(
                  v-if="item.campaignType"
                  v-model="item.cost"
                  type="number"
                  :min=1
              )
              el-input-number(
                v-else
                v-model="item.cost"
                type="number"
                :min=0
              )

          div(v-if="item.campaignType !== 'Ticket'")
              p.pt-4
                el-checkbox(v-model="allowNamingPrices" style="margin4px;") Allow users to name price

              div(v-if="allowNamingPrices" style="margin:0;")
                p.pt-4(style="{ margin: '4px' }") Quick cost options
                div.d-flex.align-items-center
                  el-input(
                    v-for="(cost, index) in item.quickCosts"
                    :key="index"
                    v-model.number="item.quickCosts[index]"
                    size="small"
                    :placeholder="'Cost ' + (index + 1)"
                    style="margin-right: 4px; margin-top: 4px"
                    type="number"
                    step="0.01"
                  )

        el-form-item(label="Details" prop="description")
          el-input(v-model="item.description" type="textarea")

        //- .d-flex.mb-2
        //-   div Match Campaign
        //-   el-switch.ms-auto(
        //-     v-model="item.campaignType"
        //-     active-color="#13ce66"
        //-     active-value="MatchCampaign"
        //-     inactive-value=null)

        .d-flex.mb-2
          div Campaign
          el-switch.ms-auto(
            v-model="item.campaignType"
            active-color="#13ce66"
            active-value="Campaign"
            inactive-value=null)

        .d-flex.mb-2
          div Ticket
          el-switch.ms-auto(
            v-model="item.campaignType"
            active-color="#13ce66"
            active-value="Ticket"
            inactive-value=null)

        //- .d-flex.mb-2
        //-   div Sweepstake Campaign
        //-   el-switch.ms-auto(
        //-     v-model="item.campaignType"
        //-     active-color="#13ce66"
        //-     active-value="SweepstakeCampaign"
        //-     inactive-value=null)

        div(v-if="item.campaignType === 'Campaign'")
          div(v-if="item.campaignType !== 'MatchCampaign'")
            el-form-item(label="Non-Profit Suggestions (optional)")
                VueMultiselect(
                  v-model="item.causes",
                  :multiple="true",
                  :options="causeOptionsArray",
                  :searchable="true",
                  :close-on-select="true",
                  :show-labels="false"
                  :loading="causeLoading"
                  placeholder="Please enter a tag"
                  @search-change="getCausesDropdownOptions"
                  :showNoOptions="false"
                )
            el-form-item(label="Max Quantity (optional)" prop="maxQuantity")
              el-input-number(v-model="item.maxQuantity")

          div(v-if="item.campaignType === 'MatchCampaign'")
            .row
              .col-6
                el-form-item(label="Max Donation" prop="maxDonation")
                  el-input(v-model="item.maxDonation" type="number")
              .col-6
                el-form-item(label="Donation Multiplier" prop="donationMultiplier")
                  el-input-number(v-model="item.donationMultiplier")
            el-form-item(label="Non-Profit Suggestions (optional)" prop="cause")
              el-select(
                v-model="item.causes"
                multiple
                filterable
                remote
                reserve-keyword
                placeholder="Please enter a cause"
                :remote-method="getCausesDropdownOptions"
                :loading="causeLoading"
                v-on:change="onCauseValueChanged"
              )
                el-option(
                  v-for="cause in causeOptions"
                  :key="cause.value"
                  :label="cause.label"
                  :value="cause.value"
                )
            el-form-item(label="Impact" prop="impact")
              el-input(v-model="item.impact")

          el-form-item(label="Start Date" prop="startDate")
            input( v-model="item.startDate" type="date" ).w-100
          el-form-item(label="End Date" prop="endDate")
            input( v-model="item.endDate" type="date" ).w-100

        div(v-if="item.campaignType")
          div(v-if="item.campaignType === 'Ticket'")
            .row
              .col-6
                el-form-item(label="Max Quantity (optional)" prop="maxQuantity")
                  el-input-number(v-model="item.maxQuantity")
              .col-6
                el-form-item(label="Event Date & Time" prop="startDate")
                  input( v-model="item.eventDate" type="datetime-local" ).w-100
            .row
              .col-6
                el-form-item(label="Sale Start Date & Time" prop="startDate")
                  input( v-model="item.startDate" type="datetime-local" ).w-100
              .col-6
                el-form-item(label="Sale End Date & Time" prop="endDate")
                  input( v-model="item.endDate" type="datetime-local" ).w-100
            .row
              .col-6
                el-form-item(label="Location" prop="location")
                  el-input(v-model="item.location")
              .col-6
                el-form-item(label="Waiver URL" prop="waiver")
                  el-input(v-model="item.waiverURL")
            el-form-item(label="Cause (optional)")
              VueMultiselect(
                v-model="item.causes",
                :multiple="true",
                :options="causeOptionsArray",
                :searchable="true",
                :close-on-select="true",
                :show-labels="false"
                :loading="causeLoading"
                placeholder="Please enter a tag"
                @search-change="getCausesDropdownOptions"
              )

              p.pt-4 Message for Confirmation Email
              el-input(v-model="item.confirmationEmail" type="textarea" placeholder="Message for Confirmation Email")
              p.pt-4 Message for Reminder email
              el-input(v-model="item.reminderEmail" type="textarea" placeholder="Message for Reminder email")

              // tiered pricing
              el-checkbox(v-model="item.hasOptions").me-4.margin-top-20 This sticker has price tiers
              div(v-if="item.hasOptions === true")
                cost-varients-list(
                :loading="true"
                :item="item"
                @emitAddPrice="addPrice"
                @emitRemovePrice="removePrice"
                @emitChangeEvent="changeEvent").mb-4




        glow-picker(ref="glowPickerRef")
      .alert.alert-danger.margin-top-20(v-if="error.message") {{ error.message }}
      el-button(type="success" @click="validateForm" v-loading="loading") Add

        // space

</template>

<script>
import {
  ref,
  reactive,
  defineComponent,
  onMounted,
  watch,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import Draggable from 'vuedraggable';
import CostVarientsList from '../../manage-content/media/stickers/CostVarientsList.vue';
import { requiredRule } from '@/data/rules';
import fileUpload from '@/shared/upload';
import shared from '../shared';
import GlowPicker from "@/components/StickerTIcket/GlowPicker.vue";
import { validateTime, joinDateAndTime } from "@/shared/utility-functions";

export default defineComponent({
  name: 'AddItem',
  components: { VueMultiselect, Draggable, CostVarientsList, GlowPicker },
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['save-item'],
  setup() {
    const { uploadStatus, beforeUpload, handleUploadRequest } = fileUpload('stickers', 'Sticker');
    const {
      getBullhornsAsTags,
      getcausesAsDropdown,
      getBenevityCauseDetails,
      router,
      route,
      updateOrgSticker,
      getOrganization,
    } = shared();

    const refForm = ref(null);
    const tagsList = ref([]);
    const tagsOptions = ref([]);
    const tagsLoading = ref(false);
    const causeList = ref([]);
    const causeOptions = ref([]);
    const causeOptionsArray = ref([]);
    const causeLoading = ref(false);
    const error = reactive({ message: '' });
    const tagsArray = ref([]);
    const orgId = route.params.orgId;
    const isStripeOnboarded = ref(false);
    const glowPickerRef = ref(null);

    const allowNamingPrices = ref(false);
    const savedQuickCosts = ref([null, null, null, null]);
    watch(allowNamingPrices, (newVal) => {
      if (!newVal) {
        savedQuickCosts.value = [...item.quickCosts];
        item.quickCosts = [null, null, null, null];
      } else {
        item.quickCosts = savedQuickCosts.value;
      }
    });

    const item = reactive({
      id: null,
      title: '',
      url: null,
      description: '',
      tags: ref([]),
      cost: 0,
      campaignType: null,
      causes: ref([]),
      // nonProfitSuggestions: ref([]),
      maxQuantity: 0,
      maxDonation: null,
      donationMultiplier: 1,
      impact: null,
      startDate: null,
      endDate: null,
      varients: reactive([{
          id:null,
          cost: null,
          title: '',
          sortOrder:0,
          stickerId:null,
          description:null,
          isActive:true,
          createdAt:null,
          updatedAt:null,
        },
      ]),
      confirmationEmail:null,
      reminderEmail:null,
      quickCosts: [null, null, null, null],
    });

    async function getBullhornsAsTagsList() {
      const bullhornTags = await getBullhornsAsTags();
      bullhornTags.forEach((element) => {
        const data = { value: `${element.bullhorn}`, label: `${element.bullhorn}` };
        tagsList.value.push(data);
        // tagsArray is used to render the correct values for the new dropdown
        tagsArray.value.push(element.bullhorn);
      });
    }

    // eslint-disable-next-line no-unused-vars
    async function getTagsDropdownOptions(query) {
      if (query) {
        tagsLoading.value = true;
        setTimeout(() => {
          tagsLoading.value = false;
          // eslint-disable-next-line arrow-body-style
          tagsOptions.value = tagsList.value.filter((dropdownItem) => {
            return dropdownItem.label.toLowerCase().includes(query.toLowerCase());
          });
        }, 200);
      } else {
        tagsOptions.value = [];
      }
    }

    async function getOrganizationDetails() {
      const organization = await getOrganization(orgId);
      isStripeOnboarded.value = organization.stripe_onboarded;
    }

    async function getCausesList(query) {
      const benevityCauses = await getcausesAsDropdown(query);
      benevityCauses.forEach((element) => {
        const data = {
          value: `${element.name} : ${element.id}`,
          label: `${element.name} : ${element.id}`,
        };
        causeList.value.push(data);
      });
    }

    // eslint-disable-next-line no-unused-vars
    async function getCausesDropdownOptions(query) {
      if (query) {
        causeLoading.value = true;
        const benevityCauses = await getcausesAsDropdown(query);
        setTimeout(() => {
          causeLoading.value = false;
          causeOptions.value = [];
          benevityCauses.forEach((element) => {
            const data = {
              value: `${element.name} : ${element.id}`,
              label: `${element.name} : ${element.id}`,
            };
            causeOptions.value.push(data);
            causeOptionsArray.value.push(`${element.name} : ${element.id}`);
          });
        }, 200);
      } else {
        causeOptions.value = [];
      }
    }

    async function submitForm() {
      item.cost = parseFloat(parseFloat(item.cost).toFixed(2));
      item.tags = item.tags.join(' |=| ');
      item.causes = item.causes.join(' |=| ');
      item.maxDonation = parseFloat(parseFloat(item.maxDonation).toFixed(2));
      item.campaignType = item.campaignType ? item.campaignType : null;
      if((item.varients[item.varients.length - 1].cost == "" || item.varients[item.varients.length - 1].cost == null) && (item.varients[item.varients.length - 1].title == "" || item.varients[item.varients.length - 1].title == null))
        item.varients.pop();
      for(let i = 0; i < item.varients.length; i++)
        item.varients[i].cost = item.varients[i].cost ? parseInt(item.varients[i].cost) : 0;
        item.quickCosts = item.quickCosts.filter(cost => !isNaN(cost) && cost !== null);

        if (item.quickCosts.every(cost => cost === 0)) {
          item.quickCosts = null;
        }

      if (glowPickerRef?.value) {
        const { dateStart, dateEnd, timeStart, timeEnd, color, glow } = glowPickerRef.value
        if (glow) {
          item.glowStartDate =  joinDateAndTime(dateStart, timeStart);
          item.glowEndDate = joinDateAndTime(dateEnd, timeEnd);
          item.glowColor = color;
        } else {
          item.glowStartDate = null;
          item.glowEndDate = null;
          item.glowColor = null;
        }
      }

        await updateOrgSticker(orgId, item);
      router.push(`/manage/orgs/${orgId}/stickers`);
    }

    function validateForm() {
      if (item.url) {
        if (glowPickerRef.value) {
          const { color, glow, dateStart, dateEnd, timeStart, timeEnd } = glowPickerRef.value;
          let message = ''
          if (glow) {
            if (!color) {
              message = 'Color missing from glow.';
            } else if (!dateStart) {
              message = 'Start date missing from glow.';
            } else if (!dateEnd) {
              message = 'End date missing from glow.';
            } else if (!validateTime(timeStart)) {
              message = 'Start time missing or incorrectly formatted.';
            } else if (!validateTime(timeEnd)) {
              message = 'End time missing or incorrectly formatted.';
            }
          }
          if (message.length) return error.message = message;
        }

        if (isStripeOnboarded.value === false && item.campaignType !== 'Campaign' && item.cost !== 0) {
          error.message = 'This organization is currently eligible only to add campaign stickers. To add ticket stickers or paid stickers, please connect your Stripe account.';
        } else {
          refForm.value.validate((valid) => {
            if (valid) return submitForm();
            return false;
          });
        }

      } else {
        error.message = 'Please upload a Sticker before continuing.';
      }
    }

    async function handleStickerUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.url = uploadStatus.resourceUrl;
        if (item.url) {
          error.message = '';
        }
      }
    }

    function handleSuccess() {
      console.log('succc');
    }

    function handleError() {
      console.log('error');
    }

    const addPrice = (sortOrder) => {
      var total = item.varients.length;
      if(total == (sortOrder+1)){
        const newPrice = {
          id:null,
          cost: null,
          title: '',
          sortOrder: total,
          stickerId:null,
          description:null,
          isActive:true,
          createdAt:null,
          updatedAt:null,
        };
        item.varients.push(newPrice);
      }
    }

    const removePrice = () => {
      item.varients.splice(item.varients.length - 2, 1); // Remove the second-to-last element
      item.varients[item.varients.length - 1].sortOrder--;
    }

    const changeEvent = (element) => {
      const propItems = item.varients;
      const movedItem = element.moved;
      if (element.moved.newIndex > element.moved.oldIndex) {
        var itemToSet = propItems.find((x) => x.sortOrder === movedItem.oldIndex);
        propItems.forEach((x) => {
          if (x.sortOrder > movedItem.oldIndex && x.sortOrder <= movedItem.newIndex) {
            // eslint-disable-next-line no-plusplus, no-param-reassign
            x.sortOrder--;
          }
        });
        itemToSet.sortOrder = movedItem.newIndex;
      } else {
        var itemToSet = propItems.find((x) => x.sortOrder === movedItem.oldIndex);
        propItems.forEach((x) => {
          if (x.sortOrder < movedItem.oldIndex && x.sortOrder >= movedItem.newIndex) {
            // eslint-disable-next-line no-plusplus, no-param-reassign
            x.sortOrder++;
          }
        });
        // eslint-disable-next-line no-plusplus, no-param-reassign
        itemToSet.sortOrder = movedItem.newIndex;
      }

      // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
      item.varients = propItems.sort((a, b) => (a.sortOrder - b.sortOrder));
    };

    onMounted(async () => {
      await getBullhornsAsTagsList();
      await getCausesList('');
      await getOrganizationDetails();
    });

    return {
      // data
      refForm,
      rules: {
        url: requiredRule('url'),
        title: requiredRule('title'),
        description: requiredRule('description'),
        tags: requiredRule('tags'),
        startDate: requiredRule('startDate'),
        endDate: requiredRule('endDate'),
      },
      item,

      // methods
      validateForm,
      beforeUpload,
      handleStickerUpload,
      handleSuccess,
      handleError,
      tagsList,
      tagsOptions,
      tagsLoading,
      causeList,
      causeOptions,
      causeLoading,
      getBullhornsAsTagsList,
      getTagsDropdownOptions,
      getCausesList,
      getCausesDropdownOptions,
      getBenevityCauseDetails,
      error,
      tagsArray,
      causeOptionsArray,
      getOrganizationDetails,
      addPrice,
      removePrice,
      changeEvent,
      allowNamingPrices,
      glowPickerRef
    };
  },
});
</script>
