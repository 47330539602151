import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import shared from './modules/shared';
import users from './modules/users';
import onboarding from './modules/onboarding';
import organizations from './modules/organizations';
import sticker from './modules/sticker';

import { activityLogs } from './modules/activity-logs';

export default createStore({
  modules: { shared, users, onboarding, activityLogs, organizations, sticker },
  plugins: [
    createPersistedState({
      paths: ['users', 'onboarding'],
    }),
  ],
});
