<template lang="pug">
.text-center
  h4.max-w-300.mx-auto.mb-5
    span.text-success  Why are you
    |  interested in joining Spotlight?

  .text-start.max-w-300.mx-auto
    el-checkbox-group(v-model="items.whyjoin")
      .py-2(v-for="item in lookups" :key="item.id")
        el-checkbox(:label="item.id") {{ item.name }}

    .py-2
      el-checkbox(v-model="items.otherChecked").checkbox-has-input
        el-input(
          placeholder="Other (type here)"
          v-model="items.whyjoin_other"
        ).w-100

  .text-center.py-4
    el-button(
      round type="success"
      @click="submitForm"
      :disabled="items.otherChecked && !items.whyjoin_other"
    ) Next
</template>

<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { whyUsItems as lookups } from '@/data/onboarding-static';
import useOnboardingRoute from './composables/useOnboardingRoute';

export default defineComponent({
  name: 'Onboarding_WhyJoiningSpotlight',
  setup() {
    const { initData, commitAndGotoNextRoute, canOnBoard } = useOnboardingRoute('whyJoiningSpotlight');
    const items = ref(initData);
    const router = useRouter();
    // const itemOther = reactive(initData[0]);

    onMounted(async () => {
      try {
        if (!canOnBoard()) {
          router.push('/');
        }
      } catch (err) {
        console.log(err);
      }
    });

    return {
      // data (static)
      lookups,

      // data (dynamic)
      items,
      // itemOther,

      // methods
      submitForm: () => commitAndGotoNextRoute(items),
    };
  },
});
</script>
