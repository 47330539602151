/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const onboardingInt = {
  orgDetail: {
    prevRoute: false,
    nextRoute: 'verify-code',
    data: {
      name: '',
      email: '',
      phone: '',
      dob: '',
      password: '',
      confirmPassword: '',
    },
    passed: false,
  },
  verifyCode: {
    prevRoute: 'org-detail',
    nextRoute: 'org-type',
    data: {
      token: '',
    },
    passed: false,
  },
  orgType: {
    prevRoute: 'verify-code',
    nextRoute: 'agree-rules',
    data: {
      orgType: null,
      parentOfOrg: null,
      nonProfitTypes: null,
      forProfitTypes: null,
    },
    passed: false,
  },
  agreeRules: {
    prevRoute: 'org-type',
    nextRoute: 'causes',
    data: {
      codeOfConduct: false,
      clubRules: false,
    },
    passed: false,
  },
  causes: {
    prevRoute: 'agree-rules',
    nextRoute: 'channels',
    data: [],
    passed: false,
  },
  channels: {
    prevRoute: 'causes',
    nextRoute: 'org-values',
    data: [],
    passed: false,
  },
  orgValues: {
    prevRoute: 'channels',
    nextRoute: 'why-joining-spotlight',
    data: [],
    passed: false,
  },
  whyJoiningSpotlight: {
    prevRoute: 'org-values',
    nextRoute: 'onboarded',
    data: {
      whyjoin: [],
      otherChecked: false,
      whyjoin_other: '',
    },
    passed: false,
  },
  onboarded: {
    prevRoute: false,
    nextRoute: false,
    passed: false,
  },
};
const useronboardingInt = {
  userDetail: {
    prevRoute: false,
    nextRoute: 'verify-code',
    data: {
      firstname: '',
      lastname: '',
      title: '',
      phone: '',
      dob: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    passed: false,
  },
  verifyCode: {
    prevRoute: 'user-detail',
    nextRoute: 'agree-rules',
    data: {
      token: '',
    },
    passed: false,
  },
  agreeRules: {
    prevRoute: 'verify-code',
    nextRoute: 'causes',
    data: {
      codeOfConduct: false,
      clubRules: false,
    },
    passed: false,
  },
  causes: {
    prevRoute: 'agree-rules',
    nextRoute: 'channels',
    data: [],
    passed: false,
  },
  channels: {
    prevRoute: 'causes',
    nextRoute: 'values',
    data: [],
    passed: false,
  },
  Values: {
    prevRoute: 'channels',
    nextRoute: 'why-joining-spotlight',
    data: [],
    passed: false,
  },
  whyJoiningSpotlight: {
    prevRoute: 'values',
    nextRoute: 'onboarded',
    data: {
      whyjoin: [],
      otherChecked: false,
      whyjoin_other: '',
    },
    passed: false,
  },
  onboarded: {
    prevRoute: false,
    nextRoute: false,
    passed: false,
  },
};
export default {
  namespaced: true,

  state: {
    prevRoute: false,
    onboarding: JSON.parse(JSON.stringify(onboardingInt)),
    useronboarding: JSON.parse(JSON.stringify(useronboardingInt)),
  },

  mutations: {
    SET_PREV_ROUTE(state, payload) {
      // payload has string | boolean
      state.prevRoute = payload;
    },
    SET_ONBOARDING_DATA(state, { page, data }) {
      // payload i.e., { page, data } in this data can be Object | Array
      state.onboarding[page].data = data;
      state.onboarding[page].passed = true;
    },

    SET_USER_ONBOARDING_DATA(state, { page, data }) {
      // payload i.e., { page, data } in this data can be Object | Array
      state.useronboarding[page].data = data;
      state.useronboarding[page].passed = true;
    },

    SET_ONBOARDING_INIT(state, payload) {
      if (payload === null) {
        state.prevRoute = false;
        state.onboarding = JSON.parse(JSON.stringify(onboardingInt));
      }
    },

    SET_USER_ONBOARDING_INIT(state, payload) {
      if (payload === null) {
        state.prevRoute = false;
        state.useronboarding = JSON.parse(JSON.stringify(useronboardingInt));
      }
    },
  },

  actions: {
    resetBoards({ commit }) {
      commit('SET_ONBOARDING_INIT', null);
      commit('SET_USER_ONBOARDING_INIT', null);
    },
  },

  getters: {
    prevRoute: (state) => state.prevRoute,
    onboarding: (state) => state.onboarding,
    useronboarding: (state) => state.useronboarding,
    returnUser: (state) => state.returnUser,
  },
};
