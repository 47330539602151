<script>
import {
  defineComponent, reactive, ref, onMounted,
} from 'vue';
import { mask } from 'vue-the-mask';
// import { phone } from '@/data/rules';
import { checkUserExists } from '@/graphs/gql';
import shared from '../../shared';

// Cache implementation
export default defineComponent({
  name: 'Authenticator',
  directives: { mask },
  setup() {
    const {
      route,
      router,
      store,
      makeGraphRequest,
    } = shared();
    const item = reactive({
      phone: '',
      email: '',
    });
    const loading = ref(false);
    const refForm = ref(null);
    const error = ref(false);
    const errorMessage = ref();


    const phoneInput = ref(null);
    const emailInput = ref(null);

    function checkAuth() {
      if (store.getters['users/isMeSignedIn']) {
        if (route.path !== '/') router.push('/');
      }
    }
    async function submitForm() {
      try {
        loading.value = true;
        error.value = false;
        errorMessage.value = null;
        let response = null;
        let authType = null;

        if (item.phone !== '') {
          authType = 'phone';
          response = await makeGraphRequest('query', checkUserExists, { phone: item.phone, email: '', userId: '' }, null);
        } else if (item.email !== '') {
          authType = 'email';
          response = await makeGraphRequest('query', checkUserExists, { phone: '', email: item.email, userId: '' }, null);
        }

        if (response.data.checkUserExists) {
          // eslint-disable-next-line
          router.push(`/auth/forgot-password/${response.data.checkUserExists.id}/verify-resetcode?authType=${authType}`);
        } else {
          error.value = true;
        }
      } catch (err) {
        error.value = true;
        errorMessage.value = 'Account Does not Exist';
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    function validateForm() {
      error.value = false;
      errorMessage.value = null;
      if (item.phone !== '' || item.email !== '') {
        refForm.value.validate((valid) => {
          if (valid) return submitForm();
          console.log('error submit!!');
          return false;
        });
      } else {
        error.value = true;
        errorMessage.value = 'Please enter your phone number or email to reset the password.';
      }
    }

    onMounted(() => checkAuth());

    return {
      refForm,
      rules: { },
      loading,
      error,
      errorMessage,
      item,
      emailInput,
      phoneInput,
      validateForm,
    };
  },
});
</script>

<template>

  <el-form
    ref="refForm"
    :rules="rules"
    :model="item"
    status-icon
    label-position="top"
    class="c-forgot-password"
  >

    <section>
      <div class="c-forgot-password__heading">Forgot Password</div>
      <div class="c-forgot-password__subheading">Enter your phone or email to reset your password</div>
    </section>

    <div class="alert alert-danger" v-if="error">
      {{ errorMessage || 'There has been an error' }}
    </div>

    <section class="c-forgot-password__items">
      <el-form-item prop="phone" @click="phoneInput.focus()">
        <label>Phone</label>
        <input
          v-model="item.phone"
          type="tel"
          placeholder="(123) 456-7890"
          ref="phoneInput"
        />
      </el-form-item>

      <el-form-item prop="email"  @click="emailInput.focus()">
        <label>Email</label>
        <input
          v-model="item.email"
          type="email"
          placeholder="abcd@spotlight.com"
          ref="emailInput"
        />
      </el-form-item>

    </section>


    <el-button class="c-forgot-password__submit" round type="success" @click="validateForm" v-loading="loading">
      Reset Password
    </el-button>
  </el-form>

</template>

<style lang="scss">
.c-forgot-password {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .el-form-item {
    margin-bottom: 0;
  }

  &__heading, &__subheading {
    font-family: 'Red Hat Display';
    text-align: center;
  }

  &__heading {
    color: #333333;
    font-size: 30px;
    font-weight: bold;
  }

  &__subheading {
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
  }

  &__submit.el-button.el-button--success {
    width: 100%;
    height: 48px;
    font-size: 16px;
    border-radius: 56px;
    background-color: #005C6B;
    border-color: #005C6B;
  }

  &__items {
    display: flex;
    gap: 50px;
    flex-direction: column;

    .el-form-item__content {
      height: 75px;
      border: 1px solid #DEDEDE;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      label {
        font-family: "Red Hat Display";
        font-size: 12px;
        color: #666666;
        line-height: 12px;
        font-weight: 400;
      }

      input {
        box-shadow: none;
        border: 0 none transparent;
        outline: none;
        &::placeholder {
          opacity: 0.5;
        }
      }
    }
  }
}

</style>

