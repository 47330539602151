<!-- eslint-disable max-len -->
<template lang="pug">
web-app-layout(:showLogo="true")
  div
    div
      .img-uploader.pos-r.mb-4
        template(v-if="item.url")
          img(:src="item.url").img-fluid.el-upload
    div.py-2
      h6.mb-0.sticker-tag-text(v-if="item.campaignType") {{ item.campaignType }}
    div(v-if="item.organization == null")
      .row.row-xs.align-items-center.py-2
        .col-auto
          img(:src="item.user.avatar"
            v-if="item.user.avatar").d-block.size-18.obj-f-c.rounded-circle
          img(src="@/assets/images/default-profile.jpeg"
            v-if="!item.user.avatar").d-block.size-18.obj-f-c.rounded-circle
        .col.lh-1 {{ item.user.firstname }} {{ item.user.lastname }}
    div(v-if="item.organization != null")
      .row.row-xs.align-items-center.py-2
        .col-auto
          img(:src="item.organization.avatar"
            v-if="item.organization.avatar").d-block.size-18.obj-f-c.rounded-circle
          img(src="@/assets/images/default-profile.jpeg"
            v-if="!item.organization.avatar").d-block.size-18.obj-f-c.rounded-circle
        .col.lh-1 {{ item.organization.name }}
    div
      h2.mb-0.py-2 {{ item.title }}
    div
      .fs-14.sticker-price-color-text
        span
          span(v-if="item.cost == 0") FREE
          span(v-else) ${{ item.cost.toFixed(2) }}
    div(v-if="item.eventDate").row
      .col-6
        h5.mb-0.py-2 {{ getformattedDate(item.eventDate) }}
        .fs-18.lh-2 Date
      .col-6
        h5.mb-0.py-2 {{ getformattedTime(item.eventDate) }}
        .fs-18.lh-2 Time
    div.py-4(v-if="item.campaignType && item.campaignType === 'Ticket'")
      el-button(
        type='success' round
        @click='onStickerActionButtonClick'
        v-loading='loading'
      ).w-80.mb-2.width-80-percent
        span
          span(v-if="item.cost == 0 || isStickerPurchased === true") Add Sticker To Your Profile
          span(v-else-if="item.campaignType && item.campaignType === 'Ticket'") RSVP
          span(v-else-if="item.campaignType && item.campaignType === 'Campaign'") Donate
          span(v-else) Purchase

      el-button(
        type='success' round
        @click='addToCalendarClick(item.eventDate, item.description, item.location, item.title)'
        v-loading='loading'
      ).mb-2.add-calendar-btn
        fai(type="fal" icon="calendar-plus").size-24
    div.py-4(v-else)
      el-button(type='success' round
          @click='onStickerActionButtonClick' v-loading='loading').w-100.mb-2
            span
              span(v-if="item.cost == 0 || isStickerPurchased === true") Add Sticker To Your Profile
              span(v-else-if="item.campaignType && item.campaignType === 'Campaign'") Donate
              span(v-else) Purchase

    div(v-if="item.location")
      h6.mb-0.py-2 Location
      .fs-18.lh-2 {{ item.location }}

    div
      h6.mb-0.py-2 Details
      .fs-18.lh-2 {{ item.description }}

    div(v-if="item.waiverURL && item.waiverURL.indexOf('http')>=0")
      h6.mb-0.py-2 Waiver
      iframe(
        :src="item.waiverURL"
        :title="item.waiverURL"
        width="100%"
        height="250px"
      )
</template>

<script lang="ts">
import {
  defineComponent, onMounted, reactive, ref,
} from 'vue';
import { DateTime } from 'luxon';
import { useStore } from 'vuex';
import WebAppLayout from '../dashboard/WebAppLayout.vue';
import gqlRequest from '../dashboard/gql-request';
import DialogPromptDownloadApp from '../dashboard/DialogPromptDownloadApp.vue';
// import DialogPromptDownloadApp
// from '../../manage-users-and-organizations/DialogAddUserInvite.vue';

export default defineComponent({
  name: 'WebAppSticker',
  components: { WebAppLayout, DialogPromptDownloadApp },
  setup() {
    const {
      route, router, getPublicSticker,
    } = gqlRequest();

    const { stickerId } = route.params;
    const firstCauseName = ref('');
    const store = useStore();
    const isPromptDownloadApp = ref(false);
    const isStickerPurchased = ref(false);
    const loading = ref(false);

    const item = reactive({
      id: null,
      title: '',
      description: '',
      url: '',
      published: false,
      tags: ref([]),
      cost: 0,
      campaignType: null,
      causes: ref(['']),
      // nonProfitSuggestions: ref([]),
      maxQuantity: 0,
      maxDonation: null,
      donationMultiplier: 1,
      impact: null,
      startDate: null,
      endDate: null,
      user: { firstname: '', lastname: '', avatar: null },
      organization: { name: '', avatar: null },
      creatorTitle: null,
      isFeatured: false,
      isCausesSearchAllowed: false,
    });

    function checkAuth() {
      if (store.getters['users/isMeSignedIn']) {
        if (route.path !== `/web/sticker/${stickerId}`) {
          router.push(`/web/sticker/${stickerId}`);
        }
      }
    }

    function getCausesList() {
      let firstCause = '';
      console.log('causes are ', item.causes);
      if (item.causes.length) {
        // eslint-disable-next-line prefer-destructuring
        // eslint-disable-next-line prefer-destructuring
        // firstCauseName.value = `Cause: ${firstCause.split(' : ')[0]}`;
        // firstCauseName.value = item.causes.map((x) => x.split(' : ')[0]);
        // eslint-disable-next-line array-callback-return
        item.causes.map((x, index) => {
          if (index === 0) {
            firstCause = `${firstCause} ${x.split(' : ')[0]}`;
          } else { firstCause = `${firstCause}, ${x.split(' : ')[0]}`; }
        });
        console.log('firstCause', firstCause);
      }
      return firstCause;
    }

    async function getItem() {
      const sticker = await getPublicSticker(stickerId);
      Object.assign(item, sticker);
      item.causes = (sticker.causes !== null && sticker.causes !== '') ? sticker.causes.split(' |=| ') : [];
      getCausesList();
      // item.tags = (item.tags !== null && item.tags !== '') ? item.tags.split(' |=| ') : [];
      // [item.postDate, item.postTime] = dateAndTime(itm.createdAt);
      // // eslint-disable-next-line prefer-destructuring
      // item.startDate = new Date(item.startDate).toISOString().split('T')[0];
      // // eslint-disable-next-line prefer-destructuring
      // item.endDate = new Date(item.endDate).toISOString().split('T')[0];
    }

    function getformattedDate(timeOffset: string|number|Date) {
      const t = DateTime.fromISO(timeOffset.toString());
      return `${t.toFormat('MM/dd/yy')}`;
    }

    function getformattedTime(timeOffset: string|number|Date) {
      const t = DateTime.fromISO(timeOffset.toString(), { zone: 'utc' });
      return `${t.toFormat('hh:mm a')}`;
    }

    function addToCalendarClick(date: any, description: any, location: any, title: any) {
      /* Add to calendar needs the following format:
      https://calendar.google.com/calendar/render?action=TEMPLATE
        &dates=20230202T064500Z%2F20230202T071500Z
        &details=This%20is%20my%20description
        &location=home
        &text=Title */
      /*  the code below is to convert the date and time to a format that Google Calendar accepts:
        20230202T064500Z
        */
      const a = `${date.split('.')[0]}Z`;
      const b = (a).replaceAll('-', '');
      const c = b.replaceAll(':', '');
      const addToCalendarURL = `${import.meta.env.VITE_GOOGLE_CALENDAR_BASE_URL}/render?action=TEMPLATE&dates=${c}%2F${c}${'&details='}${description}&location=${location}&text=${title}`;
      window.open(addToCalendarURL, '_blank');
    }

    async function onStickerActionButtonClick() {
      router.push(`/auth/web/signup?stickerId=${stickerId}`);
    }

    onMounted(async () => {
      loading.value = true;
      await checkAuth();
      await getItem();
      store.commit('shared/SET_MAIN_MENU_COLLAPSE', false);
      loading.value = false;
    });

    return {
      route,
      router,
      loading,
      // data
      item,
      getCausesList,
      firstCauseName,
      onStickerActionButtonClick,
      isPromptDownloadApp,
      isStickerPurchased,
      getformattedDate,
      getformattedTime,
      addToCalendarClick,
    };
  },
});
</script>
<style>
.sticker-tag-text {
  background-color: #1fbeaf;
  color: white;
  border-radius: 3px !important;
  padding: 4px 8px;
  width: fit-content;
  text-transform: uppercase !important;
}
.sticker-price-color-text {
  color: #005c6b !important;
}
</style>
