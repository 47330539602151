<template lang="pug">
app-main-content-layout(title='Workflows')
  template(#actions)
    el-button(type="primary" @click="dialog=true").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Add New

  div
    el-table(
      :data="items.items"
      v-loading="loading"
      @row-click="$router.push(`workflows/${$event.id}`);"
      style="width: 100%"
    ).clickable-rows
      el-table-column(width="15")
      el-table-column(label="Title" prop="title")
      el-table-column(width="46")
        .el-table__expand-icon
          img(src="@/assets/icons/light/chevron-right-light.svg")
      el-table-column(width="15")
        // space

    DialogAddWorkflow(
      v-if="dialog"
      :loading="loading"
      v-model="dialog"
      @save-item="saveWorkflow($event)"
      @close-dialog="dialog = false"
    )
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted } from 'vue';
import DialogAddWorkflow from './DialogAddWorkflow.vue';
import shared from '../shared';

export default defineComponent({
  name: 'Workflows',
  components: { DialogAddWorkflow },
  setup() {
    const {
      doFindWorkflows,
      doMutateWorkflow,
      loading,
      dialog,
    } = shared();
    const items = reactive({ items: [] });
    async function getItems() {
      try {
        loading.value = true;
        items.items = await doFindWorkflows();
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }
    async function saveWorkflow(item: any) {
      await doMutateWorkflow(item);
      await getItems();
    }
    onMounted(async () => {
      await getItems();
    });
    return {
      items,
      loading,
      dialog,
      saveWorkflow,
    };
  },
});

</script>
