import {
  findCategories,
  mutateCategory,
  findEntityQuotes,
  mutateEntityQuote,
} from '@/graphs/gql';
import globalShared from '@/shared';

export default function commonShared(entity: string): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    removeItemGetConfirm,
    makeGraphRequest,
    getAuthToken,
    dateAndTime,
  } = globalShared();

  async function getTypedCategories(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findCategories, { type: entity }, getAuthToken(),
    );
    return response.data.findCategories;
  }

  async function getEntityQuotes(entityType: string, entityId: number): Promise<any> {
    const response = await makeGraphRequest(
      'query', findEntityQuotes, { entityType, entityId }, getAuthToken(),
    );
    return response.data.findEntityQuotes;
  }

  async function saveCategory(input: any) {
    await makeGraphRequest(
      'mutation', mutateCategory, { input }, getAuthToken(),
    );
  }

  async function saveEntityQuote(input: any) {
    await makeGraphRequest(
      'mutation', mutateEntityQuote, { input }, getAuthToken(),
    );
  }

  function exportData(items: any) {
    const encodedUri = 'data:text/json;charset=utf-8,'
      + `${encodeURIComponent(JSON.stringify(items))}`;
    console.log(encodedUri);
    const lnk = document.createElement('a');
    lnk.setAttribute('href', encodedUri);
    lnk.setAttribute('download', 'data.json');
    document.body.appendChild(lnk);
    lnk.click();
  }

  return {
    ref,
    route,
    router,
    getTypedCategories,
    saveCategory,
    removeItemGetConfirm,
    makeGraphRequest,
    getEntityQuotes,
    saveEntityQuote,
    getAuthToken,
    dateAndTime,
    exportData,
  };
}
