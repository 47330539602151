<template lang="pug">
div
  app-main-menu
    //- deafult menu items render here

  app-main-content
    h1 Marketing
    h4 Content - WIP
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Marketing',
  setup() {
    return {
      // data
      input: ref(''),
      user: {},
    };
  },
});
</script>
