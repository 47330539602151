<template lang="pug">
app-main-content-layout(title='Home Page')
  template(#actions)
      el-button(type="primary" @click="mSaveCMSHomeScreenData" color="#00BFB0" v-loading="loading").has-fai
        fai(type="fal" icon="edit").size-18.me-2
        span.min-w-110.d-inline-block Save
  div
    home-page-banners(
      :myCMSHomeScreenData='myCMSHomeScreenData',
    ).mb-5
      //- renders HomePageBanners
    .row
      div.filter-container
        span.padding-left-20( @click="currentView = 'Spot'"
      :class="getClass('Spot')") Spot collection
        span.padding-left-20( @click="currentView = 'Sticker'"
      :class="getClass('Sticker')") Sticker collection
        span.padding-left-20( @click="currentView = 'Organization'"
      :class="getClass('Organization')") Organization collection
    .row
      div(v-if="currentView === 'Spot'")
        home-page-spots(
          :myCMSHomeScreenData='myCMSHomeScreenData',
        )
          //- renders HomePageSpots

      div(v-if="currentView === 'Sticker'")
        home-page-stickers(
          :myCMSHomeScreenData='myCMSHomeScreenData',
        )
          //- renders HomePageStickers

      div(v-if="currentView === 'Organization'")
        home-page-org(
          :myCMSHomeScreenData='myCMSHomeScreenData',
        )
          //- renders HomePageOrg
</template>

<script>

import {defineComponent, onMounted, reactive, ref, computed} from 'vue';
import HomePageBanners from './components/HomePageBanners.vue';
import HomePageSpots from './components/HomePageSpots.vue';
import HomePageStickers from './components/HomePageStickers.vue';
import HomePageOrg from './components/HomePageOrg.vue';
import shared from '../shared/index';

export default defineComponent({
  name: 'ManageContent_Media_HomePage',
  components: {
    HomePageOrg,
    HomePageSpots,
    HomePageBanners,
    HomePageStickers,
  },
  setup() {
    const {
      fetchCMSHomeScreenData,
      saveCMSHomeScreenData,
    } = shared();
    const myCMSHomeScreenData = reactive({});
    const state = reactive({
      dialog: false,
      loading: false,
    });
    const loading = ref(false);

    async function getCMSHomeScreenData() {
      const mCMSHomeScreenData = await fetchCMSHomeScreenData();
      Object.assign(myCMSHomeScreenData, mCMSHomeScreenData);
      return mCMSHomeScreenData;
    }

    async function mSaveCMSHomeScreenData() {
      state.loading = true;
      const input = JSON.parse(JSON.stringify(myCMSHomeScreenData));

      const metaCarousels = [];
      const mMetaCarousel = JSON.parse(JSON.stringify(input.metaCarousel));
      mMetaCarousel.forEach((t) => {
        const metaCarousel = {
          id: t.id,
          title: t.title,
          image: t.image,
          order: t.order,
        };
        metaCarousels.push(metaCarousel);
      });

      const metaFeaturedSpotss = [];
      const mMetaFeaturedSpots = JSON.parse(JSON.stringify(input.metaFeaturedSpots));
      mMetaFeaturedSpots.forEach((t) => {
        const metaCarousel = {
          id: t.id,
          title: t.title,
          image: t.image,
          order: t.order,
        };
        metaFeaturedSpotss.push(metaCarousel);
      });

      const metaFeaturedStickerss = [];
      const mMetaFeaturedStickers = JSON.parse(JSON.stringify(input.metaFeaturedStickers));
      mMetaFeaturedStickers.forEach((t) => {
        const metaCarousel = {
          id: t.id,
          title: t.title,
          image: t.image,
          order: t.order,
        };
        metaFeaturedStickerss.push(metaCarousel);
      });

      const metaFeaturedOrgss = [];
      const mMetaFeaturedOrgss = JSON.parse(JSON.stringify(input.metaFeaturedOrgs));
      mMetaFeaturedOrgss.forEach((t) => {
        const metaCarousel = {
          id: t.id,
          title: t.title,
          image: t.image,
          order: t.order,
        };
        metaFeaturedOrgss.push(metaCarousel);
      });

      await saveCMSHomeScreenData({
        id: input.id,
        orgCollectionTitle: input.orgCollectionTitle,
        stickerCollectionTitle: input.stickerCollectionTitle,
        spotCollectionTitle: input.spotCollectionTitle,
        metaCarousel: metaCarousels,
        metaFeaturedSpots: metaFeaturedSpotss,
        metaFeaturedStickers: metaFeaturedStickerss,
        metaFeaturedOrgs: metaFeaturedOrgss,
      });

      await getCMSHomeScreenData();
      state.loading = false;
    }
    // ------active component-----
    const currentView = ref('Spot');
    function getClass(currentItem) {
      if (currentItem === currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }

    const headerFilter = computed(() => {
      switch (currentView.value) {
        case 'active':
          items.items = allItems.items.filter((item) => item.active === true);
        break;
        case 'inActive':
          items.items = allItems.items.filter((item) => item.active === false);
        break;
        case 'invited':
          items.items = invited.items.filter((item) => item.entity === 'User');
        break;
        default:
          items.items = allItems.items;
      }
    })

    onMounted(async () => {
      state.loading = true;
      await getCMSHomeScreenData();
      state.loading = false;
    });

    return {
      myCMSHomeScreenData,
      currentView,
      loading,
      mSaveCMSHomeScreenData,
      headerFilter,
      getClass,
    };
  },
});
</script>
