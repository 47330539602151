<template lang="pug">
app-main-content-layout(title='Stickers')
  template(#actions)
    el-button(
      type="primary"
      @click="$router.push(`/manage-content/media/add-stickers-v2`)"
      color='#1EBEAF'
      size="large").add-new-button
      span Add New
      template(#icon)
        img(src="@/assets/icons/plusIcon.svg" style="width: 20px; height: 20px;")

  div
    div.filter-container
      span.padding-left-20(@click="headerFilter('ALL')"
      :class="getClass('ALL')") All
      span.padding-left-20(@click="headerFilter('Approved')"
        :class="getClass('Approved')") Approved
      span.padding-left-20(@click="headerFilter('Pending')"
      :class="getClass('Pending')") Pending
      span.padding-left-20(@click="headerFilter('Archived')"
      :class="getClass('Archived')") Archived
    .container.align-items-center
      .row.align-items-center
        .col-lg
          .row.align-items-center {{selected.length}} selected
            .col-auto()
                  VueMultiselect(
                  v-model="setAs"
                  :multiple="false",
                  :options="setAsOption",
                  :searchable="false",
                  :close-on-select="true",
                  :show-labels="false"
                  :disabled="selected.length === 0"
                  label="label"
                  placeholder="Set as"
                  @select="bulkUpdate(setAs.value)"
                  style="width: 180px;"
                )
        .col-md
            .row.align-items-center
              el-input(
                v-model="textFilter"
                placeholder="Filter Stickers"
                suffix-icon="el-icon-users"
                style="width: 200px;"
                @change="filterStickers"
              ).input-search-filter
                template(#suffix='')
                  img(
                    src="@/assets/icons/searchIcon.svg",
                    style="font-size: 20px;")
              el-button(type="info" v-show="isFiltered" @click="clearTextFilter").has-fai.clear-filter-button
                fai(type="fal" icon="times").me-2
                span Clear Filter
              .col-auto()
                VueMultiselect(
                    v-model="sortBy",
                    :multiple="false",
                    :options="sortOptions",
                    :searchable="false",
                    :close-on-select="true",
                    :show-labels="false"
                    label="label"
                    placeholder="Sort"
                    @close="sortTable"
                    style="width: 180px;"
                  )


    el-table(
      :data="items.items"
      @row-click="$router.push(`stickers-v2/${$event.id}`)"
      @selection-change="onCheckboxChange"
      v-loading="loading"
      style="width: 100%"
    ).clickable-rows
      el-table-column(width="40")
        template(#default="scope")
          //- input(type="checkbox"
          //- v-model="scope.row.isChecked" @click="onCheckboxChange($event, scope.row.id)")
        // space

      el-table-column(type="selection" width="55")

      el-table-column(width="150")
        template(#default="scope")
          img(:src="scope.row.url").size-50px.obj-f-c
      el-table-column.table-title(prop="title" label="Title" width="200" class="table-title")
        template(#default="scope")
          div.table-title {{scope.row.title}}
      //- el-table-column.table-title(prop="link" label="Link" width="300" class="table-link-stickers")
      //-   template(#default="scope")
      //-     div.table-link-stickers Share sticker
      el-table-column(prop="published" label="Type" width="200" class="table-title")
        template(#default="scope")
          .font-medium  Sticker
      el-table-column(prop="published" label="Status" width="300" class="table-title")
        template(#default="scope")
          div(:class="getButtonClass(stickerState(scope.row))")
            p {{ stickerState(scope.row) }}
      el-table-column(width="500")
        // space
</template>
<style>

</style>
<script>

import {
  reactive, onMounted, defineComponent, ref,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import DialogAddSticker from './DialogAddSticker.vue';
import shared from '../shared';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ManageContent_Media_Stickers',
  components: { DialogAddSticker, VueMultiselect },
  setup() {
    const {
      getStickers,
      updateSticker,
      loading,
      dialog,
      saveStickerStatusBulk,
    } = shared();
    const items = reactive({ items: [] });
    const allItems = reactive({ items: [] });
    const store = useStore();

    const currentView = ref('ALL');
    const selected = ref([]);
    const textFilter = ref('');
    const isFiltered = ref(false);
    const sortOptions = [
      { label: 'Most Recent', value: 'date_desc' },
      { label: 'Oldest First', value: 'date_asc' },
      { label: 'Alphabetical (A-Z)', value: 'title' },
      { label: 'Alphabetical (Z-A)', value: 'title_desc' }
    ];

    const sortBy = ref('');
    const setAsOption = [{label:'Approved', value:'Active'},
                          {label:'Pending', value:'Pending'},
                          {label:'Archived', value:'Archived'}]
    const setAs = ref('');
    function clearTextFilter() {
      textFilter.value = '';
      isFiltered.value = false;
      items.items = allItems.items;
    }

    function strToDate(str) {
      return new Date(str).getTime();
    }

    function onCheckboxChange(checkedItems) {
      console.log('checkedItems', checkedItems);
      selected.value = checkedItems;
      // // eslint-disable-next-line max-len
      // if (selected.value.indexOf(listItem) < 0) {
      //  selected.value.push(listItem); } else { selected.value.pop(listItem); }
    }

    function sortTable() {
      const sortValue = sortBy.value.value;

      switch (sortValue) {
        case 'date_desc':
          items.items = items.items.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB - dateA;
          });
          break;
        case 'date_asc':
          items.items = items.items.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateA - dateB;
          });
          break;
        case 'title':
          items.items = items.items.sort((a, b) => {
            const titleA = a?.title || '';
            const titleB = b?.title || '';
            return titleA.localeCompare(titleB);
          });
          break;
        case 'title_desc':
          items.items = items.items.sort((a, b) => {
            const titleA = a?.title || '';
            const titleB = b?.title || '';
            return titleB.localeCompare(titleA);
          });
          break;
        default:
          case 'date_desc':
      }
    }

    function filterStickers() {
      if (textFilter.value === '') {
        isFiltered.value = false;
      }
      const regex = new RegExp(`${textFilter.value}`, 'gi');
      items.items = allItems.items.filter((x) => {
        if (x.title && x.title.match(regex)) {
          isFiltered.value = true;
          return true;
        }
        return false;
      });
    }

    function getCreator(itm) {
      if (itm.isSpotlight) {
        return 'Spotlight';
      }
      if (itm.entity === 'Organization') {
        return itm.organization.name;
      }
      return itm.creatorTitle;
    }

    function stickerState(itm) {
      let statusText = '';
      statusText = itm.published ? 'Approved' : 'Pending';
      if (itm.status === 'Archived') {
        statusText = 'Archived';
      }
      return statusText;
    }

    function getClass(currentItem) {
      if (currentItem === currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }

    function getButtonClass(currentItem) {
      switch (currentItem) {
        case 'Pending':
          return 'pending-button';
        case 'Archived':
          return 'archived-button';
        default:
          return 'approved-button';
      }
    }

    function headerFilter(filterBy) {
      currentView.value = filterBy;
      if (filterBy === 'ALL') {
        items.items = allItems.items;
      } else {
      // eslint-disable-next-line array-callback-return
        items.items = allItems.items.filter((item) => item.status === filterBy);
      }
    }

    async function getItems() {
      try {
        loading.value = true;
        items.items = await getStickers();
        // eslint-disable-next-line array-callback-return
        items.items.map((innerItem) => {
          // set status on the sticker object so it can be easily filtered
          // eslint-disable-next-line no-param-reassign
          innerItem.status = stickerState(innerItem);
          // eslint-disable-next-line no-param-reassign
          innerItem.isChecked = false;
        });
        // set the default order to be descending
        items.items = items.items.sort((a, b) => strToDate(b.createdAt) - strToDate(a.createdAt));
        allItems.items = items.items;
        // console.log('all items are ', allItems);
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    async function bulkUpdate(type) {
      const bulkStickers = [];
      selected.value.forEach((element) => {
        const item = { id: element.id, status: type };
        bulkStickers.push(item);
      });
      await saveStickerStatusBulk(bulkStickers);
      await getItems();
      setAs.value = '';
    }

    async function saveItem(input) {
      await updateSticker(input);
      await getItems();
    }

    onMounted(async () => {
      await store.dispatch('sticker/RESET_TO_DEFAULT');
      await getItems();
    });

    return {
      items,
      loading,
      dialog,
      currentView,
      textFilter,
      isFiltered,
      sortOptions,
      sortBy,
      selected,
      setAsOption,
      setAs,
      // methods
      getCreator,
      saveItem,
      stickerState,
      headerFilter,
      getClass,
      filterStickers,
      clearTextFilter,
      sortTable,
      onCheckboxChange,
      bulkUpdate,
      getButtonClass,
    };
  },
});
</script>
