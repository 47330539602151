import {
  findValues,
  findValue,
  mutateValue,
} from '@/graphs/gql';

import commonShared from '../shared';

export default function shared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    getAuthToken,
    removeItemGetConfirm,
    makeGraphRequest,
    getEntityQuotes,
    saveEntityQuote,
    dateAndTime,
    exportData,
  } = commonShared('VALUE');

  const loading = ref(false);
  async function saveValue(input: { [key: string]: any }): Promise<any> {
    await makeGraphRequest(
      'query', mutateValue, { input }, getAuthToken(),
    );
  }

  async function getValues(getfavourite: boolean): Promise<any> {
    const response = await makeGraphRequest(
      'query', findValues, { getfavourite }, getAuthToken(),
    );
    return response.data.findValues;
  }

  async function getValue(id: number): Promise<any> {
    const response = await makeGraphRequest(
      'query', findValue, { id }, getAuthToken(),
    );
    return response.data.findValue;
  }

  return {
    loading,
    route,
    router,
    getValues,
    getValue,
    saveValue,
    removeItemGetConfirm,
    getEntityQuotes,
    saveEntityQuote,
    dateAndTime,
    exportData,
  };
}
