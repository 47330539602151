<template lang="pug">
el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(title='Bullhorns' back-to='/manage-content/directory/bullhorns')
    template(#actions)
      .row.row-sm.align-items-center
        .col-auto
          div(@click.stop="handleToggleAddToFav").cp
            img(v-if="item.favorite" src="@/assets/icons/star-green.svg").size-32
            img(v-else src="@/assets/icons/star.svg").size-32

        .col-auto
          el-button(type="danger" plain @click="removeBullhornItem").has-fai
            fai(type="fal" icon="trash").size-18.me-2
            span.min-w-110.d-inline-block Delete
          el-button(type="primary"  color="#1EBEAF"  @click="validateForm" v-loading="loading").has-fai
            fai(type="fal" icon="edit").size-18.me-2
            span.min-w-110.d-inline-block Save  
    
    template(#col-left)
      div
        el-form-item(label="Bullhorn Name" prop="bullhorn")
          el-input(v-model="item.bullhorn")
       
        el-form-item(label="Category", prop="categoryId")
          VueMultiselect(
            v-model="item.categoryId",
            :multiple="false",
            label="category",
            track-by="id",
            :options="categories.categories",
            :searchable="true",
            :close-on-select="true",
            :show-labels="false"
            placeholder="Pick a value"
          )

        el-form-item(label="Description" prop="description").mb-0
          el-input(v-model="item.description" type="textarea")

      div
        el-form-item.mb-0
          template(#label)
            h5 Quotes
          .p-3.bg-light.rounded
            quote(
              v-for="(quote, index) in item.quotes" :key="index"
              :item="quote" @save-item="handleAddNewQuote($event)"
              @delete-item="handleDeleteQuote($event)"
            ).mb-3
            div(v-if="addNewQuote")
              quote(
                is-new-item
                :item="{ author: '', quote: '' }"
                @save-item="handleAddNewQuote($event)"
                @cancel-item="addNewQuote = false"
              )
            .text-center(v-else)
              el-button(type="primary"  color="#1EBEAF"  @click="addNewQuote = true" ).has-fai
                span.min-w-110.d-inline-block Add New

    template(#col-right)
      div
        el-form-item(label="Creator")
          el-input(v-model="item.creator" disabled)

        el-form-item
          template(#label)
            | Post Date
            span.text-muted.ms-2 Active for 3 Days
          el-input(v-model="item.postDate" disabled)

        el-form-item(label="Post Time").mb-0
          el-input(v-model="item.postTime" disabled)
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';
import { bullhorn, category } from '@/data/rules';
import VueMultiselect from 'vue-multiselect';
import shared from './shared';
import Quote from '../components/Quote.vue';

export default defineComponent({
  name: 'ManageContent_Directory_BullhornDeail',
  components: { Quote, VueMultiselect },
  setup() {
    const {
      route,
      router,
      loading,
      getTypedCategories,
      getBullhorn,
      saveBullhorn,
      removeItemGetConfirm,
      dateAndTime,
      getEntityQuotes,
      saveEntityQuote,
    } = shared();
    const refForm = ref(null);
    const addNewQuote = ref(false);
    const { id } = route.params;
    const categories = reactive({ categories: [] });
    const item = reactive({
      id: -1,
      bullhorn: 'Very Long Spot Name',
      category: {

      },
      favorite: false,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      quotes: [],
      creator: 'Admin Name',
      postDate: '02/12/2021',
      postTime: '2:30 PM',
    });

    async function getItem() {
      loading.value = true;
      const itm = await getBullhorn(parseInt(id, 10));
      item.id = itm.id;
      item.bullhorn = itm.bullhorn;
      item.description = itm.description;
      item.creator = itm.createdBy;
      item.favorite = itm.favorite;
      item.categoryId = itm.category;
      [item.postDate, item.postTime] = dateAndTime(itm.createdAt);
      loading.value = false;
    }

    async function saveItem() {
      loading.value = true;
      await saveBullhorn({
        id: parseInt(item.id, 10),
        bullhorn: item.bullhorn,
        description: item.description,
        categoryId: parseInt(item.categoryId.id, 10),
      });
      loading.value = false;
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    onMounted(async () => {
      categories.categories = await getTypedCategories();
      await getItem();
      item.quotes = await getEntityQuotes('BULLHORN', parseInt(id, 10));
    });

    async function removeItem() {
      try {
        await saveBullhorn({
          id: parseInt(item.id, 10),
          delete: true,
        });
      } catch (err) {
        console.log('err', err);
      }
      router.push('/manage-content/directory/bullhorns');
      // console.log('remvoe item here :>> ');
    }

    function removeBullhornItem() {
      removeItemGetConfirm(removeItem);
    }

    async function handleAddNewQuote(quote) {
      const input = {
        entityType: 'BULLHORN',
        entityId: parseInt(id, 10),
        author: quote.value.author,
        quote: quote.value.quote,
      };
      if (quote.value.id) {
        input.id = parseInt(quote.value.id, 10);
      }
      await saveEntityQuote(input);
      item.quotes = await getEntityQuotes('BULLHORN', parseInt(id, 10));
      addNewQuote.value = false;
    }

    async function handleDeleteQuote(quote) {
      removeItemGetConfirm(async () => {
        const input = {
          id: parseInt(quote.id, 10),
          entityType: 'BULLHORN',
          entityId: parseInt(id, 10),
          delete: true,
        };
        try {
          await saveEntityQuote(input);
        } catch (e) {
          console.log(e);
        }
        item.quotes = await getEntityQuotes('BULLHORN', parseInt(id, 10));
      });
    }

    async function handleToggleAddToFav() {
      item.favorite = !item.favorite;
      loading.value = true;
      await saveBullhorn({
        id: parseInt(item.id, 10),
        favorite: item.favorite,
      });
      loading.value = false;
    }

    return {
      // data
      refForm,
      rules: { bullhorn, categoryId: category },
      item,
      loading,
      categories,
      addNewQuote,

      // methods
      validateForm,
      removeBullhornItem,
      handleAddNewQuote,
      handleDeleteQuote,
      handleToggleAddToFav,
    };
  },
});
</script>
