<!-- eslint-disable max-len -->
<template lang="pug">
.px-3
  .max-w-300.mx-auto.mb-4
    .d-flex.mb-4
      div
        .fs-22.text-primary.lh-n {{stickerDetails.campaignType == null ? '' : stickerDetails.campaignType === 'Campaign' ? 'Donate' : 'Purchase'}}
        .fs-12 {{ userName() }}
      .ms-auto
        img(:src="avatar()").size-48.obj-f-c.rounded-circle

    .row.row-xs.align-items-center
      .col-auto
        img(:src="stickerDetails.url").w-60.obj-f-c
      .col.lh-1
          .ms-auto {{ stickerDetails.title }}
          .fs-12 {{ stickerDetails.creatorTitle }}
      .col-auto
        .ms-auto ${{ parseFloat(amt).toFixed(2) }} {{ assignStickerCostToAmount() }}
      .alert.alert-danger.margin-top-20(v-if="!isValid()") {{ validationError() }}

</template>

<script>
import {
  ref, defineComponent,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'StickerInputForm',
  props: {
    isEditable: { type: Boolean, default: false },
    userDetails: { type: Object, default: () => ({}) },
    showForm: { type: Boolean, default: true },
    stickerDetails: { type: Object, default: () => ({}) },
    validationDetails: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const amt = ref();
    const route = useRoute();

    const {
      variantId,
      isWebUser,
    } = route.query;

    const termsAndConditionsLink = import.meta.env.VITE_TERMS_AND_CONDITIONS_LINK;
    const privacyPoliciesLink = import.meta.env.VITE_PRIVACY_POLICY_LINK;


    function userName() {
      if (this.userDetails.organizationId) {
        return this.userDetails.organizationName;
      }
      if (this.userDetails.id) {
        return `${this.userDetails.firstname} ${this.userDetails.lastname}`;
      }
      return '';
    }

    function avatar() {
      if (this.userDetails.organizationId && this.userDetails.organizationAvatar) {
        return this.userDetails.organizationAvatar;
      }
      if (this.userDetails.id && this.userDetails.userAvatar) {
        return this.userDetails.userAvatar;
      }
      return '@/assets/images/default-profile.jpeg';
    }

    function assignStickerCostToAmount() {
      if (route.query.cost && route.query.cost !== 'NA') {
        amt.value = parseFloat(route.query.cost);
      } else if (variantId) {
        const variant = props.stickerDetails?.varients?.find((x) => x.id === variantId);
        amt.value = variant?.cost;
      } else {
        amt.value = this.stickerDetails.cost;
      }
    }

    function isValid() {
      return this.validationDetails.isValid;
    }

    function validationError() {
      if (!this.validationDetails.isValid && this.validationDetails.errors) {
        return this.validationDetails.errors[0];
      }
      return null;
    }

    function getRedirectUrl() {
      if(isWebUser === 'true'){
        return import.meta.env.VITE_WEB_BASE_URL;
      }
      else {
        return import.meta.env.VITE_APP_REDIRECT_BASE_URL;
      }
    }

    function cancelIntent() {
      window.location.href = `${getRedirectUrl()}?action=StickerPurchaseCancel`;
    }

    watch(
      () => amt.value,
      () => {
        emit('input-amt', amt.value);
      },
    );

    return {
      amt,
      termsAndConditionsLink,
      privacyPoliciesLink,
      userName,
      cancelIntent,
      avatar,
      assignStickerCostToAmount,
      isValid,
      validationError,
    };
  },
});
</script>
