<template>
  <web-app-layout :showLogo="true">
    <SmartBanner />
    <div class="webStickerBody">
      <div v-if="item.published === true" class="webSticker">
        <div class="sticker-border">
          <div class="stickerImage">
            <div class="img-uploader pos-r mx-auto webStickerImage">
              <template v-if="item.url">
                <img :src="item.url" class="img-fluid el-upload" />
              </template>
            </div>
          </div>
        </div>
        <div class="sticker-details">
          <div class="py-2">
            <h6 v-if="item.campaignType" class="mb-0 sticker-tag-text sticker-campaign-type fs-11">{{ item.campaignType }}</h6>
          </div>
          <div v-if="item.organization == null">
            <div class="row row-xs align-items-center py-2">
              <div class="col-auto">
                <img
                  v-if="item.user.avatar"
                  :src="item.user.avatar"
                  class="d-block size-18 obj-f-c rounded-circle sticker-avatar"
                />
                <img
                  v-else
                  src="@/assets/images/default-profile.jpeg"
                  class="d-block size-18 obj-f-c rounded-circle sticker-avatar"
                />
              </div>
              <div class="col lh-1 sticker-avatar-name fs-11">
                {{ item.user.firstname }} {{ item.user.lastname }}
              </div>
            </div>
          </div>
          <div v-if="item.organization != null">
            <div class="row row-xs align-items-center py-2">
              <div class="col-auto">
                <img
                  v-if="item.organization.avatar"
                  :src="item.organization.avatar"
                  class="d-block size-18 obj-f-c rounded-circle w-80"
                />
                <img
                  v-else
                  src="@/assets/images/default-profile.jpeg"
                  class="d-block size-18 obj-f-c rounded-circle"
                />
              </div>
              <div class="col lh-1">{{ item.organization.name }}</div>
            </div>
          </div>
          <div>
            <h2 class="mb-0 pt-2 fs-34">{{ item.title }}</h2>
          </div>

          <article>
            <section class="pb-4 fs-14 sticker-price-color-text" v-if="!item?.quickCosts?.length">
              <span>
                <span v-if="item.cost == 0 && !item.varients.length && !item.sweepstakesSticker" class="fs-24">FREE</span>
                <span v-else-if="item.varients.length">{{ variantsRange }}</span>
                <span v-else-if="item.sweepstakesSticker">{{ sweepstakesRange }}</span>
                <span v-else class="fs-24">${{ item.cost.toFixed(2) }}</span>
              </span>
            </section>

            <section class="c-quick-costs" v-if="item.quickCosts && item.quickCosts.some(cost => cost !== null)">
                <div class="c-quick-costs__custom">
                    <div class="c-quick-costs__custom__label">Enter an amount (USD)</div>
                    <div class="position-relative">
                        <span class="c-quick-costs__custom__dollar" >$</span>
                        <input
                            v-model="customAmount"
                            @input="validateCustomAmount"
                            class="c-quick-costs__custom__input"
                            min="0.01"
                            type="number"
                        />
                    </div>
                </div>
                <div class="c-quick-costs__costs">
                    <div
                        class="c-quick-costs__costs__value"
                        v-for="quickCost in item.quickCosts"
                        @click="customAmount = quickCost"
                    >
                        ${{ quickCost }}
                    </div>
                </div>
            </section>

            <template v-if="item.campaignType === 'Sweepstakes'">
            <div style="margin: 20px 0; padding: 10px;">
              <!-- Display Free and entry costs based on the current slider value -->
              <div style="display: flex; width: 100%; justify-content: space-between; margin-bottom: 10px">
                <span style="font-weight: bold;">{{ currentEntryText }}</span>
                <span style="font-weight: bold;">{{ currentCostText }}</span>
              </div>
              <VueSlider
                v-model="value"
                :adsorb="true"
                :min="0"
                :max="item.sweepstakesSticker.entries.length"
                :marks="marks"
                tooltip="none"
                :processStyle="{ backgroundColor: '#1fbeaf' }"
                class="custom-slider"
              />
              <div class="dots-container">
                <span
                  v-for="dot in item.sweepstakesSticker.entries.length"
                  :key="dot"
                  class="dot"
                  :class="{ 'active': dot - 1 === value }"
                  @click="selectValue(dot - 1)"
                ></span>
              </div>
            </div>
          </template>


          </article>

          <div v-if="item.eventDate" class="row">
            <div class="col-6">
              <h5 class="mb-0 pt-2 fs-18">{{ getformattedDate(item.eventDate) }}</h5>
              <div class="fs-14 lh-2 fw-b">Date</div>
            </div>
            <div class="col-6">
              <h5 class="mb-0 pt-2 fs-18">{{ getformattedTime(item.eventDate) }}</h5>
              <div class="fs-14 lh-2 fw-b">Time</div>
            </div>
          </div>

          <section class="c-variants-picker" v-if="item.varients?.length">
              <article
                v-for="variant in item.varients"
                class="c-variants-picker__variant"
                :class="{'c-variants-picker__variant--selected': selectedVariants.includes(variant.id)}"
                @click="onSelectVariant(variant.id)">
                <section class="d-flex justify-content-between">
                  <span class="c-variants-picker__variant__title">{{ variant.title }}</span>
                  <select
                    :disabled="!selectedVariants.includes(variant.id)"
                    class="c-variants-picker__variant__select"
                    v-model="selectedVariantsAmounts[variant.id]"
                    size="large"
                    @click.stop
                  >
                    <option value="0">
                      0
                    </option>
                    <option v-for="amount in 100" :key="amount" :value="amount">
                      {{ amount }}
                    </option>
                  </select>
                </section>

                <section class="c-variants-picker__variant__cost">
                  ${{ variant.cost }}
                </section>

                <section  class="c-variants-picker__variant__description" v-if="variant.description?.length">
                  {{ variant.description }}
                </section>
              </article>
          </section>

          <div class="fixed-button">
            <div v-if="item.campaignType && item.campaignType === 'Ticket'" class="py-4">
              <el-button
                type="success"
                round
                @click="onStickerActionButtonClick"
                v-loading="loading"
                :disabled="item.campaignType == 'Ticket' && selectedVariants.length == 0"
                class="text-white action-button action-button-ticket"
              >
                <span>
                  <span class="action-button-rsvp">RSVP</span>
                </span>
              </el-button>
              <el-button
                type="success"
                round
                @click="addToCalendarClick(item.eventDate, item.description, item.location, item.title)"
                v-loading="loading"
                class="add-calendar-btn"
              >
                <fai type="fal" icon="calendar-plus" class="size-24"></fai>
              </el-button>
            </div>
            <div v-else class="pt-4">
              <el-button
                type="success"
                round
                @click="() => {
                    if (item?.quickCosts?.length && !(customAmount >= 0) && (item.campaignType == 'Campaign' || item.campaignType === 'campaign' || !item.campaignType)) {
                      return;
                    }
                    return onStickerActionButtonClick()
                }"
                v-loading="loading"
                class="text-white action-button"
                :class="{'button-disabled' : item.quickCosts?.length && !(customAmount >= 0)}"
              >
                <span>
                  <span v-if="(item.campaignType === 'Sweepstakes' && customAmount == 0 ) && (item.cost == 0 || isStickerPurchased === true)">Add Sticker To Your Profile</span>
                  <span v-else-if="item.campaignType && item.campaignType === 'Ticket'" class="action-button-rsvp">RSVP'D</span>
                  <span v-else-if="item.campaignType && (item.campaignType === 'Campaign' || item.campaignType === 'Sweepstakes')">Donate</span>
                  <span v-else-if="item.campaignType && item.campaignType === 'Sweepstakes'">Donate</span>
                  <span v-else>Purchase</span>
                </span>
              </el-button>
            </div>
          </div>
          <div class="pb-5 position-sticker-details">
            <div v-if="item.location">
              <h6 class="mb-0 py-2 pt-4 fw-b fs-14">Location</h6>
              <div class="fs-14 lh-2 fc-gray">{{ item.location }}</div>
            </div>
            <div class="my-4">
              <h6 class="mb-0 py-2 fw-b fs-14">Details</h6>
              <div class="fs-14 lh-2 fc-gray" v-html="item.description"></div>
            </div>
            <div v-if="item.waiverURL && item.waiverURL.indexOf('http') >= 0" class="my-4">
              <h6 class="mb-0 py-2 fw-b">Waiver</h6>
              <iframe
                :src="item.waiverURL"
                :title="item.waiverURL"
                width="100%"
                height="250px"
              ></iframe>
            </div>
            <el-dialog
              v-model="isToChooseCause"
              class="stickerDetailsDialog text-center"
              :title="'Choose a Charity'"
              :modal="true"
              :show-close="false"
              :lock-scroll="true"
            >
              <img src="@/assets/images/gift-solid.svg" class="charity-image" />
              <div v-if="item.isCausesSearchAllowed">
                <VueMultiselect
                  v-model="selectedCause"
                  :multiple="false"
                  :taggable="false"
                  :showNoOptions="false"
                  :options="causeOptionsArray"
                  :searchable="true"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :loading="causeLoading"
                  :custom-label="customLabel"
                  placeholder="Search for Charity"
                  @search-change="getCausesDropdownOptions"
                  @select="searchSelected"
                />
              </div>
              <div class="fs-14 lh-2 creator-suggestion">
                <span>Creator's Suggestions</span>
              </div>
              <el-radio-group
                v-model="selectedCauseId"
                class="row text-start d-flex"
              >
                <div
                  v-for="(cause, index) in item.causes"
                  :key="index"
                  class="max-w-200"
                >
                  <div v-if="cause !== undefined" class="col-auto py-2 min-w-200">
                    <el-radio :value="cause" :label="cause.split(' : ')[1]" class="charity-suggestion">
                      {{ cause.split(' : ')[0] }}
                    </el-radio>
                    <hr v-if="index < item.causes.length - 1" class="hr-color" />
                  </div>
                </div>
              </el-radio-group>
              <div class="py-4">
                <el-button
                  type="success"
                  round
                  @click="onStickerActionButtonClick"
                  v-loading="loading"
                  class="w-100 sign-in-button"
                >
                  <span>Continue</span>
                </el-button>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
      <div v-else-if="item.published === false">
        <div class="row row-xs align-items-center py-2">
          <div class="col-auto">
            <h6 class="mb-0 py-2">Sorry, but this content is missing.</h6>
            <div>
              Good news! There are other fish in the sea. Check out the Marketplace for other great Stickers that want to
              be found by you.
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row row-xs align-items-center py-2">
          <div class="col-auto">
            <h6 class="mb-0 py-2">
              "The two most powerful warriors are patience and time." - Leo Tolstoy
            </h6>
            <div>
              Hang tight! We are loading your content. If it fails to turn up, please reload the page in 5 seconds.
            </div>
          </div>
        </div>
      </div>
    </div>
  </web-app-layout>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import { useStore } from 'vuex';
import { DateTime } from 'luxon';
import { faDistributeSpacingVertical } from '@fortawesome/pro-light-svg-icons';
import WebAppLayout from '../dashboard/WebAppLayout.vue';
import gqlRequest from '../dashboard/gql-request';
import VueMultiselect from 'vue-multiselect';
import SmartBanner from './SmartBanner.vue';
import VueSlider from "vue-3-slider-component";

interface Cause {
  name: string;
  id: string;
}

interface Item {
    id: number | null;
    title: string;
    description: string;
    url: string;
    published: any;
    tags: string[];
    cost: number;
    campaignType: null | string;
    causes: string[];
    maxQuantity: number;
    maxDonation: null | number;
    donationMultiplier: number;
    isCausesSearchAllowed: boolean;
    quickCosts?: any[];
    user?: any;
    organization?: any;
    varients?: any;
    sweepstakesSticker:any;
    startDate?: any;
    endDate?: any;
    eventDate?: any;
    location?: any;
    waiverURL?: any;
    impact: any;
    creatorTitle: any;
    isFeatured: boolean;
}

class CauseItem {
  public value: string;
  public label: string;

  constructor(value: string, label: string) {
    this.value = value;
    this.label = label;
  }
}

export default defineComponent({
  name: 'WebAppSticker',
  components: {
    WebAppLayout,
    VueMultiselect,
    SmartBanner,
    VueSlider,
    faDistributeSpacingVertical
},
  setup() {
    const {
      route,
      router,
      getSticker,
      doCreateStickerWebIntent,
      doCreateStickerWebIntentPublic,
      checkIsStickerPurchased,
      getCuratedBenevityCauses,
      getcausesAsDropdown,
    } = gqlRequest();

    const { stickerId } = route.params;
    const store = useStore();
    const selectedVariantsAmounts = ref<{ [key: string]: number }>({});
    const selectedVariants = ref<string[]>([]);
    const causeOptionsArray = ref<any[]>([]);
    const causeOptions = ref<any[]>([]);
    const isPromptDownloadApp = ref(false);
    const isStickerPurchased = ref(false);
    const isToChooseCause = ref(false);
    const causeLoading = ref(false);
    const loading = ref(false);
    const selectedCauseId = ref('NA');
    const firstCauseName = ref('');
    const selectedCause = ref('');
    const customAmount = ref();
    const value = ref(0);

    const maxValue = 10;

    const item = reactive<Item>({
      id: null,
      title: '',
      description: '',
      url: '',
      published: null,
      tags:[],
      cost: 0,
      campaignType: null,
      causes: [''],
      maxQuantity: 0,
      maxDonation: null,
      donationMultiplier: 1,
      impact: null,
      startDate: null,
      endDate: null,
      sweepstakesSticker: {
        aboutTheCause: null,
        approximateRetailValue: null,
        checkoutPageLegal: null,
        createdAt: null,
        drawingDateTime: null,
        entries: [null],
        entryCost: [null],
        experienceDateTime: null,
        nonProfitBeneficiary: null,
        numberOfPrizesAndWinners: null,
        prizes: null,
        sweepstakesConfirmation: null,
        sweepstakesLoserMessage: null,
        sweepstakesWinnerMessage: null,
      },
      user: {
        firstname: '',
        lastname: '',
        avatar: null,
      },
      organization: { name: '', avatar: null },
      creatorTitle: null,
      isFeatured: false,
      isCausesSearchAllowed: false,
    });

    // SWEEPSTAKES SLIDER
    function sortEntriesAndCosts() {
      const entriesCopy = [...item.sweepstakesSticker.entries];
      const entryCostCopy = [...item.sweepstakesSticker.entryCost];

      const sortedIndices = entriesCopy.map((value, index) => ({ value, index }))
                                      .sort((a, b) => a.value - b.value)
                                      .map(sorted => sorted.index);

      item.sweepstakesSticker.entries = sortedIndices.map(i => entriesCopy[i]);
      item.sweepstakesSticker.entryCost = sortedIndices.map(i => entryCostCopy[i]);
    }

    const currentEntryText = computed(() => {
      if (value.value === 0) {
        return `1 Entry`; // Default value when slider is at index 0
      }
      return `${item.sweepstakesSticker.entries[value.value - 1]} Entries`;
    });

    const currentCostText = computed(() => {
      if (value.value === 0) {
        item.cost = 0;
        customAmount.value = 0;
        return `Free`; // Default value when slider is at index 0
      }
      customAmount.value = item.sweepstakesSticker.entryCost[value.value - 1] ?? 0;
      return `Donate $${item.sweepstakesSticker.entryCost[value.value - 1]}`;
    });

    const marks = computed(() => {
      const marks = {};
      const length = item.sweepstakesSticker.entries.length;

      for (let i = 0; i <= length; i++) {
        marks[i] = '';
      }
      return marks;
    });

    const selectValue = (index: number) => {
      value.value = index;
    };

    // SWEEPSTAKES SLIDER END

    const onSelectVariant = (id: string) => {
      const index = selectedVariants.value.indexOf(id);
      if (index > -1) {
        selectedVariants.value.splice(index, 1);
        // return delete selectedVariantsAmounts.value[id];
        return selectedVariantsAmounts.value[id] = 0;
      }
      selectedVariantsAmounts.value[id] = 1;
      selectedVariants.value.push(id);
    }

    async function getCausesDropdownOptions(query: string) {
      if (query) {
        causeLoading.value = true;
        const benevityCauses: Array<Cause> = await getcausesAsDropdown(query);
        setTimeout(() => {
          causeLoading.value = false;
          causeOptions.value = [];
          benevityCauses.forEach((element) => {
            const data = new CauseItem(
              `${element.name} : ${element.id}`,
              `${element.name} : ${element.id}`,
            );
            causeOptions.value.push(data);
            causeOptionsArray.value.push(`${element.name} : ${element.id}`);
          });
        }, 200);
      } else {
        causeOptions.value = [];
      }
    }
    function customLabel(option: string) {
      return option.split(' : ')[0];
    }

    function handleCauseChange() {
      selectedCause.value = '';
      causeOptionsArray.value = [];
    }

    function searchSelected() {
      selectedCauseId.value = 'NA';
    }

    function getCausesList() {
      let firstCause = '';
      if (item.causes.length) {
        // eslint-disable-next-line prefer-destructuring
        // eslint-disable-next-line prefer-destructuring
        // firstCauseName.value = `Cause: ${firstCause.split(' : ')[0]}`;
        // firstCauseName.value = item.causes.map((x) => x.split(' : ')[0]);
        // eslint-disable-next-line array-callback-return
        item.causes.map((x, index) => {
          if (index === 0) {
            firstCause = `${firstCause} ${x.split(' : ')[0]}`;
          } else {
            firstCause = `${firstCause}, ${x.split(' : ')[0]}`;
          }
        });
      }
      return firstCause;
    }

    async function getItem() {
      try {
        const sticker = await getSticker(stickerId);
        Object.assign(item, sticker);
        item.causes = (sticker.causes !== null && sticker.causes !== '') 
          ? sticker.causes.split(' |=| ') 
          : [];
        getCausesList();

        if (!item.causes.length && (item.campaignType === 'Campaign' || item.campaignType === 'Sweepstakes')) {
          const curatedCauses: string[] = [];
          const curatedBenevityCauses = await getCuratedBenevityCauses();
          curatedBenevityCauses.forEach((curatedCause: any) => {
            curatedCauses.push(`${curatedCause.name} : ${curatedCause.id}`);
          });
          Object.assign(item.causes, curatedCauses);
        }

        if (item.varients && Array.isArray(item.varients)) {
          item.varients.forEach(variant => {
            selectedVariantsAmounts.value[variant.id] = 0; // Initialize with a default value, like 0
          });
        }

        // Check if item.sweepstakesSticker exists and is not null before accessing entries
        if (item.sweepstakesSticker && item.sweepstakesSticker.entries != null) {
          sortEntriesAndCosts();
        }

      } catch (e) {
        console.error('Error in getItem:', e);
      }
    }

    function addUserToUrl(url) {
      const urlObj = new URL(url, window.location.origin);
      const params = new URLSearchParams(urlObj.search);

      params.set('isWebUser', 'true');

      urlObj.search = params.toString();

      return urlObj.toString();
    }

    // function addUserToUrl(url) {
    //   const userParamPattern = /\/payment\/intent\/user\/[^/]+\/sticker\//;

    //   if (!userParamPattern.test(url)) {
    //     // Add the user parameter with "NA" value
    //     const intentIndex = url.indexOf("/payment/intent/");
    //     if (intentIndex !== -1) {
    //       const stickerIndex = url.indexOf("/sticker/", intentIndex);
    //       if (stickerIndex !== -1) {
    //         return (
    //           url.substring(0, stickerIndex) +
    //           "/user/NA" +
    //           url.substring(stickerIndex)
    //         );
    //       }
    //     }
    //   }
    //   return url; // Return the original URL if user parameter is already present
    // }

    async function doCheckIsStickerPurchased() {

      if(store.getters['users/me'] !== null){
      const isStickerPurchasedResponse = await checkIsStickerPurchased('User', store.getters['users/me'].id, stickerId);
      isStickerPurchased.value = isStickerPurchasedResponse;
      isStickerPurchased.value = false;
      }
    }

    async function onStickerActionButtonClick() {

      if (isStickerPurchased.value === true) {
        return;
      }
      const variants = selectedVariants.value?.join(',');
      const quantities = selectedVariants.value
        .map(variant => selectedVariantsAmounts.value[variant])
        .filter(value => value !== 0)
        .join(',');

      if (selectedCause.value) {
        // Donate to the selected charity based on search result
        const [, id] = selectedCause.value.split(' : ');
        selectedCauseId.value = id;
        const stickerWebIntent = store.getters['users/me'] != null
          ? await doCreateStickerWebIntent(stickerId, selectedCauseId.value, customAmount.value ?? 'NA')
          : await doCreateStickerWebIntentPublic(stickerId, selectedCauseId.value, customAmount.value ?? 'NA');

        let url = stickerWebIntent;

        // Check if variants and quantities exist
        if (variants?.length && quantities?.length) {
          // If the URL contains ?cost, we need to place variants/quantities before it
          if (url.includes('?cost')) {
            const [baseUrl, costParam] = url.split('?');
            url = `${baseUrl}/variants/${variants}/quantities/${quantities}?${costParam}`;
          } else {
            url = `${url}/variants/${variants}/quantities/${quantities}`;
          }
        }

        window.location.href = addUserToUrl(url);
      } else if (item.causes.length && selectedCauseId.value === 'NA' && item.campaignType != null) {
        isToChooseCause.value = true;
      } else if (item.causes.length && selectedCauseId.value === 'NA' && item.campaignType != null && item.campaignType == 'Sweepstakes' ) {
        isToChooseCause.value = true;
      }
      else {
        // Donate to the selected charity based on radio button selection
        if (selectedCauseId.value.includes(":")) {
          selectedCauseId.value = selectedCauseId.value.split(":")[1].trim();
        }
        const stickerWebIntent = store.getters['users/me'] != null
          ? await doCreateStickerWebIntent(stickerId, selectedCauseId.value, customAmount.value ?? 'NA')
          : await doCreateStickerWebIntentPublic(stickerId, selectedCauseId.value, customAmount.value ?? 'NA');

        let url = stickerWebIntent;

        // Check if variants and quantities exist
        if (variants?.length && quantities?.length) {
          if (url.includes('?cost')) {
            const [baseUrl, costParam] = url.split('?');
            url = `${baseUrl}/variants/${variants}/quantities/${quantities}?${costParam}`;
          } else {
            url = `${url}/variants/${variants}/quantities/${quantities}`;
          }
        }

        window.location.href = addUserToUrl(url);
      }
    }

    // function addUserToUrl(url) {
    //   const urlObj = new URL(url, window.location.origin);
    //   const params = new URLSearchParams(urlObj.search);

    //   params.set('isWebUser', 'true');

    //   urlObj.search = params.toString();

    //   return urlObj.toString();
    // }

    function getformattedDate(timeOffset: string | number | Date) {
      const t = DateTime.fromISO(timeOffset.toString());
      return `${t.toFormat('MM/dd/yy')}`;
    }

    function getformattedTime(timeOffset: string | number | Date) {
      const t = DateTime.fromISO(timeOffset.toString(), { zone: 'utc' });
      return `${t.toFormat('hh:mm a')}`;
    }

    function addToCalendarClick(date: any, description: any, location: any, title: any) {
      /* Add to calendar needs the following format:
      https://calendar.google.com/calendar/render?action=TEMPLATE
        &dates=20230202T064500Z%2F20230202T071500Z
        &details=This%20is%20my%20description
        &location=home
        &text=Title */
      /*  the code below is to convert the date and time to a format that Google Calendar accepts:
        20230202T064500Z
        */
      const a = `${date.split('.')[0]}Z`;
      const b = (a).replaceAll('-', '');
      const c = b.replaceAll(':', '');
      const addToCalendarURL = `${import.meta.env.VITE_GOOGLE_CALENDAR_BASE_URL}/render?action=TEMPLATE&dates=${c}%2F${c}${'&details='}${description}&location=${location}&text=${title}`;
      window.open(addToCalendarURL, '_blank');
    }

    const validateCustomAmount = (event: Event) => {
      const target = event.target as HTMLInputElement;
      let value = Number(target.value);
      if (value < 1) {
        value = 1;
      }
      customAmount.value = value;
      target.value = value.toString();
    };

    onMounted(async () => {
      loading.value = true;
      await getItem();
      await doCheckIsStickerPurchased();
      store.commit('shared/SET_MAIN_MENU_COLLAPSE', false);
      loading.value = false;
    });

    const variantsRange = computed(() => {
      const range = item.varients.map((i) => {
        return i.cost
      })
      range.sort();
      const r1 =  range[0] ?? ''
      const r2 =  range[range.length-1] ?? ''
      return '$' +r1 + '-$' + r2;
    })

    const sweepstakesRange = computed(() => {
      if (Array.isArray(item.sweepstakesSticker.entryCost) && item.sweepstakesSticker.entryCost.length > 0) {
        const range = [...item.sweepstakesSticker.entryCost, 0].sort((a:any, b:any) => a - b);
        const r1 = range[0];
        const r2 = range[range.length - 1] ?? '';
        return `$${r1} - $${r2}`;
      } else {
        return '';
      }
    });

    return {
      route,
      router,
      loading,
      // data
      item,
      getCausesList,
      causeLoading,
      causeOptions,
      causeOptionsArray,
      getCausesDropdownOptions,
      customLabel,
      selectedCause,
      firstCauseName,
      onStickerActionButtonClick,
      isPromptDownloadApp,
      isStickerPurchased,
      isToChooseCause,
      selectedCauseId,
      getformattedDate,
      getformattedTime,
      addToCalendarClick,
      handleCauseChange,
      searchSelected,
      onSelectVariant,
      selectedVariants,
      selectedVariantsAmounts,
      customAmount,
      validateCustomAmount,
      maxValue,
      selectValue,
      currentEntryText,
      currentCostText,
      value,
      marks,
      variantsRange,
      sweepstakesRange
    };
  },
});
</script>

<style lang="scss" scoped>
.sticker-details {
  width: 100%;

  .button-disabled {
      cursor: not-allowed;
  }
}
.c-variants-picker {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  &__variant {
    cursor: pointer;
    border: 1pt solid #DEDEDE;
    border-radius: 10px;
    //min-height: 187px;
    width: 100%;
    max-width: 335px;
    padding: 20px 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__title {
      color: #1A1A1A;
      font-family: "Red Hat Display";
      font-weight: 900;
      font-size: 20px;
      text-transform: capitalize;
    }

    &__select {
      width: 68px;
      height: 48px;
      border-radius: 8px;
      border: none;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;

      background: #F4F6F9 url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 320 512%27%3E%3Cpath d=%27M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z%27/%3E%3C/svg%3E') no-repeat right 15px center;
      background-size: 9px 14px;

      padding: 15px 30px 15px 15px;
      text-align: center;

      font-size: 14px;
      font-weight: 500;
    }

    &__cost {
      color: #1A1A1A;
      font-size: 30px;
      font-family: "Red Hat Display";
      font-weight: 900;
    }

    &__description {
      font-family: "Red Hat Display";
      font-weight: 500;
      color: #1A1A1A;
      font-size: 16px;
      line-height: 26px;
    }

    &--selected {
      border-color: #1EBEAF;
    }
  }
}

.c-quick-costs {
    margin-top: 13px;
    &__custom {
        font-family: "Red Hat Display";
        font-weight: 700;

        &__label {
            color: black;
            font-size: 11px;
        }

        &__dollar {
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 14px;
            color: black;
        }

        &__input {
            height: 42px;
            width: 100%;
            border: 0.5px solid #DEDEDE;
            border-radius: 5px;
            font-size: 14px;
            padding: 12px 12px 12px 24px;
            font-weight: 700;
            -moz-appearance: textfield; // For Firefox
            -webkit-appearance: none;   // For WebKit browsers
            appearance: none;           // Standard property

            &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    &__costs {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-top: 12px;

        &__value {
            height: 34px;
            width: 60px;
            border-radius: 5px;
            background-color: #F4F6F9;
            color: #005C6B;
            font-size: 14px;
            font-family: "Red Hat Display";
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .dots-container {
      display: flex;
      justify-content: space-between; /* Adjusts spacing based on the number of dots */
      margin-top: 10px; /* Space between the slider and dots */
    }

    .dot {
      width: 10px; /* Adjust size as needed */
      height: 10px;
      border-radius: 50%; /* Make it round */
      background-color: lightgray; /* Default color */
      cursor: pointer; /* Cursor indicates clickable */
    }

    .dot.active {
      background-color: green; /* Active dot color */
    }

  }
</style>
