import { gql } from '@apollo/client/core';

export const createInvite = gql`mutation ($input: CreateInviteInput!){
  createInvite(input: $input) {
     id
     name
     phone
     invite_code
  }
}`;

export const createUserInvite = gql`mutation ($input: CreateInviteInput!){
   createUserInvite(input: $input) {
      id
      name
      phone
      invite_code
   }
 }`;

export const getInvites = gql`query {
   getInvites {
      id
      name
      email
      phone
      invite_code
      invite_accepted
      invite_stage
      entity
      createdAt
   }
}`;

export const getInvite = gql`query ($id: String!) {
    getInvite(id: $id) {
       id
       email
       name
       phone
       invite_code
       invite_accepted
       invite_stage
    }
}`;

export const returningUser = gql`query ($inviteId: String!) {
     returningUser(inviteId: $inviteId) {
         id
         email
         phone
         firstname
         lastname
         title
     }
}`;

export const resendInvitation = gql`mutation ($id: String!) {
    resendInvitation(id: $id)
}`;

export const removeInvitation = gql`mutation ($id: String!) {
    removeInvitation(id: $id)
}`;

export const makeOrgAdmin = gql`mutation ($input: MakeOrgAdminInput!) {
   makeOrgAdmin(input: $input) {
      id
      firstname
      lastname
      email
      phone
   }
}`;

export const signUp = gql`mutation ($input: SignUpInput!){
   signUp(input: $input) {
      id
      firstname
      lastname
      avatar
      email
      phone
   }
 }`;
