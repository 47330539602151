<template lang="pug">
AppMainMenu
router-view
       //- views render here
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';

import AppMainMenu from '@/components/AppMainMenu.vue';


export default defineComponent({
  name: 'DashView',
  components: { AppMainMenu },
  computed: {
    ...mapGetters('shared', ['isMainMenuCollapsed']),
  },
});
</script>
