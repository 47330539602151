<template>
  <nav class="c-nav">
    <section class="c-nav__content">
      <div @click="navigateToAuth">
        <img class="c-nav__logo" src="@/assets/images/logos/logo_circle.png"  />
        <img class="c-nav__logo c-nav__logo--r" src="@/assets/images/logos/text_logo_white.png"  />
      </div>
      <ul>
        <li @click="() => onOpenLink('legal')">Legal</li>
        <li @click="() => onOpenLink('help')">Help</li>
      </ul>
    </section>
  </nav>
  <section class="c-auth">
      <main class="c-auth__content" role="main" v-loading="loading">
        <router-view v-slot="{ Component }">
          <component :is="Component">
            <!-- views render here -->
          </component>
        </router-view>
      </main>
    <article class="c-auth__image">
      <img src="@/assets/images/logos/bolt-green-hr.svg"  />
    </article>
  </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'AuthView',
  computed: {
    ...mapGetters('shared', ['loading']),
  },
  setup() {
    const router = useRouter();

    const navigateToAuth = () => {
      router.push('/auth');
    };

    const onOpenLink = (linkType: string) => {
      if (linkType === 'legal') {
        return window.open('https://www.spotlight.xyz/legal-stuff', '_blank');
      }
      return window.open('https://www.spotlight.xyz/knowledge-center', '_blank');
    }

    return {
      navigateToAuth,
      onOpenLink
    };
  }
});
</script>

<style lang="scss">
.c-nav {
  height: 60px;
  background: #005C6B;
  color: white;

  &__content {
    max-width: 730px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;

    .c-nav__logo {
      height: 30px;
      cursor: pointer;

      &--r {
        margin-left: 7.5px;
        height: 22.5px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      gap: 30px;
      align-items: center;
      justify-content: center;
      font-family: "Red Hat Display";
      font-weight: bold;

      li {
        cursor: pointer;
      }
    }
  }
}
.c-auth {
  background: white;
  width: 100%;
  max-width: 730px;
  border-radius: 10px;
  display: flex;
  margin: 120px auto;
  display: flex;
  overflow: hidden;
  min-height: 453px;

  &__content {
    padding: 20px 30px;
    flex: 1;
  }

  &__image {
    background-color: #005C6B;
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    img {
      opacity: 0.3;
    }
  }
}
</style>
