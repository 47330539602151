<template lang="pug">
.dialog-focus-lock(ref="root")
  slot
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'AppFocusLock',

  setup() {
    let focusableEls = [];
    let firstFocusableEl = [];
    let lastFocusableEl = [];
    const focusedElBeforeOpen = document.activeElement;
    const root = ref(null);

    function handleBackwardTab(e) {
      if (focusedElBeforeOpen === firstFocusableEl) {
        e.preventDefault();
        lastFocusableEl.focus();
      }
    }

    function handleForwardTab(e) {
      if (focusedElBeforeOpen === lastFocusableEl) {
        e.preventDefault();
        firstFocusableEl.focus();
      }
    }

    function handleKeyDown(e) {
      const KEY_TAB = 9;

      /* eslint-disable indent */
      switch (e.keyCode) {
        case KEY_TAB:
          if (focusableEls.length === 1) {
            e.preventDefault();
            break;
          }
          if (e.shiftKey) {
            handleBackwardTab(e);
          } else {
            handleForwardTab(e);
          }
          break;
        default:
          break;
      }
      /* eslint-enable */
    }

    function handleDialogFocusLock() {
      const selectors = ''
        + 'a[href], '
        + 'input:not([disabled]), '
        + 'select:not([disabled]), '
        + 'textarea:not([disabled]), '
        + 'button:not([disabled])';

      const getEls = root.value.querySelectorAll(selectors);

      focusableEls = Array.prototype.slice.call(getEls);
      [firstFocusableEl] = focusableEls; // gets first array item
      [lastFocusableEl] = focusableEls.slice(-1); // gets last array item

      root.value.addEventListener('keydown', (e) => handleKeyDown(e));

      // Uncomment below to focus first element
      // this.firstFocusableEl.focus();
    }

    onMounted(handleDialogFocusLock());

    return { root };
  },
});
</script>
