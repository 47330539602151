import { gql } from '@apollo/client/core';

export const getHomeScreenAppData = gql`
query {
  getHomeScreenAppData {
    sliderData {
      id
      featuredImage
      title
      description
      navigateTo
    }
    stickersData {
      id
      title
      url
      description
      entity
      user {
        id
        firstname
        lastname
        avatar
        background
      }
      organization {
        id
        name
        avatar
        background
      }
      tags
      cost
      causes
      campaignType
      causes
      isCausesSearchAllowed
      raised
      donors
      isSpotlight
    }
  }
}`;