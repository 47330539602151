<template lang="pug">
el-dialog(
  :title="dialogTitle",
  top="5vh" width="100%"
  :close-on-click-modal="false"
  class="el-dialog__max-w-700"
)
  el-form(
    ref="refCategory"
    :rules="categoryRules"
    :model="categoryItem"
    status-icon
    label-position="top"
    v-if="showAddCat"
  )
    .row
      .col
        el-form-item(
          label="Channel Category Name"
          prop="category"
          :rules="categoryRules.category"
        )
          el-input(v-model="categoryItem.category")
      .col-auto
        el-form-item(label=".")
          el-tooltip(content="Cancel")
            el-button(@click="showAddCat = false" circle)
              template(#icon='')
                img(src="@/assets/icons/close.svg")
          el-tooltip(content="Save")
            el-button(type="success" @click="validateCategory" circle)
              template(#icon='')
                img(src="@/assets/icons/light/check-light.svg")

  el-form(
    ref="refForm"
    :rules="rules"
    :model="item"
    status-icon
    label-position="top"
    v-if="!showAddCat"
  )
    el-form-item(label="Channel Name" prop="channel")
      el-input(v-model="item.channel")
    .row
      .col
        el-form-item(label="Category" prop="categoryId")
          VueMultiselect(
            v-model="item.categoryId",
            :multiple="false",
            label="category",
            track-by="id",
            :options="categories",
            :searchable="true",
            :close-on-select="true",
            :show-labels="false"
            placeholder="Pick a value"
          )
      .col-auto
        el-form-item(label=".")
          el-tooltip(content="Edit Category")
            el-button(@click="showCatDialog = true" circle)
              template(#icon='')
                img(src="@/assets/icons/square-edit-outline.svg")
          el-tooltip(content="Add New Category")
            el-button(@click="showAddCatInput()" circle)
              template(#icon='')
                img(src="@/assets/icons/plus.svg")

    el-form-item(label="Description" prop="description" v-if="!showAddCat")
      el-input(v-model="item.description" type="textarea")

  template(#footer)
    el-button(type="success" @click="validateForm" v-loading="loading"  v-if="!showAddCat") Add

  el-dialog(
    v-if="showCatDialog"
    title="Edit Channel Category"
    v-model="showCatDialog"
    top="8vh" width="100%" append-to-body
    class="el-dialog__max-w-670 has-footer-edit-form"
  )
    .position-relative
      el-table(
        :data="categories"
        @row-click="editChannelCat"
        style="width: 100%" height="350" :show-header="false" stripe
      ).bdr-b-0
        el-table-column(prop="category")
        el-table-column(width="46")
          .el-table__expand-icon
            i.el-icon.el-icon-edit

    transition(name="fade")
      .pin.pin-xy-5.bg-dark-op-p5.z1(v-if="showEditChannelCat")
        .pin.pin-bx.p-3
          .card
            .card-body
              el-form(
                ref="refForm"
                :rules="rules"
                :model="item"
                status-icon
                label-position="top"
              )
                .row
                  .col
                    el-form-item(
                      label="Edit Channel Category Name"
                      prop="category"
                      :rules="rules.name"
                    )
                      el-input(v-model="categoryItem.category")
                  .col-auto
                    el-form-item(label=".")
                      el-tooltip(content="Cancel")
                        el-button(@click="showEditChannelCat = false" circle)
                          template(#icon='')
                            img(src="@/assets/icons/close.svg")
                      el-tooltip(content="Save")
                        el-button(
                          type="success"
                          @click="updateCategory()"
                          circle
                        )
                          template(#icon='')
                            img(src="@/assets/icons/light/check-light.svg")
</template>

<script>

import {
  defineComponent, reactive,
  ref, computed, onMounted,
} from 'vue';
import { channel, category } from '@/data/rules';
import VueMultiselect from 'vue-multiselect';
import shared from './shared';

export default defineComponent({
  name: 'DialogAddChannel',

  props: {
    loading: { type: Boolean, default: false },
    categories: { type: Array, default: () => [] },
  },
  components: { VueMultiselect },

  emits: ['close-dialog', 'save-item', 'save-category'],

  setup(_, { emit }) {
    const {
      getTypedCategories,
      getChannels,
    } = shared();
    // data
    const refForm = ref(null);
    const refCategory = ref(null);
    const showCatDialog = ref(false);
    const showAddCat = ref(false);
    const showEditChannelCat = ref(false);
    const categories = reactive({ categories: [] });
    const item = reactive({
      channel: '',
      categoryId: null,
      description: '',
    });

    const dialogTitle = computed(() => {
      if (showAddCat.value) {
        return 'Add Channel Category';
      }
      return 'Add Channel';
    });

    const categoryItem = reactive({
      id: null,
      category: '',
      categoryType: 'CHANNEL',
    });

    function submitForm() {
      item.categoryId = parseInt(item.categoryId.id, 10);
      emit('save-item', item);
      emit('close-dialog');
    }

    function showAddCatInput() {
      categoryItem.id = null;
      categoryItem.category = '';
      showAddCat.value = true;
    }

    function saveCategory() {
      emit('save-category', categoryItem);
      showAddCat.value = false;
    }

    function updateCategory() {
      emit('save-category', categoryItem);
      showEditChannelCat.value = false;
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) return submitForm();
        console.log('error submit!!');
        return false;
      });
    }

    onMounted(async () => {
      const response = await getChannels();
      item.channel = response.channels;
      categories.categories = await getTypedCategories();
    });

    function validateCategory() {
      refCategory.value.validate((valid) => {
        if (valid) {
          emit('save-category', categoryItem);
          showAddCat.value = false;
        }
      });
    }

    function editChannelCat(row) {
      categoryItem.id = parseInt(row.id, 10);
      categoryItem.category = row.category;
      showEditChannelCat.value = true;
    }

    return {
      // data
      refForm,
      refCategory,
      rules: {
        channel,
        categoryId: category,
      },
      categoryRules: {
        category,
      },
      item,
      categoryItem,

      showAddCat,
      showEditChannelCat,
      showCatDialog,
      dialogTitle,

      // methods
      validateForm,
      validateCategory,
      editChannelCat,
      showAddCatInput,
      saveCategory,
      updateCategory,

    };
  },
});
</script>
