import {
  findChannels,
  findChannel,
  mutateChannel,
} from '@/graphs/gql';

import commonShared from '../shared';

export default function shared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    getAuthToken,
    removeItemGetConfirm,
    makeGraphRequest,
    getTypedCategories,
    saveCategory,
    dateAndTime,
    exportData,
  } = commonShared('CHANNEL');

  const loading = ref(false);

  async function saveChannel(input: { [key: string]: any }): Promise<any> {
    await makeGraphRequest(
      'query', mutateChannel, { input }, getAuthToken(),
    );
  }

  async function getChannels(getfavourite: boolean): Promise<any> {
    const response = await makeGraphRequest(
      'query', findChannels, { getfavourite }, getAuthToken(),
    );
    return response.data.findChannels;
  }

  async function getChannel(id: number): Promise<any> {
    const response = await makeGraphRequest(
      'query', findChannel, { id }, getAuthToken(),
    );
    return response.data.findChannel;
  }

  return {
    loading,
    route,
    router,
    removeItemGetConfirm,
    getTypedCategories,
    getChannels,
    getChannel,
    saveChannel,
    saveCategory,
    dateAndTime,
    exportData,
  };
}
