import {
  getOrgAdminPaymentsLogV2,
  getPaymentsLog,
} from '@/graphs/gql';
import globalShared from '@/shared';

export default function commonShared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    removeItemGetConfirm,
    makeGraphRequest,
    getAuthToken,
    dateAndTime,
    preSignedUrl,
  } = globalShared();

  async function findAllPaymentLogs() {
    const response = await makeGraphRequest(
      'query', getPaymentsLog, {}, getAuthToken(),
    );
    return response.data.getPaymentsLogV2;
  }

  async function findOrgAdminPaymentsLogV2(entity: string, entityId: string) {
    const response = await makeGraphRequest(
      'query', getOrgAdminPaymentsLogV2, { entity, entityId }, getAuthToken(),
    );
    return response.data.getOrgAdminPaymentsLogV2;
  }

  return {
    ref,
    route,
    router,
    loading: ref(false),
    dialog: ref(false),
    removeItemGetConfirm,
    makeGraphRequest,
    preSignedUrl,
    getAuthToken,
    dateAndTime,
    findAllPaymentLogs,
    findOrgAdminPaymentsLogV2,
  };
}
