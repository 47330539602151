import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  DocumentNode,
  DefaultOptions,
} from '@apollo/client/core';

const baseURL = import.meta.env.VITE_GRAPHQL_BASE_URL;

const httpLink = createHttpLink({
  uri: baseURL,
});

const cache = new InMemoryCache();
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const gqlClient = new ApolloClient({
  link: httpLink,
  cache,
  defaultOptions,
});

export async function makeRequest(
  type: string, gql: DocumentNode,
  variables: { [key: string]: any },
  token: string | null,
): Promise<any> {
  let context = {};
  let promis = null;
  if (token) {
    context = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  switch (type) {
    case 'query':
      promis = gqlClient.query({
        query: gql,
        variables,
        context,
      });
      break;
    case 'mutation':
      promis = gqlClient.mutate({
        mutation: gql,
        variables,
        context,
      });
      break;
    default:
      return null;
  }
  const results = await promis;
  return results;
}
