<!-- eslint-disable max-len -->
<template>
  <section>
    <div class="card-header p-4 bg-transparent">
      <div class="row row-sm align-items-center">
        <div class="col">
          <div class="d-flex">
            <router-link to="/manage-content/media/stickers">
              <fai type="fal" icon="angle-left" class="size-28 me-2"></fai>
            </router-link>
            <h4 class="mb-0">Stickers</h4>
          </div>
        </div>
        <div class="col-auto">
          <el-button type="danger" plain @click="removeItem" class="has-fai">
            <fai type="fal" icon="trash" class="size-18 me-2"></fai>
            <span class="min-w-110 d-inline-block">Delete</span>
          </el-button>

          <el-button
            v-if="!item.seen || (item.seen && item.published)"
            type="danger"
            @click="publishItem(false)"
            class="has-fai"
          >
            <fai type="fal" icon="times-circle" class="size-18 me-2"></fai>
            <span class="min-w-110 d-inline-block">Deny</span>
          </el-button>
          <el-button
            v-if="!item.seen || (item.seen && !item.published)"
            type="success"
            :style="{ color: '#1EBEAF' }"
            @click="publishItem(true)"
            class="has-fai"
          >
            <fai type="fal" icon="check-circle" class="size-18 me-2" style="color: #fff"></fai>
            <span class="min-w-110 d-inline-block text-white">Approve</span>
          </el-button>

          <el-button type="primary" @click="validateForm" v-loading="loading" class="has-fai">
            <fai type="fal" icon="edit" class="size-18 me-2"></fai>
            <span class="min-w-110 d-inline-block">Save</span>
          </el-button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <el-form
        ref="refForm"
        :rules="rules"
        :model="item"
        status-icon
        label-position="top"
      >
        <div class="row">
          <div class="col-md-8">
            <el-form-item label="Title" prop="title">
              <el-input v-model="item.title"></el-input>
            </el-form-item>

            <div class="row">
              <div class="col-6">
                <el-form-item label="Tags">
                  <VueMultiselect
                    v-model="item.tags"
                    :multiple="true"
                    :options="tagsArray"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="tagsLoading"
                    placeholder="Please enter a tag"
                  />
                </el-form-item>
              </div>
              <div class="col-6">
                <el-form-item label="Cost (optional)" prop="cost" v-if="!item.hasOptions">
                  <el-input-number
                    v-if="item.campaignType"
                    v-model="item.cost"
                    size="large"
                    type="number"
                    :min="0"
                  />
                  <el-input-number
                    v-else
                    v-model="item.cost"
                    type="number"
                    size="large"
                    :min="0"
                  />
                </el-form-item>
              </div>
              <div class="col-12 my-2" v-if="item.campaignType !== 'Ticket'">
                <el-checkbox v-model="allowNamingPrices">Allow users to name price</el-checkbox>

                <div v-if="allowNamingPrices" class="py-2">
                  <p>Quick cost options</p>
                  <div class="d-flex align-items-center">
                    <el-input
                      v-for="(cost, index) in item.quickCosts"
                      :key="index"
                      v-model.number="item.quickCosts[index]"
                      size="small"
                      :placeholder="'Cost ' + (index + 1)"
                      type="number"
                      step="1"
                      min="0"
                      style="margin-right: 4px; margin-top: 4px;"
                      @input="validateInput(index)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <el-form-item label="Description" prop="description">
              <el-input v-model="item.description" type="textarea"></el-input>
            </el-form-item>

            <div class="d-flex mb-2">
              <div>Allow app users to search for more campaigns</div>
              <el-switch
                class="ms-auto"
                v-model="item.isCausesSearchAllowed"
                active-color="#13ce66"
                :active-value="true"
                :inactive-value="false"
              />
            </div>

            <div class="d-flex mb-2">
              <div>Campaign</div>
              <el-switch
                class="ms-auto"
                v-model="item.campaignType"
                active-color="#13ce66"
                :active-value="'Campaign'"
                :inactive-value="null"
              />
            </div>

            <div class="d-flex mb-2">
              <div>Ticket</div>
              <el-switch
                class="ms-auto"
                v-model="item.campaignType"
                active-color="#13ce66"
                :active-value="'Ticket'"
                :inactive-value="null"
              />
            </div>

            <div v-if="item.campaignType === 'Campaign'">
              <el-form-item label="Non-Profit Suggestions (optional)">
                <VueMultiselect
                  v-model="item.causes"
                  :multiple="true"
                  :options="causeOptionsArray"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :loading="causeLoading"
                  placeholder="Please enter a tag"
                  @search-change="getCausesDropdownOptions"
                />
              </el-form-item>

              <div class="row">
                <div class="col-4">
                  <el-form-item label="Max Quantity (optional)" prop="maxQuantity">
                    <el-input-number v-model="item.maxQuantity" size="large" />
                  </el-form-item>
                </div>
                <div class="col-4">
                  <el-form-item label="Start Date" prop="startDate">
                    <input type="datetime-local" v-model="item.startDate" class="w-100" />
                  </el-form-item>
                </div>
                <div class="col-4">
                  <el-form-item label="End Date" prop="endDate">
                    <input type="datetime-local" v-model="item.endDate" class="w-100" />
                  </el-form-item>
                </div>
              </div>
            </div>

            <div v-if="item.campaignType === 'MatchCampaign'">
              <div class="row">
                <div class="col-6">
                  <el-form-item label="Max Donation" prop="maxDonation">
                    <el-input v-model="item.maxDonation" type="number" />
                  </el-form-item>
                </div>
                <div class="col-6">
                  <el-form-item label="Donation Multiplier" prop="donationMultiplier">
                    <el-input-number v-model="item.donationMultiplier" />
                  </el-form-item>
                </div>
              </div>

              <el-form-item label="Non-Profit Suggestions (optional)" prop="cause">
                <el-select
                  v-model="item.causes"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  placeholder="Please enter a cause"
                  :remote-method="getCausesDropdownOptions"
                  :loading="causeLoading"
                >
                  <el-option
                    v-for="cause in causeOptions"
                    :key="cause.value"
                    :label="cause.label"
                    :value="cause.value"
                  />
                </el-select>
              </el-form-item>

              <el-form-item label="Impact" prop="impact">
                <el-input v-model="item.impact" />
              </el-form-item>

              <div class="row">
                <div class="col-6">
                  <el-form-item label="Start Date" prop="startDate">
                    <input type="datetime-local" v-model="item.startDate" class="w-100" />
                  </el-form-item>
                </div>
                <div class="col-6">
                  <el-form-item label="End Date" prop="endDate">
                    <input type="datetime-local" v-model="item.endDate" class="w-100" />
                  </el-form-item>
                </div>
              </div>
            </div>

            <div v-if="item.campaignType === 'Ticket'">
              <div class="row">
                <div class="col-6">
                  <el-form-item label="Max Quantity (optional)" prop="maxQuantity">
                    <el-input-number v-model="item.maxQuantity" size="large" />
                  </el-form-item>
                </div>
                <div class="col-6">
                  <el-form-item label="Event Date & Time" prop="startDate">
                    <input type="datetime-local" v-model="item.eventDate" class="w-100" />
                  </el-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <el-form-item label="Sale Start Date & Time" prop="startDate">
                    <input type="datetime-local" v-model="item.startDate" class="w-100" />
                  </el-form-item>
                </div>
                <div class="col-6">
                  <el-form-item label="Sale End Date & Time" prop="endDate">
                    <input type="datetime-local" v-model="item.endDate" class="w-100" />
                  </el-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <el-form-item label="Location" prop="location">
                    <el-input v-model="item.location" />
                  </el-form-item>
                </div>
                <div class="col-6">
                  <el-form-item label="Waiver URL" prop="waiver">
                    <el-input v-model="item.waiverURL" />
                  </el-form-item>
                </div>
              </div>

              <el-form-item label="Cause (optional)">
                <VueMultiselect
                  v-model="item.causes"
                  :multiple="true"
                  :options="causeOptionsArray"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :loading="causeLoading"
                  placeholder="Please enter a tag"
                  @search-change="getCausesDropdownOptions"
                />
              </el-form-item>

              <div class="col">
                <p class="pt-4">Message for Confirmation Email</p>
                <el-input
                  v-model="item.confirmationEmail"
                  type="textarea"
                  placeholder="Message for Confirmation Email"
                />
              </div>

              <div class="col">
                <p class="pt-4">Message for Reminder email</p>
                <el-input
                  v-model="item.reminderEmail"
                  type="textarea"
                  placeholder="Message for Reminder email"
                />
              </div>

              <hr />
              <p class="pt-4">Options</p>
              <el-checkbox v-model="item.hasOptions" class="me-4">
                This sticker has price tiers
              </el-checkbox>

              <div v-if="item.hasOptions === true">
                <cost-varients-list
                  :loading="loading"
                  :item="item"
                  @emitAddPrice="addPrice"
                  @emitRemovePrice="removePrice"
                  @emitChangeEvent="changeEvent"
                  class="mb-4"
                />
              </div>
            </div>

            <glow-picker ref="glowPickerRef" />
          </div>

          <div class="col-md-4">
            <el-upload
              :show-file-list="false"
              :on-success="handleSuccess"
              :on-error="handleError"
              :before-upload="beforeUpload"
              :http-request="handleUpload"
              v-loading="uploading"
              action=""
              class="img-uploader pos-r mb-4"
            >
              <template v-if="item.url">
                <img :src="item.url" class="img-fluid" />
                <div class="pin pin-bx d-flex p-2">
                  <el-button type="primary" class="ms-auto">Change</el-button>
                </div>
              </template>
              <template v-else>
                <img src="@/assets/icons/file-image-outline.svg" class="img-uploader-icon" />
                <p class="upload-picture-text">Drop an image file here or</p>
                <div class="fake-upload-button">Browse For File</div>
              </template>
            </el-upload>

            <el-form-item label="Share Sticker">
              <div class="px-3">
                <qrcode-vue :value="stickerUrl" size="300" />
                <div class="primary-color-text">{{ stickerUrl }}</div>
              </div>
            </el-form-item>



            <el-form-item label="Creator">
              <el-input v-model="item.creatorTitle" disabled />
            </el-form-item>

            <el-form-item label="Is Free?">
              <el-input v-model="item.isFree" disabled />
            </el-form-item>

            <el-form-item>
              <template #label>
                Post Date<span class="text-muted ms-2">Active for 3 Days</span>
              </template>
              <el-input v-model="item.postDate" disabled />
            </el-form-item>

            <el-form-item label="Post Time">
              <el-input v-model="item.postTime" disabled />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <sticker-insights
      :loading="loading"
      :isStickerInsights="isStickerInsights"
      :stickerInsights="stickerInsights"
      @close-dialog="isStickerInsights = !isStickerInsights"
    />
  </section>
</template>

<script>
import {
  defineComponent, ref, onMounted, reactive, nextTick
} from 'vue';
import QrcodeVue from 'qrcode.vue';

import VueMultiselect from 'vue-multiselect';
import fileUpload from '@/shared/upload';
import { requiredRule, title } from '@/data/rules';
import CostVarientsList from './CostVarientsList.vue';
import StickerInsights from './StickerInsights.vue';
import shared from '../shared';
import Draggable from 'vuedraggable';
import GiftASticker from './GiftASticker.vue';
import GlowPicker from "@/components/StickerTIcket/GlowPicker.vue";
import { validateTime, joinDateAndTime, unJoinDateAndTime } from "@/shared/utility-functions";

export default defineComponent({
  name: 'StickerDetail',
  components: {
    GlowPicker,
    VueMultiselect, CostVarientsList, StickerInsights, QrcodeVue, Draggable, GiftASticker
  },
  setup() {
    const {
      route, router, loading, getSticker,
      updateSticker, removeItemGetConfirm,
      dateAndTime, removeSticker, getBullhornsAsTags,
      getcausesAsDropdown, getBenevityCauseDetails,
      findStickerInsights, approveSticker,
    } = shared();
    const refForm = ref(null);
    const {
      uploading, uploadStatus,
      beforeUpload, handleUploadRequest,
    } = fileUpload('stickers', 'Sticker');

    const { id } = route.params;
    const tagsList = ref([]);
    const tagsOptions = ref([]);
    const tagsLoading = ref(false);
    const causeList = ref([]);
    const causeOptions = ref([]);
    const causeOptionsArray = ref([]);
    const causeLoading = ref(false);
    const tagsArray = ref([]);
    const isStickerInsights = ref(false);
    const stickerInsights = reactive({});
    const glowPickerRef = ref(null);
    const item = reactive({
      id,
      title: '',
      description: '',
      url: '',
      published: false,
      tags: ref([]),
      cost: 0,
      campaignType: null,
      causes: ref([]),
      // nonProfitSuggestions: ref([]),
      maxQuantity: 0,
      maxDonation: null,
      donationMultiplier: 1,
      impact: null,
      eventDate: null,
      startDate: null,
      endDate: null,
      user: { name: '' },
      creatorTitle: null,
      isFeatured: false,
      isCausesSearchAllowed: false,
      location: '',
      waiverURL: '',
      varients: reactive([{
          id:null,
          cost: null,
          title: '',
          sortOrder:0,
          stickerId:null,
          description:null,
          isActive:true,
          createdAt:null,
          updatedAt:null,
        },
      ]),
      confirmationEmail:'',
      reminderEmail:'',
      quickCosts: [null, null, null, null],
    });

    const allowNamingPrices = ref(false);

    const drag = ref(null);

    const dragChoose = () => {
      drag.value = true;
    };

    const dragUnchoose = () => {
      drag.value = false;
    };

    const dragStart = () => {
      drag.value = true;
    };

    const dragEnd = () => {
      drag.value = false;
    };

    const changeEvent = (element) => {
      const propItems = item.varients;
      const movedItem = element.moved;
      if (element.moved.newIndex > element.moved.oldIndex) {
        var itemToSet = propItems.find((x) => x.sortOrder === movedItem.oldIndex);
        propItems.forEach((x) => {
          if (x.sortOrder > movedItem.oldIndex && x.sortOrder <= movedItem.newIndex) {
            // eslint-disable-next-line no-plusplus, no-param-reassign
            x.sortOrder--;
          }
        });
        itemToSet.sortOrder = movedItem.newIndex;
      } else {
        var itemToSet = propItems.find((x) => x.sortOrder === movedItem.oldIndex);
        propItems.forEach((x) => {
          if (x.sortOrder < movedItem.oldIndex && x.sortOrder >= movedItem.newIndex) {
            // eslint-disable-next-line no-plusplus, no-param-reassign
            x.sortOrder++;
          }
        });
        // eslint-disable-next-line no-plusplus, no-param-reassign
        itemToSet.sortOrder = movedItem.newIndex;
      }

      // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
      item.varients = propItems.sort((a, b) => (a.sortOrder - b.sortOrder));
    };

    const addPrice = (sortOrder) => {
      var total = item.varients.length;
      if(total == (sortOrder+1)){
        const newPrice = {
          cost: null,
          title: '',
          sortOrder: total,
        };
        item.varients.push(newPrice);
      }
    }

    const removePrice = () => {
      item.varients.splice(item.varients.length - 2, 1); // Remove the second-to-last element
      item.varients[item.varients.length - 1].sortOrder--;
    }

    async function getItem() {
      loading.value = true;
      const itm = await getSticker(route.params.id);
      Object.assign(item, itm);
      item.tags = (item.tags !== null && item.tags !== '') ? item.tags.split(' |=| ') : [];
      item.causes = (item.causes !== null && item.causes !== '') ? item.causes.split(' |=| ') : [];
      [item.postDate, item.postTime] = dateAndTime(itm.createdAt);

      item.startDate = item.startDate?.substring(0, 16);
      item.endDate = item.endDate?.substring(0, 16);
      item.eventDate = item.eventDate?.substring(0, 16);
      item.varients = item.varients.filter((x) => x.isActive === true);
      if (item.campaignType === 'Ticket')  {
        if (item.varients?.length == 0) {
          item.varients = reactive([{
          id: null,
          cost: null,
          title: '',
          sortOrder:0,},])
        } else {
          let sortOrder = item.varients[item.varients?.length-1].sortOrder;
          addPrice(sortOrder);
        }
      }

      for (const i in item.quickCosts) {
        if (i) {
          allowNamingPrices.value = true;
        }
      }

      if (!item.quickCosts?.length) {
        item.quickCosts = [null, null, null, null];
      } else {
        const additionalCostsNeeded = 4 - item.quickCosts.length;
        if (additionalCostsNeeded > 0) {
          item.quickCosts = [...item.quickCosts, ...Array(additionalCostsNeeded).fill(null)];
        }
      }

      nextTick(()=> {
        if (item.glowColor) {
          if (item.glowColor) {
            glowPickerRef.value.glow = true; // decide to check it
          }
          glowPickerRef.value.color = item.glowColor;
          const start = unJoinDateAndTime(item.glowStartDate);
          glowPickerRef.value.dateStart = start.theDate;
          glowPickerRef.value.timeStart = start.time;
          const end = unJoinDateAndTime(item.glowEndDate);
          glowPickerRef.value.dateEnd = end.theDate;
          glowPickerRef.value.timeEnd = end.time;
        }
      })

      loading.value = false;
    }

    async function getBullhornsAsTagsList() {
      const bullhornTags = await getBullhornsAsTags();
      bullhornTags.forEach((element) => {
        const data = { value: `${element.bullhorn}`, label: `${element.bullhorn}` };
        tagsList.value.push(data);
        // tagsArray is used to render the correct values for the new dropdown
        tagsArray.value.push(element.bullhorn);
      });
    }

    // eslint-disable-next-line no-unused-vars
    async function getTagsDropdownOptions(query) {
      if (query) {
        tagsLoading.value = true;
        setTimeout(() => {
          tagsLoading.value = false;
          // eslint-disable-next-line arrow-body-style
          tagsOptions.value = tagsList.value.filter((dropdownItem) => {
            return dropdownItem.label.toLowerCase().includes(query.toLowerCase());
          });
        }, 200);
      } else {
        tagsOptions.value = [];
      }
    }

    async function getCausesList(query) {
      const benevityCauses = await getcausesAsDropdown(query);
      benevityCauses.forEach((element) => {
        const data = {
          value: `${element.name} : ${element.id}`,
          label: `${element.name} : ${element.id}`,
        };
        causeList.value.push(data);
        causeOptions.value.push(data);
        causeOptionsArray.value.push(`${element.name} : ${element.id}`);
      });
    }

    async function getCausesDropdownOptions(query) {
      if (query) {
        causeLoading.value = true;
        const benevityCauses = await getcausesAsDropdown(query);
        setTimeout(() => {
          causeLoading.value = false;
          causeOptions.value = [];
          benevityCauses.forEach((element) => {
            const data = {
              value: `${element.name} : ${element.id}`,
              label: `${element.name} : ${element.id}`,
            };
            causeOptions.value.push(data);
            causeOptionsArray.value.push(`${element.name} : ${element.id}`);
          });
        }, 200);
      } else {
        causeOptions.value = [];
      }
    }

    async function publishItem(opt) {
      console.log("Setting item active " + id)
      let status = 'Active';
      if(opt === false) status = 'Archived';
      await approveSticker({
        id,
        status: status,
      });
      await getItem();
    }

    async function handleUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.url = uploadStatus.resourceUrl;
      }
    }

    function handleSuccess() {
      console.log('success');
    }

    function handleError() {
      console.log('error');
    }

    async function saveItem() {
      loading.value = true;
      const input = JSON.parse(JSON.stringify(item));
      if (item.campaignType === 'Ticket' && input?.varients.length > 0) {
        input.varients.pop();
        input.varients.forEach(x => {
          delete x.__typename;
          x.cost = parseFloat(parseFloat(x.cost).toFixed(2));
        });

      }

      if (item.campaignType !== 'Ticket' && input?.varients.length > 0) {
        input.varients = [];
      }

      let itemToSave = {
        id,
        title: input.title,
        url: input.url,
        description: input.description,
        tags: input.tags.join(' |=| '),
        cost: parseFloat(parseFloat(input.cost).toFixed(2)),
        campaignType: input.campaignType ? input.campaignType : null,
        causes: `${input.causes.join(' |=| ')}`,
        maxQuantity: input.maxQuantity,
        maxDonation: parseFloat(parseFloat(input.maxDonation).toFixed(2)),
        donationMultiplier: input.donationMultiplier,
        impact: input.impact,
        eventDate: input.eventDate,
        startDate: input.startDate,
        endDate: input.endDate,
        isFeatured: input.isFeatured,
        isCausesSearchAllowed: input.isCausesSearchAllowed,
        location: input.location,
        waiverURL: input.waiverURL,
        hasOptions: input.hasOptions,
        confirmationEmail: input.confirmationEmail,
        reminderEmail: input.reminderEmail,
        varients: input.varients,
      }

      if (item.campaignType !== 'Ticket') {
        const updatedQuickCosts = [];
        item.quickCosts.map(cost => {
          if (cost !== '' && cost !== null) {
            updatedQuickCosts.push(cost);
          }
        });

        let quickCosts = allowNamingPrices.value ? updatedQuickCosts : null;
        itemToSave = Object.assign({}, itemToSave, {quickCosts});
      }

      if (glowPickerRef?.value) {
        const { dateStart, dateEnd, timeStart, timeEnd, color, glow } = glowPickerRef.value
        if (glow) {
          itemToSave.glowStartDate =  joinDateAndTime(dateStart, timeStart);
          itemToSave.glowEndDate = joinDateAndTime(dateEnd, timeEnd);
          itemToSave.glowColor = color;
        } else {
          itemToSave.glowStartDate = null;
          itemToSave.glowEndDate = null;
          itemToSave.glowColor = null;
        }
      }

      await updateSticker(itemToSave);
      loading.value = false;
    }


    const validateInput = (index) => {
      if (item.quickCosts[index] < 0) {
        item.quickCosts[index] = 0;
      }
    }

    function removeItem() {
      removeItemGetConfirm(async () => {
        await removeSticker(item.id);
        router.push('/manage-content/media/stickers');
      });
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    async function getStickerInsights() {
      loading.value = true;
      const mStickerInsights = await findStickerInsights(route.params.id);
      Object.assign(stickerInsights, mStickerInsights);
      loading.value = false;
    }

    const stickerUrl = `${import.meta.env.VITE_WEB_BASE_URL}/web/sticker/${item.id}`;

    onMounted(async () => {
      loading.value = true;
      await getBullhornsAsTagsList();
      await getCausesList('');
      await getItem();
      await getStickerInsights();
      loading.value = false;
    });

    return {
      // data
      refForm,
      item,
      loading,
      publishItem,
      uploading,
      rules: {
        title,
        description: requiredRule('description'),
        tags: requiredRule('tags'),
        startDate: requiredRule('startDate'),
        endDate: requiredRule('endDate'),
      },
      stickerUrl,
      // methods
      beforeUpload,
      tagsList,
      tagsOptions,
      tagsLoading,
      causeList,
      causeOptions,
      causeLoading,
      getBullhornsAsTagsList,
      getTagsDropdownOptions,
      getCausesList,
      getCausesDropdownOptions,
      getBenevityCauseDetails,
      handleUpload,
      handleSuccess,
      handleError,
      removeItem,
      validateForm,
      tagsArray,
      causeOptionsArray,
      getItem,
      isStickerInsights,
      stickerInsights,
      changeEvent,
      removePrice,
      addPrice,
      allowNamingPrices,
      validateInput,
      glowPickerRef
    };
  },
});
</script>
<style>
.el-select {
  display: block !important;
}
</style>
