<template lang="pug">
el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(
    title='Segment'
    back-to='/manage-content/directory/segments'
    v-loading="loading"
  )
    template(#actions)
      .row.row-sm.align-items-center
        .col-auto
          el-button(type="danger" plain @click="removeSegment").has-fai
            fai(type="fal" icon="trash").size-18.me-2
            span.min-w-110.d-inline-block Delete

          el-button(type="primary" @click="validateForm").has-fai
            fai(type="fal" icon="edit").size-18.me-2
            span.min-w-110.d-inline-block Save

    template(#col-left)
      div
        el-form-item(label="Segment Title" prop="segment")
          el-input(v-model="item.segment")

        el-form-item(label="Details" prop="description")
          el-input(v-model="item.description" type="textarea")

    template(#col-right)
      div
        el-upload(
            :show-file-list="false"
            :on-success="handleSuccess"
            :on-error="handleError"
            :before-upload="beforeUpload"
            :http-request="handleSegmentCoverUpload"
            v-loading="uploading"
            action=""
          ).img-uploader.pos-r.mb-4
            template(v-if="item.cover")
              img(:src="item.cover").img-fluid
              .pin.pin-bx.d-flex.p-2
                el-button(type="primary").ms-auto Change
            template(v-else)
              img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
              p.upload-picture-text Drop an image file here or
              div.fake-upload-button Browse For File
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
} from 'vue';
import { requiredRule } from '@/data/rules';
import fileUpload from '@/shared/upload';
import shared from './shared';

export default defineComponent({
  name: 'ManageContent_Directory_SegmentDetail',
  setup() {
    const {
      route,
      router,
      loading,
      getSegment,
      saveSegment,
      removeItemGetConfirm,
    } = shared();
    const refForm = ref(null);
    const { id } = route.params;
    const { uploadStatus, beforeUpload, handleUploadRequest } = fileUpload('segments', 'Segment');
    const item = reactive({
      id: -1,
      cover: null,
      segment: '',
      description: '',
      entity: null,
      entityId: null,
      isActive: true,
    });

    async function getItem() {
      loading.value = true;
      const itm = await getSegment(id);
      item.id = itm.id;
      item.cover = itm.cover;
      item.segment = itm.segment;
      item.description = itm.description;
      item.entity = itm.entity;
      item.entityId = itm.entityId;
      item.isActive = itm.isActive;
      loading.value = false;
    }

    async function saveItem() {
      await saveSegment({
        id: item.id,
        cover: item.cover,
        segment: item.segment,
        description: item.description,
        entity: item.entity,
        entityId: item.entityId,
        isActive: item.isActive,
      });
    }

    async function handleSegmentCoverUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.cover = uploadStatus.resourceUrl;
      }
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    onMounted(async () => {
      await getItem();
    });

    async function removeItem() {
      await saveSegment({
        id: item.id,
        cover: item.cover,
        segment: item.segment,
        description: item.description,
        entity: item.entity,
        entityId: item.entityId,
        isActive: false,
      });
      router.push('/manage-content/directory/segments');
    }

    function removeSegment() {
      removeItemGetConfirm(removeItem);
    }

    return {
      // data
      refForm,
      rules: {
        cover: requiredRule('cover'),
        segment: requiredRule('segment'),
        description: requiredRule('description'),
      },
      item,
      loading,

      // methods
      validateForm,
      removeSegment,
      handleSegmentCoverUpload,
      beforeUpload,
    };
  },
});
</script>
