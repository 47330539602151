import { gql } from '@apollo/client/core';

const getActivityLogs = gql`
  query($entity: String!, $entityId: String!) {
    GetPaymentsLogsForActivity(entity: $entity, entityId: $entityId) {
      transactionId
      date
      userName
      amount
      item
      details
      email
      type
      stickerId
    }
  }
`;

const getActivityDetails = gql`
  query ($transactionId: String!) {
  GetDetailsAboutTransaction(transactionId: $transactionId) {
    paymentInfo
    amount
    paymentType
    status
    createdAt

    user {
      id
      usertype
      firstname
      lastname
      title
      avatar
      background
      age
      email
      phone
      email_verified
      active
      is_creator
      status
      createdAt
      phone_verified
    }

    organization {
      id
      name
      avatar
      background
      about
      purpose
      dob
      email
      phone
      stripeAccountId
      address_line_1
      address_line_2
      city
      state
      zipcode
      createdAt
      organization_type
      profit_type
      nonprofit_type
      is_creator
      onboarded
      active
      approved
      denied
      withdrawn
      stripe_onboarded
    }

    sticker {
      id
      user {
        id
        firstname
        lastname
        email
        phone
        avatar
      }
      organization {
        id
        name
        email
        phone
        avatar
        stripeAccountId
      }
      title
      url
      description
      cost
      campaignType
      createdAt
      updatedAt
    }

    benevityTransaction {
      id
      transactionType
      amount
      notes
      meta
      organization {
        id
        name
        email
        phone
        avatar
      }
      user {
        id
        firstname
        lastname
        email
        phone
        avatar
      }
      causeId
      causeName
      description
      createdAt
      updatedAt
    }

    benevityW2WCreditTransaction {
      id
      benevityTransactionId
      transactionType
      from
      to
      credits
      notes
      status
      message
      meta
      fromOrganization {
        id
        name
        email
        phone
        avatar
      }
      fromUser {
        id
        firstname
        lastname
        email
        phone
        avatar
      }
      toOrganization {
        id
        name
        email
        phone
        avatar
      }
      toUser {
        id
        firstname
        lastname
        email
        phone
        avatar
      }
      createdAt
      updatedAt
    }

    stripeTransaction {
      id
      entity
      entityId
      intentId
      transactionType
      meta
      amount
      fee_Convenience
      fee_CreditCard
      walletDeposit
      processed
      createdAt
      updatedAt
    }

    orgPaymentStripeTransaction {
      id
      entity
      entityId
      intentId
      transactionType
      meta
      amount
      fee_Convenience
      fee_CreditCard
      walletDeposit
      processed
      createdAt
      updatedAt
    }

    stickerTransaction {
      id
      entity
      entityId
      stickerId
      causeId
      variantId
      cost
      amount_donated
      amount_rebate
      campaignType
      purchaseType
      benevity_transaction_Id
      stripe_transaction_Id
      Org_Payment_stripe_transaction_Id
      orgStripeAccountId
      transactionMeta
      isProcessed
      createdAt
      updatedAt
    }
  }
}
`

export { getActivityLogs, getActivityDetails };
