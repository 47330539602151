import { gql } from '@apollo/client/core';

export const canIntent = gql`query($userId: String!){
  canIntent(userId: $userId) {
    id
    firstname
    lastname
    userAvatar
  }
}`;

export const canIntentForOrg = gql`query($userId: String!, $orgId: String! ){
  canIntentForOrg(userId: $userId, orgId: $orgId) {
    id
    firstname
    lastname
    organizationId
    organizationName
    organizationAvatar
    organizationType
  }
}`;

export const createPaymentIntent = gql`mutation ($userId: String!, $item: PaymentInput! ) {
   createPaymentIntent(userId: $userId, item: $item )
}`;

export const createPaymentIntentForOrg = gql`mutation ($userId: String!,
   $orgId: String!, $item: PaymentInput! ) {
   createPaymentIntentForOrg(userId: $userId, orgId: $orgId, item: $item )
}`;

export const benevityCauses = gql`query($q: String!, $page: String! ){
  benevityCauses(q: $q, page: $page) {
    causes {
      id
      name
    }
  }
}`;

export const benevityCauseDetails = gql`query($causeId: String! ){
  benevityCauseDetails(causeId: $causeId) {
    id
    attributes {
      name
    }
  }
}`;

export const createStickerPurchaseIntent = gql`mutation ($input: StickerPurchaseInput! ) {
  createStickerPurchaseIntent(input: $input)
}`;

export const canIntentForSticker = gql`query($stickerId: String!){
  canIntentForSticker(stickerId: $stickerId) {
    id
    title
    url
    description
    entity
    entityId
    user {
      id
      firstname
      lastname
    }
    varients {
      id
      stickerId
      title
      cost
      description
      sortOrder
      isActive
    }
    sweepstakesSticker {
      checkoutPageLegal
    }
    published
    tags
    cost
    campaignType
    causes
    nonProfitSuggestions
    maxQuantity
    maxDonation
    donationMultiplier
    impact
    startDate
    endDate
    seen
    isSpotlight
    creatorTitle
    createdAt
  }
}`;

export const validateIntentForSticker = gql`
query($stickerId: String!, $entity: String!, $entityId: String!){
  validateIntentForSticker(stickerId: $stickerId, entity: $entity, entityId: $entityId) {
    id
    isValid
    errors
  }
}`;
export const validateIntentForStickerPublic = gql`
query($stickerId: String!){
  validateIntentForStickerPublic(stickerId: $stickerId) {
    id
    isValid
    errors
  }
}`;

export const createInitiateWalletIntent = gql`mutation ($input: InitiateWalletInput! ) {
  createInitiateWalletIntent(input: $input)
}`;

export const canIntentForWalletPricing = gql`query($walletPricingId: String!){
  canIntentForWalletPricing(walletPricingId: $walletPricingId) {
    id
    cost
    walletType
    employeesRange
    features
    logo
    description
    productId
  }
}`;

export const curatedBenevityMarketPlaceUpgrade = gql`query {
  curatedBenevityMarketPlaceUpgrade {
    id
    cost
    features
    logo
    description
    productId
  }
}`;

export const getPaymentsLog = gql`query {
  getPaymentsLogV2 {
    user {
      id
      firstname
      lastname
      avatar
      email
      phone
    }
    organization {
      id
      name
      avatar
      email
      phone
    }
    benevityTransaction {
      id
      transactionType
      amount
      causeId
      causeName
      meta
    }
    benevityW2WCreditTransaction {
      id
      credits
      notes
      message
      meta
      fromEntity
      toEntity
      fromUser {
        id
        firstname
        lastname
        avatar
        email
        phone
      }
      fromOrganization {
        id
        name
        email
        avatar
        email
        phone
      }
      toUser {
        id
        firstname
        lastname
        avatar
        email
        phone
      }
      toOrganization {
        id
        name
        avatar
        email
        phone
      }
    }
    stickerTransaction {
      id
      stickerId
      causeId
      variantId
      cost
      amount_donated
      amount_rebate
      campaignType
      purchaseType
      benevity_transaction_Id
      stripe_transaction_Id
      orgStripeAccountId
      Org_Payment_stripe_transaction_Id
      transactionMeta
      createdAt
      isProcessed
      transactionMeta
    }
    stripeTransaction {
      id
      intentId
      transactionType
      meta
      amount
      walletDeposit
      fee_Convenience
      fee_CreditCard
      processed
    }
    orgPaymentStripeTransaction {
      id
      intentId
      transactionType
      meta
      amount
      walletDeposit
      fee_Convenience
      fee_CreditCard
      processed
    }
    sticker {
      id
      title
      url
      description
      entity
      entityId
      user {
        id
        firstname
        lastname
        avatar
        email
        phone
      }
      organization {
        id
        name
        email
        avatar
        email
        phone
      }
      published
      tags
      cost
      campaignType
    }
    amount
    paymentType
    paymentInfo
    status
    createdAt
  }
}`;

export const getOrgAdminPaymentsLogV2 = gql`query($entity: String! $entityId: String!){
  getOrgAdminPaymentsLogV2(entity: $entity entityId: $entityId) {
    user {
      id
      firstname
      lastname
      avatar
      email
      phone
    }
    organization {
      id
      name
      avatar
      email
      phone
    }
    benevityTransaction {
      id
      transactionType
      amount
      causeId
      causeName
      meta
    }
    benevityW2WCreditTransaction {
      id
      credits
      notes
      message
      meta
      fromEntity
      toEntity
      fromUser {
        id
        firstname
        lastname
        avatar
        email
        phone
      }
      fromOrganization {
        id
        name
        email
        avatar
        email
        phone
      }
      toUser {
        id
        firstname
        lastname
        avatar
        email
        phone
      }
      toOrganization {
        id
        name
        avatar
        email
        phone
      }
    }
    stickerTransaction {
      id
      stickerId
      causeId
      variantId
      cost
      amount_donated
      amount_rebate
      campaignType
      purchaseType
      benevity_transaction_Id
      stripe_transaction_Id
      orgStripeAccountId
      Org_Payment_stripe_transaction_Id
      transactionMeta
      createdAt
      isProcessed
      transactionMeta
    }
    stripeTransaction {
      id
      intentId
      transactionType
      meta
      amount
      walletDeposit
      fee_Convenience
      fee_CreditCard
      processed
    }
    orgPaymentStripeTransaction {
      id
      intentId
      transactionType
      meta
      amount
      walletDeposit
      fee_Convenience
      fee_CreditCard
      processed
    }
    sticker {
      id
      title
      url
      description
      entity
      entityId
      user {
        id
        firstname
        lastname
        avatar
        email
        phone
      }
      organization {
        id
        name
        email
        avatar
        email
        phone
      }
      published
      tags
      cost
      campaignType
    }
    amount
    paymentType
    paymentInfo
    status
    createdAt
  }
}`;

export const getCouponByPromoCode = gql`query($promotionCode: String!){
  getCouponByPromoCode(promotionCode: $promotionCode) {
    name
    code
    promoCodeId
    couponCodeId
    percent_off
    amount_off
    max_redemptions
    times_redeemed
    livemode
    active
    valid
    applicable_products
  }
}`;

export const createStickerWebIntent = gql`mutation ($stickerId: String!, $causeId: String!, $cost: String!){
  createStickerWebIntent(stickerId: $stickerId, causeId: $causeId, cost: $cost)
}`;

export const createStickerWebIntentPublic = gql`mutation ($stickerId: String!, $causeId: String!, $cost: String!){
  createStickerWebIntentPublic(stickerId: $stickerId, causeId: $causeId, cost: $cost)
}`;


export const curatedBenevityCauses = gql`query {
  curatedBenevityCauses {
    id
    name
    logo
    description
  }
}`;

export const createAnonymousStickerTransaction = gql`mutation ($intentId: String!, $clientSecret: String!, $email: String!, $phone: String!){
  createAnonymousStickerTransaction(intentId: $intentId, clientSecret: $clientSecret, email: $email, phone: $phone)
}`;


export const createStickerTicketEmailConfirmation = gql`
  mutation ($descriptions: [StickerTicketEmailDescriptionTypeInput!]!, $email: String!, $name: String!, $intentId: String!, $clientSecret: String!, $userId: String!, $stickerId: String!) {
    createStickerTicketEmailConfirmation(descriptions: $descriptions, email: $email, name: $name, intentId: $intentId, clientSecret: $clientSecret, userId: $userId, stickerId: $stickerId) {
      title
      description
      ticketDescription
      unitPrice
      quantity
      totalAmount,
      email,
      name,
      userId,
      stickerId
    }
  }
`;
