import {
  findSticker,
  createUserInvite,
  findPublishedStickers,
  findPublishedStickersWithFiltering,
  findPurchasedStickers,
  checkUserExists,
  benevityCauses,
  getHomeScreenAppData,
} from '@/graphs/gql';
import { signUp } from '@/graphs/invite';
import { createStickerWebIntent, curatedBenevityCauses, createStickerWebIntentPublic } from '@/graphs/payment';
import { isStickerPurchased } from '@/graphs/stickers';
import globalShared from '@/shared';

export default function commonShared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    removeItemGetConfirm,
    makeGraphRequest,
    getAuthToken,
    preSignedUrl,
  } = globalShared();

  async function getPublicSticker(id: number): Promise<any> {
    const response = await makeGraphRequest(
      'query',
      findSticker,
      { id },
      null,
    );
    return response.data.findSticker;
  }

  async function getSticker(id: number): Promise<any> {
    const response = await makeGraphRequest(
      'query', findSticker, { id }
    );
    return response.data.findSticker;
  }

  async function getPublishedStickers(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findPublishedStickers, {}, getAuthToken(),
    );
    return response.data.findPublishedStickers;
  }

  async function getPublishedStickersWithFiltering(filter: any): Promise<any> {
    const response = await makeGraphRequest(
      'query', findPublishedStickersWithFiltering, {filter}, getAuthToken(),
    );
    return response.data.findPublishedStickers;
  }

  async function getPurchasedStickers(entity: string, entityId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', findPurchasedStickers, { entity, entityId }, getAuthToken(),
    );
    return response.data.findPurchasedStickers;
  }

  async function doCreateUserInvite(input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      createUserInvite,
      { input },
      null,
    );
    return response.data.createUserInvite;
  }

  async function doSignUp(input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      signUp,
      { input },
      null,
    );
    return response.data.signUp;
  }

  type CreateStickerWebIntentVariables = {
    stickerId: string,
    causeId: string,
    cost?: string
  }

  async function doCreateStickerWebIntent(stickerId: string, causeId: string, customAmount?: number | string): Promise<any> {
    console.log('AMM1', customAmount);
    const variables: CreateStickerWebIntentVariables = { stickerId, causeId }
    if (customAmount !== undefined) {
      variables.cost = customAmount.toString();
    }
    const response = await makeGraphRequest(
      'mutation',
      createStickerWebIntent,
      variables,
      getAuthToken(),
    );
    return response.data.createStickerWebIntent;
  }
  async function doCreateStickerWebIntentPublic(stickerId: string, causeId: string, customAmount?: number | string): Promise<any> {
    console.log('AMM2', customAmount);

    const variables: CreateStickerWebIntentVariables = { stickerId, causeId }
    if (customAmount !== undefined) {
      variables.cost = customAmount.toString();
    }
    const response = await makeGraphRequest(
      'mutation',
      createStickerWebIntentPublic,
      variables,
    );
    return response.data.createStickerWebIntentPublic;
  }


  // eslint-disable-next-line max-len
  async function checkIsStickerPurchased(entity: string, entityId: string, stickerId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', isStickerPurchased, { entity, entityId, stickerId }, getAuthToken(),
    );
    return response.data.isStickerPurchased;
  }

  async function doCheckUserExists(phone: string, email: string, userId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', checkUserExists, { phone, email, userId }, null,
    );
    return response;
  }

  async function getCuratedBenevityCauses(): Promise<any> {
    const response = await makeGraphRequest(
      'query', curatedBenevityCauses, { }, null,
    );
    return response.data.curatedBenevityCauses;
  }

  async function getcausesAsDropdown(query: string): Promise<Array<Map<string, any>>> {
    const response = await makeGraphRequest(
      'query', benevityCauses, { q: query, page: '1' }, getAuthToken(),
    );
    return response.data.benevityCauses.causes;
  }

  async function fetchHomeScreenAppData(): Promise<any> {
    const response = await makeGraphRequest(
      'query', getHomeScreenAppData, {}, null,
    );
    return response.data.getHomeScreenAppData;
  }

  return {
    ref,
    route,
    router,
    loading: ref(false),
    dialog: ref(false),
    removeItemGetConfirm,
    makeGraphRequest,
    preSignedUrl,

    getSticker,
    doCreateUserInvite,
    getPublishedStickers,
    getPublishedStickersWithFiltering,
    getPurchasedStickers,
    doCreateStickerWebIntent,
    doCreateStickerWebIntentPublic,
    checkIsStickerPurchased,
    getPublicSticker,
    doSignUp,
    doCheckUserExists,
    getCuratedBenevityCauses,
    getcausesAsDropdown,
    fetchHomeScreenAppData,
  };
}
