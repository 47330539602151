<template lang="pug">
.card.max-w-560.px-3.mx-auto
  .card-body
    transition(name="fade" mode="out-in")
      div(v-if="$route.name === 'OrgDetail'").d-flex.align-items-center.mb-5
        img(src="@/assets/images/logos/logo.png").w-170.h-122.mx-auto

      div(v-else-if="$route.name === 'Onboarded'").d-flex.align-items-center.mb-5.pt-5
        img(src="@/assets/images/logos/logo.png").w-270.mx-auto

      div(v-else-if="$route.name === 'OrgType'").d-flex.align-items-center.mb-5.pt-5
        img(src="@/assets/images/logos/logo.png").w-270.mx-auto

      div(v-else).d-flex.align-items-center.h-122.mb-2
        router-link(:to="prevRoute" v-if="prevRoute")
          fai(type="fal" icon="arrow-left").size-28
        img(src="@/assets/images/logos/bolt-rounded.png").w-60.mx-auto
        .size-28(v-if="prevRoute")
          // space

    main
      router-view(v-slot="{ Component }")
        transition(name="fade" mode="out-in")
          component(:is="Component")
            //- views render here
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'UserOnboardingView',
  computed: {
    ...mapGetters('onboarding', ['prevRoute']),
  },
});
</script>
