<template lang="pug">
div
  el-form(
    ref="refForm"
    :rules="rules"
    :model="item"
    status-icon
    label-position="top"
  )
    el-form-item(label="Organization's Name" prop="name")
      el-input(v-model="item.name")

    el-form-item(prop="email")
      template(#label)
        | Organizations Email
        el-tooltip(effect="customized" content="Organziations Email" placement="top")
          fai(type="fal" icon="info-circle").size-18.ms-2
      el-input(v-model="item.email" type="email" disabled)
      .el-form-item__error(v-if="serverError === 'Email Already exists'")
        | Email already exits
    el-form-item(label="Phone Number" prop="phone")
      el-input(
        v-model="item.phone"
        v-mask="'(###) ###-####'"
        type="tel"
        placeholder="(123) 456-7890"
      )
      .el-form-item__error(v-if="serverError === 'Phone Already exists'")
        | Phone already exits

    el-form-item(label="Established Date" prop="dob")
      el-input(
        v-model="item.dob"
        type="date"
        placeholder="MM/DD/YYYY"
      ).w-100
      div {{item.dob}}

    el-form-item(label="Password" prop="password")
      el-input(v-model="item.password" type="password")

    el-form-item(label="Confirm Password" prop="confirmPassword")
      el-input(v-model="item.confirmPassword" type="password")

    .p-4.text-center
      el-button(round type="success" @click="validateForm" v-loading="loading") Continue
</template>

<script>

import {
  defineComponent,
  reactive, onMounted,
  ref,
} from 'vue';
import { mask } from 'vue-the-mask';
import { useRouter } from 'vue-router';
import { email, name, phone } from '@/data/rules';
import useOnboardingRoute from './composables/useOnboardingRoute';

export default defineComponent({
  name: 'Onboarding_OrgDetail',
  directives: { mask },
  setup() {
    const {
      initData,
      validateForm,
      validateInviteCode,
      canOnBoard,
      resetState,
      serverError,
      getReturningUser,
    } = useOnboardingRoute('orgDetail');
    const refForm = ref(null);
    const loading = ref(false);
    const router = useRouter();
    const item = reactive(initData);
    onMounted(async () => {
      try {
        if (!canOnBoard()) {
          router.push('/');
        }
        const result = await validateInviteCode();
        if (result.errors && result.errors.length > 0) {
          router.push('/');
        }
        resetState();
        const itm = result.data.validateInvite;
        if (itm.returning_user) {
          const returnUser = await getReturningUser();
          console.log(returnUser);
        }
        item.email = itm.email;
        item.phone = itm.phone;
        item.name = itm.name;
      } catch (err) {
        console.log(err);
      }
    });

    function checkConfirmPassword(rule, value, callback) {
      if (value !== item.password) {
        callback('Confirm Password should match password');
      } else {
        callback();
      }
    }

    const passwordRules = [
      {
        required: true,
        pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}/,
        message: 'Password is required, (at least 6 characters at least one number, one lowercase and one upper case)',
      },
    ];

    const confirmPasswordRules = [
      { validator: checkConfirmPassword, trigger: 'blur' },
    ];

    return {
      // data (static)
      refForm,
      rules: {
        email,
        phone,
        name,
        password: passwordRules,
        confirmPassword: confirmPasswordRules,
      },
      // data (dynamic)
      loading,
      item,
      serverError,
      // methods
      validateForm: () => validateForm(refForm, initData),
    };
  },
});
</script>
