import { library } from '@fortawesome/fontawesome-svg-core';

// not working in vue3, need to check couple of days later
// current implementation from:
// https://halilyuce.com/web/how-to-add-font-awesome-to-your-vue-3-project-typescript/
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// import {
//   faAngleLeft,
//   faAngleDown,
//   faAngleRight,
//   faArrowLeft,
//   faBell,
//   faCheckCircle,
//   faDollarSign,
//   faEdit,
//   faEnvelope,
//   faFilter,
//   faGripVertical,
//   faInfoCircle,
//   faPlus,
//   faPlusCircle,
//   faStar,
//   faTimes,
//   faTimesCircle,
//   faTrash,
//   faSync,
//   faUsers,
// } from '@fortawesome/pro-light-svg-icons';

/** Import all icons from fontawesome
 * Find references here - https://fontawesome.com/v5/search?q=home&o=r
 */
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import FontAwesomeIcon from './FontAwesomeIcon.vue';

library.add(
  fal,
  fas,
);

export default FontAwesomeIcon;
