import store from '../store/index';
import { refreshToken } from '@/graphs/gql';
import { makeRequest } from '@/graphs/index';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (to, from, next) => {
  const path = window.location.href;
  const isAuthFlow = path.includes('/auth') || to.name === 'WebAppSignIn' || to.name === 'WebAppInvite' || to.path ==='/auth';
  const isPaymentFlow = to.name === 'InitiateWalletPaymentIntent' || path.includes('/payment/');
  const isStickerFlow = to.name === 'WebAppSticker' || to.name === 'StickerPurchase';
  const isWebApp = to.path.startsWith('/web') && !isAuthFlow;

  if(!isAuthFlow && !isPaymentFlow && !isStickerFlow){
    checkIsAccessTokenExpired();
  }

  if (store.getters['users/isMeSignedIn'] || isAuthFlow || isPaymentFlow || isStickerFlow) {
    // I am signed in, or working through one of the auth flows
    next();
  } else {
    if (isWebApp) {
      next('/auth/web/signin');
    }
    next('/auth');
  }
};

function checkIsAccessTokenExpired(){
  var accessToken;
  var refreshTokenValue;
  try{
    accessToken = store.getters['users/getToken'];
    refreshTokenValue = store.getters['users/getRefreshToken'];
  } catch (e){
    store.commit('users/SET_ME', null);
    store.commit('users/SET_IS_ME_SIGNED_IN', false);
  }
  if(accessToken != null && accessToken != undefined && accessToken != "")
  {
    let jwtPayload = parseJwt(accessToken);
    let expDif = Date.now()/1000 - jwtPayload.exp;
    //cheking if token is expired more then 600sec (10 min)
    if(expDif>=600){
      store.commit('users/SET_ME', null);
      store.commit('users/SET_IS_ME_SIGNED_IN', false);
    }
    else if(expDif>-30 && expDif < 600){
      if(refreshTokenValue != null && refreshTokenValue != undefined && refreshTokenValue != ""){
        newTokenPromise(refreshTokenValue).then(res =>{
          store.commit('users/SET_NEW_ACCESS_TOKEN', res);
        });
      }
      else{
        store.commit('users/SET_ME', null);
        store.commit('users/SET_IS_ME_SIGNED_IN', false);
      }
    }
  }
  else{
    store.commit('users/SET_ME', null);
    store.commit('users/SET_IS_ME_SIGNED_IN', false);
  }
}

function parseJwt (token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

async function getNewAccessToken(refreshTokenValue){
  const response = await makeRequest('mutation', refreshToken, { token: refreshTokenValue }, null);
  return response.data.refreshToken;
}

function newTokenPromise(refreshTokenValue){
  return new Promise((resolve) => {
      const result = getNewAccessToken(refreshTokenValue);
      resolve(result);
  });
}