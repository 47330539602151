<script lang="ts" setup>
import {computed, ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
import {useStore} from 'vuex';
import { useRoute } from 'vue-router'
import { ElLink } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import { type ActivityLogRow } from 'src/data/types'
import { formatDateSevenDaysBack } from '../../shared/utility-functions'
import ActivityDetails from "@/views/activity/ActivityDetails/ActivityDetails.vue";

const store = useStore();
const route = useRoute();

const selectedSort = computed(() => store.getters["activityLogs/GET_SORT"]);
const isOrgAdmin = store.getters['users/isOrgAdmin'];

const isSortable = (sortableType: string) => {
  return sortableType === selectedSort.value;
}

const date = computed(() => store.getters["activityLogs/GET_DATE"]);
const search = computed(() => store.getters["activityLogs/GET_SEARCH"]);

const logs =  computed(() => store.getters["activityLogs/GET_LOGS"]);
const logsLoading =  computed(() => store.getters["activityLogs/GET_LOGS_LOADING"]);
const logsError =  computed(() => store.getters["activityLogs/GET_LOGS_ERROR"]);

const currentPage = ref(1);
const rowsPerPage = ref(8);
const totalRows = ref(0);

const tableData = ref<ActivityLogRow[]>([]);

watch(logs, (newLogs) => {
  if (newLogs && newLogs.length) {
    tableData.value = newLogs.map(item => ({
      ...item,
      checked: false
    }));
  }
}, { immediate: true });
const filteredTableData = computed(() => {
  let filteredData = tableData.value;

  if (date.value && Array.isArray(date.value) && date.value.length === 2) {
    const [startDate, endDate] = date.value.map(d => new Date(d));

    filteredData = filteredData.filter((log) => {
      const logDate = new Date(log.date);
      return logDate >= startDate && logDate <= endDate;
    });
  }

  if (search.value) {
    const searchTerm = search.value.toLowerCase();
    filteredData = filteredData.filter((log) => {
      return Object.keys(log).some((key) => {
        const value = log[key];

        if (typeof value === 'string' && value.toLowerCase().includes(searchTerm)) {
          return true;
        }

        if (value instanceof Date || !isNaN(Date.parse(value))) {
          const formattedDate = formatDateSevenDaysBack(value);
          return (
            formattedDate.toLowerCase().includes(searchTerm) ||
            new Date(value).toDateString().toLowerCase().includes(searchTerm) ||
            new Date(value).toLocaleDateString('en-US').toLowerCase().includes(searchTerm) ||
            new Date(value).toLocaleDateString().toLowerCase().includes(searchTerm)
          );
        }

        return false;
      });
    });
  }

  const start = (currentPage.value - 1) * rowsPerPage.value;
  const end = start + rowsPerPage.value;

  totalRows.value = filteredData.length;
  return filteredData.slice(start, end);
});

const deleteSearchAndFilters = () => {
  store.dispatch("activityLogs/SET_SEARCH", (''));
  store.dispatch("activityLogs/SET_SORT", ('transaction'));
  store.dispatch("activityLogs/SET_DATE", (''));
}

const rowsSelected = computed(() => {
  let selected = 0;
  const selectedRows = [];
  tableData.value.forEach((row) => {
    if (row.checked) {
      selected++;
      selectedRows.push(row);
    }
  })
  store.dispatch('activityLogs/SET_SELECTED_LOGS', selectedRows);
  return selected;
});

const onSelectAllRows = () => {
  tableData.value.forEach(row => {
    row.checked = true;
  });
  rowsSelected.value = logs.value.map(row => row.transaction);
};

const onUnselectAllRows = () => {
  tableData.value.forEach(row => {
    row.checked = false;
  });
  rowsSelected.value = logs.value.map(row => row.transaction);
};

const hasSelectedOnPage = computed(() => {
    return filteredTableData?.value.length > 0 && filteredTableData.value.some(row => row.checked);
})

const isAllSelectedOnPage = computed({
  get() {
    return filteredTableData.value.length > 0 && filteredTableData.value.every(row => row.checked);
  },
  set(value: boolean) {
    filteredTableData.value.forEach(row => {
      row.checked = value;
    });
  }
});

const resizeHeader = () => {
  let elapsedTime = 0;
  const maxTime = 10000;
  const intervalId = setInterval(() => {
    elapsedTime += 100;

    const headerElement = document.querySelector('.el-table__header');
    if (headerElement) {
      const headerHeight = headerElement.clientHeight - 2;
      const selectAllElement = document.querySelector('.c-activity-table__select-all');
      if (selectAllElement) {
        selectAllElement.style.height = `${headerHeight}px`;
        clearInterval(intervalId);
      }
    }

    if (elapsedTime >= maxTime) {
      clearInterval(intervalId);
    }
  }, 100);

  onBeforeUnmount(() => {
    clearInterval(intervalId);
  });
}

const showDetails = ref(false);
const detailsTransactionId = ref();
const onOpenDetails = (row: ActivityLogRow) => {
  const { transactionId } = row;
  showDetails.value = true;
  detailsTransactionId.value = transactionId;
}

const handleSortChange = ({ prop, order }) => {
  if (order === null) {
    tableData.value = [...logs.value].map(item => ({
      ...item,
      checked: false
    }));
  } else if (prop && order) {
    tableData.value.sort((a, b) => {
      let aValue, bValue;
      const orderMultiplier = order === 'ascending' ? 1 : -1;
      if (prop === 'amount') {
        aValue = parseFloat(a.amount);
        bValue = parseFloat(b.amount);
        return (aValue - bValue) * orderMultiplier;
      } else if (prop === 'date') {
        aValue = new Date(a.date);
        bValue = new Date(b.date);
        return (aValue - bValue) * orderMultiplier;
      } else if (prop === 'userName' || prop === 'transactionId' || prop === 'details' || prop === 'email' || prop === 'item' || prop === 'type') {
        aValue = a[prop] ? a[prop].toLowerCase() : '';
        bValue = b[prop] ? b[prop].toLowerCase() : '';
        return aValue.localeCompare(bValue) * orderMultiplier;
      } else {
        return 0;
      }
    });
  }
};

onMounted(async () => {
  if (isOrgAdmin) {
    await store.dispatch("activityLogs/FETCH_ACTIVITY_LOGS", { entity: 'Organization', entityId: route.params.id });
  } else {
    await store.dispatch("activityLogs/FETCH_ACTIVITY_LOGS", { entity: '', entityId: '' });
  }
  await resizeHeader();
});


</script>

<template>
  <section class="c-activity-table">
    <template v-if="logsLoading">
      <div class="c-loading-spinner" />
    </template>
    <section v-else-if="!logsLoading && logsError" class="error-message">
      <div class="error-icon">&#9888;</div>
      <div class="error-text">
        <h2>Oops! Something went wrong.</h2>
        <p>We encountered an error. <br/> Please try refreshing the page or come back later.</p>
      </div>
    </section>
    <template v-else>

      <article class="c-activity-table__select-all" :class="{ 'c-activity-table__select-all--small' : rowsSelected === 0}">
        <el-checkbox :indeterminate="hasSelectedOnPage && !isAllSelectedOnPage" class="mr-2" v-model="isAllSelectedOnPage" />
<!--        <template v-show="rowsSelected > 0">-->
          <div class="fs-12 fw-medium">{{ rowsSelected }} selected</div>
          <el-link v-if="rowsSelected !== tableData.length" @click="onSelectAllRows" class="text-success fw-bold fs-10">Select All</el-link>
          <el-link v-else @click="onUnselectAllRows" class="text-success fw-bold fs-10">Unselect All</el-link>
<!--        </template>-->
      </article>
      <el-table id="activity-table"
                :data="filteredTableData"
                ref="activityTable"
                :default-sort="{ prop: selectedSort.value, order: 'descending' }"
                @row-click="onOpenDetails"
                @sort-change="handleSortChange"
      >
        <el-table-column width="40">
          <template #default="scope">
            <el-checkbox :value="scope.row.checked" v-model="scope.row.checked" @click.stop />
          </template>
        </el-table-column>

        <el-table-column :sortable="isSortable('transaction')" label="Transaction" prop="transactionId">
          <template #default="scope">
            <div class="c-activity-table__transaction-id">
              #{{ scope.row.transactionId.slice(-5) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :sortable="isSortable('date')" label="Date" prop="date">
          <template #default="scope">
            {{ formatDateSevenDaysBack(scope.row.date) }}
          </template>
        </el-table-column>
        <el-table-column :sortable="isSortable('user')" label="User" prop="userName"/>
        <el-table-column :sortable="isSortable('total')" label="Total" prop="amount">

          <template #default="scope">
            ${{ scope.row.amount.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column :sortable="isSortable('item')" label="Item" prop="item">
          <template #default="scope">
            <div class="c-activity-table__item">
              {{ scope.row.item }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :sortable="isSortable('details')" label="Details" prop="details"/>
        <el-table-column :sortable="isSortable('email')" label="Email" prop="email"/>
        <el-table-column :sortable="isSortable('type')" label="Type" prop="type"/>
        <template #empty>
          <section class="c-no-results">
            <el-icon :size="80">
              <Search />
            </el-icon>
            <h2 class="text-black">No results found</h2>
            <h6 class="text-black-50 fw-light">Try changing the filters or search terms for this view</h6>
            <el-button @click="deleteSearchAndFilters" size="large" type="success">View all transactions</el-button>
          </section>
        </template>
      </el-table>
      <el-pagination v-model:current-page="currentPage" :pager-count="11"
                     :total="totalRows" class="ac-pagination text-center mt-4" layout="prev, pager, next"
                     size="large"/>
    </template>
  </section>

  <ActivityDetails :transaction-id="detailsTransactionId" v-if="showDetails" v-model="showDetails" />
</template>

<style lang="scss">
.c-activity-table {
  position: relative;
  z-index: 0;

  .el-table {
    .cell {
      color: black;
      font-weight: 500;
      font-size: 14px;
      font-family: "Red Hat Display";
    }

    th > .cell {
      text-transform: capitalize;
    }

    th.el-table__cell.is-leaf, td.el-table__cell {
      border-bottom-color: #CCCCCC;
    }
  }

  &__transaction-id, &__item {
    font-weight: 600;
    color: #000000;
    font-family: "Red Hat Display";
  }

  &__select-all {
    padding-left: 12px;
    position: absolute;
    z-index: 10;
    width: 100%;
    background: white;
    align-items: center;
    display: flex;
    gap: 20px;
    height: auto;
    &--small {
      overflow: hidden;
      width: 32px;
    }
  }

  .c-no-results {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 370px;
    gap: 15px;
  }

  .error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 8px;
    color: #721c24;
    font-family: Arial, sans-serif;
    width: 710px;
    margin: 100px auto;

    .error-icon {
      font-size: 40px;
      margin-right: 15px;
    }

    .error-text {
      h2 {
        margin: 0;
        font-size: 24px;
      }

      p {
        margin: 5px 0 0 0;
        font-size: 16px;
      }
    }
  }
}
</style>
