import { createApp } from 'vue';
import AppMainContent from '@/components/AppMainContent.vue';
import AppMainContentLayout from '@/components/AppMainContentLayout.vue';
import AppFocusLock from '@/components/AppFocusLock.vue';

import App from './App.vue';
import router from './router';
import store from './store';
import { components as elementUiComponents, plugins } from './plugins/element-ui/index';
import FontAwesomeIcon from './plugins/font-awesome/index';

const app = createApp(App);
app.use(store);
app.use(router);
app.component('fai', FontAwesomeIcon);
app.component('app-main-content', AppMainContent);
app.component('app-main-content-layout', AppMainContentLayout);
app.component('app-focus-lock', AppFocusLock);

elementUiComponents.forEach((component) => {
  app.component(component.name, component);
});

plugins.forEach((plugin) => {
  app.use(plugin);
});

app.mount('#app');
