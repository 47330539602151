<template lang="pug">
app-main-content-layout(title='Invitations')
  template(#actions)
    el-button(type="primary"  color="#1EBEAF" @click="dialog = true; serverError = false").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Invite Organization
    el-button(type="primary"  color="#1EBEAF" @click="dialoguser = true; serverError = false").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Invite User

  div
    .row.row-sm.align-items-center
      .col
      el-input(
        v-model="filter"
        placeholder="Search by email"
        suffix-icon="el-icon-search"
        style="width: 250px;"
    ).input-search-filter
         template(#suffix='')
          img(
            src="@/assets/icons/searchIcon.svg",
            style="font-size: 20px;")
      .col-auto()
          VueMultiselect(
            v-model="sortBy",
            :multiple="false",
            :options="sortOptions",
            :searchable="false",
            :close-on-select="true",
            :show-labels="false"
            label="label"
            placeholder="Sort"
            @close="sortTable"
            style="width: 180px;"
          )

    el-table(
      :data="pagedTableData"
      stripe
      v-loading="loading"
      @row-click="toInviteDetail($event)"
      style="width: 100%"
    ).clickable-rows.py-20
      el-table-column(width="15")
      el-table-column(label="Name" min-width="180")
        template(#default="scope")
          
            .fw-normal {{ scope.row.name }}
      el-table-column(label="Email" min-width="150")
        template(#default="scope")
          
            .fw-normal {{ scope.row.email }}
      el-table-column(label="Onboarded" min-width="150")
        template(#default="scope")
          
            .fw-normal {{ scope.row.entity }}

      el-table-column(width="15")
        .el-table__expand-icon
          img(src="@/assets/icons/light/chevron-right-light.svg")
     

    div.margin-top-20.d-flex.justify-content-center   
      el-pagination(layout="prev, pager, next"  :current-page.sync="page" :total="filtered.length" @current-change="setPage")
      

    dialog-add-invite(
      v-if="dialog"
      v-model="dialog"
      :sending-invite="sendingInvite"
      @save-item="organizationInvite"
      :server-error="serverError"
      @close-dialog="dialog=false"
    )

    dialog-add-user-invite(
      v-if="dialoguser"
      v-model="dialoguser"
      :sending-invite="sendingInvite"
      @save-item="userInvite"
      :server-error="serverError"
      @close-dialog="dialoguser=false"
    )
</template>

<script>
import {
  defineComponent, ref,
  onMounted, reactive,
  computed,
} from 'vue';
import shared from './shared';
import VueMultiselect from 'vue-multiselect';
import DialogAddInvite from './DialogAddInvite.vue';
import DialogAddUserInvite from './DialogAddUserInvite.vue';

export default defineComponent({
  name: 'Invites',
  components: { DialogAddInvite, DialogAddUserInvite, VueMultiselect },
  setup() {
    // let nextToken;
    const {
      route,
      router,
      loading,
      doGetInvites,
      doCreateInvite,
      doCreateUserInvite,
    } = shared();
    const filter = ref('');
    const items = reactive({ items: [] });
    const serverError = ref('');
    const sendingInvite = ref(false);
    const dialog = ref(false);
    const dialoguser = ref(false);
    const sortOptions = [{ label: 'Name', value: 'name' }, { label: 'Email', value: 'email' }];
    const sortBy = ref('');

    function sortTable() {
      if (sortBy.value.value === 'name') {
        items.items.sort((a, b) => a.name.localeCompare(b.name));
      } else if (sortBy.value.value === 'email') {
        items.items.sort((a, b) => a.email.localeCompare(b.email));
      }
    }

    const filtered = computed(() => {
      if (filter.value === '') {
        return items.items;
      }
      const regex = new RegExp(`${filter.value}`, 'gi');
      return items.items.filter((x) => {
        if (x.name) {
          return x.name.match(regex);
        }
        return false;
      });
    });

    async function findInvites() {
      try {
        loading.value = true;
        items.items = await doGetInvites();
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
        // console.log('finally');
      }
    }

    onMounted(async () => {
      await findInvites();
    });

    function toInviteDetail(itm) {
      if (route.path === '/manage/invites') router.push(`invites/${itm.id}/info`);
    }

    async function organizationInvite(item) {
      try {
        loading.value = true;
        sendingInvite.value = true;
        await doCreateInvite(item);
        dialog.value = false;
        await findInvites();
      } catch (err) {
        serverError.value = err.message;
      } finally {
        loading.value = false;
        sendingInvite.value = false;
      }
    }

    async function userInvite(item) {
      try {
        loading.value = true;
        sendingInvite.value = true;
        await doCreateUserInvite(item);
        dialoguser.value = false;
        await findInvites();
      } catch (err) {
        serverError.value = err.message;
      } finally {
        loading.value = false;
        sendingInvite.value = false;
      }
    }
    //________create function for pagination datatable________
    const page = ref(1);
    const pageSize = 10;

    const pagedTableData = computed(() => {
      const start = (page.value - 1) * pageSize;
      const end = start + pageSize;
      if(filter.value !== '') {
        return filtered.value.slice(start, end);
      }

      return items.items.slice(start, end);
    })
  const setPage = (newPage) => {
      page.value = newPage;

    }
    return {
      // data (static)
      loading,
      filter,
      page,
      pageSize,
      pagedTableData,
      // data (reactive)
      items,
      serverError,
      input: ref(),
      dialog,
      dialoguser,
      sendingInvite,
      filtered,
      sortOptions,
      sortBy,
      // methods
      toInviteDetail,
      organizationInvite,
      setPage,
      userInvite,
      sortTable,
    };
  },
});
</script>
