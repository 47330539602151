<template lang="pug">
el-dialog(
  :title="title",
  top="5vh" width="100%"
  class="el-dialog__max-w-700"
)
  .mb-3
    el-input(v-model="filter" placeholder="Search" prefix-icon="el-icon-search")
      template(#prefix='')
        img(
          src="@/assets/icons/light/magnify-light.svg",
          style="font-size: 20px;")

  .list-group.list-group-flush
    .list-group-item(v-for="(item, index) in filteredItems")
      .row.row-sm.align-items-center.flex-nowrap
        .col-auto
          .size-14
            el-checkbox(v-model="item.featured" @change="onCheckboxChange($event, item)")

        .col-auto
          img(:src="item.image").size-50px.obj-f-c.rounded

        .col {{item.title}}

  template(#footer)
    el-button(@click="handleClose") Cancel
    el-button(type="success" v-loading="loading" @click="handleClose") Done
</template>

<script>
import { ref, defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'DialogAddLibraryItem',
  props: {
    title: { type: String, default: 'SEARCH' },
    items: { type: Array, default: () => [] },
    featuredItems: { type: Array, default: () => [] },
  },
  emits: ['close-dialog', 'save-item'],

  setup(props, { emit }) {
    const filter = ref('');
    const loading = ref(false);

    // function submitForm() {
    //   emit('save-item', state.items);
    //   emit('close-dialog');
    // }

    const onCheckboxChange = (event, listItem) => {
      if (event) {
        const item = listItem;
        if (props.featuredItems) {
          item.order = Math.max(...props.featuredItems.map((o) => o.order)) + 1;
          if (item.order === -Infinity) {
            item.order = 0;
          }
        }
        // eslint-disable-next-line vue/no-mutating-props
        props.featuredItems.push(item);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        props.featuredItems.splice(props.featuredItems.indexOf(listItem), 1);
      }
    };

  const filteredItems = computed(() => {
    const listItems = props.items || []; // Handle case where props.items is null or undefined
    const featuredItems = props.featuredItems || []; // Handle case where props.featuredItems is null or undefined

    listItems.forEach(item => {
      item.featured = Boolean(featuredItems.find(x => x.id === item.id)) || false;
    });

  const filterRegex = new RegExp(`${filter.value}`, 'gi');
  return listItems.filter(item => (item.title ? item.title.match(filterRegex) : false));
});

    return {
      filter,
      loading,
      filteredItems,
      handleClose: () => emit('close-dialog'),
      onCheckboxChange,
    };
  },
});
</script>
