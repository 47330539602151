import { gql } from '@apollo/client/core';

export const adminSignIn = gql`mutation ($input: AdminSignInInput!) {
    adminSignIn(input: $input) {
       id
       usertype
       firstname
       lastname
       email
       phone
       token
       refreshToken
    }
}`;

export const signIn = gql`mutation ($input: SignInInput!) {
    signIn(input: $input) {
      id
      usertype
      firstname
      lastname
      email
      phone
      token
    }
}`;

export const findUsers = gql`query($searchTerm: String, $sort: String, $filter: String, $page: Int, $pageSize: Int) {
  findUsersByCriteria(searchTerm: $searchTerm, sort: $sort, filter: $filter, page: $page, pageSize: $pageSize) {
    users {
      id
      usertype
      title
      firstname
      lastname
      email
      age
      phone
      active
      status
      email_verified
      phone_verified
      organizations {
        id
        name
      }
    }
    paginationMetadata {
      currentPage
      totalItemCount
      totalPageCount
    }
  }
}`;

export const findUserProfile = gql`query($id: String!) {
  findUserProfile(id: $id) {
    id
    bio
    why
    user {
      title
      firstname
      lastname
      email
      age
      phone
      active
      status
      is_creator
      createdAt
   }
   channels {
     id
     channel
   }
   bullhorns {
     id
     bullhorn
   }
   values {
     id
     value
   }
   interests {
    id
    interest
   }
   organizations {
      orgId
      email
      avatar
      name
   }
  }
}`;

export const updateProfileByAdmin = gql`mutation($input: AdminUserProfileInput!) {
   updateUserProfileByAdmin(input: $input) {
    id
    bio
    why
    user {
      title
      firstname
      lastname
      email
      age
      phone
      active
      status
      is_creator
      createdAt
     }
     channels {
       id
       channel
     }
     bullhorns {
       id
       bullhorn
     }
     values {
       id
       value
     }
   }
}`;

export const updateUserIsActiveMutation = gql`
  mutation($input: [UpdateUserIsActiveInput!]!) {
    updateUserIsActiveMutation(input: $input)
  }
`;

export const generateInviteCode = gql`query {
   generateInviteCode
}`;

export const validateInvite = gql`query ($code: String! ) {
   validateInvite(code: $code) {
      name,
      phone
      email
      invite_accepted
      returning_user
      returning_user_id
   }
}`;
export const updateOrgAndAdmin = gql`mutation ($input: OrgAndAdminInput!) {
   updateOrgAndAdmin(input: $input) {
      id
      firstname
      lastname
      email
      phone
   }
}`;

export const updateOrgAttributes = gql`mutation ($input: OrgAttributesInput!) {
   updateOrgAttributes(input: $input) {
      id
      name
      email
      phone
   }
}`;

export const updateUserAttributes = gql`mutation ($input: OrgAttributesInput!) {
  updateUserAttributes(input: $input) {
    id
    firstname
    lastname
    email
    phone
  }
}`;

export const verifyEmailOtp = gql`mutation ($input: VerifyEmailInput!) {
  verifyEmailOtp(input: $input) {
     id
     firstname
     lastname
     email
     phone
     token
  }
}`;

export const verifyPhoneNumber = gql`mutation ($input: VerifyPhoneInput!) {
  verifyPhoneNumber(input: $input) {
     id
     firstname
     lastname
     email
     phone
     token
  }
}`;

export const resendPhoneCode = gql`query($phone: String!){
  resendPhoneCode(phone: $phone)
}`;

export const resendEmailCode = gql`query($email: String!){
  resendEmailCode(email: $email)
}`;

export const changePassword = gql`mutation ($oldPassword: String! $password: String!) {
   changePassword(oldPassword: $oldPassword password: $password)
}`;

export const resetPassword = gql`mutation ($password: String!) {
  resetPassword(password: $password)
}`;

export const checkUserExists = gql`query($phone: String! $email: String! $userId: String!) {
  checkUserExists(phone: $phone, email: $email, userId: $userId) {
    id
    phone
    email
  }
}`;

export const deleteUserProfileByAdmin = gql`
mutation($id: String!) {
  deleteUserProfileByAdmin(userId: $id)
}`;

export const refreshToken = gql`mutation ($token: String!) {
  refreshToken(token: $token)
}`;


