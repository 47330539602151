<template lang="pug">
main(
  role="main"
  v-loading="loading"
  :class="{ 'pl-main-menu-width': !isMainMenuCollapsed }"
).main-content.pos-r
  .main-content_inner-wrap
    div
      slot
        //- default slot
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'AppMainContent',
  computed: {
    ...mapGetters('shared', ['loading', 'isMainMenuCollapsed']),
  },
});
</script>
