const state = {
  sort: 'transaction',
  date: '',
  search: '',
  logs: [],
  logsLoading: false,
  logsError: false,
  selectedLogs: [],
}

export default state
