<template lang="pug">
app-main-content-layout(:title='item?.causeName' back-to='/manage/benevity-claim')
        
        //- Left Column
        template(#col-left)
          div
            .row
              h3 General
              .organization-logo
                img(v-if="item?.logo" :src="item?.logo" alt="Organization Logo")
                img(v-else src="@/assets/images/default-profile.jpeg" alt="Organization Logo")
                el-form-item(label="Organization Name")
                  el-input(:value="item?.causeName" readonly)
                el-form-item(label="About")
                  el-input(:value="item?.description" type="textarea" readonly)
                el-form-item
                  template(#label)
                    | Our Ethos
                    el-tooltip(
                      effect="customized"
                      :content="ethosTooltip"
                      placement="top"
                      popper-class="max-w-350"
                    )
                    fai(type="fal" icon="info-circle").size-18.ms-2
                  el-input(:value="item?.ethos" type="textarea" readonly)
        
        //- Right Column
        template(#col-right)
            div
                .row
                    h3 Claim Request
                    div
                        .detail-item
                            .detail-label Business Email
                            .detail-value {{ item?.eMail }}   
                        .detail-item
                            .detail-label EIN
                            .detail-value {{ item?.ein }}
                        VueMultiselect(
                          v-if="!hideStatusSelect",
                          v-model="selectedStatus",
                          :options="statusOptions",
                          :loading="isLoadingStatusChange",
                          label="label",
                          track-by="value",
                          :multiple="false",
                        ).mt-3
                        .detail-item(v-else)
                            .detail-label Status
                            .detail-value {{ item?.claimStatus }}

            div
                .row
                    h3 More
                    div
                        .detail-item
                                .detail-label Organization Type
                                .detail-value nonprofit
                        .detail-item
                            .detail-label Admin Email
                            .detail-value {{ item?.adminEmail }}
                        .detail-item
                            .detail-label EIN
                            .detail-value {{ item?.causeId}}
</template>
  
  <script>
  import { onMounted, reactive, ref, watch } from 'vue';
  import globalShared from '@/shared' 
  import shared from '@/views/manage-users-and-organizations/shared';
  import VueMultiselect from 'vue-multiselect';
  
  export default {
    name: 'ClaimRequestDetails',
    components: {
      VueMultiselect,
    },
    setup() {
      const isLoading = ref(false);
      const isLoadingStatusChange = ref(false);
      const hideStatusSelect = ref(true);
      const selectedStatus = ref(null);
      const item = reactive({});
      const ethosTooltip = 'This section describes...';
      const statusOptions = [
        { value: 'Approved', label: 'Approve' },
        { value: 'Declined', label: 'Decline' },
      ];
  
      const { route } = globalShared();
      const { getClaimRequestDetails, mutateClaimStatus } = shared();
      const { claimRequestId } = route.params;

      async function updateClaimStatus() {
        isLoadingStatusChange.value = true;
        try {
          await mutateClaimStatus({ id: claimRequestId, status: selectedStatus.value.value });
        } finally {
          isLoadingStatusChange.value = false;
          item.claimStatus = selectedStatus.value.value;
          hideStatusSelect.value = true;
        }
      }
  
      onMounted(async () => {
        isLoading.value = true;
        try {
          const response = await getClaimRequestDetails(claimRequestId);
          item.logo = response.logo;
          item.causeName = response.causeName;
          item.causeId = response.causeId;
          item.description = response.description;
          item.eMail = response.eMail;
          item.ein = response.ein;
          item.claimStatus = response.claimStatus;
          item.adminEmail = response.userInfo?.email || 'N/A';
          hideStatusSelect.value = response.claimStatus !== 'Pending';
        } finally {
          isLoading.value = false;
        }
      });

      watch(() => selectedStatus.value, (newValue) => {
        if (newValue) {
          updateClaimStatus();
        }
      });
  
      return {
        item,
        isLoading,
        isLoadingStatusChange,
        ethosTooltip,
        statusOptions,
        hideStatusSelect,
        selectedStatus,
      };
    },
  }
  </script>

<style lang="scss" scoped>
.detail-item {
  margin-bottom: 10px;
}
.detail-label {
  color: gray;
  font-size: smaller;
  margin-bottom: 5px;
}
.detail-value {
  color: black;
  font-size: medium;
}
.organization-logo img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-bottom: 10px;
    margin-left: 2%;
}
</style>