import globalShared from '@/shared';

export default function commonShared(): { [key: string]: any } {
  const { ref, route, router } = globalShared();
  return {
    ref,
    route,
    router,
    loading: ref(false),
    dialog: ref(false),
  };
}
