/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  namespaced: true,

  state: {
    pgTitle: '',
    loading: false,
    isMainMenuCollapsed: false,
  },

  mutations: {
    // Unless it's an ajax request in vue template this can be accessed directly by
    // $store.commit('shared/SET_LOADING')
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_PG_TITLE(state, payload) {
      state.pgTitle = payload;
    },
    SET_MAIN_MENU_COLLAPSE(state) {
      state.isMainMenuCollapsed = !state.isMainMenuCollapsed;
    },
  },

  // use `actions` for ajax request or to just follow vuex pattern
  actions: {
    setLoading({ commit }, payload) {
      commit('SET_LOADING', payload);
    },
    setPgTitle({ commit }, payload) {
      commit('SET_PG_TITLE', payload);
    },
    setMainMenuCollapse({ commit }) {
      commit('SET_MAIN_MENU_COLLAPSE');
    },
  },

  getters: {
    loading: (state) => state.loading,
    pgTitle: (state) => state.pgTitle,
    isMainMenuCollapsed: (state) => state.isMainMenuCollapsed,
  },
};
