<script setup lang="ts">
  import { Search } from '@element-plus/icons-vue'
  import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
  import { useStore } from 'vuex';
  import { debounce } from '../../shared/utility-functions';
  import { Calendar, Close } from '@element-plus/icons-vue';

  const store = useStore();

  const sortOptions = [
    { value: 'transaction', label: 'Transaction' },
    { value: 'date', label: 'Date' },
    { value: 'user', label: 'User' },
    { value: 'total', label: 'Total' },
    { value: 'item', label: 'Item' },
    { value: 'details', label: 'Details' },
    { value: 'email', label: 'Email' },
    { value: 'type', label: 'Type' },
  ]
  const sort = computed({
    get: () => store.getters["activityLogs/GET_SORT"],
    set: (value) => store.dispatch("activityLogs/SET_SORT", (value))
  })

  const date = computed({
    get: () => store.getters["activityLogs/GET_DATE"],
    set: (value) => store.dispatch("activityLogs/SET_DATE", (value))
  })

  const search = ref(store.getters['activityLogs/GET_SEARCH']);

  const debouncedSearch = ref(search.value);
  const datePickerRef = ref(null);

  const onOpenCalendar = () => {
    datePickerRef.value.handleOpen();
  }

  const onClearCalendar = () => {
    store.dispatch("activityLogs/SET_DATE", (null))
  }

  watch(
    search,
    debounce((newValue) => {
      debouncedSearch.value = newValue;
      store.dispatch('activityLogs/SET_SEARCH', newValue);
    }, 300)
  );

  onUnmounted(() => {
    store.dispatch("activityLogs/SET_SORT", ('transaction'));
    store.dispatch("activityLogs/SET_DATE", (''));
    store.dispatch("activityLogs/SET_SEARCH", (''));
  });

</script>

<template>
  <section class="d-flex gap-4 c-activity-filters">
    <el-input v-model="search" :prefix-icon="Search"  type="text" placeholder="Filter transactions" />

    <section class="c-activity-filters__date">
      <el-button class="c-activity-filters__date-button" plain :icon="Calendar" @click="onOpenCalendar" />
      <el-button
        class="c-activity-filters__date-button"
        plain
        :icon="Close"
        @click="onClearCalendar"
        :disabled="!date"
      />

      <el-date-picker
        class="c-activity-filters__date"
        ref="datePickerRef"
        v-model="date"
        placeholder="Pick a day"
        size="large"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
      />
    </section>

    <el-select
      v-model="sort"
      placeholder="Sort"
      size="large"
      style="width: 240px"
    >
      <el-option
        v-for="item in sortOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </section>
</template>

<style lang="scss">
.c-activity-filters {
  height: 40px;

  &__date {
    position: relative;
    width: 150px;
    text-align: center;

    .el-button {
      height: 40px;
    }
  }
  .el-range-editor.el-input__wrapper {
    width: 100px;
    opacity: 1;
    pointer-events: none;
    left: 0;
    height: 0px;
    overflow: hidden;
  }
}

@media screen and (max-width: 1522px) {
  .c-activity-filters {
    &__date {
      width: 170px;
    }
  }
}

@media screen and (max-width: 1253px) {
  .c-activity-filters {
    &__date {
      width: 210px;
    }
  }
}


@media screen and (max-width: 860px) {
  .c-activity-filters {
    &__date {
      width: 250px;
    }
  }
}
</style>
