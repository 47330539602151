const timezones = [
  { label: "(UTC-12:00) Baker Island", value: "Etc/GMT+12" },
  { label: "(UTC-11:00) Niue", value: "Pacific/Niue" },
  { label: "(UTC-10:00) Hawaii-Aleutian Time", value: "Pacific/Honolulu" },
  { label: "(UTC-09:30) Marquesas Islands", value: "Pacific/Marquesas" },
  { label: "(UTC-09:00) Alaska Time", value: "America/Anchorage" },
  { label: "(UTC-08:00) Pacific Time (US & Canada)", value: "America/Los_Angeles" },
  { label: "(UTC-07:00) Mountain Time (US & Canada)", value: "America/Denver" },
  { label: "(UTC-06:00) Central Time (US & Canada)", value: "America/Chicago" },
  { label: "(UTC-05:00) Eastern Time (US & Canada)", value: "America/New_York" },
  { label: "(UTC-04:00) Atlantic Time (Canada)", value: "America/Halifax" },
  { label: "(UTC-03:30) Newfoundland", value: "America/St_Johns" },
  { label: "(UTC-03:00) Buenos Aires", value: "America/Argentina/Buenos_Aires" },
  { label: "(UTC-02:00) South Georgia/Sandwich Islands", value: "Atlantic/South_Georgia" },
  { label: "(UTC-01:00) Azores", value: "Atlantic/Azores" },
  { label: "(UTC+00:00) Greenwich Mean Time", value: "Etc/UTC" },
  { label: "(UTC+01:00) Central European Time", value: "Europe/Berlin" },
  { label: "(UTC+02:00) Eastern European Time", value: "Europe/Istanbul" },
  { label: "(UTC+03:00) Moscow Standard Time", value: "Europe/Moscow" },
  { label: "(UTC+03:30) Iran Standard Time", value: "Asia/Tehran" },
  { label: "(UTC+04:00) Gulf Standard Time", value: "Asia/Dubai" },
  { label: "(UTC+04:30) Afghanistan Time", value: "Asia/Kabul" },
  { label: "(UTC+05:00) Pakistan Time", value: "Asia/Karachi" },
  { label: "(UTC+05:30) India Standard Time", value: "Asia/Kolkata" },
  { label: "(UTC+05:45) Nepal Time", value: "Asia/Kathmandu" },
  { label: "(UTC+06:00) Bangladesh Time", value: "Asia/Dhaka" },
  { label: "(UTC+06:30) Cocos Islands", value: "Indian/Cocos" },
  { label: "(UTC+07:00) Indochina Time", value: "Asia/Bangkok" },
  { label: "(UTC+08:00) China Standard Time", value: "Asia/Shanghai" },
  { label: "(UTC+08:45) Australian Central Western Time", value: "Australia/Eucla" },
  { label: "(UTC+09:00) Japan Standard Time", value: "Asia/Tokyo" },
  { label: "(UTC+09:30) Australian Central Time", value: "Australia/Darwin" },
  { label: "(UTC+10:00) Australian Eastern Time", value: "Australia/Sydney" },
  { label: "(UTC+10:30) Lord Howe Island", value: "Australia/Lord_Howe" },
  { label: "(UTC+11:00) Solomon Islands", value: "Pacific/Guadalcanal" },
  { label: "(UTC+12:00) New Zealand Time", value: "Pacific/Auckland" },
  { label: "(UTC+13:00) Tonga Time", value: "Pacific/Tongatapu" },
  { label: "(UTC+14:00) Line Islands", value: "Pacific/Kiritimati" }
];

export { timezones };
