/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  namespaced: true,

  state: {
    me: null,
    isMeSignedIn: false, // this need to set false, once new backend implemented
    scopedCompany: null,
  },

  mutations: {
    SET_ME(state, payload) {
      state.me = payload;
    },
    SET_IS_ME_SIGNED_IN(state, payload) {
      state.isMeSignedIn = payload;
    },
    SET_NEW_ACCESS_TOKEN(state, payload) {
      state.me.token = payload;
    },
  },

  actions: {
    async setMe({
      commit,
      // dispatch
    }, payload) {
      // receiving 'payload' from authGuard.js
      // this run only once when app is refreshed but in authGuard.js if you remove this condition:
      // `if (store.getters['users/isMeSignedIn'])` then this can run each time when
      // route changes only on `meta: { requiresAuth: true }`
      if (payload) {
        commit('SET_ME', payload);
        commit('SET_IS_ME_SIGNED_IN', true);
      }

      // // receiving 'try' request from app.vue without payload
      // // this run only once when app is refreshed
      // try {
      //   const user = await Auth.currentAuthenticatedUser();
      //   // const results = await getUserRecordByEmail(user.attributes.email);
      //   // const userRecord = results.data.getUserByEmail.items[0];
      //   // user.userRecord = userRecord;
      //   commit('SET_ME', user);
      //   commit('SET_IS_ME_SIGNED_IN', true);
      // } catch (err) {
      //   // console.log('Is a Guest User');
      //   dispatch('resetMe');
      // }
    },

    resetMe({ commit }) {
      commit('SET_ME', null);
      commit('SET_IS_ME_SIGNED_IN', false);
    },

    setIsMeSignedIn({ commit }, payload) {
      commit('SET_IS_ME_SIGNED_IN', payload);
    },
  },

  getters: {
    me: (state) => state.me,
    isMeSignedIn: (state) => state.isMeSignedIn,
    scopedCompany: (state) => state.scopedCompany,
    getToken: (state) => state.me.token,
    getRefreshToken: (state) => state.me.refreshToken,
    isAdmin: (state) => state.me.usertype === 'ADMIN',
    isOrgAdmin: (state) => state.me.usertype === 'ORG',
  },  
};
