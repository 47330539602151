<template lang="pug">
div.invite-container
 .d-flex.flex-column
   div.d-flex.mb-2.mx-auto.spot-logo-container.align-items-center
      img(src="@/assets/images/logos/logo_circle.png").w-40.h-40
      img(
      src="@/assets/images/logos/logo-text.png"
        srcset="@/assets/images/logos/text_logo_white.png"
      ).h-30
   div.d-flex.flex-column.min-vh-100.invite-form-container
      h1.mb-0.mx-auto Get Started
      .fs-14.lh-2.mx-auto.d-flex.align-items-center
        span Already have an account?
        router-link(:to="`/auth/web/signin${queryString}`")
          span.el-button.el-button--text &nbsp;Login
      div.px-20.py-4
        el-form(
          ref="refForm"
          :rules="rules"
          :model="item"
          status-icon
          label-position="top"
          hide-required-asterisk=true
        )

          el-form-item(label="First Name" prop="firstname"  class="signup-label")
            el-input(v-model="item.firstname").sign-up-inputs

          el-form-item(label="Last Name" prop="lastname" class="signup-label")
            el-input(v-model="item.lastname").sign-up-inputs

          el-form-item(label="Email" prop="email" class="signup-label")
            el-input(v-model="item.email").sign-up-inputs

          el-form-item(label="Password" prop="password" class="signup-label")
            el-input(v-model="item.password" type="password").sign-up-inputs

          el-form-item(label="Confirm Password" prop="confirmPassword" class="signup-label").mb-2
            el-input(v-model="item.confirmPassword" type="password").sign-up-inputs
          span.fs-12.fc-gray Must contain an uppercase, lowercase, and special character

          el-form-item(label="Phone" prop="phone" class="signup-label").mt-2.mb-3
            el-input(
              v-model="item.phone"
              type="tel"
              placeholder="(123) 456-7890"
              v-mask="'(###) ###-####'"
            ).sign-up-inputs

          el-form-item(label="Your Age" prop="age" class="signup-label" class="signup-label").mb-2
            el-input(
              v-model="item.age"
              type="number"
              min=18
              max=100
              placeholder='18-100'
            ).sign-up-inputs
          span.fs-12.fc-gray Must be 18

          div(v-if="error") 
            .alert.text-alert {{ errorMessage }}

        el-checkbox(v-model="item.isMarketingAgreed" class="accept-terms-checkbox").me-4.mt-3
           .d-flex.align-items-center
            span I Accept all
            span(@click="openLegalPage()").el-button.el-button--text.me-5 &nbsp;Terms and Conditions

        .p-4.text-center
            el-button(round type="success" color="#1FBEAF"
                @click="validateForm" v-loading="loading"
                :disabled="!item.isMarketingAgreed").sign-in-button Continue
</template>

<script>
import {
  defineComponent, reactive, ref, onMounted,
} from 'vue';
import { mask } from 'vue-the-mask';
import { signIn } from '@/graphs/auth';
import { name, email, phone } from '@/data/rules';
import shared from '../../../shared';
import gqlRequest from '../dashboard/gql-request';

// Cache implementation
export default defineComponent({
  name: 'WebAppInvite',
  directives: { mask },
  setup() {
    const {
      route,
      router,
      store,
      makeGraphRequest,
    } = shared();
    const {
      doSignUp,
      doCheckUserExists,
    } = gqlRequest();
    const item = reactive({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      age: null,
      isMarketingAgreed: false,
    });
    const loading = ref(false);
    const refForm = ref(null);
    const error = ref(false);
    const errorMessage = ref('');
    const { stickerId } = route.query;
    const queryString = ref('');

    function checkAuth() {
      if (store.getters['users/isMeSignedIn']) {
        if (route.path !== '/web/') {
          router.push('web/');
        }
      }
    }

    function checkAge(rule, value, callback) {
      const age = parseInt(value, 10);
      if (age >= 18 && age <= 100) {
        callback();
      } else {
        callback('Age should be between 18 and 100');
      }
    }

    const ageRules = [
      { required: true },
      {
        validator: checkAge, trigger: 'blur',
      },
    ];

    const passwordRules = [
      {
        required: true,
        pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}/,
        message: 'Password is required, (at least 6 characters at least one number, one lowercase and one upper case)',
      },
    ];

    function checkConfirmPassword(rule, value, callback) {
      if (value !== item.password) {
        callback('Confirm Password should match password');
      } else {
        callback();
      }
    }

    const confirmPasswordRules = [
      { validator: checkConfirmPassword, trigger: 'blur' },
    ];

    async function checkEmailPhoneValid() {
      const result = { isValid: true, error: '' };
        const checkUsersExists = await doCheckUserExists(item.phone, item.email, '');
        if(checkUsersExists.data?.checkUsersExists != null) {
          result.isValid = false;
          result.error = 'Email address or phone number already in use';
        }
      
      
      return result;

  }

    async function signInNewUser() {
      const checkEmailExists = await doCheckUserExists('', item.email, '');
      const mPhone = checkEmailExists.data.checkUserExists.phone;
      const response = await makeGraphRequest(
        'mutation',
        signIn,
        { input: { phone: mPhone, password: item.password } },
        null,
      );
      store.commit('users/SET_IS_ME_SIGNED_IN', true);
      store.commit('users/SET_ME', response.data.signIn);
      if (stickerId !== undefined) {
        router.push(`/web/sticker/${stickerId}`);
      } else {
        router.push('/web/');
      }
    }

    async function openLegalPage() {
      window.open(import.meta.env.VITE_LEGAL_PAGE_LINK, '_blank');
    }

    async function submitForm() {
      try {
        loading.value = true;
        error.value = false;
        const user = await doSignUp({
          firstname: item.firstname,
          lastname: item.lastname,
          email: item.email,
          phone: item.phone,
          password: item.password,
          age: parseFloat(item.age),
          isMarketingAgreed: item.isMarketingAgreed,
        });
        if (user) {
          signInNewUser();
        }
      } catch (err) {
        error.value = true;
        errorMessage.value = err;
        console.log();
      } finally {
        loading.value = false;
      }
    }

    async function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) {
          const isEmailPhoneValid = await checkEmailPhoneValid();
          if (isEmailPhoneValid.isValid) {
            submitForm();
          } else {
            error.value = true;
            errorMessage.value = isEmailPhoneValid.error;
          }
        }
        return false;
      });
    }

    onMounted(() => {
       queryString.value = location?.search ?? '';
       checkAuth()
    });

    return {
      // data (static)
      refForm,
      rules: {
        firstname: name,
        lastname: name,
        age: ageRules,
        email,
        phone,
        password: passwordRules,
        confirmPassword: confirmPasswordRules,
      },

      // data (dynamic)
      loading,
      error,
      item,
      queryString,

      // methods
      validateForm,
      errorMessage,
      openLegalPage,
    };
  },
});
</script>
<style>
.invite-container {
    /* background-color: #1fbeaf; */
    background-color: #4ccbbf;
    background-image: linear-gradient(to right, #1EBEAF, #57D3C7);
}

.spot-logo-container > img{
    margin: 0px 6px;
}
.invite-form-container {
    background-color: #fff;
    border-radius: 30px 30px 0px 0px;
    padding: 40px 0px;
    box-shadow: 0px -24px 0px -15px #ffffff80;
}
</style>
