<template lang="pug">
div.filter-container
    router-link(to='/manage-content/payment-logs').menu-links
      span.padding-left-20(@click="currentView = 'payment'" 
      :class="getClass('payment')") Payment logs
    router-link(to='/manage-resources/todos').menu-links
      span.padding-left-20(@click="currentView = 'resources'" 
      :class="getClass('resources')") Resources
    router-link(to='/manage-content/events').menu-links
      span.padding-left-20(@click="currentView = 'events'" 
      :class="getClass('events')") Events
    router-link(to='/manage-content/directory/products').menu-links
      span.padding-left-20(@click="currentView = 'products'" 
      :class="getClass('products')") Products
    router-link(to='/manage-content/directory/roles').menu-links
      span.padding-left-20(@click="currentView = 'roles'" 
      :class="getClass('roles')") Roles
    router-link(to='/manage-content/directory/security').menu-links
      span.padding-left-20(@click="currentView = 'security'" 
      :class="getClass('security')") Security
</template>
      
<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'PaymentLogsTabs',
    
    setup() {

    const currentView = ref('payment');

    function getClass(currentItem: string) {
      if(currentItem == currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }

    return {
      currentView,
      // methods
      getClass,
    };
    
    }
  });
</script>
      