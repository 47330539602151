<template lang="pug">

el-form(
  ref="refForm"
  :rules="rules"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(
    v-loading="loading"
    title='Library'
    back-to='/manage-content/media/library'
  )
    template(#actions)
      el-button(type="danger" plain @click="removeItem").has-fai
        fai(type="fal" icon="trash").size-18.me-2
        span.min-w-110.d-inline-block Delete

      el-button(type="primary" @click="validateForm" v-loading="loading").has-fai
        fai(type="fal" icon="edit").size-18.me-2
        span.min-w-110.d-inline-block Save

    template(#col-left)
      div
          el-form-item(label="Title" prop="title")
            el-input(v-model="item.title")

          el-form-item(label="Description" prop="description")
          el-input(v-model="item.details" type="textarea")

          //- el-form-item(label="Channel" prop="channel")
          //-   el-select(v-model="item.channel.id"  placeholder="Select").w-100
          //-     el-option(
          //-        v-for="channel in channels.channels"
          //-        :key="channel.id"
          //-        :label="channel.channel"
          //-        :value="channel.id"
          //-     )
              div
          el-form-item(label="Channel" prop="channel")
            VueMultiselect(
              v-model="item.channel",
              label="channel",
              track-by="id",
              :options="channels.channels",
              :searchable="true",
              :close-on-select="true",
              :show-labels="false"
              placeholder="Pick a value"
            )

    template(#col-right)
      div
        el-upload(
            :show-file-list="false"
            :on-success="handleSuccess"
            :on-error="handleError"
            :before-upload="beforeUpload"
            :http-request="handleMediaUpload"
            v-loading="uploading"
            action=""
          ).img-uploader.pos-r.mb-4
            template(v-if="item.image")
              img(:src="item.image").img-fluid
              .pin.pin-bx.d-flex.p-2
                el-button(type="primary").ms-auto Change
            template(v-else)
              img(src="@/assets/icons/file-image-outline.svg").img-uploader-icon
              p.upload-picture-text Drop an image file here or
              div.fake-upload-button Browse For File

        el-form-item(label="Creator")
          el-input(v-model="creator" disabled)
        el-form-item
          template(#label)
            | Post Date
            span.text-muted.ms-2 Active for 3 Days
          el-input(v-model="item.postDate" disabled)

        el-form-item(label="Post Time")
          el-input(v-model="item.postTime" disabled)
</template>

<script>
import {
  defineComponent, ref, onMounted, reactive,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import fileUpload from '@/shared/upload';
import { requiredRule } from '@/data/rules';
import shared from '../shared';
import channelShared from '../../directory/channels/shared';

export default defineComponent({
  name: 'ManageContent_Media_Library',
  components: { VueMultiselect },
  setup() {
    const {
      route, router, loading, dateAndTime,
      // eslint-disable-next-line no-unused-vars
      doMutateMedia, removeItemGetConfirm,
      findMedia, removeMedia,
    } = shared();
    const refForm = ref(null);
    const creator = ref('');
    const {
      uploading, uploadStatus,
      beforeUpload, handleUploadRequest,
    } = fileUpload('media', 'Media');

    const {
      getChannels,
    } = channelShared();

    const { id } = route.params;

    const item = reactive({
      id,
      title: '',
      details: '',
      image: '',
      channel: {
        id: '-1',
      },
    });

    const channels = reactive({ channels: [] });
    async function getItem() {
      const itm = await findMedia(route.params.id);
      Object.assign(item, itm);
      ['createdAt', 'createdBy'].forEach((x) => {
        delete item[x];
      });
      if (!item.channel) {
        item.channel = {};
      }
      creator.value = itm.createdBy;
      [item.postDate, item.postTime] = dateAndTime(itm.createdAt);
    }

    async function handleMediaUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.image = uploadStatus.resourceUrl;
      }
    }

    function handleSuccess() {
      console.log('success');
    }

    function handleError() {
      console.log('error');
    }

    async function saveItem() {
      loading.value = true;
      // eslint-disable-next-line no-unused-vars
      const input = JSON.parse(JSON.stringify(item));
      await doMutateMedia({
        id,
        title: input.title,
        details: input.details,
        image: input.image,
        channel: {
          id: parseInt(input.channel.id, 10),
        },
      });
      loading.value = false;
    }

    function removeItem() {
      removeItemGetConfirm(async () => {
        await removeMedia(item.id);
        router.push('/manage-content/media/library');
      });
    }

    function validateForm() {
      refForm.value.validate(async (valid) => {
        if (valid) await saveItem();
        return false;
      });
    }

    onMounted(async () => {
      channels.channels = await getChannels(false);
      await getItem();
    });

    return {
      // data
      refForm,
      item,
      creator,
      loading,
      uploading,
      rules: {
        image: requiredRule('url'),
        title: requiredRule('title'),
        details: requiredRule('details'),
      },
      // methods
      beforeUpload,
      handleMediaUpload,
      handleSuccess,
      handleError,
      channels,

      removeItem,
      validateForm,
    };
  },
});
</script>
p
