<!-- eslint-disable max-len -->
<template lang="pug">
.spotlight_card.fw-b
  initiate-wallet-input-form(
    v-if='!loading'
    :is-editable="editAmt"
    :show-form="!continuePayment"
    :userDetails='details',
    :walletPricingDetails='walletPricingDetails',
    :curatedMarketPlaceUpgrade='curatedMarketPlaceUpgrade',
    :loading='loading'
    :amt='amt'
    :isMarketPlaceUpgradeEnabled='isMarketPlaceUpgradeEnabled'
  )
  .pt-2.bg-gray-100.mb-4(v-if="!continuePayment")

  .px-3(v-if="editAmt")
    .d-flex.mb-2.max-w-300.mx-auto
      el-input(v-model="couponCode.value" placeholder="add coupon ex: FREESUB")
      el-button(type="primary" @click="getCouponByPromoCode").margin-left-10
        span.d-inline-block Apply
    .max-w-300.mx-auto(v-if="couponMessage")
      span.fs-12.lh-2.paymentText {{ couponMessage }}
    .max-w-300.mx-auto.margin-top-10
      el-button(type='success' round
        @click='makeIntent' v-loading='loading').w-100.mb-2 Continue

  div(v-show="!editAmt && !continuePayment")
    .px-3.mb-4
      .max-w-300.mx-auto
        div#card-element
        div#card-errors.card_errors(role='alert', v-if='hasCardErrors') {{ cardErrors }}
    .pt-2.bg-gray-100.mb-4

    .px-3
      .max-w-300.mx-auto
        .fs-14.mb-4
          .d-flex.mb-2
            div Subscription
            .ms-auto ${{ parseFloat(amt.value).toFixed(2) }}
          .d-flex.mb-2
            div Convenience Fees
            div(v-if="convenienceFeePercentageValue != -1")
              span &nbsp;({{ convenienceFeePercentageValue }}%)
            .ms-auto ${{ convenienceFee.toFixed(2) }}
          .d-flex.mb-2
            div Credit Card Fees (2.9% + ¢30)
            .ms-auto ${{ stripeFee.toFixed(2) }}
          .d-flex.mb-2(v-if="couponCode.value")
            div Coupon
            div
              span(v-if="couponDiscountPercentageValue != null") &nbsp;({{ couponDiscountPercentageValue }}%)
              span &nbsp; {{ coupon.code }}
            .ms-auto -${{ couponDiscount }}
          .d-flex.mb-2(v-if="couponCode.value")
            div Net amount
            .ms-auto ${{ withCouponNetAmount }}
          .d-flex.mb-2(v-if="couponCode.value")
            div New Credit Card Fees
            .ms-auto ${{ withCouponStripeFee }}
          .d-flex
            div Total
            .ms-auto ${{ totalAmount.toFixed(2) }}
        div
          el-button(type='success'
            round @click="continueToPayment"
            v-if="!hasCardErrors").w-100.mb-2 Continue
  div(v-show="continuePayment")
    .px-3
      .max-w-300.mx-auto
        .fs-14.mb-4
          .d-flex.mb-2
            div Subscription
            .ms-auto ${{ parseFloat(amt.value).toFixed(2) }}
          .d-flex.mb-2
            div Convenience Fees
            div(v-if="convenienceFeePercentageValue != -1")
              span &nbsp;({{ convenienceFeePercentageValue }}%)
            .ms-auto ${{ convenienceFee.toFixed(2) }}
          .d-flex.mb-2
            div Credit Card Fees (2.9% + ¢30)
            .ms-auto ${{ stripeFee.toFixed(2) }}
          .d-flex.mb-2(v-if="couponCode.value")
            div Coupon
            div
              span(v-if="couponDiscountPercentageValue != null") &nbsp;({{ couponDiscountPercentageValue }}%)
              span &nbsp; {{ coupon.code }}
            .ms-auto -${{ couponDiscount }}
          .d-flex.mb-2(v-if="couponCode.value")
            div Net amount
            .ms-auto ${{ withCouponNetAmount }}
          .d-flex.mb-2(v-if="couponCode.value")
            div New Credit Card Fees
            .ms-auto ${{ withCouponStripeFee }}
          .d-flex
            div Total
            .ms-auto  ${{ totalAmount.toFixed(2) }}
      .max-w-300.mx-auto
        //- .mb-4.lh-sm
        //-   i.fs-12( v-html="paymentText")
        .mt-2
          el-button(type='success'
            v-loading='loading'
            round @click="finalizePayment"
            v-if="!hasCardErrors").w-100.mb-2 Confirm Purchase
  .px-3
    .max-w-300.mx-auto
      el-button(link round, @click.stop='cancelIntent').w-100.text-muted Cancel
</template>

<script>
import {
  ref,
  reactive,
  defineComponent,
  onMounted,
  watchEffect,
} from 'vue';
import shared from './shared';
import globalShared from '@/shared';
import { paymentText } from '../../data/payment-text';

import InitiateWalletInputForm from './InitiateWalletInputForm.vue';
import { ElLoading } from 'element-plus';

export default defineComponent({
  name: 'InitiateWalletPaymentIntent',
  components: { InitiateWalletInputForm },
  setup() {
    const {
      loading,
      route,
      requestCanIntent,
      requestCanIntentForOrg,
      requestCreateInitiateWalletIntent,
      requestCanIntentForWalletPricing,
      requestCuratedMarketPlaceUpgrade,
      requestCouponByPromoCode,
    } = shared();

    const { getMobileOperatingSystem, isSafari, handleSafariPrompt } = globalShared();

    const { userId, orgId, walletPricingId } = route.params;
    const walletPricingDetails = reactive({});
    const curatedMarketPlaceUpgrade = reactive({});

    const readyToPay = ref(false);
    const hasCardErrors = ref(true);
    const cardErrors = ref('');
    let card;
    let stripe;

    const userOrOrg = ref('user');
    const amt = reactive({ value: 0 });
    const fee = ref(0);
    const convenienceFee = ref(0);
    const convenienceFeePercentageValue = ref(0);
    const coupon = reactive({});
    const couponCode = reactive({ value: null });
    const couponDiscount = ref(0);
    const couponDiscountPercentageValue = ref(0);
    const couponMessage = ref(null);
    const withCouponNetAmount = ref(0);
    const withCouponStripeFee = ref(0);
    const stripeFee = ref(0);
    const editAmt = ref(true);
    const continuePayment = ref(false);
    const details = reactive({});
    const paymentIntent = reactive({});
    const paymentDetails = reactive({});
    const isMarketPlaceUpgradeEnabled = reactive({ value: false });
    const totalAmount = ref(0);
    const subTotalDisplayAmount = ref(0);

    function loadStripe(stripeURL, callback) {
      const obj = document.createElement('script');
      const script = document.getElementsByTagName('script')[0];
      obj.src = `//${stripeURL}`;
      if (callback) {
        obj.addEventListener('load', (e) => {
          callback(null, e);
        }, false);
      }
      // console.log(obj.src);
      script.parentNode.insertBefore(obj, script);
    }

    function calculateFinalPayment(depositAmount, isProfit) {
      let mTotalAmountWithConvenienceFee = 0;
      let mConvenienceFee = 0;
      let mConvenienceFeePercentageValue = 0;

      if (isProfit === true) {
          mConvenienceFeePercentageValue = 8.0;
          mConvenienceFee = parseFloat((0.08 * depositAmount).toFixed(2));
          mTotalAmountWithConvenienceFee = parseFloat((depositAmount + mConvenienceFee).toFixed(2));
      } else {
        // eslint-disable-next-line no-lonely-if
        if (depositAmount > 0 && depositAmount <= 250) {
          mConvenienceFeePercentageValue = 5.0;
          mConvenienceFee = parseFloat((0.05 * depositAmount).toFixed(2));
        } else if (depositAmount > 250 && depositAmount <= 500) {
          mConvenienceFeePercentageValue = 4.0;
          mConvenienceFee = parseFloat((0.04 * depositAmount).toFixed(2));
        } else if (depositAmount > 500 && depositAmount <= 1000) {
          mConvenienceFeePercentageValue = 3.5;
          mConvenienceFee = parseFloat((0.035 * depositAmount).toFixed(2));
        } else {
          mConvenienceFeePercentageValue = -1;
          mConvenienceFee = parseFloat((30 + depositAmount).toFixed(2));
        }

        if (mConvenienceFee > 30) {
          mConvenienceFeePercentageValue = -1;
          mConvenienceFee = 30;
        }

        mTotalAmountWithConvenienceFee = parseFloat((depositAmount + mConvenienceFee).toFixed(2));
      }

      // ([Amount] + [Convenience Fee] + [0.30 stripe fixed fee]) / (1 - [2.9 stripe % fee])
      // eslint-disable-next-line max-len
      let mTotalAmount = parseFloat(((mTotalAmountWithConvenienceFee + 0.30) / (1 - 0.029)).toFixed(2));
      // if marketplace upgrade is enabled, add extra 0.30 (stripe fixed fee) to the total amount
      if (isMarketPlaceUpgradeEnabled.value) {        
        mTotalAmount += 0.30 / 0.971;
      }
      let mStripeFee = parseFloat(mTotalAmount - mTotalAmountWithConvenienceFee).toFixed(2);

      if (couponCode.value) {
        if (coupon.percent_off != null && coupon.percent_off != 0) {
          console.log("percent coupon value is " + coupon.percent_off);
          // eslint-disable-next-line max-len
          couponDiscount.value = Math.round(coupon.percent_off * mTotalAmount)/100;
          mTotalAmount -= couponDiscount.value;
          withCouponStripeFee.value = parseFloat((0.3 + (0.029 * mTotalAmount)).toFixed(2));
          withCouponNetAmount.value = parseFloat(mTotalAmount - withCouponStripeFee.value).toFixed(2);
        } else {
          couponDiscount.value = parseFloat(coupon.amount_off / 100).toFixed(2);
          console.log("discounted coupon value is " + couponDiscount.value);
          mTotalAmount -= couponDiscount.value;
          withCouponStripeFee.value = parseFloat((0.3 + (0.029 * mTotalAmount)).toFixed(2));
          // eslint-disable-next-line max-len
          withCouponNetAmount.value = parseFloat(mTotalAmount - withCouponStripeFee.value).toFixed(2);
        }
      }

      const result = {
        total_amount: mTotalAmount ?? 0,
        deposit_amount: depositAmount ?? 0,
        convenienceFee: mConvenienceFee ?? 0,
        convenienceFeePercentageValue: mConvenienceFeePercentageValue ?? 0,
        creditCardFee: mStripeFee ?? 0,
      };
      return result;
    }

    async function makeIntent() {
      const amount = parseFloat(parseFloat(amt.value).toFixed(2));
      if (amount > 0) {
        let response = '';
        let mEntityType = '';
        let isProfit = false;

        if (userOrOrg.value === 'user') {
          mEntityType = 'User';
          isProfit = false;
        } else if (userOrOrg.value === 'org') {
          mEntityType = 'Organization';
          isProfit = details.organizationType === 'FORPROFIT';
        } else {
          console.log('error');
        }

        loading.value = true;
        const finalPaymentCalculation = calculateFinalPayment(amount, isProfit);
        convenienceFee.value = finalPaymentCalculation.convenienceFee;
        stripeFee.value = parseFloat(parseFloat(finalPaymentCalculation.creditCardFee).toFixed(2));
        fee.value = parseFloat(parseFloat(convenienceFee.value + stripeFee.value).toFixed(2));
        totalAmount.value = parseFloat(parseFloat(finalPaymentCalculation.total_amount).toFixed(2));
        // eslint-disable-next-line max-len
        convenienceFeePercentageValue.value = finalPaymentCalculation.convenienceFeePercentageValue;

        response = await requestCreateInitiateWalletIntent(
          {
            entityType: mEntityType,
            userId: details.id,
            orgId: details.organizationId,
            walletPricingId,
            cost: amount,
            totalCost: totalAmount.value,
            totalFee: fee.value,
            convenienceFee: convenienceFee.value,
            creditCardFee: couponCode.value ? withCouponStripeFee.value : stripeFee.value,
            isMarketPlaceUpgrade: isMarketPlaceUpgradeEnabled.value,
            // eslint-disable-next-line max-len
            marketPlaceUpgradeId: isMarketPlaceUpgradeEnabled.value ? curatedMarketPlaceUpgrade.id : null,
            // eslint-disable-next-line max-len
            marketPlaceUpgradeCost: isMarketPlaceUpgradeEnabled.value ? parseFloat(parseFloat(curatedMarketPlaceUpgrade.cost).toFixed(2)) : 0.00,
            couponCode: couponCode.value ? coupon.code : null,
            couponCodeId: couponCode.value ? coupon.couponCodeId : null,
            couponPromoCodeId: couponCode.value ? coupon.promoCodeId : null,
          },
        );

        loading.value = false;

        if (response !== '') {
          Object.assign(paymentDetails, JSON.parse(atob(response)));
          paymentDetails.amount = totalAmount.value;
          Object.assign(paymentIntent, paymentDetails);
          editAmt.value = false;
          loadStripe('js.stripe.com/v3/', () => {
            stripe = window.Stripe(paymentDetails.stripe_pub_key);
            const elements = stripe.elements();
            const style = {
              base: {
                fontSize: '16px',
                color: '#32325d',
              },
            };
            card = elements.create('card', { style });
            card.mount('#card-element');
            card.on('change', (e) => {
              if (e.complete) {
                readyToPay.value = true;
                hasCardErrors.value = false;
              } else if (e.error) {
                hasCardErrors.value = true;
                readyToPay.value = false;
                cardErrors.value = e.error.message;
              }
            });
          });
        }
      }
    }

    function finalizePayment(e) {
      loading.value = true;
      e.preventDefault();
      stripe.confirmCardPayment(paymentDetails.client_secret, {
        payment_method: {
          card,
          billing_details: {
            name: paymentDetails.username,
          },
        },
      }).then((result) => {
        const amount = parseFloat(amt.value).toFixed(2);
        if (result.error) {

          console.log(result.error.message);
          document.body.innerHTML = 'Failed Initiating Wallet';
          window.location.href = `${getRedirectUrl()}/?`
            + `action=initiateWallet&amount=${amount}&success=false`;


        } else if (result.paymentIntent.status === 'succeeded') {
          
          document.body.innerHTML = 'Thank you for Initiating Wallet';
          redirectMobileApp();
          
        } else {

          document.body.innerHTML = 'Failed Initiating Wallet';
          const { isWebUser } = route.query;
          if (isWebUser) {
            return window.location.href = `${getRedirectUrl()}/?action=initiateWallet&amount=${amount}&success=false`;
          }

          return redirectMobileApp(true);
        }
      }).finally(() => {
        loading.value = false;
      });
    }

    async function redirectMobileApp(failed = false){
      const amount = parseFloat(amt.value).toFixed(2);
      const loadingInstance = ElLoading.service({
          lock: false
      })
      const isAndroid = getMobileOperatingSystem() === "Android";
      let browserFallbackUrlBase = import.meta.env.VITE_WEB_BASE_URL;
      let browserFallbackUrlPath = 'web/dashboard';
      let query =  '';
      if (!failed) {
          query = `?action=initiateWallet&amount=${amount}&success=true`
      } else {
        query = `?action=initiateWallet&amount=${amount}&success=${failed}`
      }

      if (isAndroid) {
        let appId = import.meta.env.VITE_APP_ID;
        let appHost = import.meta.env.VITE_APP_HOST;
        console.log('yest');
        window.location.href = `intent://${appHost}/${query}#Intent;scheme=${appId};package=${appId};end`;
      } else {
        window.location.href = `${getRedirectIOSUrl()}${query}`;
      }

      // Detect if redirect was successful
      let didSafariRedirect = false;
      if(isSafari()){
          didSafariRedirect = await handleSafariPrompt();
      }

      // we are checking for strong inequality with false, because null indicates that the prompt timed out, not that the redirection was unsuccessful
      // unfortunately this will attempt to redirect the user to the app store if they decline the redirect to the app on Safari
      // currently no better option exists for iOS Safari
      if(!isSafari() || didSafariRedirect !== false){
          setTimeout(function() {
            // redirect user to the dashboard if the document is hidden (i.e. the app launched successfully)
            if(document.hidden){
                window.location.href = `${browserFallbackUrlBase}/${browserFallbackUrlPath}`;
                loadingInstance.close();
            }
            // keep user on site to allow redirect with button
            else{
                loadingInstance.close();
            }
          }, 1500);
      }
      // iOS Safari failed redirect
      else{
          loadingInstance.close();
      }
    }

    function getRedirectUrl() {
      return import.meta.env.VITE_APP_REDIRECT_BASE_URL;
    }

    function getRedirectIOSUrl() {
      return import.meta.env.VITE_APP_REDIRECT_URL;
    }

    function cancelIntent() {
      window.location.href = `${getRedirectUrl()}?action=initiateWalletCancel`;
    }

    function continueToPayment(e) {
      e.preventDefault();
      continuePayment.value = true;
    }

    async function getUserIntent() {
      const response = await requestCanIntent(userId);
      Object.assign(details, response);
    }

    async function getOrgIntent() {
      const response = await requestCanIntentForOrg(userId, orgId);
      Object.assign(details, response);
    }

    async function getWalletPricingIntent() {
      const response = await requestCanIntentForWalletPricing(walletPricingId);
      Object.assign(walletPricingDetails, response);
      amt.value = parseFloat(parseFloat(walletPricingDetails.cost).toFixed(2));
    }

    async function getCuratedMarketPlaceUpgrade() {
      const response = await requestCuratedMarketPlaceUpgrade();
      Object.assign(curatedMarketPlaceUpgrade, response);
    }

/**
 * Retrieves a coupon by promo code and applies it to the final calculation if applicable.
 * @async
 * @function getCouponByPromoCode
 * @returns {Promise<void>} Nothing explicitly returned.
 */
    async function getCouponByPromoCode() {
      if (couponCode.value) {
        const response = await requestCouponByPromoCode(couponCode.value);
        if (response) {
          Object.assign(coupon, response);
          if (isMarketPlaceUpgradeEnabled.value) {
            if (coupon.applicable_products.includes(curatedMarketPlaceUpgrade.productId) && coupon.applicable_products.includes(walletPricingDetails.productId)) {
              couponMessage.value = `Coupon applied ${coupon.name}`;
              couponDiscountPercentageValue.value = coupon.percent_off;
            } else {
              couponMessage.value = 'Coupon not applicable for this products!';
              couponCode.value = null;
            }
          } else if (coupon.applicable_products.includes(walletPricingDetails.productId)) {
            couponMessage.value = `Coupon applied ${coupon.name}`;
            couponDiscountPercentageValue.value = coupon.percent_off;
          } else {
            couponMessage.value = 'Coupon not applicable for this product!';
            couponCode.value = null;
          }
        } else {
          couponMessage.value = 'Invalid coupon!';
          couponCode.value = null;
        }
      }
    }

    function testFn() {
      console.log('Hello world');
    }

    onMounted(async () => {
      loading.value = true;
      if (userId && orgId) {
        userOrOrg.value = 'org';
        await getOrgIntent();
      } else if (userId && !orgId) {
        userOrOrg.value = 'user';
        await getUserIntent();
      } else {
        userOrOrg.value = 'false';
        console.log('error');
      }
      await getWalletPricingIntent();
      await getCuratedMarketPlaceUpgrade();
      await getCouponByPromoCode();
      loading.value = false;
    });

    watchEffect(
      () => amt.value,
    );

    return {
      amt,
      fee,
      convenienceFee,
      stripeFee,
      convenienceFeePercentageValue,
      paymentIntent,
      editAmt,
      continuePayment,
      loading,
      makeIntent,
      requestCanIntent,
      requestCanIntentForOrg,
      readyToPay,
      hasCardErrors,
      cardErrors,
      paymentDetails,
      finalizePayment,
      cancelIntent,
      details,
      continueToPayment,
      paymentText,
      requestCanIntentForWalletPricing,
      walletPricingDetails,
      curatedMarketPlaceUpgrade,
      isMarketPlaceUpgradeEnabled,
      testFn,
      couponDiscount,
      couponDiscountPercentageValue,
      coupon,
      couponCode,
      subTotalDisplayAmount,
      totalAmount,
      getCouponByPromoCode,
      couponMessage,
      withCouponNetAmount,
      withCouponStripeFee,
    };
  },
});
</script>
