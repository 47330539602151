<template lang="pug">
app-main-content-layout(title='Library')
  template(#actions)
    el-button(type="primary" @click="dialog = true").has-fai
      fai(type="fal" icon="plus-circle").size-18.me-2
      span.min-w-110.d-inline-block Add New

  div
    el-table(
      :data="items.items"
      stripe
      v-loading="loading"
      @row-click="$router.push(`library/${$event.id}`);"
      style="width: 100%"
    ).clickable-rows
      el-table-column(width="15")
        // space
      el-table-column(prop="title" label="Title" width="200")
      el-table-column(width="70")
        template(#default="scope")
          img(:src="scope.row.image").size-50px.obj-f-c
      el-table-column(prop="details" label="Details")
      el-table-column(width="46")
        .el-table__expand-icon
          img(src="@/assets/icons/light/chevron-right-light.svg")

      el-table-column(width="15")
        // space

    dialog-add-library-item(
      v-if="dialog"
      :loading="loading"
      v-model="dialog"
      @save-item="saveItem"
      @close-dialog="dialog = false"
    )
</template>

<script>

import { reactive, onMounted, defineComponent } from 'vue';
import DialogAddLibraryItem from './DialogAddLibraryItem.vue';
import shared from '../shared/index';

export default defineComponent({
  name: 'ManageContent_Media_Library',
  components: { DialogAddLibraryItem },
  setup() {
    const {
      findAllMedia,
      doMutateMedia,
      loading,
      dialog,
    } = shared();
    const items = reactive({ items: [] });

    async function getItems() {
      try {
        loading.value = true;
        items.items = await findAllMedia();
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }
    async function saveItem(input) {
      await doMutateMedia(input);
      await getItems();
    }

    onMounted(async () => {
      await getItems();
    });

    return {
      items,
      loading,
      dialog,
      // methods
      saveItem,
    };
  },
});
</script>
