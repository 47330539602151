<template lang="pug">
.text-center.py-4
  h4.max-w-300.mx-auto.mb-4 What type of
    span.text-success  Organization
    |  are you?

  .mb-4
    el-radio-group(
      v-model="item.orgType"
      @change="handleOrgTypeChange"
    )
      el-radio-button(label="NONPROFIT") Non-Profit
      el-radio-button(label="FORPROFIT") For-Profit

  el-radio-group(
    v-if="item.orgType === 'NONPROFIT'"
    v-model="item.nonProfitTypes"
  ).row.justify-content-center.text-start.d-flex
    .col-auto.py-2.min-w-200
      el-radio(label="501(C)3") Non-Profit 501(C)3
    .col-auto.py-2.min-w-200
      el-radio(label="501(C)4") Non-Profit 501(C)4
    .col-auto.py-2.min-w-200
      el-radio(label="501(C)6") Non-Profit 501(C)6
    .col-auto.py-2.min-w-200
      el-radio(label="OTHER") Other

  el-radio-group(
    v-if="item.orgType === 'FORPROFIT'"
    v-model="item.forProfitTypes"
  ).row.justify-content-center.text-start.d-flex
    .col-auto.py-2.min-w-200
      el-radio(label="BENIFIT CORPORATION") Benifit Corporation
    .col-auto.py-2.min-w-200
      el-radio(label="SOCIAL ENTERPRISE") Social Enterprise
    .col-auto.py-2.min-w-200
      el-radio(label="SOCIALLY RESPONSIBLE") Socially Responsible
    .col-auto.py-2.min-w-200
      el-radio(label="OTHER") Other

  h4.max-w-350.mx-auto.mb-4.mt-5 Are you a
    span.text-success  parent or chapter
    |  of another organization?

  .mb-5
    el-radio-group(v-model="item.parentOfOrg")
      el-radio-button(label="true") Yes
      el-radio-button(label="false") No

  .text-center
    el-button(
      round type="success"
      :disabled="!(item.orgType && item.parentOfOrg)"
      @click="submitForm"
    ) Next
</template>

<script>

import {
  defineComponent, reactive,
  onMounted, ref,
} from 'vue';
import { useRouter } from 'vue-router';
import useOnboardingRoute from './composables/useOnboardingRoute';

export default defineComponent({
  name: 'Onboarding_OrgType',

  setup() {
    const {
      initData,
      commitAndGotoNextRoute,
      canOnBoard,
    } = useOnboardingRoute('orgType');

    const refForm = ref(null);
    const item = reactive(initData);
    const router = useRouter();

    onMounted(async () => {
      try {
        if (!canOnBoard()) {
          router.push('/');
        }
      } catch (err) {
        console.log(err);
      }
    });

    function handleOrgTypeChange() {
      item.nonProfitTypes = null;
      item.forProfitTypes = null;
    }

    return {
      // data (static)
      refForm,

      // data (dynamic)
      item,

      // methods
      handleOrgTypeChange,
      submitForm: () => commitAndGotoNextRoute(initData),
    };
  },
});
</script>
