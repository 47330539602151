<template lang="pug">
div
  app-main-menu
    //- deafult nav items render here

  app-main-content
    .card-header.p-4.bg-transparent
      .row.row-sm.align-items-center
        .col
          h4.mb-0 Settings
    .p-4
      h5 Change Password
      change-password
        //- form renders here
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChangePassword from './ChangePassword.vue';

export default defineComponent({
  name: 'Settings',
  components: { ChangePassword },
});
</script>
