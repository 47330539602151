<template lang="pug">
el-form(
  ref="refForm"
  :model="item"
  status-icon
  label-position="top"
)
  app-main-content-layout(title='Manage Invite' back-to='/manage/invites')
    template(#actions v-if="!item.invite_accepted")
      el-button( @click="handleResend()" v-loading="resending").has-fai.button-green
        fai(type="fal" icon="sync").size-18.me-2
        span.min-w-110.d-inline-block Resend Invitation
      el-button(type="primary" color="#1EBEAF" @click="handleRemove()" v-loading="removing").has-fai
        fai(type="fal" icon="trash").size-18.me-2
        span.min-w-110.d-inline-block Delete Invitation

    div
      .row
        .col-md-8.col-lg-6
          el-form-item(label="Organization")
            el-input(v-model="item.name" disabled)

          el-form-item(label="Email")
            el-input(v-model="item.email" disabled)

          //- el-form-item(label="Phone")
          //-   el-input(v-model="item.phone" disabled)
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import shared from './shared';

const itemData = {
  id: -1,
  name: '',
  email: '',
  phone: '',
  invite_code: '',
  invite_accepted: false,
  invite_stage: '',
};

export default defineComponent({
  name: 'InviteDetail',
  setup() {
    const {
      route,
      router,
      resendInvite,
      removeInvite,
      removeItemGetConfirm,
      doGetInvite,
    } = shared();
    const { inviteId } = route.params;
    const item = reactive(itemData);
    const resending = ref(false);
    const removing = ref(false);
    onMounted(async () => {
      const response = await doGetInvite(inviteId);
      Object.assign(item, response);
    });
    async function handleResend() {
      resending.value = true;
      await resendInvite(item.id);
      resending.value = false;
    }
    function handleRemove() {
      removeItemGetConfirm(async () => {
        await removeInvite(item.id);
        router.push('/manage/invites');
      });
    }
    return {
      resending,
      removing,
      refForm: ref(null),
      item,
      handleRemove,
      handleResend,
    };
  },
});
</script>
