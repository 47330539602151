const paymentText = `Your dondation will be made to a donor advised fund at the American Online Giving Fund (AOGF), registered in the United States. You will be able to provide donor advice to direct all, or a portion, of your donation, to the recipient charity of your choice within the Spotlight app. AOGF disburses donations to recipient charities once a month. Recipient charities will receive your donation, minus transaction fees. By continuing, you agree (i) that your donation shall be non-refundable and (ii) to our <a href="${import.meta.env.VITE_TERMS_AND_CONDITIONS_LINK}" target="_blank">Terms of Conditions.</a> <br /><br /> I agree to share my information with receiving charities including my name, email address, and donation amount.`;
const paymentTextPaid  = `By making this purchase, you accept Spotlight's <a href="${import.meta.env.VITE_TERMS_AND_CONDITIONS_LINK}" target="_blank">Terms and Conditions</a>, <a href="${import.meta.env.VITE_PRIVACY_POLICY_LINK}" target="_blank">Privacy Policy</a>, Collective Rules, Financial Incentive,
and fees. The payment is non-refundable. You also agree to share your information with the listing Creator including your name, email address, phone number and payment amount. You also agree to receive external communications from the Creator after your purchase. <br /><br /> If your checkout includes a donation, then your donation will be made to a donor advised fund at the American Online Giving Fund (AOGF), registered in the United States. You will be able to provide donor advice to direct all, or a portion, of your donation, to the recipient charity of your choice within the Spotlight app. AOGF disburses donations to recipient charities once a month. Recipient charities will receive your donation, minus transaction fees. By continuing, you agree (i) that your donation shall be non-refundable and (ii) to our Terms and Conditions. <br /><br /> By directing this donation as a grant, you also accept Spotlight's Privacy Policy, Collective Rules, Financial Incentive, and fees. You also agree to share your information with the Sticker Creator including your name, phone number, email address, and grant amount.`;
const sweepstakesPaymentText = `
  <ul style="list-style-type: disc; padding-left: 20px; line-height: 1.6;">
    <li>You are allowed to submit a maximum of 10,000 entries (or the equivalent of $500) per sweepstakes.</li>

    <li>Each properly submitted “Alternate Method of Entry (AMOE)” form provides 1,000 entries, which is the same amount of entries you would receive for a $50 donation, and users can submit more than one free entry form.</li>

    <li>There is a limit of two (2) prizes awarded to the same winner per calendar year.</li>

    <li>Winners must be drawn electronically using a computer-generated method. For each winner, a random number is drawn by our backend API and automatically assigns the number to an entrant, weighted based on total entries recorded. If there is more than one winner, this process is repeated until all winners have been chosen.</li>

    <li>Typically, winners are chosen 3-5 days after the end of each sweepstakes to provide ample time for mail-in AMOE entries to be received and processed. All mail-in entries postmarked by the close of each sweepstakes will be valid.</li>
  </ul>

  <p style="line-height: 1.6; text-align: justify;">
    At the end of each Sweepstakes Period, randomly draw one (1) potential winner (a "Potential Winner") from all eligible entries for each prize in a Promotion. The Potential Winner will be awarded the applicable prize pending his or her confirmation and/or verification of his or her eligibility and compliance with these Rules and any Promotion Rules. If the potential winner(s) cannot verify eligibility, the drawing must take place again for only that unverified person.
  </p>
`;


const text = '';
export {
  paymentText,
  paymentTextPaid,
  sweepstakesPaymentText,
  text,
};
