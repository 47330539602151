import { gql } from '@apollo/client/core';

export const organization = gql`query($id: String!) {
  getOrganization(orgId: $id) {
    id
    name
    email
    avatar
    about
    purpose
    background
    approved
    denied
    withdrawn
    bullhorns {
      id
      bullhorn
    }
    values {
      id
      value
    }
    channels {
      id
      channel
    }
    interests {
      id
      interest
    }
    organization_type
    profit_type
    nonprofit_type
    whyjoin
    whyjoin_other
    dob
    active
    is_creator
    stripe_onboarded
    stripeAccountId
    organizationTypes
    invite_code
    active,
    primaryAdmin {
      id
      email
      firstname
      lastname
      avatar
   }
   admins{
    id
    firstname
    lastname
    email
    avatar
  }
   createdAt
   isFeatured
  }
}`;

export const updateOrganizationProfileByAdmin = gql` mutation ($input: AdminOrgProfileInput!){
   updateOrganizationProfileByAdmin(input: $input) {
      id
      name
      email
      avatar
      about
      purpose
      background
      bullhorns {
        id
        bullhorn
      }
      values {
        id
        value
      }
      channels {
        id
        channel
      }
      organization_type
      profit_type
      nonprofit_type
      whyjoin
      whyjoin_other
      dob
      organizationTypes
      invite_code
      active
      is_creator
      stripe_onboarded
      stripeAccountId
      primaryAdmin {
        id
        email
        firstname
        lastname
        avatar
      }
      createdAt
      isFeatured
   }
}`;

export const updateOrganizationProfile = gql` mutation ($orgId: String! $input: OrgProfileInput!){
   updateOrganizationProfile(orgId: $orgId input: $input) {
      id
      name
      email
      avatar
      about
      purpose
      background
      bullhorns {
        id
        bullhorn
      }
      values {
        id
        value
      }
      channels {
        id
        channel
      }
      organization_type
      profit_type
      nonprofit_type
      whyjoin
      whyjoin_other
      dob
      organizationTypes
      invite_code
      active
      is_creator
      stripe_onboarded
      stripeAccountId
      primaryAdmin {
        id
        email
        firstname
        lastname
        avatar
      }
      createdAt
   }
}`;

export const findAllOrganizations = gql`query {
   findAllOrganizations {
       id
       name
       onboarded
       active
       email
       avatar
       active
       onboarded
       approved
       denied
       withdrawn
   }
}`;

export const findPendingOrganizations = gql`query {
   findPendingOrganizations {
       id
       name
       onboarded
       approved
       denied
       active
       email
   }
}`;

export const getStripeAuthorizeUrl = gql`query getStripeAuthorizeUrl($orgId: String!) {
    getStripeAuthorizeUrl(orgId: $orgId)
}`;

export const completeStripeOnboarding = gql`mutation completeStripeOnboarding(
   $code: String! $state: String!) {
   completeStripeOnboarding(code: $code state: $state) {
      id
      name
      email
      avatar
      about
      purpose
      background
      bullhorns {
        id
        bullhorn
      }
      values {
        id
        value
      }
      channels {
        id
        channel
      }
      organization_type
      profit_type
      nonprofit_type
      whyjoin
      whyjoin_other
      dob
      organizationTypes
      invite_code
      active
      is_creator
      stripe_onboarded
      stripeAccountId
      primaryAdmin {
        id
        email
        firstname
        lastname
        avatar
      }
      createdAt
   }
}`;

export const createOrgFromInvite = gql`mutation($input: OrgInviteInput!){
  createOrgFromInvite(input: $input){
    id
    name
    dob
    email
    phone
    invite_code
  }
}`;

export const approveDenyOrganization = gql`mutation($id: String!, $approve: Boolean! ){
   approveDenyOrganization(id: $id, approve: $approve) {
     id
     approved
     active
     withdrawn
   }
 }`;

export const addAdminUserToOrgQuery = gql`mutation($orgId: String!, $userEmail: String!){
  addAdminUserToOrg(orgId: $orgId, userEmail: $userEmail)
}`;

export const removeAdminUserFromOrgQuery = gql`mutation($orgId: String!, $userId: String!){
  removeAdminUserFromOrg(orgId: $orgId, userId: $userId)
}`;

export const removeStripeAccount = gql`mutation($orgId: String!){
  removeStripeAccount(orgId: $orgId)
}`;

// eslint-disable-next-line max-len
export const findSyncedBenevityCauses = gql`query ($benevityCausesPaginationInput: BenevityCausesPaginationInput!) {
  findSyncedBenevityCauses(benevityCausesPaginationInput: $benevityCausesPaginationInput) {
    totalCount
    benevityCauses {
      id
      causeId
      causeName
      claimStatus
      email
      claimer {
        id
        firstname
        lastname
      }
    }
  }
}`;

export const getBenevityCauseSyncById = gql`query ($claimRequestId: String!) {
  getBenevityCauseSyncById(id: $claimRequestId) {
    id
    causeId
    causeName
    logo
    eMail
    description
    claimStatus
    userInfo {
      id
      email
    }
    ein
  }
}`;

export const updateBulkClaimStatus = gql`
mutation($input: [updateStatusInput!]!) {
  updateBulkClaimStatus(input: $input)
}`;

export const updateClaimStatus = gql`
mutation($input: updateStatusInput!) {
  updateBenevityCauseStatus(updateStatus: $input)
}`;

export const findOrganizationsV2 = gql`query ($baseInput: BaseInput!) {
  findOrganizationsV2(baseInput: $baseInput) {
      id
      name
      onboarded
      active
      email
      avatar
      active
      onboarded
      approved
      denied
      withdrawn
  }
}`;
