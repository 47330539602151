<template lang="pug">
el-dialog(
  title="Add Dictionary Item"
  top="5vh" width="100%"
  class="el-dialog__max-w-700"
  :close-on-click-modal="false"
)
  el-form(
    ref="refForm"
    :rules="rules"
    :model="item"
    status-icon
    label-position="top"
  )
    el-form-item(label="Term" prop="term")
      el-input(v-model="item.term")

    el-form-item(label="Bullhorn(s)" prop="bullhornId")
      el-select(v-model="item.bullhornIds"  placeholder="Select" multiple).w-100
        el-option(
          v-for="lookups in bullhorns"
          :key="lookups.id"
          :label="lookups.bullhorn"
          :value="lookups.id"
        )

    el-form-item(label="Description" prop="description")
      el-input(v-model="item.description" type="textarea")

    el-form-item(label="Source" prop="source")
      el-input(v-model="item.source")

  template(#footer)
    el-button(type="success" @click="validateForm" v-loading="loading") Add
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import { requiredRule } from '@/data/rules';

export default defineComponent({
  name: 'DialogAddDictionary',
  props: {
    loading: { type: Boolean, default: false },
    bullhorns: { type: Array, default: () => [] },
  },
  emits: ['close-dialog', 'save-item', 'save-category'],

  setup(_, { emit }) {
    const refForm = ref(null);
    const item = reactive({
      term: '',
      bullhornIds: [],
      description: '',
      source: '',
    });

    function submitForm() {
      emit('save-item', item);
      emit('close-dialog');
    }

    function validateForm() {
      refForm.value.validate((valid) => {
        if (valid) return submitForm();
        console.log('error submit!!');
        return false;
      });
    }

    return {
      // data
      refForm,
      rules: {
        term: requiredRule('term'),
        source: requiredRule('source'),
        description: requiredRule('description'),
      },
      item,

      // methods
      validateForm,
    };
  },
});
</script>
