import {
  dictionaryItems,
  dictionaryItem,
  mutateDictionaryItem,
} from '@/graphs/gql';

import commonShared from '../shared';
import bullhornShared from '../bullhorns/shared';

export default function shared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    getAuthToken,
    removeItemGetConfirm,
    makeGraphRequest,
    dateAndTime,
    exportData,
  } = commonShared('CHANNEL');

  const { getBullhorns } = bullhornShared();
  const loading = ref(false);

  async function saveDictionaryItem(input: { [key: string]: any }): Promise<any> {
    await makeGraphRequest(
      'mutation', mutateDictionaryItem, { input }, getAuthToken(),
    );
  }

  async function getDictionaryItems(): Promise<any> {
    const response = await makeGraphRequest(
      'query', dictionaryItems, {}, getAuthToken(),
    );
    return response.data.dictionaryItems;
  }

  async function getDictionaryItem(id: number): Promise<any> {
    const response = await makeGraphRequest(
      'query', dictionaryItem, { id }, getAuthToken(),
    );
    return response.data.dictionaryItem;
  }

  return {
    loading,
    route,
    router,
    getBullhorns,
    getDictionaryItems,
    getDictionaryItem,
    saveDictionaryItem,
    removeItemGetConfirm,
    dateAndTime,
    exportData,
  };
}
