<template lang="pug">


app-main-content
  div
      div.filter-container
        router-link(to='/manage-content/directory/bullhorns').menu-links
          span.padding-left-20(@click="currentView = 'bullhorns'" 
        :class="getClass('bullhorns')") Bullhorns
        router-link(to='/manage-content/directory/channels').menu-links
          span.padding-left-20(@click="currentView = 'channels'" 
        :class="getClass('channels')") Channels
        router-link(to='/manage-content/directory/values').menu-links
          span.padding-left-20(@click="currentView = 'values'" 
        :class="getClass('values')")  Values
        router-link(to='/manage-content/directory/interests').menu-links
          span.padding-left-20(@click="currentView = 'interests'" 
        :class="getClass('interests')")   Interests
        router-link(to='/manage-content/directory/dictionary').menu-links
          span.padding-left-20(@click="currentView = 'dictionary'" 
        :class="getClass('dictionary')") Dictionary
        router-link(to='/manage-content/directory/segments').menu-links 
          span.padding-left-20(@click="currentView = 'segments'" 
        :class="getClass('segments')")  Segments
      .row.row-sm.align-items-center.mb-4.mx-5.justify-content-between
        router-view(v-slot="{ Component }")
          transition(name="fade" mode="out-in")
            component(:is="Component")
              //- views render here
</template>

<script lang="ts">
import { defineComponent, ref} from 'vue';

import { dashboard as menuItems } from '@/data/main-menu';

export default defineComponent({
  name: 'ManageContent_Directory',
  setup() {

    const currentView = ref('bullhorns');


    function getClass(currentItem: string) {
      if(currentItem == currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }
   
    return {
      // data
      menuItems,
      currentView,
      getClass,
    };
  },
});
</script>
