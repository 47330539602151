<template lang="pug">
el-form(
  ref="refForm"
  :model="item"
  status-icon
  label-position="top"
)

  app-main-content-layout(title='Manage Workflow' back-to='/manage-resources/workflows')
    template(#actions)
      el-button(type="primary" @click="handleRemove()" v-loading="removing").has-fai
        fai(type="fal" icon="trash").size-18.me-2
        span.min-w-110.d-inline-block Delete

    div
      .row
        .col-sm-6
          el-form-item(label="Title")
            el-input(v-model="item.title")
</template>

<script>
import {
  ref,
  reactive,
  defineComponent,
  onMounted,
} from 'vue';
import shared from '../shared';

const itemData = {
  id: -1,
  todo: '',
  protip: '',
};

export default defineComponent({
  name: 'Todo',
  setup() {
    const {
      route,
      // router,
      doFindWorkflow,
    } = shared();
    const { id } = route.params;
    console.log(id);
    const loading = ref(false);
    const item = reactive(itemData);

    onMounted(async () => {
      loading.value = true;
      const response = await doFindWorkflow(id);
      loading.value = false;
      Object.assign(item, response);
    });

    return {
      loading,
      item,
    };
  },
});
</script>
