<template lang='pug'>
.pin.pin-xy.bg-white.max-w-400.mx-auto
  .max-w-300.mx-auto.mb-5
    .pt-5
      .d-flex.align-items-center.justify-content-center
        div
          img(
            src="@/assets/images/logos/logo-text.png"
            srcset="@/assets/images/logos/logo-text@2x.png 2x"
          )

  main(role="main").pos-r
    router-view(v-slot="{ Component }")
      transition(name='fade')
        component(:is="Component")
</template>

<script lang="ts">
import shared from '@/shared';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const {
      route,
    } = shared();
    const {
      isWebUser,
    } = route.query;
    const {
      stickerId,
    } = route.params;

    function getRedirectUrl() {
      if(isWebUser === 'true'){
        return import.meta.env.VITE_WEB_BASE_URL;
      }
      else {
        return import.meta.env.VITE_APP_REDIRECT_BASE_URL;
      }
    }

    function cancelLink() {
      const path = isWebUser === 'true' ? `/web/sticker/${stickerId}` : '?action=addToWalletCancel';
      window.location.href = `${getRedirectUrl()}${path}`;
    }

    return {
      cancelLink,
    };
  },
});
</script>
