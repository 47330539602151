<template lang="pug">
app-main-content
    template(v-if="$route.path === '/manage-resources'")
      .p-5.text-center.text-muted
        h6 Select the section from Menu to manage resources
    template(v-else)
    div.filter-container
      router-link(to='/manage-resources/todos').menu-links
        span.padding-left-20(@click="currentView = 'payment'" 
        :class="getClass('payment')") Todo
      router-link(to='/manage-resources/workflows').menu-links
        span.padding-left-20(@click="currentView = 'resources'" 
        :class="getClass('resources')") Workflows
    router-view(v-slot="{ Component }")
          transition(name="fade" mode="out-in")
            component(:is="Component")
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { manageResources as menuItems } from '@/data/main-menu';

export default defineComponent({
  name: 'ManageResources',
  setup() {
    const currentView = ref('payment');

    function getClass(currentItem: string) {
      if(currentItem == currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }

    return {
      menuItems,
      currentView,
      getClass,
    };
  },
});
</script>
