<script setup lang="ts">
import { defineProps } from 'vue';
import type { PaymentLogType } from 'src/data/types';

const props = defineProps<{
  paymentLog: PaymentLogType | null;
}>();

const { paymentLog } = props;
</script>

<template>
  <section
    class="row row-xs align-items-center"
    v-if="paymentLog.stripeTransaction"
  >
    <div class=“col-auto”>
      <div class="fw-m py-1">Stripe Transaction Id: {{ paymentLog.stripeTransaction.id }}</div>
      <div class="fw-m py-1">isProcessed: {{ paymentLog.stripeTransaction.processed }}</div>
      <div class="fw-m py-1">transactionType: {{ paymentLog.stripeTransaction.transactionType }}</div>
      <div class="fw-m py-1">intentId: {{ paymentLog.stripeTransaction.intentId }}</div>
      <div class="fw-m py-1">Total Amount: {{ paymentLog.stripeTransaction.amount }}</div>
      <div class="fw-m py-1">Wallet Deposit: {{ paymentLog.stripeTransaction.walletDeposit }}</div>
      <div class="fw-m py-1">Convenience Fee: {{ paymentLog.stripeTransaction.fee_Convenience }}</div>
      <div class="fw-m py-1">Credit Card Fee: {{ paymentLog.stripeTransaction.fee_CreditCard }}</div>
      <div class="fw-m py-1">Metadata: {{ paymentLog.stripeTransaction.meta }}</div>
    </div>
  </section>
</template>

<style scoped>

</style>
