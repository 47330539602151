<script setup lang="ts">
import VueMultiselect from 'vue-multiselect';
import GlowPicker from "@/components/StickerTIcket/GlowPicker.vue";
import {ref, computed, defineProps, onMounted} from "vue";
import { useStore } from 'vuex';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '../../../../../assets/styles/qill.scss';

const props = defineProps<{
  availableTags: string[];
}>()

const store = useStore();
const allowNamingPrices = ref(false);

const item = computed({
  get: () => store.getters['sticker/GET_STICKER'],
  set: (value) => {
    store.dispatch('sticker/SET_STICKER', value);
  }
})

onMounted(() => {
  if (item?.value?.quickCosts?.length) {
    for (let qc of item.value.quickCosts) {
      if (qc !== null) {
        allowNamingPrices.value = true;
      }
    }
  }
})

const preventArrowKeys = (event: KeyboardEvent) => {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    event.preventDefault();
  }
};

</script>

<template>
  <section class="c-default-sticker">
    <article class="c-default-sticker__row c-default-sticker__row--column">
      <span class="c-default-sticker__label">Sticker Title*</span>
      <input class="c-input" type="text" required placeholder="Title" v-model="item.title" />
    </article>

    <article class="c-default-sticker__row c-default-sticker__row--tags-cost">
      <div class="c-default-sticker__row c-default-sticker__row--column">
        <span class="c-default-sticker__label">Tags*</span>
        <VueMultiselect
          class="c-input-multiselect"
          v-model="item.tags"
          :multiple="true"
          :options="availableTags"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          placeholder="Tag"
        />
      </div>
      <div class="c-default-sticker__row c-default-sticker__row--column">
        <span class="c-default-sticker__label">Cost</span>
        <input v-model="item.cost" class="c-input" type="text" required placeholder="Cost" />
      </div>
    </article>

    <article class="c-quick-costs">
      <el-checkbox v-model="allowNamingPrices">Allow user's to name price</el-checkbox>

      <div v-if="allowNamingPrices" class="c-quick-costs__container">
        <p class="c-quick-costs__title">Quick cost options</p>
        <div class="c-quick-costs__costs">
          <section class="position-relative" v-for="(cost, index) in 4">
            <div class="c-input-prefix" v-show="item.quickCosts[index]">$</div>
            <input
              class="c-input"
              :key="cost"
              v-model.number="item.quickCosts[index]"
              size="small"
              :placeholder="'Cost ' + (cost)"
              type="number"
              step="1"
              min="0"
              @keydown="preventArrowKeys" 
              @wheel.prevent
            />
          </section>
        </div>
      </div>
    </article>

    <article class="c-default-sticker__row c-default-sticker__row--column">
      <span class="c-default-sticker__label">Details*</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.description" content-type="html" />
    </article>

    <glow-picker />
  </section>

</template>

<style scoped lang="scss">
.c-default-sticker {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 340px;

  &__label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }

  &__row {
    display: flex;

    .multiselect {
      max-width: 160px;

      &__select {
        display: none;
      }
    }
    &--column {
      flex-direction: column;
    }

    &--tags-cost {
      gap: 20px;
      >div {
        width: calc(50% - 10px);
      }
    }
  }

  /* Hide the arrows in number input fields */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Standard property for compatibility */
  }
}
</style>
