<template lang="pug">
.text-center
  h4.max-w-300.mx-auto.mb-2 Thanks for your
    span.text-success  submission

  .mb-5 Keep changing the world.

  .text-center.pb-5
    a(href="/")
      img(src="@/assets/images/download-ios.png")
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import useOnboardingRoute from './composables/useOnboardingRoute';

export default defineComponent({
  name: 'Onboarding_Onboarded',
  setup() {
    useOnboardingRoute('onboarded');

    return {
      // data (dynamic)
      loading: ref(false),
    };
  },
});
</script>
