<template lang="pug">
app-main-content-layout(title='Add Sticker' class="font-medium")
  template(#actions)
    el-button(type="primary"  color="#1EBEAF" @click="validateForm").has-fai
      span.min-w-110.d-inline-block Add sticker
    el-button(type="plain" @click="goToStickerPage").button-green
      span.min-w-110.d-inline-block Cancel



  .container.stickers-bg.px-4
    .row
      .col.bg-white.rounded.m-3.py-5.px-4(style="max-width: 500px;")
          p Add sticker
          .col(style="max-width: 100%;").pt-3
            el-upload(
              drag
              :show-file-list="false"
              :on-success="handleSuccess"
              :on-error="handleError"
              :before-upload="beforeUpload"
              :http-request="handleStickerUpload"
              action=""
            ).img-uploader.pos-r
              template(v-if="item.url")
                img(:src="item.url").img-fluid
                .pin.pin-bx.d-flex.p-2
                  el-button(type="primary").ms-auto Change
              template(v-else)
                img(src="@/assets/icons/image-add.svg").sticker-image-icon
                p.upload-picture-text Add sticker
            .alert.alert-danger.margin-top-20(v-if="error.message") {{ error.message }}
          el-form(
            ref="refForm"
            :rules="rules"
            :model="item"
            status-icon
            label-position="top"
          )
            div(v-if="!item.hasOptions")
              p.pt-5 Cost (optional)
              el-input-number(v-model="item.cost" size="large" controls-position="" )
            p.pt-4 Sticker Title *
            <el-input v-model="item.title" placeholder="Title" />
            p.pt-4 Tags *
            .col-auto()
              VueMultiselect(
                v-model="item.tags",
                :multiple="true",
                :options="tagsArray",
                :searchable="true",
                :close-on-select="true",
                :show-labels="false"
                :loading="tagsLoading"
                placeholder="Please select the tag"
                )

            div(v-if="item.campaignType !== 'Ticket'")
              p.pt-4
                el-checkbox(v-model="allowNamingPrices" style="margin4px;") Allow users to name price

              div(v-if="allowNamingPrices" style="margin:0;")
                p.pt-4(style="{ margin: '4px' }") Quick cost options
                div.d-flex.align-items-center
                  el-input(
                    v-for="(cost, index) in item.quickCosts"
                    :key="index"
                    v-model.number="item.quickCosts[index]"
                    size="small"
                    :placeholder="'Cost ' + (index + 1)"
                    style="margin-right: 4px; margin-top: 4px"
                    type="number"
                    step="0.01"
                  )

            p.pt-4 Details
            el-input(v-model="item.description" type="textarea" placeholder="Details")

      .col.bg-white.rounded.m-3.py-5.px-4
          p Create
          el-button-group
            el-button(type="primary" @click="changeCampaignType('Campaign')" :class="{ 'el-button-left-color-active': item.campaignType === 'Campaign', 'el-button-left-color': item.campaignType !== 'Campaign' }").el-button-left Campaign
            el-button(type="primary" @click="changeCampaignType('Ticket')" :class="{ 'el-button-right-color-active': item.campaignType === 'Ticket', 'el-button-right-color': item.campaignType !== 'Ticket' }").el-button-right Ticket

            //------------champaign data-----------
            div(v-if="item.campaignType === 'Campaign'")
              p.pt-4 Non-Profit Suggestions (optional)
                VueMultiselect(
                  v-model="item.causes",
                  :multiple="true",
                  :options="causeOptionsArray",
                  :searchable="true",
                  :close-on-select="true",
                  :show-labels="false"
                  :loading="causeLoading"
                  placeholder="Please enter a non-profit name"
                  @search-change="getCausesDropdownOptions"
                  :showNoOptions="false"
                )
              p.pt-4 Max Quantity (optional)
              el-input-number(v-model="item.maxQuantity" size="large" controls-position="")
              p.pt-4 Start Date *
              el-date-picker(
                v-model="item.startDate"
                type="date"
                placeholder="mm/dd/yyyy"
                format="DD/MM/YYYY"
                :default-value="new Date()"
                ).datePicker
              p.pt-4 End Date *
              el-date-picker(
                v-model="item.endDate"
                type="date"
                placeholder="mm/dd/yyyy"
                format="DD/MM/YYYY"
                :default-value="new Date()"
                ).datePicker

              //-----------------ticket data----------------
            div(v-else-if="item.campaignType === 'Ticket'")
              p.pt-4 Max Quantity (optional)
              el-input-number(v-model="item.maxQuantity" size="large" controls-position="" )
              p.pt-4 Event Date & Time *
              el-date-picker(
                v-model="item.eventDate"
                type="datetime"
                placeholder="mm/dd/yyyy --:--"
                format="DD/MM/YYYY h:mm A"
                :default-value="new Date()"
                ).datePicker
              p.pt-4 Sale Start Date & Time *
              el-date-picker(
                v-model="item.startDate"
                type="datetime"
                placeholder="mm/dd/yyyy --:--"
                format="DD/MM/YYYY h:mm A"
                :default-value="new Date()"
                ).datePicker
              p.pt-4 Sale End Date & Time *
              el-date-picker(
                v-model="item.endDate"
                type="datetime"
                placeholder="mm/dd/yyyy --:--"
                format="DD/MM/YYYY h:mm A"
                :default-value="new Date()"
                ).datePicker
              p.pt-4 Location
              el-input(v-model="item.location" placeholder="Location")
              p.pt-4 Waiver URL
              el-input(v-model="item.waiverURL" placeholder="Waiver URL")
              p.pt-4 Cause (optional)
              VueMultiselect(
                v-model="item.causes",
                :multiple="true",
                :options="causeOptionsArray",
                :searchable="true",
                :close-on-select="true",
                :show-labels="false"
                :loading="causeLoading"
                placeholder="Please enter a tag"
                @search-change="getCausesDropdownOptions"
              )
              p.pt-4 Message for Confirmation Email
              el-input(v-model="item.confirmationEmail" type="textarea" placeholder="Message for Confirmation Email")
              p.pt-4 Message for Reminder email
              el-input(v-model="item.reminderEmail" type="textarea" placeholder="Message for Reminder email")
              p.pt-4 Options
              el-checkbox(v-model="item.hasOptions").me-4 This sticker has price tiers
              div(v-if="item.hasOptions === true")
                cost-varients-list(
                :loading="loading"
                :item="item"
                @emitAddPrice="addPrice"
                @emitRemovePrice="removePrice"
                @emitChangeEvent="changeEvent").mb-4

          glow-picker(ref="glowPickerRef")

</template>

<script>
import {
  ref,
  reactive,
  defineComponent,
  onMounted,
  watch,
} from 'vue';
import VueMultiselect from 'vue-multiselect';
import { requiredRule } from '@/data/rules';
import fileUpload from '@/shared/upload';
import shared from '../shared';
import Draggable from 'vuedraggable';
import CostVarientsList from './CostVarientsList.vue';
import GlowPicker from "@/components/StickerTIcket/GlowPicker.vue";
import { validateTime, joinDateAndTime } from "@/shared/utility-functions";

export default defineComponent({
  name: 'AddItem',
  components: { GlowPicker, VueMultiselect, Draggable, CostVarientsList },
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['save-item'],
  setup() {
    const { uploadStatus, beforeUpload, handleUploadRequest } = fileUpload('stickers', 'Sticker');
    const {
      getBullhornsAsTags,
      getcausesAsDropdown,
      getBenevityCauseDetails,
      updateSticker,
      router,
    } = shared();

    const refForm = ref(null);
    const tagsList = ref([]);
    const tagsOptions = ref([]);
    const tagsLoading = ref(false);
    const causeList = ref([]);
    const causeOptions = ref([]);
    const causeOptionsArray = ref([]);
    const causeLoading = ref(false);
    const error = reactive({ message: '' });
    const tagsArray = ref([]);
    const stickerTypes = ref([{
      value: 'Sticker',
      label: 'Sticker',
    }]);

    const glowPickerRef = ref(null);

    const stickerType = ref(null);

    const drag = ref(null);

    const allowNamingPrices = ref(false);
    const savedQuickCosts = ref([null, null, null, null]);
    watch(allowNamingPrices, (newVal) => {
      if (!newVal) {
        savedQuickCosts.value = [...item.quickCosts];
        item.quickCosts = [null, null, null, null];
      } else {
        item.quickCosts = savedQuickCosts.value;
      }
    });

    const dragChoose = () => {
      drag.value = true;
    };

    const dragUnchoose = () => {
      drag.value = false;
    };

    const dragStart = () => {
      drag.value = true;
    };

    const dragEnd = () => {
      drag.value = false;
    };

    const changeEvent = (element) => {
      const propItems = item.varients;
      const movedItem = element.moved;
      if (element.moved.newIndex > element.moved.oldIndex) {
        var itemToSet = propItems.find((x) => x.sortOrder === movedItem.oldIndex);
        propItems.forEach((x) => {
          if (x.sortOrder > movedItem.oldIndex && x.sortOrder <= movedItem.newIndex) {
            // eslint-disable-next-line no-plusplus, no-param-reassign
            x.sortOrder--;
          }
        });
        itemToSet.sortOrder = movedItem.newIndex;
      } else {
        var itemToSet = propItems.find((x) => x.sortOrder === movedItem.oldIndex);
        propItems.forEach((x) => {
          if (x.sortOrder < movedItem.oldIndex && x.sortOrder >= movedItem.newIndex) {
            // eslint-disable-next-line no-plusplus, no-param-reassign
            x.sortOrder++;
          }
        });
        // eslint-disable-next-line no-plusplus, no-param-reassign
        itemToSet.sortOrder = movedItem.newIndex;
      }

      // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
      item.varients = propItems.sort((a, b) => (a.sortOrder - b.sortOrder));
    };

    const item = reactive({
      id: null,
      title: '',
      description: '',
      url: '',
      tags: ref([]),
      cost: 0,
      campaignType: null,
      causes: ref([]),
      // nonProfitSuggestions: ref([]),
      maxQuantity: 0,
      maxDonation: null,
      donationMultiplier: 1,
      impact: null,
      eventDate: null,
      startDate: null,
      endDate: null,
      isFeatured: false,
      isCausesSearchAllowed: false,
      location: '',
      waiverURL: '',
      hasOptions:false,
      confirmationEmail:'',
      reminderEmail:'',
      varients: reactive([{
          id:null,
          cost: null,
          title: '',
          sortOrder:0,
          stickerId:null,
          description:null,
          isActive:true,
          createdAt:null,
          updatedAt:null,
        },
      ]),
      quickCosts: [null, null, null, null],
    });

    // change campaign type
    const changeCampaignType = (value) => {
        item.campaignType = value;
    };
    // go to sticker page
    const goToStickerPage = () => {
      router.push('/manage-content/media/stickers');
    };

    async function getBullhornsAsTagsList() {
      const bullhornTags = await getBullhornsAsTags();
      bullhornTags.forEach((element) => {
        const data = { value: `${element.bullhorn}`, label: `${element.bullhorn}` };
        tagsList.value.push(data);
        // tagsArray is used to render the correct values for the new dropdown
        tagsArray.value.push(element.bullhorn);
      });
    }

    // eslint-disable-next-line no-unused-vars
    async function getTagsDropdownOptions(query) {
      if (query) {
        tagsLoading.value = true;
        setTimeout(() => {
          tagsLoading.value = false;
          // eslint-disable-next-line arrow-body-style
          tagsOptions.value = tagsList.value.filter((dropdownItem) => {
            return dropdownItem.label.toLowerCase().includes(query.toLowerCase());
          });
        }, 200);
      } else {
        tagsOptions.value = [];
      }
    }

    async function getCausesList(query) {
      const benevityCauses = await getcausesAsDropdown(query);
      benevityCauses.forEach((element) => {
        const data = {
          value: `${element.name} : ${element.id}`,
          label: `${element.name} : ${element.id}`,
        };
        causeList.value.push(data);
      });
    }

    // eslint-disable-next-line no-unused-vars
    async function getCausesDropdownOptions(query) {
      if (query) {
        causeLoading.value = true;
        const benevityCauses = await getcausesAsDropdown(query);
        setTimeout(() => {
          causeLoading.value = false;
          causeOptions.value = [];
          benevityCauses.forEach((element) => {
            const data = {
              value: `${element.name} : ${element.id}`,
              label: `${element.name} : ${element.id}`,
            };
            causeOptions.value.push(data);
            causeOptionsArray.value.push(`${element.name} : ${element.id}`);
          });
        }, 200);
      } else {
        causeOptions.value = [];
      }
    }

    // eslint-disable-next-line no-unused-vars
    async function saveItem(input) {

      console.log('input', input);
      const newItem = await updateSticker(input);
      console.log('newItem is ', newItem);
      router.push('/manage-content/media/stickers');
    }


    function submitForm() {
      item.cost = parseFloat(parseFloat(item.cost).toFixed(2));
      item.tags = item.tags?.join(' |=| ');
      item.causes = item.causes?.join(' |=| ');
      item.maxDonation = parseFloat(parseFloat(item.maxDonation).toFixed(2));
      item.campaignType = item.campaignType ? item.campaignType : null;
      // eslint-disable-next-line no-alert
      item.varients.pop();
      item.varients.forEach(x => {
        x.cost =  parseFloat(parseFloat(x.cost).toFixed(2));
      });
      if (item.varients.length > 0) {
        item.cost = null;
      }

      item.quickCosts = item.quickCosts.filter(cost => !isNaN(cost) && cost !== null);
      if (item.quickCosts.every(cost => cost === 0)) {
        item.quickCosts = null;
      }

      if (glowPickerRef?.value) {
        const { dateStart, dateEnd, timeStart, timeEnd, color, glow } = glowPickerRef.value
        if (glow) {
          item.glowStartDate =  joinDateAndTime(dateStart, timeStart);
          item.glowEndDate = joinDateAndTime(dateEnd, timeEnd);
          item.glowColor = color;
        } else {
          item.glowStartDate = null;
          item.glowEndDate = null;
          item.glowColor = null;
        }
      }

      saveItem(item);
    }


    function validateForm() {

      if (!item.url) {
          error.message = 'Please upload a Sticker before continuing.';
          return;
      }

      const validationError = validateFields();

      if (validationError) {
          error.message = validationError;
          return;
      }

      refForm.value.validate((valid) => {
          if (valid) return submitForm();
          return false;
    });
    }


    function validateFields() {
        if (glowPickerRef.value) {
          const { color, glow, dateStart, dateEnd, timeStart, timeEnd } = glowPickerRef.value;
          let message = ''
          if (glow) {
            if (!color) {
              message = 'Color missing from glow.';
            } else if (!dateStart) {
              message = 'Start date missing from glow.';
            } else if (!dateEnd) {
              message = 'End date missing from glow.';
            } else if (!validateTime(timeStart)) {
              message = 'Start time missing or incorrectly formatted.';
            } else if (!validateTime(timeEnd)) {
              message = 'End time missing or incorrectly formatted.';
            }
          }
          if (message.length) return message;
        }

        if (!item.title || item.title === " " || item.title.length === 0) {
            return 'Title is required.';
        }
        if (!item.tags || item.tags.length === 0) {
            return 'Tags are required.';
        }

        if (item.campaignType && (!item.startDate || !item.endDate)) {
            return 'Please enter start and end date.';
        }

        if (item.campaignType === 'Ticket' && item.maxQuantity === 0) {
          return 'Please enter Max Quantity';
        }
        return null;
    }

    async function handleStickerUpload(payload) {
      await handleUploadRequest(payload);
      if (uploadStatus.status === 'ok') {
        item.url = uploadStatus.resourceUrl;
        if (item.url) {
          error.message = '';
        }
      }
    }

    function handleSuccess() {
      // console.log('succc');
    }

    function handleError() {
      // console.log('error');
    }

    const addPrice = (sortOrder) => {
      var total = item.varients.length;
      if(total == (sortOrder+1)){
        const newPrice = {
          id:null,
          cost: null,
          title: '',
          sortOrder: total,
          stickerId:null,
          description:null,
          isActive:true,
          createdAt:null,
          updatedAt:null,
        };
        item.varients.push(newPrice);
      }
    }

    const removePrice = () => {
      item.varients.splice(item.varients.length - 2, 1); // Remove the second-to-last element
      item.varients[item.varients.length - 1].sortOrder--;
    }

    onMounted(async () => {
      await getBullhornsAsTagsList();
      await getCausesList('');
    });

    return {
      // data
      refForm,
      rules: {
        url: requiredRule('url'),
        title: requiredRule('title'),
        description: requiredRule('description'),
        tags: requiredRule('tags'),
        startDate: requiredRule('startDate'),
        endDate: requiredRule('endDate'),
      },
      item,
      // methods
      validateForm,
      beforeUpload,
      handleStickerUpload,
      handleSuccess,
      handleError,
      tagsList,
      tagsOptions,
      tagsLoading,
      causeList,
      causeOptions,
      causeLoading,
      getBullhornsAsTagsList,
      getTagsDropdownOptions,
      getCausesList,
      getCausesDropdownOptions,
      getBenevityCauseDetails,
      error,
      tagsArray,
      causeOptionsArray,
      stickerTypes,
      stickerType,
      changeCampaignType,
      goToStickerPage,
      addPrice,
      removePrice,
      changeEvent,
      allowNamingPrices,
      glowPickerRef
    };
  },
});
</script>
