import {
  canIntent,
  canIntentForOrg,
  createPaymentIntent,
  createPaymentIntentForOrg,
  canIntentForSticker,
  createStickerPurchaseIntent,
  validateIntentForSticker,
  validateIntentForStickerPublic,
  createInitiateWalletIntent,
  curatedBenevityMarketPlaceUpgrade,
  createAnonymousStickerTransaction,
  createStickerTicketEmailConfirmation
} from '@/graphs/gql';
import { canIntentForWalletPricing, getCouponByPromoCode } from '@/graphs/payment';
import globalShared from '@/shared';

import { StickerTicketEmailDescriptionType } from 'src/data/types';

export default function shared(): { [key: string]: any } {
  const {
    ref,
    route,
    router,
    makeGraphRequest,
  } = globalShared();

  const loading = ref(false);

  async function requestCanIntent(userId: string): Promise<any> {
    // console.log(canIntent);
    const response = await makeGraphRequest(
      'query', canIntent, { userId }, null,
    );
    return response.data.canIntent;
  }

  async function requestCanIntentForOrg(userId: string, orgId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', canIntentForOrg, { userId, orgId }, null,
    );
    return response.data.canIntentForOrg;
  }

  async function requestCreatePaymentIntent(
    userId: string,
    paymentInput: { [key: string]: any },
  ): Promise<any> {
    // console.log('0-000E;');
    // console.log(userId);
    // console.log(paymentInput);
    const response = await makeGraphRequest(
      'mutation',
      createPaymentIntent,
      { userId, item: paymentInput },
      null,
    );
    return response.data.createPaymentIntent;
  }

  async function requestCreatePaymentIntentForOrg(
    userId: string,
    orgId: string,
    paymentInput: { [key: string]: any },
  ): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      createPaymentIntentForOrg,
      { userId, orgId, item: paymentInput },
      null,
    );
    return response.data.createPaymentIntentForOrg;
  }

  async function requestCanIntentForSticker(stickerId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', canIntentForSticker, { stickerId }, null,
    );
    return response.data.canIntentForSticker;
  }

  async function requestValidateIntentForSticker(
    stickerId: string, entity: string, entityId: string,
  ): Promise<any> {
    const response = await makeGraphRequest(
      'query', validateIntentForSticker, { stickerId, entity, entityId }, null,
    );
    return response.data.validateIntentForSticker;
  }

  async function requestValidateIntentForStickerPublic(
    stickerId: string, entity: string, entityId: string,
  ): Promise<any> {
    const response = await makeGraphRequest(
      'query', validateIntentForStickerPublic, { stickerId, entity, entityId }, null,
    );
    return response.data.validateIntentForStickerPublic;
  }

  async function requestCreateStickerPurchaseIntent(
    input: { [key: string]: any },
  ): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      createStickerPurchaseIntent,
      { input },
      null,
    );
    return response.data.createStickerPurchaseIntent;
  }

  async function requestCreateInitiateWalletIntent(
    input: { [key: string]: any },
  ): Promise<any> {
    const response = await makeGraphRequest(
      'mutation',
      createInitiateWalletIntent,
      { input },
      null,
    );
    return response.data.createInitiateWalletIntent;
  }

  async function requestCanIntentForWalletPricing(walletPricingId: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', canIntentForWalletPricing, { walletPricingId }, null,
    );
    return response.data.canIntentForWalletPricing;
  }

  async function requestCuratedMarketPlaceUpgrade(): Promise<any> {
    const response = await makeGraphRequest(
      'query', curatedBenevityMarketPlaceUpgrade, {}, null,
    );
    return response.data.curatedBenevityMarketPlaceUpgrade;
  }

  async function requestCouponByPromoCode(promotionCode: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', getCouponByPromoCode, { promotionCode }, null,
    );
    if (response.data) {
      return response.data.getCouponByPromoCode;
    }
    return null;
  }

  async function requestCreateAnonymousStickerTransaction(intentId: string, clientSecret: string, email: string, phone: string): Promise<any> {
    const response = await makeGraphRequest(
      'mutation', createAnonymousStickerTransaction, { intentId, clientSecret, email, phone }, null,
    );
    return response.data.createAnonymousStickerTransaction;
  }

  async function requestStickerTicketEmailConfirmation(descriptions: Array<StickerTicketEmailDescriptionType>, email: string, name: string, intentId: string, clientSecret: string, userId: string, stickerId: string,) {
    const response = await makeGraphRequest(
      'mutation',
      createStickerTicketEmailConfirmation,
      { descriptions, email, name, intentId, clientSecret, userId, stickerId}
    );
    return response.data.createStickerTicketEmailConfirmation;
  }

  return {
    loading,
    route,
    router,
    requestCanIntent,
    requestCanIntentForOrg,
    requestCreatePaymentIntent,
    requestCreatePaymentIntentForOrg,
    requestCanIntentForSticker,
    requestValidateIntentForSticker,
    requestValidateIntentForStickerPublic,
    requestCreateStickerPurchaseIntent,
    requestCreateInitiateWalletIntent,
    requestCanIntentForWalletPricing,
    requestCuratedMarketPlaceUpgrade,
    requestCouponByPromoCode,
    requestCreateAnonymousStickerTransaction,
    requestStickerTicketEmailConfirmation,
  };
}
