import globalShared from '@/shared';
import {
  findTodos,
  mutateTodo,
  findTodo,
  findWorkflows,
  mutateWorkflow,
  findWorkflow,
} from '@/graphs/gql';

export default function commonShared(): { [key: string]: any } {
  const {
    ref,
    route,
    getAuthToken,
    makeGraphRequest,
    dateAndTime,
    removeItemGetConfirm,
  } = globalShared();

  async function doFindTodos(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findTodos, {}, getAuthToken(),
    );
    return response.data.findTodos;
  }

  async function doMutateTodo(input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation', mutateTodo, { input }, getAuthToken(),
    );
    return response.data.mutateTodo;
  }

  async function doFindTodo(id: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', findTodo, { id }, getAuthToken(),
    );
    return response.data.findTodo;
  }

  async function doFindWorkflows(): Promise<any> {
    const response = await makeGraphRequest(
      'query', findWorkflows, {}, getAuthToken(),
    );
    return response.data.findWorkflows;
  }

  async function doMutateWorkflow(input: any): Promise<any> {
    const response = await makeGraphRequest(
      'mutation', mutateWorkflow, { input }, getAuthToken(),
    );
    return response.data.mutateWorkflow;
  }

  async function doFindWorkflow(id: string): Promise<any> {
    const response = await makeGraphRequest(
      'query', findWorkflow, { id }, getAuthToken(),
    );
    return response.data.findWorkflow;
  }

  return {
    route,
    loading: ref(false),
    dialog: ref(false),
    doFindTodos,
    doMutateTodo,
    doFindTodo,
    doFindWorkflows,
    doMutateWorkflow,
    doFindWorkflow,
    dateAndTime,
    removeItemGetConfirm,
  };
}
